/**
 * @fileoverview gRPC-Web generated client stub for com.kipp.miraibarai.grpc.ops.v1
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v3.20.3
// source: miraibaraiops/operator_api.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as miraibaraiops_operator_api_pb from '../miraibaraiops/operator_api_pb'; // proto import: "miraibaraiops/operator_api.proto"


export class OperatorAPIClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorLogin = new grpcWeb.MethodDescriptor(
    '/com.kipp.miraibarai.grpc.ops.v1.OperatorAPI/Login',
    grpcWeb.MethodType.UNARY,
    miraibaraiops_operator_api_pb.LoginRequest,
    miraibaraiops_operator_api_pb.LoginResponse,
    (request: miraibaraiops_operator_api_pb.LoginRequest) => {
      return request.serializeBinary();
    },
    miraibaraiops_operator_api_pb.LoginResponse.deserializeBinary
  );

  login(
    request: miraibaraiops_operator_api_pb.LoginRequest,
    metadata?: grpcWeb.Metadata | null): Promise<miraibaraiops_operator_api_pb.LoginResponse>;

  login(
    request: miraibaraiops_operator_api_pb.LoginRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: miraibaraiops_operator_api_pb.LoginResponse) => void): grpcWeb.ClientReadableStream<miraibaraiops_operator_api_pb.LoginResponse>;

  login(
    request: miraibaraiops_operator_api_pb.LoginRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: miraibaraiops_operator_api_pb.LoginResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.kipp.miraibarai.grpc.ops.v1.OperatorAPI/Login',
        request,
        metadata || {},
        this.methodDescriptorLogin,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.kipp.miraibarai.grpc.ops.v1.OperatorAPI/Login',
    request,
    metadata || {},
    this.methodDescriptorLogin);
  }

  methodDescriptorChangePassword = new grpcWeb.MethodDescriptor(
    '/com.kipp.miraibarai.grpc.ops.v1.OperatorAPI/ChangePassword',
    grpcWeb.MethodType.UNARY,
    miraibaraiops_operator_api_pb.ChangePasswordRequest,
    miraibaraiops_operator_api_pb.ChangePasswordResponse,
    (request: miraibaraiops_operator_api_pb.ChangePasswordRequest) => {
      return request.serializeBinary();
    },
    miraibaraiops_operator_api_pb.ChangePasswordResponse.deserializeBinary
  );

  changePassword(
    request: miraibaraiops_operator_api_pb.ChangePasswordRequest,
    metadata?: grpcWeb.Metadata | null): Promise<miraibaraiops_operator_api_pb.ChangePasswordResponse>;

  changePassword(
    request: miraibaraiops_operator_api_pb.ChangePasswordRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: miraibaraiops_operator_api_pb.ChangePasswordResponse) => void): grpcWeb.ClientReadableStream<miraibaraiops_operator_api_pb.ChangePasswordResponse>;

  changePassword(
    request: miraibaraiops_operator_api_pb.ChangePasswordRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: miraibaraiops_operator_api_pb.ChangePasswordResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.kipp.miraibarai.grpc.ops.v1.OperatorAPI/ChangePassword',
        request,
        metadata || {},
        this.methodDescriptorChangePassword,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.kipp.miraibarai.grpc.ops.v1.OperatorAPI/ChangePassword',
    request,
    metadata || {},
    this.methodDescriptorChangePassword);
  }

  methodDescriptorChangeOtherOperatorPassword = new grpcWeb.MethodDescriptor(
    '/com.kipp.miraibarai.grpc.ops.v1.OperatorAPI/ChangeOtherOperatorPassword',
    grpcWeb.MethodType.UNARY,
    miraibaraiops_operator_api_pb.ChangeOtherOperatorPasswordRequest,
    miraibaraiops_operator_api_pb.ChangeOtherOperatorPasswordResponse,
    (request: miraibaraiops_operator_api_pb.ChangeOtherOperatorPasswordRequest) => {
      return request.serializeBinary();
    },
    miraibaraiops_operator_api_pb.ChangeOtherOperatorPasswordResponse.deserializeBinary
  );

  changeOtherOperatorPassword(
    request: miraibaraiops_operator_api_pb.ChangeOtherOperatorPasswordRequest,
    metadata?: grpcWeb.Metadata | null): Promise<miraibaraiops_operator_api_pb.ChangeOtherOperatorPasswordResponse>;

  changeOtherOperatorPassword(
    request: miraibaraiops_operator_api_pb.ChangeOtherOperatorPasswordRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: miraibaraiops_operator_api_pb.ChangeOtherOperatorPasswordResponse) => void): grpcWeb.ClientReadableStream<miraibaraiops_operator_api_pb.ChangeOtherOperatorPasswordResponse>;

  changeOtherOperatorPassword(
    request: miraibaraiops_operator_api_pb.ChangeOtherOperatorPasswordRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: miraibaraiops_operator_api_pb.ChangeOtherOperatorPasswordResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.kipp.miraibarai.grpc.ops.v1.OperatorAPI/ChangeOtherOperatorPassword',
        request,
        metadata || {},
        this.methodDescriptorChangeOtherOperatorPassword,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.kipp.miraibarai.grpc.ops.v1.OperatorAPI/ChangeOtherOperatorPassword',
    request,
    metadata || {},
    this.methodDescriptorChangeOtherOperatorPassword);
  }

  methodDescriptorAddOperator = new grpcWeb.MethodDescriptor(
    '/com.kipp.miraibarai.grpc.ops.v1.OperatorAPI/AddOperator',
    grpcWeb.MethodType.UNARY,
    miraibaraiops_operator_api_pb.AddOperatorRequest,
    miraibaraiops_operator_api_pb.AddOperatorResponse,
    (request: miraibaraiops_operator_api_pb.AddOperatorRequest) => {
      return request.serializeBinary();
    },
    miraibaraiops_operator_api_pb.AddOperatorResponse.deserializeBinary
  );

  addOperator(
    request: miraibaraiops_operator_api_pb.AddOperatorRequest,
    metadata?: grpcWeb.Metadata | null): Promise<miraibaraiops_operator_api_pb.AddOperatorResponse>;

  addOperator(
    request: miraibaraiops_operator_api_pb.AddOperatorRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: miraibaraiops_operator_api_pb.AddOperatorResponse) => void): grpcWeb.ClientReadableStream<miraibaraiops_operator_api_pb.AddOperatorResponse>;

  addOperator(
    request: miraibaraiops_operator_api_pb.AddOperatorRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: miraibaraiops_operator_api_pb.AddOperatorResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.kipp.miraibarai.grpc.ops.v1.OperatorAPI/AddOperator',
        request,
        metadata || {},
        this.methodDescriptorAddOperator,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.kipp.miraibarai.grpc.ops.v1.OperatorAPI/AddOperator',
    request,
    metadata || {},
    this.methodDescriptorAddOperator);
  }

  methodDescriptorSuspendOperator = new grpcWeb.MethodDescriptor(
    '/com.kipp.miraibarai.grpc.ops.v1.OperatorAPI/SuspendOperator',
    grpcWeb.MethodType.UNARY,
    miraibaraiops_operator_api_pb.SuspendOperatorRequest,
    miraibaraiops_operator_api_pb.SuspendOperatorResponse,
    (request: miraibaraiops_operator_api_pb.SuspendOperatorRequest) => {
      return request.serializeBinary();
    },
    miraibaraiops_operator_api_pb.SuspendOperatorResponse.deserializeBinary
  );

  suspendOperator(
    request: miraibaraiops_operator_api_pb.SuspendOperatorRequest,
    metadata?: grpcWeb.Metadata | null): Promise<miraibaraiops_operator_api_pb.SuspendOperatorResponse>;

  suspendOperator(
    request: miraibaraiops_operator_api_pb.SuspendOperatorRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: miraibaraiops_operator_api_pb.SuspendOperatorResponse) => void): grpcWeb.ClientReadableStream<miraibaraiops_operator_api_pb.SuspendOperatorResponse>;

  suspendOperator(
    request: miraibaraiops_operator_api_pb.SuspendOperatorRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: miraibaraiops_operator_api_pb.SuspendOperatorResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.kipp.miraibarai.grpc.ops.v1.OperatorAPI/SuspendOperator',
        request,
        metadata || {},
        this.methodDescriptorSuspendOperator,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.kipp.miraibarai.grpc.ops.v1.OperatorAPI/SuspendOperator',
    request,
    metadata || {},
    this.methodDescriptorSuspendOperator);
  }

  methodDescriptorResumeOperator = new grpcWeb.MethodDescriptor(
    '/com.kipp.miraibarai.grpc.ops.v1.OperatorAPI/ResumeOperator',
    grpcWeb.MethodType.UNARY,
    miraibaraiops_operator_api_pb.ResumeOperatorRequest,
    miraibaraiops_operator_api_pb.ResumeOperatorResponse,
    (request: miraibaraiops_operator_api_pb.ResumeOperatorRequest) => {
      return request.serializeBinary();
    },
    miraibaraiops_operator_api_pb.ResumeOperatorResponse.deserializeBinary
  );

  resumeOperator(
    request: miraibaraiops_operator_api_pb.ResumeOperatorRequest,
    metadata?: grpcWeb.Metadata | null): Promise<miraibaraiops_operator_api_pb.ResumeOperatorResponse>;

  resumeOperator(
    request: miraibaraiops_operator_api_pb.ResumeOperatorRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: miraibaraiops_operator_api_pb.ResumeOperatorResponse) => void): grpcWeb.ClientReadableStream<miraibaraiops_operator_api_pb.ResumeOperatorResponse>;

  resumeOperator(
    request: miraibaraiops_operator_api_pb.ResumeOperatorRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: miraibaraiops_operator_api_pb.ResumeOperatorResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.kipp.miraibarai.grpc.ops.v1.OperatorAPI/ResumeOperator',
        request,
        metadata || {},
        this.methodDescriptorResumeOperator,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.kipp.miraibarai.grpc.ops.v1.OperatorAPI/ResumeOperator',
    request,
    metadata || {},
    this.methodDescriptorResumeOperator);
  }

  methodDescriptorAssignRole = new grpcWeb.MethodDescriptor(
    '/com.kipp.miraibarai.grpc.ops.v1.OperatorAPI/AssignRole',
    grpcWeb.MethodType.UNARY,
    miraibaraiops_operator_api_pb.AssignRoleRequest,
    miraibaraiops_operator_api_pb.AssignRoleResponse,
    (request: miraibaraiops_operator_api_pb.AssignRoleRequest) => {
      return request.serializeBinary();
    },
    miraibaraiops_operator_api_pb.AssignRoleResponse.deserializeBinary
  );

  assignRole(
    request: miraibaraiops_operator_api_pb.AssignRoleRequest,
    metadata?: grpcWeb.Metadata | null): Promise<miraibaraiops_operator_api_pb.AssignRoleResponse>;

  assignRole(
    request: miraibaraiops_operator_api_pb.AssignRoleRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: miraibaraiops_operator_api_pb.AssignRoleResponse) => void): grpcWeb.ClientReadableStream<miraibaraiops_operator_api_pb.AssignRoleResponse>;

  assignRole(
    request: miraibaraiops_operator_api_pb.AssignRoleRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: miraibaraiops_operator_api_pb.AssignRoleResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.kipp.miraibarai.grpc.ops.v1.OperatorAPI/AssignRole',
        request,
        metadata || {},
        this.methodDescriptorAssignRole,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.kipp.miraibarai.grpc.ops.v1.OperatorAPI/AssignRole',
    request,
    metadata || {},
    this.methodDescriptorAssignRole);
  }

  methodDescriptorGetCurrentOperationRoles = new grpcWeb.MethodDescriptor(
    '/com.kipp.miraibarai.grpc.ops.v1.OperatorAPI/GetCurrentOperationRoles',
    grpcWeb.MethodType.UNARY,
    miraibaraiops_operator_api_pb.GetCurrentOperationRolesRequest,
    miraibaraiops_operator_api_pb.GetCurrentOperationRolesResponse,
    (request: miraibaraiops_operator_api_pb.GetCurrentOperationRolesRequest) => {
      return request.serializeBinary();
    },
    miraibaraiops_operator_api_pb.GetCurrentOperationRolesResponse.deserializeBinary
  );

  getCurrentOperationRoles(
    request: miraibaraiops_operator_api_pb.GetCurrentOperationRolesRequest,
    metadata?: grpcWeb.Metadata | null): Promise<miraibaraiops_operator_api_pb.GetCurrentOperationRolesResponse>;

  getCurrentOperationRoles(
    request: miraibaraiops_operator_api_pb.GetCurrentOperationRolesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: miraibaraiops_operator_api_pb.GetCurrentOperationRolesResponse) => void): grpcWeb.ClientReadableStream<miraibaraiops_operator_api_pb.GetCurrentOperationRolesResponse>;

  getCurrentOperationRoles(
    request: miraibaraiops_operator_api_pb.GetCurrentOperationRolesRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: miraibaraiops_operator_api_pb.GetCurrentOperationRolesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.kipp.miraibarai.grpc.ops.v1.OperatorAPI/GetCurrentOperationRoles',
        request,
        metadata || {},
        this.methodDescriptorGetCurrentOperationRoles,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.kipp.miraibarai.grpc.ops.v1.OperatorAPI/GetCurrentOperationRoles',
    request,
    metadata || {},
    this.methodDescriptorGetCurrentOperationRoles);
  }

}

