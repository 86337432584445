import { CheckCircle, KeyboardArrowDown, KeyboardArrowRight } from '@mui/icons-material'
import { Button, Chip, Collapse, TableCell, TableRow } from '@mui/material'
import React, { useState } from 'react'

import AccessControl from 'shared/components/AccessControl'
import PaginatableTable from 'shared/components/PaginatableTable'
import PaginatableTableHeader from 'shared/components/PaginatableTableHeader'
import usePagination from 'shared/hooks/usePagination'
import useToggle from 'shared/hooks/useToggle'

import CreateNewPostPayScreeningRuleSet from '@/components/CreateNewPostPayScreeningRuleSet'
import { useListPostPayScreeningRuleSetsQuery } from '@/hooks/graphql'
import { assumedRoles } from '@/hooks/graphql/queries/list_post_pay_screening_rule_sets.gql'

export const allowedRoles = assumedRoles

const PagePostPayScreeningRuleSets: React.VFC = () => {
  const { skip, paginate, perPage, currentPage } = usePagination()
  const { data, refetch } = useListPostPayScreeningRuleSetsQuery({ skip })
  const [showNewVersionDialog, { enable: openDialog, disable: hideDialog }] = useToggle(false)
  const [openedVersion, setOpenedVersions] = useState<string[]>([])

  const header = (
    <PaginatableTableHeader
      title="検査ルールセットの設定履歴"
      actions={
        <Button variant="contained" color="primary" onClick={openDialog}>
          新しいバージョンを作成する
        </Button>
      }
    />
  )

  return (
    <AccessControl allowedRoles={allowedRoles}>
      <PaginatableTable
        page={currentPage}
        perPage={perPage}
        cols={['', 'バージョン', '設定']}
        totalCount={data?.totalCount}
        onPaginate={paginate}
        header={header}
      >
        {data &&
          data.result.map((ruleSet, index) => (
            <TableRow selected={index === 0} hover key={`${ruleSet.ruleSetVersion}-${currentPage}-${index}`}>
              <TableCell>{index === 0 && <Chip label="最新" icon={<CheckCircle />} color="primary" />}</TableCell>
              <TableCell style={{ width: '120px' }}>{ruleSet.ruleSetVersion}</TableCell>
              <TableCell>
                {index > 0 && !openedVersion.includes(ruleSet.ruleSetVersion) && (
                  <Button
                    startIcon={<KeyboardArrowRight />}
                    size="small"
                    color="info"
                    onClick={() => {
                      setOpenedVersions([...openedVersion, ruleSet.ruleSetVersion])
                    }}
                  >
                    設定を見る
                  </Button>
                )}
                {index > 0 && openedVersion.includes(ruleSet.ruleSetVersion) && (
                  <Button
                    startIcon={<KeyboardArrowDown />}
                    size="small"
                    color="info"
                    onClick={() => {
                      setOpenedVersions(openedVersion.filter((v) => v !== ruleSet.ruleSetVersion))
                    }}
                  >
                    閉じる
                  </Button>
                )}
                <Collapse in={index === 0 || openedVersion.includes(ruleSet.ruleSetVersion)}>
                  <pre>{ruleSet.setting.protoString}</pre>
                </Collapse>
              </TableCell>
            </TableRow>
          ))}
      </PaginatableTable>
      {data && showNewVersionDialog && (
        <CreateNewPostPayScreeningRuleSet
          oldSetting={data.result[0].setting.protoString}
          onSuccess={() => {
            hideDialog()
            refetch()
          }}
          onCancel={hideDialog}
        />
      )}
    </AccessControl>
  )
}

export default PagePostPayScreeningRuleSets
