import { gql } from '@apollo/client'

import { OperationRole } from 'shared/grpc/miraibaraiops/ops_common_pb'

export const query = gql`
  query ListPostPayScreeningRuleSets(
    $limit: Int!
    $skip: Int!
    $desc: Boolean = true
    $sort_field: String = "ruleSetVersion"
  ) {
    totalCount: postPayScreeningRuleSetsCount
    result: postPayScreeningRuleSets(
      limit: $limit
      skip: $skip
      desc: $desc
      sort_field: $sort_field
    ) {
      ruleSetVersion
      setting {
        protoString
      }
    }
  }
`

export const assumedRoles = [OperationRole.OPERATION_ROLE_SERVICE_ADMINISTRATOR]
