// source: miraibaraiops/operator_api.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return Function('return this')(); }).call(null);

var miraibaraiops_operator_pb = require('../miraibaraiops/operator_pb.js');
goog.object.extend(proto, miraibaraiops_operator_pb);
var miraibaraiops_ops_common_pb = require('../miraibaraiops/ops_common_pb.js');
goog.object.extend(proto, miraibaraiops_ops_common_pb);
var scalapb_scalapb_pb = require('../scalapb/scalapb_pb.js');
goog.object.extend(proto, scalapb_scalapb_pb);
goog.exportSymbol('proto.com.kipp.miraibarai.grpc.ops.v1.AddOperatorRequest', null, global);
goog.exportSymbol('proto.com.kipp.miraibarai.grpc.ops.v1.AddOperatorResponse', null, global);
goog.exportSymbol('proto.com.kipp.miraibarai.grpc.ops.v1.AssignRoleRequest', null, global);
goog.exportSymbol('proto.com.kipp.miraibarai.grpc.ops.v1.AssignRoleResponse', null, global);
goog.exportSymbol('proto.com.kipp.miraibarai.grpc.ops.v1.ChangeOtherOperatorPasswordRequest', null, global);
goog.exportSymbol('proto.com.kipp.miraibarai.grpc.ops.v1.ChangeOtherOperatorPasswordResponse', null, global);
goog.exportSymbol('proto.com.kipp.miraibarai.grpc.ops.v1.ChangePasswordRequest', null, global);
goog.exportSymbol('proto.com.kipp.miraibarai.grpc.ops.v1.ChangePasswordResponse', null, global);
goog.exportSymbol('proto.com.kipp.miraibarai.grpc.ops.v1.GetCurrentOperationRolesRequest', null, global);
goog.exportSymbol('proto.com.kipp.miraibarai.grpc.ops.v1.GetCurrentOperationRolesResponse', null, global);
goog.exportSymbol('proto.com.kipp.miraibarai.grpc.ops.v1.LoginRequest', null, global);
goog.exportSymbol('proto.com.kipp.miraibarai.grpc.ops.v1.LoginResponse', null, global);
goog.exportSymbol('proto.com.kipp.miraibarai.grpc.ops.v1.ResumeOperatorRequest', null, global);
goog.exportSymbol('proto.com.kipp.miraibarai.grpc.ops.v1.ResumeOperatorResponse', null, global);
goog.exportSymbol('proto.com.kipp.miraibarai.grpc.ops.v1.SuspendOperatorRequest', null, global);
goog.exportSymbol('proto.com.kipp.miraibarai.grpc.ops.v1.SuspendOperatorResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.kipp.miraibarai.grpc.ops.v1.LoginRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.kipp.miraibarai.grpc.ops.v1.LoginRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.kipp.miraibarai.grpc.ops.v1.LoginRequest.displayName = 'proto.com.kipp.miraibarai.grpc.ops.v1.LoginRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.kipp.miraibarai.grpc.ops.v1.LoginResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.kipp.miraibarai.grpc.ops.v1.LoginResponse.repeatedFields_, null);
};
goog.inherits(proto.com.kipp.miraibarai.grpc.ops.v1.LoginResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.kipp.miraibarai.grpc.ops.v1.LoginResponse.displayName = 'proto.com.kipp.miraibarai.grpc.ops.v1.LoginResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.kipp.miraibarai.grpc.ops.v1.ChangePasswordRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.kipp.miraibarai.grpc.ops.v1.ChangePasswordRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.kipp.miraibarai.grpc.ops.v1.ChangePasswordRequest.displayName = 'proto.com.kipp.miraibarai.grpc.ops.v1.ChangePasswordRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.kipp.miraibarai.grpc.ops.v1.ChangePasswordResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.kipp.miraibarai.grpc.ops.v1.ChangePasswordResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.kipp.miraibarai.grpc.ops.v1.ChangePasswordResponse.displayName = 'proto.com.kipp.miraibarai.grpc.ops.v1.ChangePasswordResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.kipp.miraibarai.grpc.ops.v1.ChangeOtherOperatorPasswordRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.kipp.miraibarai.grpc.ops.v1.ChangeOtherOperatorPasswordRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.kipp.miraibarai.grpc.ops.v1.ChangeOtherOperatorPasswordRequest.displayName = 'proto.com.kipp.miraibarai.grpc.ops.v1.ChangeOtherOperatorPasswordRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.kipp.miraibarai.grpc.ops.v1.ChangeOtherOperatorPasswordResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.kipp.miraibarai.grpc.ops.v1.ChangeOtherOperatorPasswordResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.kipp.miraibarai.grpc.ops.v1.ChangeOtherOperatorPasswordResponse.displayName = 'proto.com.kipp.miraibarai.grpc.ops.v1.ChangeOtherOperatorPasswordResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.kipp.miraibarai.grpc.ops.v1.AddOperatorRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.kipp.miraibarai.grpc.ops.v1.AddOperatorRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.kipp.miraibarai.grpc.ops.v1.AddOperatorRequest.displayName = 'proto.com.kipp.miraibarai.grpc.ops.v1.AddOperatorRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.kipp.miraibarai.grpc.ops.v1.AddOperatorResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.kipp.miraibarai.grpc.ops.v1.AddOperatorResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.kipp.miraibarai.grpc.ops.v1.AddOperatorResponse.displayName = 'proto.com.kipp.miraibarai.grpc.ops.v1.AddOperatorResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.kipp.miraibarai.grpc.ops.v1.SuspendOperatorRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.kipp.miraibarai.grpc.ops.v1.SuspendOperatorRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.kipp.miraibarai.grpc.ops.v1.SuspendOperatorRequest.displayName = 'proto.com.kipp.miraibarai.grpc.ops.v1.SuspendOperatorRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.kipp.miraibarai.grpc.ops.v1.SuspendOperatorResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.kipp.miraibarai.grpc.ops.v1.SuspendOperatorResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.kipp.miraibarai.grpc.ops.v1.SuspendOperatorResponse.displayName = 'proto.com.kipp.miraibarai.grpc.ops.v1.SuspendOperatorResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.kipp.miraibarai.grpc.ops.v1.ResumeOperatorRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.kipp.miraibarai.grpc.ops.v1.ResumeOperatorRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.kipp.miraibarai.grpc.ops.v1.ResumeOperatorRequest.displayName = 'proto.com.kipp.miraibarai.grpc.ops.v1.ResumeOperatorRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.kipp.miraibarai.grpc.ops.v1.ResumeOperatorResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.kipp.miraibarai.grpc.ops.v1.ResumeOperatorResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.kipp.miraibarai.grpc.ops.v1.ResumeOperatorResponse.displayName = 'proto.com.kipp.miraibarai.grpc.ops.v1.ResumeOperatorResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.kipp.miraibarai.grpc.ops.v1.AssignRoleRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.kipp.miraibarai.grpc.ops.v1.AssignRoleRequest.repeatedFields_, null);
};
goog.inherits(proto.com.kipp.miraibarai.grpc.ops.v1.AssignRoleRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.kipp.miraibarai.grpc.ops.v1.AssignRoleRequest.displayName = 'proto.com.kipp.miraibarai.grpc.ops.v1.AssignRoleRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.kipp.miraibarai.grpc.ops.v1.AssignRoleResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.kipp.miraibarai.grpc.ops.v1.AssignRoleResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.kipp.miraibarai.grpc.ops.v1.AssignRoleResponse.displayName = 'proto.com.kipp.miraibarai.grpc.ops.v1.AssignRoleResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.kipp.miraibarai.grpc.ops.v1.GetCurrentOperationRolesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.kipp.miraibarai.grpc.ops.v1.GetCurrentOperationRolesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.kipp.miraibarai.grpc.ops.v1.GetCurrentOperationRolesRequest.displayName = 'proto.com.kipp.miraibarai.grpc.ops.v1.GetCurrentOperationRolesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.kipp.miraibarai.grpc.ops.v1.GetCurrentOperationRolesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.kipp.miraibarai.grpc.ops.v1.GetCurrentOperationRolesResponse.repeatedFields_, null);
};
goog.inherits(proto.com.kipp.miraibarai.grpc.ops.v1.GetCurrentOperationRolesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.kipp.miraibarai.grpc.ops.v1.GetCurrentOperationRolesResponse.displayName = 'proto.com.kipp.miraibarai.grpc.ops.v1.GetCurrentOperationRolesResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.LoginRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.kipp.miraibarai.grpc.ops.v1.LoginRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.kipp.miraibarai.grpc.ops.v1.LoginRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.kipp.miraibarai.grpc.ops.v1.LoginRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    loginId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    password: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.kipp.miraibarai.grpc.ops.v1.LoginRequest}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.LoginRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.kipp.miraibarai.grpc.ops.v1.LoginRequest;
  return proto.com.kipp.miraibarai.grpc.ops.v1.LoginRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.kipp.miraibarai.grpc.ops.v1.LoginRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.kipp.miraibarai.grpc.ops.v1.LoginRequest}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.LoginRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLoginId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.LoginRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.kipp.miraibarai.grpc.ops.v1.LoginRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.kipp.miraibarai.grpc.ops.v1.LoginRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.kipp.miraibarai.grpc.ops.v1.LoginRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLoginId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string login_id = 1;
 * @return {string}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.LoginRequest.prototype.getLoginId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.kipp.miraibarai.grpc.ops.v1.LoginRequest} returns this
 */
proto.com.kipp.miraibarai.grpc.ops.v1.LoginRequest.prototype.setLoginId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string password = 2;
 * @return {string}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.LoginRequest.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.kipp.miraibarai.grpc.ops.v1.LoginRequest} returns this
 */
proto.com.kipp.miraibarai.grpc.ops.v1.LoginRequest.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.kipp.miraibarai.grpc.ops.v1.LoginResponse.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.LoginResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.kipp.miraibarai.grpc.ops.v1.LoginResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.kipp.miraibarai.grpc.ops.v1.LoginResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.kipp.miraibarai.grpc.ops.v1.LoginResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && miraibaraiops_ops_common_pb.ErrorMessage.toObject(includeInstance, f),
    sessionToken: jspb.Message.getFieldWithDefault(msg, 2, ""),
    operatorName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    operatorId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    rolesList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    lastPasswordUpdatedMs: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.kipp.miraibarai.grpc.ops.v1.LoginResponse}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.LoginResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.kipp.miraibarai.grpc.ops.v1.LoginResponse;
  return proto.com.kipp.miraibarai.grpc.ops.v1.LoginResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.kipp.miraibarai.grpc.ops.v1.LoginResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.kipp.miraibarai.grpc.ops.v1.LoginResponse}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.LoginResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new miraibaraiops_ops_common_pb.ErrorMessage;
      reader.readMessage(value,miraibaraiops_ops_common_pb.ErrorMessage.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessionToken(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setOperatorName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setOperatorId(value);
      break;
    case 5:
      var values = /** @type {!Array<!proto.com.kipp.miraibarai.grpc.ops.v1.OperationRole>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addRoles(values[i]);
      }
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setLastPasswordUpdatedMs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.LoginResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.kipp.miraibarai.grpc.ops.v1.LoginResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.kipp.miraibarai.grpc.ops.v1.LoginResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.kipp.miraibarai.grpc.ops.v1.LoginResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      miraibaraiops_ops_common_pb.ErrorMessage.serializeBinaryToWriter
    );
  }
  f = message.getSessionToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOperatorName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOperatorId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getRolesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      5,
      f
    );
  }
  f = message.getLastPasswordUpdatedMs();
  if (f !== 0) {
    writer.writeUint64(
      6,
      f
    );
  }
};


/**
 * optional ErrorMessage error = 1;
 * @return {?proto.com.kipp.miraibarai.grpc.ops.v1.ErrorMessage}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.LoginResponse.prototype.getError = function() {
  return /** @type{?proto.com.kipp.miraibarai.grpc.ops.v1.ErrorMessage} */ (
    jspb.Message.getWrapperField(this, miraibaraiops_ops_common_pb.ErrorMessage, 1));
};


/**
 * @param {?proto.com.kipp.miraibarai.grpc.ops.v1.ErrorMessage|undefined} value
 * @return {!proto.com.kipp.miraibarai.grpc.ops.v1.LoginResponse} returns this
*/
proto.com.kipp.miraibarai.grpc.ops.v1.LoginResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.kipp.miraibarai.grpc.ops.v1.LoginResponse} returns this
 */
proto.com.kipp.miraibarai.grpc.ops.v1.LoginResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.LoginResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string session_token = 2;
 * @return {string}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.LoginResponse.prototype.getSessionToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.kipp.miraibarai.grpc.ops.v1.LoginResponse} returns this
 */
proto.com.kipp.miraibarai.grpc.ops.v1.LoginResponse.prototype.setSessionToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string operator_name = 3;
 * @return {string}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.LoginResponse.prototype.getOperatorName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.kipp.miraibarai.grpc.ops.v1.LoginResponse} returns this
 */
proto.com.kipp.miraibarai.grpc.ops.v1.LoginResponse.prototype.setOperatorName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string operator_id = 4;
 * @return {string}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.LoginResponse.prototype.getOperatorId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.kipp.miraibarai.grpc.ops.v1.LoginResponse} returns this
 */
proto.com.kipp.miraibarai.grpc.ops.v1.LoginResponse.prototype.setOperatorId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated OperationRole roles = 5;
 * @return {!Array<!proto.com.kipp.miraibarai.grpc.ops.v1.OperationRole>}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.LoginResponse.prototype.getRolesList = function() {
  return /** @type {!Array<!proto.com.kipp.miraibarai.grpc.ops.v1.OperationRole>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<!proto.com.kipp.miraibarai.grpc.ops.v1.OperationRole>} value
 * @return {!proto.com.kipp.miraibarai.grpc.ops.v1.LoginResponse} returns this
 */
proto.com.kipp.miraibarai.grpc.ops.v1.LoginResponse.prototype.setRolesList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {!proto.com.kipp.miraibarai.grpc.ops.v1.OperationRole} value
 * @param {number=} opt_index
 * @return {!proto.com.kipp.miraibarai.grpc.ops.v1.LoginResponse} returns this
 */
proto.com.kipp.miraibarai.grpc.ops.v1.LoginResponse.prototype.addRoles = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.kipp.miraibarai.grpc.ops.v1.LoginResponse} returns this
 */
proto.com.kipp.miraibarai.grpc.ops.v1.LoginResponse.prototype.clearRolesList = function() {
  return this.setRolesList([]);
};


/**
 * optional uint64 last_password_updated_ms = 6;
 * @return {number}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.LoginResponse.prototype.getLastPasswordUpdatedMs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.kipp.miraibarai.grpc.ops.v1.LoginResponse} returns this
 */
proto.com.kipp.miraibarai.grpc.ops.v1.LoginResponse.prototype.setLastPasswordUpdatedMs = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.ChangePasswordRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.kipp.miraibarai.grpc.ops.v1.ChangePasswordRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.kipp.miraibarai.grpc.ops.v1.ChangePasswordRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.kipp.miraibarai.grpc.ops.v1.ChangePasswordRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    oldPassword: jspb.Message.getFieldWithDefault(msg, 1, ""),
    newPassword: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.kipp.miraibarai.grpc.ops.v1.ChangePasswordRequest}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.ChangePasswordRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.kipp.miraibarai.grpc.ops.v1.ChangePasswordRequest;
  return proto.com.kipp.miraibarai.grpc.ops.v1.ChangePasswordRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.kipp.miraibarai.grpc.ops.v1.ChangePasswordRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.kipp.miraibarai.grpc.ops.v1.ChangePasswordRequest}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.ChangePasswordRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOldPassword(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNewPassword(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.ChangePasswordRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.kipp.miraibarai.grpc.ops.v1.ChangePasswordRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.kipp.miraibarai.grpc.ops.v1.ChangePasswordRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.kipp.miraibarai.grpc.ops.v1.ChangePasswordRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOldPassword();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNewPassword();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string old_password = 1;
 * @return {string}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.ChangePasswordRequest.prototype.getOldPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.kipp.miraibarai.grpc.ops.v1.ChangePasswordRequest} returns this
 */
proto.com.kipp.miraibarai.grpc.ops.v1.ChangePasswordRequest.prototype.setOldPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string new_password = 2;
 * @return {string}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.ChangePasswordRequest.prototype.getNewPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.kipp.miraibarai.grpc.ops.v1.ChangePasswordRequest} returns this
 */
proto.com.kipp.miraibarai.grpc.ops.v1.ChangePasswordRequest.prototype.setNewPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.ChangePasswordResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.kipp.miraibarai.grpc.ops.v1.ChangePasswordResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.kipp.miraibarai.grpc.ops.v1.ChangePasswordResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.kipp.miraibarai.grpc.ops.v1.ChangePasswordResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && miraibaraiops_ops_common_pb.ErrorMessage.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.kipp.miraibarai.grpc.ops.v1.ChangePasswordResponse}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.ChangePasswordResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.kipp.miraibarai.grpc.ops.v1.ChangePasswordResponse;
  return proto.com.kipp.miraibarai.grpc.ops.v1.ChangePasswordResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.kipp.miraibarai.grpc.ops.v1.ChangePasswordResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.kipp.miraibarai.grpc.ops.v1.ChangePasswordResponse}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.ChangePasswordResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new miraibaraiops_ops_common_pb.ErrorMessage;
      reader.readMessage(value,miraibaraiops_ops_common_pb.ErrorMessage.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.ChangePasswordResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.kipp.miraibarai.grpc.ops.v1.ChangePasswordResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.kipp.miraibarai.grpc.ops.v1.ChangePasswordResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.kipp.miraibarai.grpc.ops.v1.ChangePasswordResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      miraibaraiops_ops_common_pb.ErrorMessage.serializeBinaryToWriter
    );
  }
};


/**
 * optional ErrorMessage error = 1;
 * @return {?proto.com.kipp.miraibarai.grpc.ops.v1.ErrorMessage}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.ChangePasswordResponse.prototype.getError = function() {
  return /** @type{?proto.com.kipp.miraibarai.grpc.ops.v1.ErrorMessage} */ (
    jspb.Message.getWrapperField(this, miraibaraiops_ops_common_pb.ErrorMessage, 1));
};


/**
 * @param {?proto.com.kipp.miraibarai.grpc.ops.v1.ErrorMessage|undefined} value
 * @return {!proto.com.kipp.miraibarai.grpc.ops.v1.ChangePasswordResponse} returns this
*/
proto.com.kipp.miraibarai.grpc.ops.v1.ChangePasswordResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.kipp.miraibarai.grpc.ops.v1.ChangePasswordResponse} returns this
 */
proto.com.kipp.miraibarai.grpc.ops.v1.ChangePasswordResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.ChangePasswordResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.ChangeOtherOperatorPasswordRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.kipp.miraibarai.grpc.ops.v1.ChangeOtherOperatorPasswordRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.kipp.miraibarai.grpc.ops.v1.ChangeOtherOperatorPasswordRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.kipp.miraibarai.grpc.ops.v1.ChangeOtherOperatorPasswordRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    operatorId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    newPassword: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.kipp.miraibarai.grpc.ops.v1.ChangeOtherOperatorPasswordRequest}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.ChangeOtherOperatorPasswordRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.kipp.miraibarai.grpc.ops.v1.ChangeOtherOperatorPasswordRequest;
  return proto.com.kipp.miraibarai.grpc.ops.v1.ChangeOtherOperatorPasswordRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.kipp.miraibarai.grpc.ops.v1.ChangeOtherOperatorPasswordRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.kipp.miraibarai.grpc.ops.v1.ChangeOtherOperatorPasswordRequest}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.ChangeOtherOperatorPasswordRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOperatorId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNewPassword(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.ChangeOtherOperatorPasswordRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.kipp.miraibarai.grpc.ops.v1.ChangeOtherOperatorPasswordRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.kipp.miraibarai.grpc.ops.v1.ChangeOtherOperatorPasswordRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.kipp.miraibarai.grpc.ops.v1.ChangeOtherOperatorPasswordRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOperatorId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNewPassword();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string operator_id = 1;
 * @return {string}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.ChangeOtherOperatorPasswordRequest.prototype.getOperatorId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.kipp.miraibarai.grpc.ops.v1.ChangeOtherOperatorPasswordRequest} returns this
 */
proto.com.kipp.miraibarai.grpc.ops.v1.ChangeOtherOperatorPasswordRequest.prototype.setOperatorId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string new_password = 2;
 * @return {string}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.ChangeOtherOperatorPasswordRequest.prototype.getNewPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.kipp.miraibarai.grpc.ops.v1.ChangeOtherOperatorPasswordRequest} returns this
 */
proto.com.kipp.miraibarai.grpc.ops.v1.ChangeOtherOperatorPasswordRequest.prototype.setNewPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.ChangeOtherOperatorPasswordResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.kipp.miraibarai.grpc.ops.v1.ChangeOtherOperatorPasswordResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.kipp.miraibarai.grpc.ops.v1.ChangeOtherOperatorPasswordResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.kipp.miraibarai.grpc.ops.v1.ChangeOtherOperatorPasswordResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && miraibaraiops_ops_common_pb.ErrorMessage.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.kipp.miraibarai.grpc.ops.v1.ChangeOtherOperatorPasswordResponse}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.ChangeOtherOperatorPasswordResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.kipp.miraibarai.grpc.ops.v1.ChangeOtherOperatorPasswordResponse;
  return proto.com.kipp.miraibarai.grpc.ops.v1.ChangeOtherOperatorPasswordResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.kipp.miraibarai.grpc.ops.v1.ChangeOtherOperatorPasswordResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.kipp.miraibarai.grpc.ops.v1.ChangeOtherOperatorPasswordResponse}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.ChangeOtherOperatorPasswordResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new miraibaraiops_ops_common_pb.ErrorMessage;
      reader.readMessage(value,miraibaraiops_ops_common_pb.ErrorMessage.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.ChangeOtherOperatorPasswordResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.kipp.miraibarai.grpc.ops.v1.ChangeOtherOperatorPasswordResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.kipp.miraibarai.grpc.ops.v1.ChangeOtherOperatorPasswordResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.kipp.miraibarai.grpc.ops.v1.ChangeOtherOperatorPasswordResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      miraibaraiops_ops_common_pb.ErrorMessage.serializeBinaryToWriter
    );
  }
};


/**
 * optional ErrorMessage error = 1;
 * @return {?proto.com.kipp.miraibarai.grpc.ops.v1.ErrorMessage}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.ChangeOtherOperatorPasswordResponse.prototype.getError = function() {
  return /** @type{?proto.com.kipp.miraibarai.grpc.ops.v1.ErrorMessage} */ (
    jspb.Message.getWrapperField(this, miraibaraiops_ops_common_pb.ErrorMessage, 1));
};


/**
 * @param {?proto.com.kipp.miraibarai.grpc.ops.v1.ErrorMessage|undefined} value
 * @return {!proto.com.kipp.miraibarai.grpc.ops.v1.ChangeOtherOperatorPasswordResponse} returns this
*/
proto.com.kipp.miraibarai.grpc.ops.v1.ChangeOtherOperatorPasswordResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.kipp.miraibarai.grpc.ops.v1.ChangeOtherOperatorPasswordResponse} returns this
 */
proto.com.kipp.miraibarai.grpc.ops.v1.ChangeOtherOperatorPasswordResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.ChangeOtherOperatorPasswordResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.AddOperatorRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.kipp.miraibarai.grpc.ops.v1.AddOperatorRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.kipp.miraibarai.grpc.ops.v1.AddOperatorRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.kipp.miraibarai.grpc.ops.v1.AddOperatorRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    loginId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    password: jspb.Message.getFieldWithDefault(msg, 3, ""),
    createApiToken: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.kipp.miraibarai.grpc.ops.v1.AddOperatorRequest}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.AddOperatorRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.kipp.miraibarai.grpc.ops.v1.AddOperatorRequest;
  return proto.com.kipp.miraibarai.grpc.ops.v1.AddOperatorRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.kipp.miraibarai.grpc.ops.v1.AddOperatorRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.kipp.miraibarai.grpc.ops.v1.AddOperatorRequest}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.AddOperatorRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLoginId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCreateApiToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.AddOperatorRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.kipp.miraibarai.grpc.ops.v1.AddOperatorRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.kipp.miraibarai.grpc.ops.v1.AddOperatorRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.kipp.miraibarai.grpc.ops.v1.AddOperatorRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLoginId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCreateApiToken();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.AddOperatorRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.kipp.miraibarai.grpc.ops.v1.AddOperatorRequest} returns this
 */
proto.com.kipp.miraibarai.grpc.ops.v1.AddOperatorRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string login_id = 2;
 * @return {string}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.AddOperatorRequest.prototype.getLoginId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.kipp.miraibarai.grpc.ops.v1.AddOperatorRequest} returns this
 */
proto.com.kipp.miraibarai.grpc.ops.v1.AddOperatorRequest.prototype.setLoginId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string password = 3;
 * @return {string}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.AddOperatorRequest.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.kipp.miraibarai.grpc.ops.v1.AddOperatorRequest} returns this
 */
proto.com.kipp.miraibarai.grpc.ops.v1.AddOperatorRequest.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool create_api_token = 4;
 * @return {boolean}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.AddOperatorRequest.prototype.getCreateApiToken = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.kipp.miraibarai.grpc.ops.v1.AddOperatorRequest} returns this
 */
proto.com.kipp.miraibarai.grpc.ops.v1.AddOperatorRequest.prototype.setCreateApiToken = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.AddOperatorResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.kipp.miraibarai.grpc.ops.v1.AddOperatorResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.kipp.miraibarai.grpc.ops.v1.AddOperatorResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.kipp.miraibarai.grpc.ops.v1.AddOperatorResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && miraibaraiops_ops_common_pb.ErrorMessage.toObject(includeInstance, f),
    token: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.kipp.miraibarai.grpc.ops.v1.AddOperatorResponse}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.AddOperatorResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.kipp.miraibarai.grpc.ops.v1.AddOperatorResponse;
  return proto.com.kipp.miraibarai.grpc.ops.v1.AddOperatorResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.kipp.miraibarai.grpc.ops.v1.AddOperatorResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.kipp.miraibarai.grpc.ops.v1.AddOperatorResponse}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.AddOperatorResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new miraibaraiops_ops_common_pb.ErrorMessage;
      reader.readMessage(value,miraibaraiops_ops_common_pb.ErrorMessage.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.AddOperatorResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.kipp.miraibarai.grpc.ops.v1.AddOperatorResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.kipp.miraibarai.grpc.ops.v1.AddOperatorResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.kipp.miraibarai.grpc.ops.v1.AddOperatorResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      miraibaraiops_ops_common_pb.ErrorMessage.serializeBinaryToWriter
    );
  }
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional ErrorMessage error = 1;
 * @return {?proto.com.kipp.miraibarai.grpc.ops.v1.ErrorMessage}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.AddOperatorResponse.prototype.getError = function() {
  return /** @type{?proto.com.kipp.miraibarai.grpc.ops.v1.ErrorMessage} */ (
    jspb.Message.getWrapperField(this, miraibaraiops_ops_common_pb.ErrorMessage, 1));
};


/**
 * @param {?proto.com.kipp.miraibarai.grpc.ops.v1.ErrorMessage|undefined} value
 * @return {!proto.com.kipp.miraibarai.grpc.ops.v1.AddOperatorResponse} returns this
*/
proto.com.kipp.miraibarai.grpc.ops.v1.AddOperatorResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.kipp.miraibarai.grpc.ops.v1.AddOperatorResponse} returns this
 */
proto.com.kipp.miraibarai.grpc.ops.v1.AddOperatorResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.AddOperatorResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string token = 2;
 * @return {string}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.AddOperatorResponse.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.kipp.miraibarai.grpc.ops.v1.AddOperatorResponse} returns this
 */
proto.com.kipp.miraibarai.grpc.ops.v1.AddOperatorResponse.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.SuspendOperatorRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.kipp.miraibarai.grpc.ops.v1.SuspendOperatorRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.kipp.miraibarai.grpc.ops.v1.SuspendOperatorRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.kipp.miraibarai.grpc.ops.v1.SuspendOperatorRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    targetOperatorId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.kipp.miraibarai.grpc.ops.v1.SuspendOperatorRequest}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.SuspendOperatorRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.kipp.miraibarai.grpc.ops.v1.SuspendOperatorRequest;
  return proto.com.kipp.miraibarai.grpc.ops.v1.SuspendOperatorRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.kipp.miraibarai.grpc.ops.v1.SuspendOperatorRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.kipp.miraibarai.grpc.ops.v1.SuspendOperatorRequest}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.SuspendOperatorRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTargetOperatorId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.SuspendOperatorRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.kipp.miraibarai.grpc.ops.v1.SuspendOperatorRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.kipp.miraibarai.grpc.ops.v1.SuspendOperatorRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.kipp.miraibarai.grpc.ops.v1.SuspendOperatorRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTargetOperatorId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string target_operator_id = 1;
 * @return {string}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.SuspendOperatorRequest.prototype.getTargetOperatorId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.kipp.miraibarai.grpc.ops.v1.SuspendOperatorRequest} returns this
 */
proto.com.kipp.miraibarai.grpc.ops.v1.SuspendOperatorRequest.prototype.setTargetOperatorId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.SuspendOperatorResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.kipp.miraibarai.grpc.ops.v1.SuspendOperatorResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.kipp.miraibarai.grpc.ops.v1.SuspendOperatorResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.kipp.miraibarai.grpc.ops.v1.SuspendOperatorResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && miraibaraiops_ops_common_pb.ErrorMessage.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.kipp.miraibarai.grpc.ops.v1.SuspendOperatorResponse}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.SuspendOperatorResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.kipp.miraibarai.grpc.ops.v1.SuspendOperatorResponse;
  return proto.com.kipp.miraibarai.grpc.ops.v1.SuspendOperatorResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.kipp.miraibarai.grpc.ops.v1.SuspendOperatorResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.kipp.miraibarai.grpc.ops.v1.SuspendOperatorResponse}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.SuspendOperatorResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new miraibaraiops_ops_common_pb.ErrorMessage;
      reader.readMessage(value,miraibaraiops_ops_common_pb.ErrorMessage.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.SuspendOperatorResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.kipp.miraibarai.grpc.ops.v1.SuspendOperatorResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.kipp.miraibarai.grpc.ops.v1.SuspendOperatorResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.kipp.miraibarai.grpc.ops.v1.SuspendOperatorResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      miraibaraiops_ops_common_pb.ErrorMessage.serializeBinaryToWriter
    );
  }
};


/**
 * optional ErrorMessage error = 1;
 * @return {?proto.com.kipp.miraibarai.grpc.ops.v1.ErrorMessage}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.SuspendOperatorResponse.prototype.getError = function() {
  return /** @type{?proto.com.kipp.miraibarai.grpc.ops.v1.ErrorMessage} */ (
    jspb.Message.getWrapperField(this, miraibaraiops_ops_common_pb.ErrorMessage, 1));
};


/**
 * @param {?proto.com.kipp.miraibarai.grpc.ops.v1.ErrorMessage|undefined} value
 * @return {!proto.com.kipp.miraibarai.grpc.ops.v1.SuspendOperatorResponse} returns this
*/
proto.com.kipp.miraibarai.grpc.ops.v1.SuspendOperatorResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.kipp.miraibarai.grpc.ops.v1.SuspendOperatorResponse} returns this
 */
proto.com.kipp.miraibarai.grpc.ops.v1.SuspendOperatorResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.SuspendOperatorResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.ResumeOperatorRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.kipp.miraibarai.grpc.ops.v1.ResumeOperatorRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.kipp.miraibarai.grpc.ops.v1.ResumeOperatorRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.kipp.miraibarai.grpc.ops.v1.ResumeOperatorRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    targetOperatorId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.kipp.miraibarai.grpc.ops.v1.ResumeOperatorRequest}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.ResumeOperatorRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.kipp.miraibarai.grpc.ops.v1.ResumeOperatorRequest;
  return proto.com.kipp.miraibarai.grpc.ops.v1.ResumeOperatorRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.kipp.miraibarai.grpc.ops.v1.ResumeOperatorRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.kipp.miraibarai.grpc.ops.v1.ResumeOperatorRequest}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.ResumeOperatorRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTargetOperatorId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.ResumeOperatorRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.kipp.miraibarai.grpc.ops.v1.ResumeOperatorRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.kipp.miraibarai.grpc.ops.v1.ResumeOperatorRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.kipp.miraibarai.grpc.ops.v1.ResumeOperatorRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTargetOperatorId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string target_operator_id = 1;
 * @return {string}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.ResumeOperatorRequest.prototype.getTargetOperatorId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.kipp.miraibarai.grpc.ops.v1.ResumeOperatorRequest} returns this
 */
proto.com.kipp.miraibarai.grpc.ops.v1.ResumeOperatorRequest.prototype.setTargetOperatorId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.ResumeOperatorResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.kipp.miraibarai.grpc.ops.v1.ResumeOperatorResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.kipp.miraibarai.grpc.ops.v1.ResumeOperatorResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.kipp.miraibarai.grpc.ops.v1.ResumeOperatorResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && miraibaraiops_ops_common_pb.ErrorMessage.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.kipp.miraibarai.grpc.ops.v1.ResumeOperatorResponse}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.ResumeOperatorResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.kipp.miraibarai.grpc.ops.v1.ResumeOperatorResponse;
  return proto.com.kipp.miraibarai.grpc.ops.v1.ResumeOperatorResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.kipp.miraibarai.grpc.ops.v1.ResumeOperatorResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.kipp.miraibarai.grpc.ops.v1.ResumeOperatorResponse}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.ResumeOperatorResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new miraibaraiops_ops_common_pb.ErrorMessage;
      reader.readMessage(value,miraibaraiops_ops_common_pb.ErrorMessage.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.ResumeOperatorResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.kipp.miraibarai.grpc.ops.v1.ResumeOperatorResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.kipp.miraibarai.grpc.ops.v1.ResumeOperatorResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.kipp.miraibarai.grpc.ops.v1.ResumeOperatorResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      miraibaraiops_ops_common_pb.ErrorMessage.serializeBinaryToWriter
    );
  }
};


/**
 * optional ErrorMessage error = 1;
 * @return {?proto.com.kipp.miraibarai.grpc.ops.v1.ErrorMessage}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.ResumeOperatorResponse.prototype.getError = function() {
  return /** @type{?proto.com.kipp.miraibarai.grpc.ops.v1.ErrorMessage} */ (
    jspb.Message.getWrapperField(this, miraibaraiops_ops_common_pb.ErrorMessage, 1));
};


/**
 * @param {?proto.com.kipp.miraibarai.grpc.ops.v1.ErrorMessage|undefined} value
 * @return {!proto.com.kipp.miraibarai.grpc.ops.v1.ResumeOperatorResponse} returns this
*/
proto.com.kipp.miraibarai.grpc.ops.v1.ResumeOperatorResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.kipp.miraibarai.grpc.ops.v1.ResumeOperatorResponse} returns this
 */
proto.com.kipp.miraibarai.grpc.ops.v1.ResumeOperatorResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.ResumeOperatorResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.kipp.miraibarai.grpc.ops.v1.AssignRoleRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.AssignRoleRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.kipp.miraibarai.grpc.ops.v1.AssignRoleRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.kipp.miraibarai.grpc.ops.v1.AssignRoleRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.kipp.miraibarai.grpc.ops.v1.AssignRoleRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    operatorId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    roleAssignmentsList: jspb.Message.toObjectList(msg.getRoleAssignmentsList(),
    miraibaraiops_operator_pb.RoleAssignment.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.kipp.miraibarai.grpc.ops.v1.AssignRoleRequest}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.AssignRoleRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.kipp.miraibarai.grpc.ops.v1.AssignRoleRequest;
  return proto.com.kipp.miraibarai.grpc.ops.v1.AssignRoleRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.kipp.miraibarai.grpc.ops.v1.AssignRoleRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.kipp.miraibarai.grpc.ops.v1.AssignRoleRequest}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.AssignRoleRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOperatorId(value);
      break;
    case 2:
      var value = new miraibaraiops_operator_pb.RoleAssignment;
      reader.readMessage(value,miraibaraiops_operator_pb.RoleAssignment.deserializeBinaryFromReader);
      msg.addRoleAssignments(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.AssignRoleRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.kipp.miraibarai.grpc.ops.v1.AssignRoleRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.kipp.miraibarai.grpc.ops.v1.AssignRoleRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.kipp.miraibarai.grpc.ops.v1.AssignRoleRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOperatorId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRoleAssignmentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      miraibaraiops_operator_pb.RoleAssignment.serializeBinaryToWriter
    );
  }
};


/**
 * optional string operator_id = 1;
 * @return {string}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.AssignRoleRequest.prototype.getOperatorId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.kipp.miraibarai.grpc.ops.v1.AssignRoleRequest} returns this
 */
proto.com.kipp.miraibarai.grpc.ops.v1.AssignRoleRequest.prototype.setOperatorId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated RoleAssignment role_assignments = 2;
 * @return {!Array<!proto.com.kipp.miraibarai.grpc.ops.v1.RoleAssignment>}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.AssignRoleRequest.prototype.getRoleAssignmentsList = function() {
  return /** @type{!Array<!proto.com.kipp.miraibarai.grpc.ops.v1.RoleAssignment>} */ (
    jspb.Message.getRepeatedWrapperField(this, miraibaraiops_operator_pb.RoleAssignment, 2));
};


/**
 * @param {!Array<!proto.com.kipp.miraibarai.grpc.ops.v1.RoleAssignment>} value
 * @return {!proto.com.kipp.miraibarai.grpc.ops.v1.AssignRoleRequest} returns this
*/
proto.com.kipp.miraibarai.grpc.ops.v1.AssignRoleRequest.prototype.setRoleAssignmentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.com.kipp.miraibarai.grpc.ops.v1.RoleAssignment=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.kipp.miraibarai.grpc.ops.v1.RoleAssignment}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.AssignRoleRequest.prototype.addRoleAssignments = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.com.kipp.miraibarai.grpc.ops.v1.RoleAssignment, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.kipp.miraibarai.grpc.ops.v1.AssignRoleRequest} returns this
 */
proto.com.kipp.miraibarai.grpc.ops.v1.AssignRoleRequest.prototype.clearRoleAssignmentsList = function() {
  return this.setRoleAssignmentsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.AssignRoleResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.kipp.miraibarai.grpc.ops.v1.AssignRoleResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.kipp.miraibarai.grpc.ops.v1.AssignRoleResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.kipp.miraibarai.grpc.ops.v1.AssignRoleResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && miraibaraiops_ops_common_pb.ErrorMessage.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.kipp.miraibarai.grpc.ops.v1.AssignRoleResponse}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.AssignRoleResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.kipp.miraibarai.grpc.ops.v1.AssignRoleResponse;
  return proto.com.kipp.miraibarai.grpc.ops.v1.AssignRoleResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.kipp.miraibarai.grpc.ops.v1.AssignRoleResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.kipp.miraibarai.grpc.ops.v1.AssignRoleResponse}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.AssignRoleResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new miraibaraiops_ops_common_pb.ErrorMessage;
      reader.readMessage(value,miraibaraiops_ops_common_pb.ErrorMessage.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.AssignRoleResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.kipp.miraibarai.grpc.ops.v1.AssignRoleResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.kipp.miraibarai.grpc.ops.v1.AssignRoleResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.kipp.miraibarai.grpc.ops.v1.AssignRoleResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      miraibaraiops_ops_common_pb.ErrorMessage.serializeBinaryToWriter
    );
  }
};


/**
 * optional ErrorMessage error = 1;
 * @return {?proto.com.kipp.miraibarai.grpc.ops.v1.ErrorMessage}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.AssignRoleResponse.prototype.getError = function() {
  return /** @type{?proto.com.kipp.miraibarai.grpc.ops.v1.ErrorMessage} */ (
    jspb.Message.getWrapperField(this, miraibaraiops_ops_common_pb.ErrorMessage, 1));
};


/**
 * @param {?proto.com.kipp.miraibarai.grpc.ops.v1.ErrorMessage|undefined} value
 * @return {!proto.com.kipp.miraibarai.grpc.ops.v1.AssignRoleResponse} returns this
*/
proto.com.kipp.miraibarai.grpc.ops.v1.AssignRoleResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.kipp.miraibarai.grpc.ops.v1.AssignRoleResponse} returns this
 */
proto.com.kipp.miraibarai.grpc.ops.v1.AssignRoleResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.AssignRoleResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.GetCurrentOperationRolesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.kipp.miraibarai.grpc.ops.v1.GetCurrentOperationRolesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.kipp.miraibarai.grpc.ops.v1.GetCurrentOperationRolesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.kipp.miraibarai.grpc.ops.v1.GetCurrentOperationRolesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.kipp.miraibarai.grpc.ops.v1.GetCurrentOperationRolesRequest}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.GetCurrentOperationRolesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.kipp.miraibarai.grpc.ops.v1.GetCurrentOperationRolesRequest;
  return proto.com.kipp.miraibarai.grpc.ops.v1.GetCurrentOperationRolesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.kipp.miraibarai.grpc.ops.v1.GetCurrentOperationRolesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.kipp.miraibarai.grpc.ops.v1.GetCurrentOperationRolesRequest}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.GetCurrentOperationRolesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.GetCurrentOperationRolesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.kipp.miraibarai.grpc.ops.v1.GetCurrentOperationRolesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.kipp.miraibarai.grpc.ops.v1.GetCurrentOperationRolesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.kipp.miraibarai.grpc.ops.v1.GetCurrentOperationRolesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.kipp.miraibarai.grpc.ops.v1.GetCurrentOperationRolesResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.GetCurrentOperationRolesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.kipp.miraibarai.grpc.ops.v1.GetCurrentOperationRolesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.kipp.miraibarai.grpc.ops.v1.GetCurrentOperationRolesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.kipp.miraibarai.grpc.ops.v1.GetCurrentOperationRolesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && miraibaraiops_ops_common_pb.ErrorMessage.toObject(includeInstance, f),
    rolesList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.kipp.miraibarai.grpc.ops.v1.GetCurrentOperationRolesResponse}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.GetCurrentOperationRolesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.kipp.miraibarai.grpc.ops.v1.GetCurrentOperationRolesResponse;
  return proto.com.kipp.miraibarai.grpc.ops.v1.GetCurrentOperationRolesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.kipp.miraibarai.grpc.ops.v1.GetCurrentOperationRolesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.kipp.miraibarai.grpc.ops.v1.GetCurrentOperationRolesResponse}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.GetCurrentOperationRolesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new miraibaraiops_ops_common_pb.ErrorMessage;
      reader.readMessage(value,miraibaraiops_ops_common_pb.ErrorMessage.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var values = /** @type {!Array<!proto.com.kipp.miraibarai.grpc.ops.v1.OperationRole>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addRoles(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.GetCurrentOperationRolesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.kipp.miraibarai.grpc.ops.v1.GetCurrentOperationRolesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.kipp.miraibarai.grpc.ops.v1.GetCurrentOperationRolesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.kipp.miraibarai.grpc.ops.v1.GetCurrentOperationRolesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      miraibaraiops_ops_common_pb.ErrorMessage.serializeBinaryToWriter
    );
  }
  f = message.getRolesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      2,
      f
    );
  }
};


/**
 * optional ErrorMessage error = 1;
 * @return {?proto.com.kipp.miraibarai.grpc.ops.v1.ErrorMessage}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.GetCurrentOperationRolesResponse.prototype.getError = function() {
  return /** @type{?proto.com.kipp.miraibarai.grpc.ops.v1.ErrorMessage} */ (
    jspb.Message.getWrapperField(this, miraibaraiops_ops_common_pb.ErrorMessage, 1));
};


/**
 * @param {?proto.com.kipp.miraibarai.grpc.ops.v1.ErrorMessage|undefined} value
 * @return {!proto.com.kipp.miraibarai.grpc.ops.v1.GetCurrentOperationRolesResponse} returns this
*/
proto.com.kipp.miraibarai.grpc.ops.v1.GetCurrentOperationRolesResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.kipp.miraibarai.grpc.ops.v1.GetCurrentOperationRolesResponse} returns this
 */
proto.com.kipp.miraibarai.grpc.ops.v1.GetCurrentOperationRolesResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.GetCurrentOperationRolesResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated OperationRole roles = 2;
 * @return {!Array<!proto.com.kipp.miraibarai.grpc.ops.v1.OperationRole>}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.GetCurrentOperationRolesResponse.prototype.getRolesList = function() {
  return /** @type {!Array<!proto.com.kipp.miraibarai.grpc.ops.v1.OperationRole>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<!proto.com.kipp.miraibarai.grpc.ops.v1.OperationRole>} value
 * @return {!proto.com.kipp.miraibarai.grpc.ops.v1.GetCurrentOperationRolesResponse} returns this
 */
proto.com.kipp.miraibarai.grpc.ops.v1.GetCurrentOperationRolesResponse.prototype.setRolesList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {!proto.com.kipp.miraibarai.grpc.ops.v1.OperationRole} value
 * @param {number=} opt_index
 * @return {!proto.com.kipp.miraibarai.grpc.ops.v1.GetCurrentOperationRolesResponse} returns this
 */
proto.com.kipp.miraibarai.grpc.ops.v1.GetCurrentOperationRolesResponse.prototype.addRoles = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.kipp.miraibarai.grpc.ops.v1.GetCurrentOperationRolesResponse} returns this
 */
proto.com.kipp.miraibarai.grpc.ops.v1.GetCurrentOperationRolesResponse.prototype.clearRolesList = function() {
  return this.setRolesList([]);
};


goog.object.extend(exports, proto.com.kipp.miraibarai.grpc.ops.v1);
