import { Card, CardContent, CardHeader, Grid, Typography } from '@mui/material'
import { useContext, useMemo } from 'react'

import AccessControl from 'shared/components/AccessControl'
import { context as OperationAppContext } from 'shared/context/OperationApp'
import { formatAsDatetime } from 'shared/lib/dateUtils'

import UpdateExternalScreeningApiSetting from '@/components/UpdateExternalScreeningApiSetting'
import { useGetExternalScreeningApiSettingQuery } from '@/hooks/graphql'
import { assumedRoles } from '@/hooks/graphql/queries/get_external_screening_api_setting.gql'

export const allowedRoles = assumedRoles

const PageExternalScreeningApiSetting: React.VFC = () => {
  const { data, refetch } = useGetExternalScreeningApiSettingQuery()
  const appContext = useContext(OperationAppContext)

  const lastUpdatedSubheaderText = useMemo(
    () =>
      data?.result?.updatedMs
        ? `最終更新日時: ${formatAsDatetime(data.result.updatedMs)}`
        : data
          ? '既に保存されている設定はありません'
          : '',
    [data],
  )

  const params = useMemo(() => {
    if (!data?.result?.encryptedExternalScreeningApiSettingData?.miraibaraiInternal) {
      return {
        endpointUri: '',
        bearerToken: '',
        graphqlQueryTemplate: '',
        webhookUri: '',
      }
    }
    const { __typename, ...otherThanTypeName } = data.result.encryptedExternalScreeningApiSettingData.miraibaraiInternal
    return otherThanTypeName
  }, [data?.result])

  return (
    <AccessControl allowedRoles={allowedRoles}>
      <Grid container style={{ marginTop: '1.0em' }}>
        <Grid item xs={12} md={6}>
          <Card>
            <CardHeader title="アイフル審査内製化 API" subheader={lastUpdatedSubheaderText} />
            <CardContent>
              {data && (
                <>
                  <Typography variant="body2" gutterBottom>
                    以下の設定をもとに審査 API へのリクエストを行い、レスポンスに応じて後払い利用の可否を判断します。
                  </Typography>
                  <UpdateExternalScreeningApiSetting
                    {...params}
                    onSuccess={() => {
                      appContext.flashMessage('設定を更新しました', 'success')
                      refetch()
                    }}
                  />
                </>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </AccessControl>
  )
}

export default PageExternalScreeningApiSetting
