/**
 * @fileoverview gRPC-Web generated client stub for com.kipp.miraibarai.grpc.ops.v1
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v3.20.3
// source: miraibaraiops/post_pay_api.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as miraibaraiops_post_pay_api_pb from '../miraibaraiops/post_pay_api_pb'; // proto import: "miraibaraiops/post_pay_api.proto"


export class PostPayAPIClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorPinPostPayCreditLimit = new grpcWeb.MethodDescriptor(
    '/com.kipp.miraibarai.grpc.ops.v1.PostPayAPI/PinPostPayCreditLimit',
    grpcWeb.MethodType.UNARY,
    miraibaraiops_post_pay_api_pb.PinPostPayCreditLimitRequest,
    miraibaraiops_post_pay_api_pb.PinPostPayCreditLimitResponse,
    (request: miraibaraiops_post_pay_api_pb.PinPostPayCreditLimitRequest) => {
      return request.serializeBinary();
    },
    miraibaraiops_post_pay_api_pb.PinPostPayCreditLimitResponse.deserializeBinary
  );

  pinPostPayCreditLimit(
    request: miraibaraiops_post_pay_api_pb.PinPostPayCreditLimitRequest,
    metadata?: grpcWeb.Metadata | null): Promise<miraibaraiops_post_pay_api_pb.PinPostPayCreditLimitResponse>;

  pinPostPayCreditLimit(
    request: miraibaraiops_post_pay_api_pb.PinPostPayCreditLimitRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: miraibaraiops_post_pay_api_pb.PinPostPayCreditLimitResponse) => void): grpcWeb.ClientReadableStream<miraibaraiops_post_pay_api_pb.PinPostPayCreditLimitResponse>;

  pinPostPayCreditLimit(
    request: miraibaraiops_post_pay_api_pb.PinPostPayCreditLimitRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: miraibaraiops_post_pay_api_pb.PinPostPayCreditLimitResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.kipp.miraibarai.grpc.ops.v1.PostPayAPI/PinPostPayCreditLimit',
        request,
        metadata || {},
        this.methodDescriptorPinPostPayCreditLimit,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.kipp.miraibarai.grpc.ops.v1.PostPayAPI/PinPostPayCreditLimit',
    request,
    metadata || {},
    this.methodDescriptorPinPostPayCreditLimit);
  }

  methodDescriptorUpdatePostPayScreeningRuleSet = new grpcWeb.MethodDescriptor(
    '/com.kipp.miraibarai.grpc.ops.v1.PostPayAPI/UpdatePostPayScreeningRuleSet',
    grpcWeb.MethodType.UNARY,
    miraibaraiops_post_pay_api_pb.UpdatePostPayScreeningRuleSetRequest,
    miraibaraiops_post_pay_api_pb.UpdatePostPayScreeningRuleSetResponse,
    (request: miraibaraiops_post_pay_api_pb.UpdatePostPayScreeningRuleSetRequest) => {
      return request.serializeBinary();
    },
    miraibaraiops_post_pay_api_pb.UpdatePostPayScreeningRuleSetResponse.deserializeBinary
  );

  updatePostPayScreeningRuleSet(
    request: miraibaraiops_post_pay_api_pb.UpdatePostPayScreeningRuleSetRequest,
    metadata?: grpcWeb.Metadata | null): Promise<miraibaraiops_post_pay_api_pb.UpdatePostPayScreeningRuleSetResponse>;

  updatePostPayScreeningRuleSet(
    request: miraibaraiops_post_pay_api_pb.UpdatePostPayScreeningRuleSetRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: miraibaraiops_post_pay_api_pb.UpdatePostPayScreeningRuleSetResponse) => void): grpcWeb.ClientReadableStream<miraibaraiops_post_pay_api_pb.UpdatePostPayScreeningRuleSetResponse>;

  updatePostPayScreeningRuleSet(
    request: miraibaraiops_post_pay_api_pb.UpdatePostPayScreeningRuleSetRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: miraibaraiops_post_pay_api_pb.UpdatePostPayScreeningRuleSetResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.kipp.miraibarai.grpc.ops.v1.PostPayAPI/UpdatePostPayScreeningRuleSet',
        request,
        metadata || {},
        this.methodDescriptorUpdatePostPayScreeningRuleSet,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.kipp.miraibarai.grpc.ops.v1.PostPayAPI/UpdatePostPayScreeningRuleSet',
    request,
    metadata || {},
    this.methodDescriptorUpdatePostPayScreeningRuleSet);
  }

  methodDescriptorCancelPostPayDebt = new grpcWeb.MethodDescriptor(
    '/com.kipp.miraibarai.grpc.ops.v1.PostPayAPI/CancelPostPayDebt',
    grpcWeb.MethodType.UNARY,
    miraibaraiops_post_pay_api_pb.CancelPostPayDebtRequest,
    miraibaraiops_post_pay_api_pb.CancelPostPayDebtResponse,
    (request: miraibaraiops_post_pay_api_pb.CancelPostPayDebtRequest) => {
      return request.serializeBinary();
    },
    miraibaraiops_post_pay_api_pb.CancelPostPayDebtResponse.deserializeBinary
  );

  cancelPostPayDebt(
    request: miraibaraiops_post_pay_api_pb.CancelPostPayDebtRequest,
    metadata?: grpcWeb.Metadata | null): Promise<miraibaraiops_post_pay_api_pb.CancelPostPayDebtResponse>;

  cancelPostPayDebt(
    request: miraibaraiops_post_pay_api_pb.CancelPostPayDebtRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: miraibaraiops_post_pay_api_pb.CancelPostPayDebtResponse) => void): grpcWeb.ClientReadableStream<miraibaraiops_post_pay_api_pb.CancelPostPayDebtResponse>;

  cancelPostPayDebt(
    request: miraibaraiops_post_pay_api_pb.CancelPostPayDebtRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: miraibaraiops_post_pay_api_pb.CancelPostPayDebtResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.kipp.miraibarai.grpc.ops.v1.PostPayAPI/CancelPostPayDebt',
        request,
        metadata || {},
        this.methodDescriptorCancelPostPayDebt,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.kipp.miraibarai.grpc.ops.v1.PostPayAPI/CancelPostPayDebt',
    request,
    metadata || {},
    this.methodDescriptorCancelPostPayDebt);
  }

  methodDescriptorUpdateExternalScreeningApiSetting = new grpcWeb.MethodDescriptor(
    '/com.kipp.miraibarai.grpc.ops.v1.PostPayAPI/UpdateExternalScreeningApiSetting',
    grpcWeb.MethodType.UNARY,
    miraibaraiops_post_pay_api_pb.UpdateExternalScreeningApiSettingRequest,
    miraibaraiops_post_pay_api_pb.UpdateExternalScreeningApiSettingResponse,
    (request: miraibaraiops_post_pay_api_pb.UpdateExternalScreeningApiSettingRequest) => {
      return request.serializeBinary();
    },
    miraibaraiops_post_pay_api_pb.UpdateExternalScreeningApiSettingResponse.deserializeBinary
  );

  updateExternalScreeningApiSetting(
    request: miraibaraiops_post_pay_api_pb.UpdateExternalScreeningApiSettingRequest,
    metadata?: grpcWeb.Metadata | null): Promise<miraibaraiops_post_pay_api_pb.UpdateExternalScreeningApiSettingResponse>;

  updateExternalScreeningApiSetting(
    request: miraibaraiops_post_pay_api_pb.UpdateExternalScreeningApiSettingRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: miraibaraiops_post_pay_api_pb.UpdateExternalScreeningApiSettingResponse) => void): grpcWeb.ClientReadableStream<miraibaraiops_post_pay_api_pb.UpdateExternalScreeningApiSettingResponse>;

  updateExternalScreeningApiSetting(
    request: miraibaraiops_post_pay_api_pb.UpdateExternalScreeningApiSettingRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: miraibaraiops_post_pay_api_pb.UpdateExternalScreeningApiSettingResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.kipp.miraibarai.grpc.ops.v1.PostPayAPI/UpdateExternalScreeningApiSetting',
        request,
        metadata || {},
        this.methodDescriptorUpdateExternalScreeningApiSetting,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.kipp.miraibarai.grpc.ops.v1.PostPayAPI/UpdateExternalScreeningApiSetting',
    request,
    metadata || {},
    this.methodDescriptorUpdateExternalScreeningApiSetting);
  }

  methodDescriptorUpdatePostPaidTopUpFees = new grpcWeb.MethodDescriptor(
    '/com.kipp.miraibarai.grpc.ops.v1.PostPayAPI/UpdatePostPaidTopUpFees',
    grpcWeb.MethodType.UNARY,
    miraibaraiops_post_pay_api_pb.UpdatePostPaidTopUpFeesRequest,
    miraibaraiops_post_pay_api_pb.UpdatePostPaidTopUpFeesResponse,
    (request: miraibaraiops_post_pay_api_pb.UpdatePostPaidTopUpFeesRequest) => {
      return request.serializeBinary();
    },
    miraibaraiops_post_pay_api_pb.UpdatePostPaidTopUpFeesResponse.deserializeBinary
  );

  updatePostPaidTopUpFees(
    request: miraibaraiops_post_pay_api_pb.UpdatePostPaidTopUpFeesRequest,
    metadata?: grpcWeb.Metadata | null): Promise<miraibaraiops_post_pay_api_pb.UpdatePostPaidTopUpFeesResponse>;

  updatePostPaidTopUpFees(
    request: miraibaraiops_post_pay_api_pb.UpdatePostPaidTopUpFeesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: miraibaraiops_post_pay_api_pb.UpdatePostPaidTopUpFeesResponse) => void): grpcWeb.ClientReadableStream<miraibaraiops_post_pay_api_pb.UpdatePostPaidTopUpFeesResponse>;

  updatePostPaidTopUpFees(
    request: miraibaraiops_post_pay_api_pb.UpdatePostPaidTopUpFeesRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: miraibaraiops_post_pay_api_pb.UpdatePostPaidTopUpFeesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.kipp.miraibarai.grpc.ops.v1.PostPayAPI/UpdatePostPaidTopUpFees',
        request,
        metadata || {},
        this.methodDescriptorUpdatePostPaidTopUpFees,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.kipp.miraibarai.grpc.ops.v1.PostPayAPI/UpdatePostPaidTopUpFees',
    request,
    metadata || {},
    this.methodDescriptorUpdatePostPaidTopUpFees);
  }

  methodDescriptorTransferCollectionOwnership = new grpcWeb.MethodDescriptor(
    '/com.kipp.miraibarai.grpc.ops.v1.PostPayAPI/TransferCollectionOwnership',
    grpcWeb.MethodType.UNARY,
    miraibaraiops_post_pay_api_pb.TransferCollectionOwnershipRequest,
    miraibaraiops_post_pay_api_pb.TransferCollectionOwnershipResponse,
    (request: miraibaraiops_post_pay_api_pb.TransferCollectionOwnershipRequest) => {
      return request.serializeBinary();
    },
    miraibaraiops_post_pay_api_pb.TransferCollectionOwnershipResponse.deserializeBinary
  );

  transferCollectionOwnership(
    request: miraibaraiops_post_pay_api_pb.TransferCollectionOwnershipRequest,
    metadata?: grpcWeb.Metadata | null): Promise<miraibaraiops_post_pay_api_pb.TransferCollectionOwnershipResponse>;

  transferCollectionOwnership(
    request: miraibaraiops_post_pay_api_pb.TransferCollectionOwnershipRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: miraibaraiops_post_pay_api_pb.TransferCollectionOwnershipResponse) => void): grpcWeb.ClientReadableStream<miraibaraiops_post_pay_api_pb.TransferCollectionOwnershipResponse>;

  transferCollectionOwnership(
    request: miraibaraiops_post_pay_api_pb.TransferCollectionOwnershipRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: miraibaraiops_post_pay_api_pb.TransferCollectionOwnershipResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.kipp.miraibarai.grpc.ops.v1.PostPayAPI/TransferCollectionOwnership',
        request,
        metadata || {},
        this.methodDescriptorTransferCollectionOwnership,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.kipp.miraibarai.grpc.ops.v1.PostPayAPI/TransferCollectionOwnership',
    request,
    metadata || {},
    this.methodDescriptorTransferCollectionOwnership);
  }

}

