import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'

import { CssBaseline, NoSsr, StyledEngineProvider, Theme, ThemeProvider } from '@mui/material'
import { AppProps as NextAppProps } from 'next/app'
import Head from 'next/head'
import { SnackbarProvider } from 'notistack'

import { ChangePasswordOperation } from 'shared/components/ChangePassword'
import { Operation } from 'shared/components/GlobalNavigation'
import KippOperationAppContextHanlder from 'shared/components/KippOperationApp/ContextHandler'
import { LoginOperation } from 'shared/components/LoginScreen'

export type KippOperationAppProps = NextAppProps & {
  err: unknown

  title: string
  theme?: Theme
  sessionOperatorKey: string
  sessionExpiryKey: string
  makeGraphQLRequest: (
    params: {
      query: string
      variables: string
    },
    token: string,
  ) => Promise<{ error?: { errorMessageJa: string } | null; result: string } | undefined>
  loginOperation: LoginOperation
  changePasswordOperation: ChangePasswordOperation
  mainOperations: Operation[]
  subOperations?: Operation[]
  additionalHeaderActions?: React.ReactElement
}

const KippOperationApp: React.FC<KippOperationAppProps> = (props) => {
  const body = (
    <SnackbarProvider maxSnack={3}>
      <KippOperationAppContextHanlder {...props} />
    </SnackbarProvider>
  )

  return (
    <>
      <Head>
        <title>{props.title}</title>
        <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width" />
        <link rel="icon" type="image/png" href="/favicon-196.png" sizes="196x196" />
      </Head>
      <CssBaseline />
      <NoSsr>
        {props.theme ? (
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={props.theme}>{body}</ThemeProvider>
          </StyledEngineProvider>
        ) : (
          body
        )}
      </NoSsr>
    </>
  )
}

export default KippOperationApp
