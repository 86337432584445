import { gql } from '@apollo/client'

import { OperationRole } from 'shared/grpc/miraibaraiops/ops_common_pb'

export const query = gql`
  fragment feeTableItem on FeeTableItem {
    amountFrom
    amountTo
    feeAmount
  }
  query ListPostPaidTopUpFees {
    totalCount: postPaidTopUpFeesCount
    result: postPaidTopUpFees (
      limit: 10000
      skip: 0
      desc: true
      sort_field: "applyFromMs"
    ) {
      applyFromMs
      createdMs
      feeSetting {
        generalFeeTable {
          ...feeTableItem
        }
        partnerFeeTables {
          partnerId
          table {
            ...feeTableItem
          }
        }
      }
    }
  }
`

export const assumedRoles = [OperationRole.OPERATION_ROLE_SERVICE_ADMINISTRATOR]
