import { CheckCircle, Error } from '@mui/icons-material'
import { Chip, TableCell, TableRow } from '@mui/material'

import { InternalLink } from 'shared/components/InternalLink'
import PaginatableTable from 'shared/components/PaginatableTable'
import { formatAsDatetime } from 'shared/lib/dateUtils'

const PaginatableTableOperatorLogin: React.FC<{
  data:
    | {
        totalCount: number
        result: {
          succeeded: boolean
          userInputLoginId: string
          operatorId: string | null
          ipAddress: string
          executedMs: number
        }[]
      }
    | undefined
  paginate: (nextPage: number) => void
  perPage: number
  currentPage: number
}> = ({ data, currentPage, perPage, paginate }) => {
  return (
    <PaginatableTable
      page={currentPage}
      perPage={perPage}
      cols={['ログイン処理', 'オペレーター', 'IPアドレス', '処理時刻']}
      totalCount={data?.totalCount}
      onPaginate={paginate}
    >
      {data &&
        data.result.map((log, index) => (
          <TableRow hover key={index} style={!log.succeeded ? { background: '#eee' } : {}}>
            <TableCell align="left">
              {log.succeeded ? (
                <Chip icon={<CheckCircle />} size="small" color="primary" label="成功" />
              ) : (
                <Chip icon={<Error />} size="small" color="secondary" label="失敗" />
              )}
            </TableCell>
            <TableCell align="left">
              <strong>{log.userInputLoginId}</strong>
              <br />
              {log.operatorId && (
                <InternalLink text href={`/operators?operator-id=${log.operatorId}`}>
                  {log.operatorId}
                </InternalLink>
              )}
            </TableCell>
            <TableCell align="left">{log.ipAddress}</TableCell>
            <TableCell align="left">{formatAsDatetime(log.executedMs)}</TableCell>
          </TableRow>
        ))}
    </PaginatableTable>
  )
}

export default PaginatableTableOperatorLogin
