import 'graphiql/graphiql.min.css'

import dynamic from 'next/dynamic'
import React from 'react'

import AccessControl from 'shared/components/AccessControl'
import { OperationRole } from 'shared/grpc/miraibaraiops/ops_common_pb'

import { makeGraphQLRequest } from '@/hooks/grpc'

// graphiql -> codemirror expects window.navigator exists
const GraphiQLInterface = dynamic(() => import('shared/components/GraphiQLInterface'), {
  loading: () => <>Loading...</>,
  ssr: false,
})

export const allowedRoles = [OperationRole.OPERATION_ROLE_CUSTOMER_SUPPORT]

const PageGraphiQL: React.VFC = () => {
  return (
    <AccessControl allowedRoles={allowedRoles}>
      <GraphiQLInterface allowedRoles={allowedRoles} graphQLClient={makeGraphQLRequest} />
    </AccessControl>
  )
}

export default PageGraphiQL
