// Brought functions for exporting from opweb-epre
export const escapeForCSV = (str: string): string => `"${str.replace(/"/g, '""')}"`

export const downloadBlob = (blobparts: BlobPart[], filename: string, type = 'text/csv'): void => {
  const blob = new Blob(blobparts, { type })
  const link = document.createElement('a')
  link.href = window.URL.createObjectURL(blob)
  link.download = filename
  link.click()
  URL.revokeObjectURL(link.href)
}

// NOTE: Generate CSV with UTF-8 BOM
export const generateCsvAsBlobParts = (rows: string[][]): BlobPart[] => {
  const content = rows.map((row) => row.map(escapeForCSV).join(',')).join('\n')
  const bom = new Uint8Array([0xef, 0xbb, 0xbf])
  return [bom, content]
}
