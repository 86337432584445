// source: miraibaraiapp/miraibarai_user.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return Function('return this')(); }).call(null);

goog.exportSymbol('proto.com.kipp.miraibarai.grpc.app.v1.Gender', null, global);
goog.exportSymbol('proto.com.kipp.miraibarai.grpc.app.v1.Occupation', null, global);
goog.exportSymbol('proto.com.kipp.miraibarai.grpc.app.v1.UpdatablePersonalInformation', null, global);
goog.exportSymbol('proto.com.kipp.miraibarai.grpc.app.v1.UsagePurpose', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.kipp.miraibarai.grpc.app.v1.UpdatablePersonalInformation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.kipp.miraibarai.grpc.app.v1.UpdatablePersonalInformation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.kipp.miraibarai.grpc.app.v1.UpdatablePersonalInformation.displayName = 'proto.com.kipp.miraibarai.grpc.app.v1.UpdatablePersonalInformation';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.kipp.miraibarai.grpc.app.v1.UpdatablePersonalInformation.prototype.toObject = function(opt_includeInstance) {
  return proto.com.kipp.miraibarai.grpc.app.v1.UpdatablePersonalInformation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.kipp.miraibarai.grpc.app.v1.UpdatablePersonalInformation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.kipp.miraibarai.grpc.app.v1.UpdatablePersonalInformation.toObject = function(includeInstance, msg) {
  var f, obj = {
    nameKanji: jspb.Message.getFieldWithDefault(msg, 1, ""),
    nameKana: jspb.Message.getFieldWithDefault(msg, 2, ""),
    emailAddress: jspb.Message.getFieldWithDefault(msg, 3, ""),
    mobilePhoneNumber: jspb.Message.getFieldWithDefault(msg, 4, ""),
    homePhoneNumber: jspb.Message.getFieldWithDefault(msg, 5, ""),
    birthdate: jspb.Message.getFieldWithDefault(msg, 6, ""),
    postalCode: jspb.Message.getFieldWithDefault(msg, 7, ""),
    residentAddress: jspb.Message.getFieldWithDefault(msg, 8, ""),
    occupation: jspb.Message.getFieldWithDefault(msg, 9, 0),
    usagePurpose: jspb.Message.getFieldWithDefault(msg, 10, 0),
    gender: jspb.Message.getFieldWithDefault(msg, 11, 0),
    identityVerificationCompleted: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
    partnerUserSignupMs: jspb.Message.getFieldWithDefault(msg, 14, 0),
    deviceIdentifier: jspb.Message.getFieldWithDefault(msg, 15, ""),
    deviceLanguage: jspb.Message.getFieldWithDefault(msg, 16, ""),
    deviceOs: jspb.Message.getFieldWithDefault(msg, 17, ""),
    deviceOsVersion: jspb.Message.getFieldWithDefault(msg, 18, ""),
    partnerAdditionalInfo: jspb.Message.getFieldWithDefault(msg, 19, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.kipp.miraibarai.grpc.app.v1.UpdatablePersonalInformation}
 */
proto.com.kipp.miraibarai.grpc.app.v1.UpdatablePersonalInformation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.kipp.miraibarai.grpc.app.v1.UpdatablePersonalInformation;
  return proto.com.kipp.miraibarai.grpc.app.v1.UpdatablePersonalInformation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.kipp.miraibarai.grpc.app.v1.UpdatablePersonalInformation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.kipp.miraibarai.grpc.app.v1.UpdatablePersonalInformation}
 */
proto.com.kipp.miraibarai.grpc.app.v1.UpdatablePersonalInformation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setNameKanji(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNameKana(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmailAddress(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setMobilePhoneNumber(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setHomePhoneNumber(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setBirthdate(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setPostalCode(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setResidentAddress(value);
      break;
    case 9:
      var value = /** @type {!proto.com.kipp.miraibarai.grpc.app.v1.Occupation} */ (reader.readEnum());
      msg.setOccupation(value);
      break;
    case 10:
      var value = /** @type {!proto.com.kipp.miraibarai.grpc.app.v1.UsagePurpose} */ (reader.readEnum());
      msg.setUsagePurpose(value);
      break;
    case 11:
      var value = /** @type {!proto.com.kipp.miraibarai.grpc.app.v1.Gender} */ (reader.readEnum());
      msg.setGender(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIdentityVerificationCompleted(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setPartnerUserSignupMs(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceIdentifier(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceLanguage(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceOs(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceOsVersion(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setPartnerAdditionalInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.kipp.miraibarai.grpc.app.v1.UpdatablePersonalInformation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.kipp.miraibarai.grpc.app.v1.UpdatablePersonalInformation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.kipp.miraibarai.grpc.app.v1.UpdatablePersonalInformation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.kipp.miraibarai.grpc.app.v1.UpdatablePersonalInformation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNameKanji();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNameKana();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEmailAddress();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMobilePhoneNumber();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getHomePhoneNumber();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getBirthdate();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPostalCode();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getResidentAddress();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getOccupation();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
  f = message.getUsagePurpose();
  if (f !== 0.0) {
    writer.writeEnum(
      10,
      f
    );
  }
  f = message.getGender();
  if (f !== 0.0) {
    writer.writeEnum(
      11,
      f
    );
  }
  f = message.getIdentityVerificationCompleted();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
  f = message.getPartnerUserSignupMs();
  if (f !== 0) {
    writer.writeUint64(
      14,
      f
    );
  }
  f = message.getDeviceIdentifier();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getDeviceLanguage();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getDeviceOs();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getDeviceOsVersion();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getPartnerAdditionalInfo();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
};


/**
 * optional string name_kanji = 1;
 * @return {string}
 */
proto.com.kipp.miraibarai.grpc.app.v1.UpdatablePersonalInformation.prototype.getNameKanji = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.kipp.miraibarai.grpc.app.v1.UpdatablePersonalInformation} returns this
 */
proto.com.kipp.miraibarai.grpc.app.v1.UpdatablePersonalInformation.prototype.setNameKanji = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name_kana = 2;
 * @return {string}
 */
proto.com.kipp.miraibarai.grpc.app.v1.UpdatablePersonalInformation.prototype.getNameKana = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.kipp.miraibarai.grpc.app.v1.UpdatablePersonalInformation} returns this
 */
proto.com.kipp.miraibarai.grpc.app.v1.UpdatablePersonalInformation.prototype.setNameKana = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string email_address = 3;
 * @return {string}
 */
proto.com.kipp.miraibarai.grpc.app.v1.UpdatablePersonalInformation.prototype.getEmailAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.kipp.miraibarai.grpc.app.v1.UpdatablePersonalInformation} returns this
 */
proto.com.kipp.miraibarai.grpc.app.v1.UpdatablePersonalInformation.prototype.setEmailAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string mobile_phone_number = 4;
 * @return {string}
 */
proto.com.kipp.miraibarai.grpc.app.v1.UpdatablePersonalInformation.prototype.getMobilePhoneNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.kipp.miraibarai.grpc.app.v1.UpdatablePersonalInformation} returns this
 */
proto.com.kipp.miraibarai.grpc.app.v1.UpdatablePersonalInformation.prototype.setMobilePhoneNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string home_phone_number = 5;
 * @return {string}
 */
proto.com.kipp.miraibarai.grpc.app.v1.UpdatablePersonalInformation.prototype.getHomePhoneNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.kipp.miraibarai.grpc.app.v1.UpdatablePersonalInformation} returns this
 */
proto.com.kipp.miraibarai.grpc.app.v1.UpdatablePersonalInformation.prototype.setHomePhoneNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string birthdate = 6;
 * @return {string}
 */
proto.com.kipp.miraibarai.grpc.app.v1.UpdatablePersonalInformation.prototype.getBirthdate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.kipp.miraibarai.grpc.app.v1.UpdatablePersonalInformation} returns this
 */
proto.com.kipp.miraibarai.grpc.app.v1.UpdatablePersonalInformation.prototype.setBirthdate = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string postal_code = 7;
 * @return {string}
 */
proto.com.kipp.miraibarai.grpc.app.v1.UpdatablePersonalInformation.prototype.getPostalCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.kipp.miraibarai.grpc.app.v1.UpdatablePersonalInformation} returns this
 */
proto.com.kipp.miraibarai.grpc.app.v1.UpdatablePersonalInformation.prototype.setPostalCode = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string resident_address = 8;
 * @return {string}
 */
proto.com.kipp.miraibarai.grpc.app.v1.UpdatablePersonalInformation.prototype.getResidentAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.kipp.miraibarai.grpc.app.v1.UpdatablePersonalInformation} returns this
 */
proto.com.kipp.miraibarai.grpc.app.v1.UpdatablePersonalInformation.prototype.setResidentAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional Occupation occupation = 9;
 * @return {!proto.com.kipp.miraibarai.grpc.app.v1.Occupation}
 */
proto.com.kipp.miraibarai.grpc.app.v1.UpdatablePersonalInformation.prototype.getOccupation = function() {
  return /** @type {!proto.com.kipp.miraibarai.grpc.app.v1.Occupation} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.com.kipp.miraibarai.grpc.app.v1.Occupation} value
 * @return {!proto.com.kipp.miraibarai.grpc.app.v1.UpdatablePersonalInformation} returns this
 */
proto.com.kipp.miraibarai.grpc.app.v1.UpdatablePersonalInformation.prototype.setOccupation = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};


/**
 * optional UsagePurpose usage_purpose = 10;
 * @return {!proto.com.kipp.miraibarai.grpc.app.v1.UsagePurpose}
 */
proto.com.kipp.miraibarai.grpc.app.v1.UpdatablePersonalInformation.prototype.getUsagePurpose = function() {
  return /** @type {!proto.com.kipp.miraibarai.grpc.app.v1.UsagePurpose} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {!proto.com.kipp.miraibarai.grpc.app.v1.UsagePurpose} value
 * @return {!proto.com.kipp.miraibarai.grpc.app.v1.UpdatablePersonalInformation} returns this
 */
proto.com.kipp.miraibarai.grpc.app.v1.UpdatablePersonalInformation.prototype.setUsagePurpose = function(value) {
  return jspb.Message.setProto3EnumField(this, 10, value);
};


/**
 * optional Gender gender = 11;
 * @return {!proto.com.kipp.miraibarai.grpc.app.v1.Gender}
 */
proto.com.kipp.miraibarai.grpc.app.v1.UpdatablePersonalInformation.prototype.getGender = function() {
  return /** @type {!proto.com.kipp.miraibarai.grpc.app.v1.Gender} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {!proto.com.kipp.miraibarai.grpc.app.v1.Gender} value
 * @return {!proto.com.kipp.miraibarai.grpc.app.v1.UpdatablePersonalInformation} returns this
 */
proto.com.kipp.miraibarai.grpc.app.v1.UpdatablePersonalInformation.prototype.setGender = function(value) {
  return jspb.Message.setProto3EnumField(this, 11, value);
};


/**
 * optional bool identity_verification_completed = 13;
 * @return {boolean}
 */
proto.com.kipp.miraibarai.grpc.app.v1.UpdatablePersonalInformation.prototype.getIdentityVerificationCompleted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.kipp.miraibarai.grpc.app.v1.UpdatablePersonalInformation} returns this
 */
proto.com.kipp.miraibarai.grpc.app.v1.UpdatablePersonalInformation.prototype.setIdentityVerificationCompleted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};


/**
 * optional uint64 partner_user_signup_ms = 14;
 * @return {number}
 */
proto.com.kipp.miraibarai.grpc.app.v1.UpdatablePersonalInformation.prototype.getPartnerUserSignupMs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.kipp.miraibarai.grpc.app.v1.UpdatablePersonalInformation} returns this
 */
proto.com.kipp.miraibarai.grpc.app.v1.UpdatablePersonalInformation.prototype.setPartnerUserSignupMs = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional string device_identifier = 15;
 * @return {string}
 */
proto.com.kipp.miraibarai.grpc.app.v1.UpdatablePersonalInformation.prototype.getDeviceIdentifier = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.kipp.miraibarai.grpc.app.v1.UpdatablePersonalInformation} returns this
 */
proto.com.kipp.miraibarai.grpc.app.v1.UpdatablePersonalInformation.prototype.setDeviceIdentifier = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string device_language = 16;
 * @return {string}
 */
proto.com.kipp.miraibarai.grpc.app.v1.UpdatablePersonalInformation.prototype.getDeviceLanguage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.kipp.miraibarai.grpc.app.v1.UpdatablePersonalInformation} returns this
 */
proto.com.kipp.miraibarai.grpc.app.v1.UpdatablePersonalInformation.prototype.setDeviceLanguage = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string device_os = 17;
 * @return {string}
 */
proto.com.kipp.miraibarai.grpc.app.v1.UpdatablePersonalInformation.prototype.getDeviceOs = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.kipp.miraibarai.grpc.app.v1.UpdatablePersonalInformation} returns this
 */
proto.com.kipp.miraibarai.grpc.app.v1.UpdatablePersonalInformation.prototype.setDeviceOs = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string device_os_version = 18;
 * @return {string}
 */
proto.com.kipp.miraibarai.grpc.app.v1.UpdatablePersonalInformation.prototype.getDeviceOsVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.kipp.miraibarai.grpc.app.v1.UpdatablePersonalInformation} returns this
 */
proto.com.kipp.miraibarai.grpc.app.v1.UpdatablePersonalInformation.prototype.setDeviceOsVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string partner_additional_info = 19;
 * @return {string}
 */
proto.com.kipp.miraibarai.grpc.app.v1.UpdatablePersonalInformation.prototype.getPartnerAdditionalInfo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.kipp.miraibarai.grpc.app.v1.UpdatablePersonalInformation} returns this
 */
proto.com.kipp.miraibarai.grpc.app.v1.UpdatablePersonalInformation.prototype.setPartnerAdditionalInfo = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * @enum {number}
 */
proto.com.kipp.miraibarai.grpc.app.v1.Occupation = {
  OCCUPATION_INVALID: 0,
  OCCUPATION_EMPLOYEE: 1,
  OCCUPATION_COMPANY_OFFICER: 2,
  OCCUPATION_PUBLIC_OFFICER: 3,
  OCCUPATION_SELF_EMPLOYED_WORKER: 4,
  OCCUPATION_PART_TIME_WORKER: 5,
  OCCUPATION_STUDENT: 6,
  OCCUPATION_HOMEMAKER: 7,
  OCCUPATION_PROFESSIONAL_JOB: 8,
  OCCUPATION_UNEMPLOYED: 9,
  OCCUPATION_OTHER: 10
};

/**
 * @enum {number}
 */
proto.com.kipp.miraibarai.grpc.app.v1.UsagePurpose = {
  USAGE_PURPOSE_INVALID: 0,
  USAGE_PURPOSE_PERSONAL: 1,
  USAGE_PURPOSE_BUSINESS: 2
};

/**
 * @enum {number}
 */
proto.com.kipp.miraibarai.grpc.app.v1.Gender = {
  GENDER_INVALID: 0,
  GENDER_MALE: 1,
  GENDER_FEMALE: 2,
  GENDER_NOT_ANSWERED: 3
};

goog.object.extend(exports, proto.com.kipp.miraibarai.grpc.app.v1);
