import { TextField } from '@mui/material'
import { addDays, isFuture } from 'date-fns'
import { useContext, useEffect } from 'react'
import { useForm } from 'react-hook-form'

import DialogConfirmation from 'shared/components/DialogConfirmation'
import { DialogForm } from 'shared/components/DialogForm'
import { context as OperationAppContext } from 'shared/context/OperationApp'
import useToggle from 'shared/hooks/useToggle'
import { formatAsDatetime, formatAsJST, parseDateInput } from 'shared/lib/dateUtils'

import { usePostPaidTopUpFee } from '@/hooks/grpc'

interface Form {
  feeSetting: string
  applyFrom: string
}

const CreateNewPostPaidTopUpFees: React.FC<{
  sampleFeeSetting: string
  onSuccess: () => void
  onCancel: () => void
}> = ({ sampleFeeSetting, onSuccess, onCancel }) => {
  const [openedConfirmDialog, { enable: showDialog, disable: hideDialog }] = useToggle(false)
  const appContext = useContext(OperationAppContext)
  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
    reset,
    getValues,
  } = useForm<Form>({
    mode: 'onSubmit',
    defaultValues: {
      applyFrom: formatAsJST(addDays(new Date(), 1), "yyyy-MM-dd'T'HH:mm"),
      feeSetting: sampleFeeSetting,
    },
  })
  const { call, response } = usePostPaidTopUpFee()

  useEffect(() => {
    if (response && response.error === undefined) {
      appContext.flashMessage('新しいバージョンを作成しました', 'success')
      onSuccess()
    }
  }, [appContext, response, onSuccess])

  const submit = () => {
    hideDialog()
    call({
      applyFromMs: parseDateInput(getValues().applyFrom).getTime(),
      feeSetting: getValues().feeSetting,
    })
  }

  return (
    <>
      <DialogForm
        title="新しい手数料設定を追加する"
        submitButtonTitle="追加する"
        size="md"
        reset={reset}
        onCancel={onCancel}
        onSubmit={handleSubmit(showDialog)}
      >
        <TextField
          margin="normal"
          label="適用開始日時"
          type="datetime-local"
          fullWidth
          InputLabelProps={{ shrink: true }}
          error={!!errors.applyFrom}
          helperText={errors.applyFrom && '未来の日時を指定してください'}
          {...register('applyFrom', {
            required: true,
            validate: (applyFrom) => {
              try {
                return isFuture(parseDateInput(applyFrom).getTime())
              } catch (e) {
                return false
              }
            },
          })}
        />
        <TextField
          multiline
          rows={20}
          margin="normal"
          label="設定"
          type="text"
          fullWidth
          error={!!errors.feeSetting}
          helperText={errors.feeSetting && '入力してください'}
          {...register('feeSetting', { required: true })}
        />
      </DialogForm>
      <DialogConfirmation
        open={openedConfirmDialog}
        title="新しい手数料設定を作成する"
        message={`指定された手数料設定を ${
          isValid ? formatAsDatetime(parseDateInput(getValues().applyFrom)) : ''
        } から適用します。よろしいですか？`}
        onConfirm={submit}
        onCancel={hideDialog}
      ></DialogConfirmation>
    </>
  )
}

export default CreateNewPostPaidTopUpFees
