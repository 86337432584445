import { differenceInDays, isFuture, isPast } from 'date-fns'

import { DebtCancelType } from 'shared/grpc/server/post_pay_pb'
import { formatAsDatetime, startOfDayInZone } from 'shared/lib/dateUtils'
import { formatAmountE3 } from 'shared/lib/decorators'
import { DecorativeColor, textColors } from 'shared/lib/styles'

export const presentListPostPayDebts = ({ result }: ListPostPayDebts) =>
  result.map((postPayDebt) => {
    let overdueDays = 0
    if (postPayDebt.paidMs && postPayDebt.paidMs > postPayDebt.dueMs) {
      overdueDays = differenceInDays(startOfDayInZone(postPayDebt.paidMs), startOfDayInZone(postPayDebt.dueMs)) + 1
    } else if (!postPayDebt.paidMs && isPast(postPayDebt.dueMs)) {
      overdueDays = differenceInDays(startOfDayInZone(), startOfDayInZone(postPayDebt.dueMs)) + 1
    }

    const [status, statusColor]: [string, DecorativeColor | null] = (() => {
      if (postPayDebt.canceledMs !== null) {
        return postPayDebt.cancelType?.value === DebtCancelType.DEBT_CANCEL_TYPE_REGISTER_OPERATIONAL_RECEIPT
          ? ['入金消込済み', 'teal']
          : ['取消済み', null]
      }
      if (postPayDebt.paidMs !== null) return ['返済済み', 'green']
      if (isPast(postPayDebt.dueMs)) return ['延滞中', 'amber']
      if (isFuture(postPayDebt.dueMs)) return ['未返済', 'indigo']
      return ['不明', null]
    })()

    const repaymentMethod =
      postPayDebt.paidMs &&
      (postPayDebt.repayment.find((debt) => debt.byBankPayPayment?.paidMs === postPayDebt.paidMs)
        ? 'Bank Pay'
        : postPayDebt.repayment.find((debt) => debt.byConvenienceStore?.paidMs === postPayDebt.paidMs)
          ? 'コンビニ'
          : postPayDebt.repayment.find((debt) => debt.byPartnerBalance?.paidMs === postPayDebt.paidMs)
            ? 'パートナー残高'
            : '不明')

    const closedMs = postPayDebt.paidMs || postPayDebt.canceledMs
    const closed = closedMs !== null

    return {
      id: postPayDebt.debtId,
      principalAmount: `${formatAmountE3(postPayDebt.principalAmountE3)} 円`,
      feeAmount: `${formatAmountE3(postPayDebt.feeAmountE3)} 円`,
      appUserId: postPayDebt.miraibaraiAppUser.appUserId,
      partnerId: postPayDebt.miraibaraiPartnerId,
      nameKanji: postPayDebt.miraibaraiAppUser.miraibaraiPersonalData?.nameKanji || '使用者名不明',
      status: `${status}${overdueDays > 0 ? `（${overdueDays}日延滞）` : ''}`,
      statusColor,
      canceled: postPayDebt.canceledMs !== null,
      events: (() =>
        [
          {
            label: `${formatAsDatetime(postPayDebt.appliedMs)} に申請`,
            time: postPayDebt.appliedMs,
            possible: true,
            color: null,
          },
          {
            label: `${formatAsDatetime(postPayDebt.dueMs)} が返済期限`,
            time: postPayDebt.dueMs - 1,
            possible: !closed || closedMs > postPayDebt.dueMs,
            color: (!closed || closedMs > postPayDebt.dueMs) && isPast(postPayDebt.dueMs - 1) ? textColors.amber : null,
          },
          ...(postPayDebt.paidMs
            ? [
                {
                  label: `${formatAsDatetime(postPayDebt.paidMs)} に返済完了（${repaymentMethod}）`,
                  time: postPayDebt.paidMs,
                  possible: true,
                  color: textColors.green,
                },
              ]
            : []),
          ...(postPayDebt.canceledMs
            ? [
                {
                  label: `${formatAsDatetime(postPayDebt.canceledMs)} に${
                    postPayDebt.cancelType?.value === DebtCancelType.DEBT_CANCEL_TYPE_REGISTER_OPERATIONAL_RECEIPT
                      ? '入金消込として取消'
                      : '取消'
                  }`,
                  time: postPayDebt.canceledMs,
                  possible: true,
                  color: null,
                },
              ]
            : []),
        ].sort((a, b) => a.time - b.time))(),
    }
  })
