import { ApolloProvider } from '@apollo/client'
import { Backdrop, CircularProgress, Container } from '@mui/material'
import { AlertProps } from '@mui/material/Alert'
import * as Sentry from '@sentry/react'
import { addDays, addMinutes, differenceInCalendarDays, differenceInMilliseconds } from 'date-fns'
import { useSnackbar } from 'notistack'
import { useCallback, useEffect, useMemo, useState } from 'react'

import DialogRemindFreezingAccount from 'shared/components/DialogRemindFreezingAccount'
import GlobalNavigation from 'shared/components/GlobalNavigation'
import type { KippOperationAppProps } from 'shared/components/KippOperationApp'
import LoginScreen from 'shared/components/LoginScreen'
import { context as OperationAppContext, OperationAppContextType } from 'shared/context/OperationApp'
import { useAutoReload } from 'shared/hooks/useAutoReload'
import { getCookie, markCookieExpired } from 'shared/lib/cookie'
import createApolloClient from 'shared/lib/createApolloClient'

const DURATION_IN_MINUTES_UNTIL_LOGOUT_BY_NO_OPERATION = 30

const KippOperationAppContextHanlder: React.FC<KippOperationAppProps> = ({
  Component,
  pageProps,
  err,
  title,
  sessionOperatorKey,
  sessionExpiryKey,
  makeGraphQLRequest,
  loginOperation,
  changePasswordOperation,
  mainOperations,
  subOperations,
  additionalHeaderActions = <></>,
}) => {
  const { enqueueSnackbar } = useSnackbar()
  const [currentOperator, setNewCurrentOperator] = useState<OperationAppContextType['currentOperator']>(null)
  const [sessionExpiresAt, setSessionExpiresAt] = useState<Date | null>(null)
  const [visibleIndicator, setVisibleIndicator] = useState(false)
  const [daysUntilFreezingAccount, setDaysUntilFreezingAccount] = useState<number | null>(null)

  const flashMessage = useCallback(
    (message: string, severity: AlertProps['severity']) => {
      enqueueSnackbar(message, {
        anchorOrigin: { vertical: 'top', horizontal: 'center' },
        preventDuplicate: true,
        variant: severity,
      })
    },
    [enqueueSnackbar],
  )

  const saveOperatorIntoCookie = useCallback(
    (operator: OperationAppContextType['currentOperator']) => {
      document.cookie = `${sessionOperatorKey}=${JSON.stringify(operator)}; path=/; expires=${addMinutes(
        new Date(),
        DURATION_IN_MINUTES_UNTIL_LOGOUT_BY_NO_OPERATION,
      ).toUTCString()}`
    },
    [sessionOperatorKey],
  )

  const setCurrentOperator = useCallback(
    (newOperator: OperationAppContextType['currentOperator']) => {
      if (newOperator) {
        saveOperatorIntoCookie(newOperator)
        Sentry.setUser({ operatorId: newOperator.id })
        setDaysUntilFreezingAccount(
          newOperator.lastPasswordUpdatedMs
            ? differenceInCalendarDays(addDays(new Date(newOperator.lastPasswordUpdatedMs), 90), new Date())
            : null,
        )
      } else {
        markCookieExpired(sessionOperatorKey)
        markCookieExpired(sessionExpiryKey)
        Sentry.setUser({ operatorId: null })
        flashMessage('ログインしてください', 'error')
      }
      setNewCurrentOperator(newOperator)
    },
    [saveOperatorIntoCookie, sessionOperatorKey, sessionExpiryKey, flashMessage],
  )

  useEffect(() => {
    try {
      const persistedUser: unknown = JSON.parse(getCookie(sessionOperatorKey) || 'null')
      setCurrentOperator(assertOperator(persistedUser) ? persistedUser : null)
    } catch {
      setCurrentOperator(null)
    }
  }, [sessionOperatorKey, setCurrentOperator])

  const assertOperator = (operator: unknown): operator is NonNullable<OperationAppContextType['currentOperator']> => {
    return (
      typeof operator === 'object' &&
      operator !== null &&
      'name' in operator &&
      'id' in operator &&
      'sessionToken' in operator &&
      'roles' in operator
    )
  }

  const setLoading = (isLoading: boolean) => {
    setVisibleIndicator(isLoading)

    if (isLoading) {
      // Confirm current session is still valid; other tabs might proceed logout beforehand (cookie is removed, but the tab still have an operator)
      if (getCookie(sessionOperatorKey) === null && currentOperator !== null) {
        setVisibleIndicator(false)
        forceLogout('セッションが終了しました。再度ログインしてください')
      } else {
        extendSession()
      }
    }
  }

  const forceLogout = useCallback(
    (message: string) => {
      setCurrentOperator(null)
      flashMessage(message, 'error')
    },
    [setCurrentOperator, flashMessage],
  )

  const extendSession = () => {
    saveOperatorIntoCookie(currentOperator)
    const expiresAt = addMinutes(new Date(), DURATION_IN_MINUTES_UNTIL_LOGOUT_BY_NO_OPERATION)
    setSessionExpiresAt(expiresAt)
    document.cookie = `${sessionExpiryKey}=${expiresAt.toUTCString()}` // To share among multiple tabs
  }

  useEffect(() => {
    if (currentOperator === null || sessionExpiresAt === null) return
    const autoLogout = setTimeout(
      () => {
        // When cookie is expired already;
        if (getCookie(sessionOperatorKey) === null) {
          forceLogout('一定時間を越えて操作がなかったためログアウトしました')
        } else {
          const globalSessionExpiresAt = getCookie(sessionExpiryKey)
          if (globalSessionExpiresAt) {
            const nextExpiresAt = Date.parse(globalSessionExpiresAt)
            setSessionExpiresAt(new Date(nextExpiresAt))
          }
        }
      },
      differenceInMilliseconds(sessionExpiresAt, new Date()),
    )
    return () => clearTimeout(autoLogout)
  }, [currentOperator, forceLogout, sessionExpiresAt, sessionOperatorKey, sessionExpiryKey])

  const reload = useCallback(() => document.location.reload(), [])
  useAutoReload(reload)

  const apolloClient = useMemo(() => {
    return createApolloClient(makeGraphQLRequest, currentOperator?.sessionToken || '')
  }, [makeGraphQLRequest, currentOperator])

  return (
    <OperationAppContext.Provider value={{ currentOperator, setCurrentOperator, flashMessage, setLoading }}>
      <ApolloProvider client={apolloClient}>
        {currentOperator === null ? (
          <Container component="main">
            <LoginScreen operation={loginOperation} />
          </Container>
        ) : (
          <GlobalNavigation
            mainOperations={mainOperations}
            subOperations={subOperations}
            title={title}
            changePasswordOperation={changePasswordOperation}
            additionalHeaderActions={additionalHeaderActions}
          >
            <Container component="main" maxWidth="xl">
              {/* Workaround for https://github.com/vercel/next.js/issues/8592 */}
              <Component {...pageProps} err={err} />
              {daysUntilFreezingAccount && daysUntilFreezingAccount <= 10 && (
                <DialogRemindFreezingAccount
                  remainingDays={daysUntilFreezingAccount}
                  onClose={() => setDaysUntilFreezingAccount(null)}
                />
              )}
            </Container>
          </GlobalNavigation>
        )}
        <Backdrop
          open={visibleIndicator}
          sx={(theme) => ({
            zIndex: theme.zIndex.tooltip + 1,
            color: '#fff',
          })}
        >
          <CircularProgress />
        </Backdrop>
      </ApolloProvider>
    </OperationAppContext.Provider>
  )
}

export default KippOperationAppContextHanlder
