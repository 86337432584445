import { gql } from '@apollo/client'

import { OperationRole } from 'shared/grpc/miraibaraiops/ops_common_pb'

export const query = gql`
  query GetExternalScreeningApiSetting {
    result: externalScreeningApiSetting {
      updatedMs
      encryptedExternalScreeningApiSettingData {
        miraibaraiInternal {
          endpointUri
          bearerToken
          graphqlQueryTemplate
          webhookUri
        }
      }
    }
  }
`

export const assumedRoles = [OperationRole.OPERATION_ROLE_SERVICE_ADMINISTRATOR]
