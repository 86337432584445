import { useRouter } from 'next/router'
import { useCallback, useEffect, useMemo, useState } from 'react'

import { buildRouteParams } from 'shared/lib/url'

const parsePageFromPath = (path: string): string => {
  const matched = path.match(new RegExp('[&?]page=(.*)(&|$)'))
  return (matched && matched[1]) ?? '1'
}

const usePagination = (
  perPage = 25,
): { currentPage: number; paginate: (nextPage: number) => void; perPage: number; skip: number } => {
  const router = useRouter()

  const initialPage = router.query.page ?? parsePageFromPath(router.asPath)

  const [currentPage, setPage] = useState(Number.isNaN(Number(initialPage)) ? 1 : Number(initialPage))
  const skip = useMemo(() => (currentPage - 1) * perPage, [currentPage, perPage])

  const paginate = useCallback(
    (nextPage: number) => {
      const query = { ...router.query, page: String(nextPage) }
      router.push(...buildRouteParams(router.pathname, query))
    },
    [router],
  )

  useEffect(() => {
    const handleRouteChange = (path: string) => {
      const newPage = Number(parsePageFromPath(path))
      setPage(newPage)
    }

    router.events.on('routeChangeComplete', handleRouteChange)

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events, paginate, currentPage])

  return { currentPage, paginate, perPage, skip }
}

export default usePagination
