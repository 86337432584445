import { TextField } from '@mui/material'
import { useContext, useEffect } from 'react'
import { useForm } from 'react-hook-form'

import { DialogForm } from 'shared/components/DialogForm'
import { context as OperationAppContext } from 'shared/context/OperationApp'
import { passwordRegexp } from 'shared/lib/inputValidation'

import { useOperatorChangeOtherOperatorPassword } from '@/hooks/grpc'

interface FormChangeOtherOperatorPassword {
  password: string
  passwordConfirmation: string
}

const ChangeOtherOperatorPassword: React.FC<{
  operator: ElementOf<ListOperators['result']>
  onSuccess: () => void
  onCancel: () => void
}> = ({ operator, onSuccess, onCancel }) => {
  const appContext = useContext(OperationAppContext)
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
  } = useForm<FormChangeOtherOperatorPassword>({
    mode: 'onSubmit',
  })
  const { call, response } = useOperatorChangeOtherOperatorPassword()

  const submit = (form: FormChangeOtherOperatorPassword) => {
    call({
      operatorId: operator.operatorId,
      newPassword: form.password,
    })
  }

  useEffect(() => {
    if (!response) return
    if (response.error) {
      console.error(response.error)
    } else {
      reset()
      appContext.flashMessage(`${operator.loginId} のパスワードを変更しました`, 'success')
      onSuccess()
    }
  }, [appContext, operator, response, onSuccess, reset])

  return (
    <DialogForm
      title={`${operator.name} のパスワードを変更する`}
      submitButtonTitle="変更する"
      reset={reset}
      size="xs"
      onCancel={onCancel}
      onSubmit={handleSubmit(submit)}
    >
      <TextField
        autoFocus
        autoComplete="new-password"
        margin="normal"
        label="新しいパスワード"
        type="password"
        fullWidth
        error={!!errors.password}
        helperText={errors.password && '英字、数字を含む7字以上の文字列を入力してください'}
        {...register('password', { required: true, pattern: passwordRegexp })}
      />
      <TextField
        margin="normal"
        autoComplete="new-password"
        label="パスワードの再入力"
        type="password"
        fullWidth
        error={!!errors.passwordConfirmation}
        helperText={errors.passwordConfirmation && '入力されたパスワードが一致していません'}
        {...register('passwordConfirmation', {
          required: true,
          validate: { match: (passworConfirmation) => passworConfirmation === getValues('password') },
        })}
      />
    </DialogForm>
  )
}

export default ChangeOtherOperatorPassword
