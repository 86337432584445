import { amber, green, grey, indigo, red, teal } from '@mui/material/colors'
import { SxProps, useTheme } from '@mui/material/styles'

export type DecorativeColor = 'green' | 'indigo' | 'red' | 'amber' | 'teal' | 'grey'

export const textColors = {
  green: green[800],
  indigo: indigo[400],
  red: red[800],
  amber: amber[800],
  teal: teal[800],
}

export const useDecorativeColors = (): Record<DecorativeColor, SxProps> => {
  const theme = useTheme()

  return {
    green: {
      color: theme.palette.getContrastText(green[600]),
      backgroundColor: green[600],
    },
    indigo: {
      color: theme.palette.getContrastText(indigo[100]),
      backgroundColor: indigo[100],
    },
    red: {
      color: theme.palette.getContrastText(red[600]),
      backgroundColor: red[600],
    },
    amber: {
      color: theme.palette.getContrastText(amber[600]),
      backgroundColor: amber[600],
    },
    teal: {
      color: theme.palette.getContrastText(teal[200]),
      backgroundColor: teal[200],
    },
    grey: {
      color: theme.palette.getContrastText(grey[800]),
      backgroundColor: grey[800],
    },
  }
}
