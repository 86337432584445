import { RunQueryRequest, RunQueryResponse } from 'shared/grpc/miraibaraiops/graphql_proxy_api_pb'
import { GraphQLProxyAPIClient } from 'shared/grpc/miraibaraiops/Graphql_proxy_apiServiceClientPb'
import { AddCommentRequest, AddCommentResponse } from 'shared/grpc/miraibaraiops/miraibarai_app_user_api_pb'
import { MiraibaraiAppUserAPIClient } from 'shared/grpc/miraibaraiops/Miraibarai_app_user_apiServiceClientPb'
import {
  AddOperatorRequest,
  AddOperatorResponse,
  AssignRoleRequest,
  AssignRoleResponse,
  ChangeOtherOperatorPasswordRequest,
  ChangeOtherOperatorPasswordResponse,
  ChangePasswordRequest,
  ChangePasswordResponse,
  LoginRequest,
  LoginResponse,
  ResumeOperatorRequest,
  ResumeOperatorResponse,
  SuspendOperatorRequest,
  SuspendOperatorResponse,
} from 'shared/grpc/miraibaraiops/operator_api_pb'
import { OperatorAPIClient } from 'shared/grpc/miraibaraiops/Operator_apiServiceClientPb'
import { RoleAssignment } from 'shared/grpc/miraibaraiops/operator_pb'
import {
  CancelPostPayDebtRequest,
  CancelPostPayDebtResponse,
  PinPostPayCreditLimitRequest,
  PinPostPayCreditLimitResponse,
  UpdateExternalScreeningApiSettingRequest,
  UpdatePostPaidTopUpFeesRequest,
  UpdatePostPaidTopUpFeesResponse,
  UpdatePostPayScreeningRuleSetRequest,
  UpdatePostPayScreeningRuleSetResponse,
} from 'shared/grpc/miraibaraiops/post_pay_api_pb'
import { PostPayAPIClient } from 'shared/grpc/miraibaraiops/Post_pay_apiServiceClientPb'
import { ExternalScreeningApiSettingData, MiraibaraiInternalApiSetting } from 'shared/grpc/server/screening_pb'
import { promisifyGrpcClient, requestMetadata as metadata, useGrpc } from 'shared/hooks/grpc'

const endpoint = process.env.NEXT_PUBLIC_API_ENDPOINT || 'https://unknown.miraibarai.kipp-dev.com'

const clientGraphQLProxyAPI = new GraphQLProxyAPIClient(endpoint, null, null)
const clientOperatorAPI = new OperatorAPIClient(endpoint, null, null)
const clientPostPayAPI = new PostPayAPIClient(endpoint, null, null)
const clientMiraibaraiAppUserAPI = new MiraibaraiAppUserAPIClient(endpoint, null, null)

export const makeGraphQLRequest = async (
  params: { query: string; variables: string },
  token: string,
): Promise<RunQueryResponse.AsObject | undefined> => {
  const request = new RunQueryRequest()
  request.setQuery(params.query)
  request.setVariables(params.variables)

  try {
    const response = await promisifyGrpcClient<RunQueryResponse>((cb) => {
      clientGraphQLProxyAPI.runQuery(request, metadata(token), cb)
    })
    return response.toObject()
  } catch (e) {
    console.error('Failed in GraphQL request', e)
    throw e
  }
}

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const useOperatorChangePassword = () => {
  return useGrpc<ChangePasswordRequest.AsObject, ChangePasswordResponse, ChangePasswordResponse.AsObject>(
    (params, sessionToken, cb) => {
      const request = new ChangePasswordRequest()
      request.setOldPassword(params.oldPassword)
      request.setNewPassword(params.newPassword)
      clientOperatorAPI.changePassword(request, metadata(sessionToken), cb)
    },
  )
}

export const useOperatorChangeOtherOperatorPassword = () => {
  return useGrpc<
    ChangeOtherOperatorPasswordRequest.AsObject,
    ChangeOtherOperatorPasswordResponse,
    ChangeOtherOperatorPasswordResponse.AsObject
  >((params, sessionToken, cb) => {
    const request = new ChangeOtherOperatorPasswordRequest()
    request.setOperatorId(params.operatorId)
    request.setNewPassword(params.newPassword)

    clientOperatorAPI.changeOtherOperatorPassword(request, metadata(sessionToken), cb)
  })
}

export const useOperatorAddOperator = () => {
  return useGrpc<AddOperatorRequest.AsObject, AddOperatorResponse, AddOperatorResponse.AsObject>(
    (params, sessionToken, cb) => {
      const request = new AddOperatorRequest()
      request.setName(params.name)
      request.setLoginId(params.loginId)
      request.setPassword(params.password)
      request.setCreateApiToken(params.createApiToken)

      clientOperatorAPI.addOperator(request, metadata(sessionToken), cb)
    },
  )
}

export const useOperatorSuspendOperator = () => {
  return useGrpc<SuspendOperatorRequest.AsObject, SuspendOperatorResponse, SuspendOperatorResponse.AsObject>(
    (params, sessionToken, cb) => {
      const request = new SuspendOperatorRequest()
      request.setTargetOperatorId(params.targetOperatorId)
      clientOperatorAPI.suspendOperator(request, metadata(sessionToken), cb)
    },
  )
}

export const useOperatorResumeOperator = () => {
  return useGrpc<ResumeOperatorRequest.AsObject, ResumeOperatorResponse, ResumeOperatorResponse.AsObject>(
    (params, sessionToken, cb) => {
      const request = new ResumeOperatorRequest()
      request.setTargetOperatorId(params.targetOperatorId)

      clientOperatorAPI.resumeOperator(request, metadata(sessionToken), cb)
    },
  )
}

export const useOperatorAssignRole = () => {
  return useGrpc<AssignRoleRequest.AsObject, AssignRoleResponse, AssignRoleResponse.AsObject>(
    (params, sessionToken, cb) => {
      const request = new AssignRoleRequest()
      request.setOperatorId(params.operatorId)
      request.setRoleAssignmentsList(
        params.roleAssignmentsList.map((role) => {
          const roleAssignment = new RoleAssignment()
          roleAssignment.setIpRangeId(role.ipRangeId)
          roleAssignment.setRole(role.role)
          return roleAssignment
        }),
      )

      clientOperatorAPI.assignRole(request, metadata(sessionToken), cb)
    },
  )
}

export const useOperatorLogin = () => {
  return useGrpc<LoginRequest.AsObject, LoginResponse, LoginResponse.AsObject>((params, sessionToken, cb) => {
    const request = new LoginRequest()
    request.setLoginId(params.loginId)
    request.setPassword(params.password)

    clientOperatorAPI.login(request, metadata(sessionToken), cb)
  })
}

export const usePostPayPinPostPayCreditLimit = () => {
  return useGrpc<
    PinPostPayCreditLimitRequest.AsObject,
    PinPostPayCreditLimitResponse,
    PinPostPayCreditLimitResponse.AsObject
  >((params, sessionToken, cb) => {
    const request = new PinPostPayCreditLimitRequest()
    request.setAppUserId(params.appUserId)
    request.setCreditLimitValue(params.creditLimitValue)

    clientPostPayAPI.pinPostPayCreditLimit(request, metadata(sessionToken), cb)
  })
}

export const usePostPayUpdatePostPayScreeningRuleSet = () => {
  return useGrpc<
    UpdatePostPayScreeningRuleSetRequest.AsObject,
    UpdatePostPayScreeningRuleSetResponse,
    UpdatePostPayScreeningRuleSetResponse.AsObject
  >((params, sessionToken, cb) => {
    const request = new UpdatePostPayScreeningRuleSetRequest()
    request.setRuleSetVersion(params.ruleSetVersion)
    request.setPostPayScreeningRuleSetSetting(params.postPayScreeningRuleSetSetting)

    clientPostPayAPI.updatePostPayScreeningRuleSet(request, metadata(sessionToken), cb)
  })
}

export const usePostPaidTopUpFee = () => {
  return useGrpc<
    UpdatePostPaidTopUpFeesRequest.AsObject,
    UpdatePostPaidTopUpFeesResponse,
    UpdatePostPaidTopUpFeesResponse.AsObject
  >((params, sessionToken, cb) => {
    const request = new UpdatePostPaidTopUpFeesRequest()
    request.setApplyFromMs(params.applyFromMs)
    request.setFeeSetting(params.feeSetting)

    clientPostPayAPI.updatePostPaidTopUpFees(request, metadata(sessionToken), cb)
  })
}

export const usePostPayCancelPostPayDebt = () => {
  return useGrpc<CancelPostPayDebtRequest.AsObject, CancelPostPayDebtResponse, CancelPostPayDebtResponse.AsObject>(
    (params, sessionToken, cb) => {
      const request = new CancelPostPayDebtRequest()
      request.setDebtId(params.debtId)
      request.setCancelType(params.cancelType)

      clientPostPayAPI.cancelPostPayDebt(request, metadata(sessionToken), cb)
    },
  )
}

// Request is expecting different object from Response object
export const usePostPayUpdatePostPayScreeningApiSetting = () => {
  return useGrpc<
    MiraibaraiInternalApiSetting.AsObject,
    UpdatePostPayScreeningRuleSetResponse,
    UpdatePostPayScreeningRuleSetResponse.AsObject
  >((params, sessionToken, cb) => {
    const miraibaraiInternalApiSetting = new MiraibaraiInternalApiSetting()
    miraibaraiInternalApiSetting.setBearerToken(params.bearerToken)
    miraibaraiInternalApiSetting.setEndpointUri(params.endpointUri)
    miraibaraiInternalApiSetting.setGraphqlQueryTemplate(params.graphqlQueryTemplate)
    miraibaraiInternalApiSetting.setWebhookUri(params.webhookUri)

    const data = new ExternalScreeningApiSettingData()
    data.setMiraibaraiInternal(miraibaraiInternalApiSetting)
    const request = new UpdateExternalScreeningApiSettingRequest()
    request.setData(data)

    clientPostPayAPI.updateExternalScreeningApiSetting(request, metadata(sessionToken), cb)
  })
}

export const useMiraibaraiAppUserAddComment = () => {
  return useGrpc<AddCommentRequest.AsObject, AddCommentResponse, AddCommentResponse.AsObject>(
    (params, sessionToken, cb) => {
      const request = new AddCommentRequest()
      request.setAppUserId(params.appUserId)
      request.setCommentBody(params.commentBody)

      clientMiraibaraiAppUserAPI.addComment(request, metadata(sessionToken), cb)
    },
  )
}

/* eslint-enable @typescript-eslint/explicit-module-boundary-types */
