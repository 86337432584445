import { addDays, addMonths, endOfDay, endOfMonth, isFuture, isPast, parse, startOfDay, startOfMonth } from 'date-fns'
import { format, toDate, utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz'

const PREFERRED_TIMEZONE = 'Asia/Tokyo'

/**
 *
 * @param datetime - Y-M-d or YYYY-MM-ddTHH:mm
 * @returns Date object by parsed datetime as JST
 *
 */
export const parseDateInput = (datetime: string): Date => {
  return toDate(datetime, { timeZone: PREFERRED_TIMEZONE })
}

/**
 *
 * @param datetimeString - string to be parsed
 * @param parseFormat - assumed format to parse datetimeString
 * @returns Date object for parsed datetimeString on JST
 *
 */
export const parseAsJST = (datetimeString: string, parseFormat: string): Date => {
  return zonedTimeToUtc(parse(datetimeString, parseFormat, new Date()), PREFERRED_TIMEZONE)
}

/**
 *
 * @param msOrDate - JS Date instance, epoch time in millisecond, or Y-M-d, YYYY-MM-ddTHH:mm string. If missing, uses current time
 * @returns Formatted datetime string which can be used for input[type=datetime]
 *
 */
export const formatAsDatetime = (msOrDate: number | Date | string = new Date()): string => {
  return formatAsJST(typeof msOrDate === 'string' ? parseDateInput(msOrDate) : msOrDate, 'y/MM/dd HH:mm:ss')
}

/**
 *
 * @param msOrDate - JS Date instance or epoch time in millisecond. If missing, uses current time
 * @returns Hyphenated date string which can be used for input[type=date]
 *
 */
export const formatAsHyphenatedDate = (msOrDate: number | Date = new Date()): string => {
  return formatAsJST(msOrDate, 'y-MM-dd')
}

/**
 *
 * @param msOrDate - JS Date instance or epoch time in millisecond.
 * @returns Formatted datetime string for human like 2021/07/25
 *
 */
export const formatAsCalendarDate = (msOrDate: number | Date | string): string => {
  return formatAsJST(typeof msOrDate === 'string' ? parseDateInput(msOrDate) : msOrDate, 'yyyy/MM/dd')
}

/**
 *
 * @param yymmdd - YYMMDD string
 * @returns Formatted datetime string for given YYMMDD
 *
 */
export const formatYYYYMMDDAsCalendarDate = (yymmdd: string): string => {
  try {
    return formatAsCalendarDate(parseAsJST(yymmdd, 'yyyyMMdd'))
  } catch (e) {
    if (e instanceof RangeError) {
      return '無効な日付です'
    } else {
      throw e
    }
  }
}

/**
 *
 * @param msOrDate - JS Date instance or epoch time in millisecond.
 * @param expectedFormat - https://date-fns.org/v2.23.0/docs/format
 * @returns Formatted datetime string
 *
 */
export const formatAsJST = (msOrDate: Date | number, expectedFormat: string): string => {
  return format(utcToZonedTime(msOrDate, PREFERRED_TIMEZONE), expectedFormat, {
    timeZone: PREFERRED_TIMEZONE,
  })
}

/**
 *
 * @param msOrDate - JS Date instance or epoch time in millisecond. If missing, uses current time
 * @returns Boolean to indicate whether the given msOrDate is the future as date. Any timestamp in today should be false
 *
 */
export const isFutureDate = (msOrDate: number | Date): boolean => {
  return isFuture(startOfDayInZone(msOrDate))
}

/**
 *
 * @param msOrDate - JS Date instance or epoch time in millisecond. If missing, uses current time
 * @returns Boolean to indicate whether the given msOrDate is the past as date. Any timestamp in today should be false
 *
 */
export const isPastDate = (msOrDate: number | Date): boolean => {
  return isPast(endOfDayInZone(msOrDate))
}

export const startOfDayInZone = (msOrDate: number | Date = new Date()): Date => {
  return processInZone(msOrDate, startOfDay)
}

export const endOfDayInZone = (msOrDate: number | Date = new Date()): Date => {
  return processInZone(msOrDate, endOfDay)
}

export const startOfMonthInZone = (msOrDate: number | Date = new Date()): Date => {
  return processInZone(msOrDate, startOfMonth)
}

export const endOfMonthInZone = (msOrDate: number | Date = new Date()): Date => {
  return processInZone(msOrDate, endOfMonth)
}

export const isThisMonthInZone = (msOrDate: number | Date = new Date()): boolean => {
  return isFuture(endOfMonthInZone(msOrDate)) && isPast(startOfMonthInZone(msOrDate))
}

export const yesterdayInZone = (msOrDate: number | Date = new Date()) => {
  return processInZone(msOrDate, (zonedDate) => addDays(zonedDate, -1))
}

export const lastMonthInJST = (msOrDate: number | Date = new Date()) => {
  return processInZone(msOrDate, (zonedDate) => addMonths(zonedDate, -1))
}

export const everyPastMonthInZoneFrom = (startDatetimeString: string): Date[] => {
  let generationDate = parseDateInput(startDatetimeString)
  const months: Date[] = []
  while (isPast(generationDate)) {
    months.push(generationDate)
    generationDate = processInZone(generationDate, (date) => addMonths(date, 1))
  }
  return months
}

const processInZone = (msOrDate: number | Date, processor: (zonedDate: Date) => Date): Date => {
  const zoned = utcToZonedTime(msOrDate, PREFERRED_TIMEZONE)
  return zonedTimeToUtc(processor(zoned), PREFERRED_TIMEZONE)
}
