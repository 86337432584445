import { gql } from '@apollo/client'

import { OperationRole } from 'shared/grpc/miraibaraiops/ops_common_pb'

export const query = gql`
  query ListMiraibaraiAppUsers(
    $limit: Int!
    $skip: Int!
    $appUserId: String
    $partnerId: String
    $partnerUserId: String
    $nameKanji: String
    $nameKana: String
    $emailAddress: String
    $homePhoneNumber: String
    $mobilePhoneNumber: String
    $postalCode: String
    $residentAddress: String
    $birthdate: String
  ) {
    totalCount: miraibaraiAppUsersCount(
      appUserId: $appUserId
      partnerId: $partnerId
      partnerUserId: $partnerUserId
      nameKanji: $nameKanji
      nameKana: $nameKana
      emailAddress: $emailAddress
      homePhoneNumber: $homePhoneNumber
      mobilePhoneNumber: $mobilePhoneNumber
      postalCode: $postalCode
      residentAddress: $residentAddress
      birthdate: $birthdate
    )
    result: miraibaraiAppUsers(
      limit: $limit
      skip: $skip
      appUserId: $appUserId
      partnerId: $partnerId
      partnerUserId: $partnerUserId
      nameKanji: $nameKanji
      nameKana: $nameKana
      emailAddress: $emailAddress
      homePhoneNumber: $homePhoneNumber
      mobilePhoneNumber: $mobilePhoneNumber
      postalCode: $postalCode
      residentAddress: $residentAddress
      birthdate: $birthdate
    ) {
      appUserId
      partnerId
      partnerUserId
      miraibaraiPersonalData {
        nameKanji
        nameKana
        emailAddress
        birthdate
        homePhoneNumber
        mobilePhoneNumber
      }
      createdMs
      identityVerificationCompleted
    }
  }
`

export const assumedRoles = [OperationRole.OPERATION_ROLE_CUSTOMER_SUPPORT]
