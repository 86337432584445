import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'

import { DialogForm } from 'shared/components/DialogForm'
import { context as OperationAppContext } from 'shared/context/OperationApp'
import {
  endOfDayInZone,
  formatAsDatetime,
  formatAsHyphenatedDate,
  formatAsJST,
  parseDateInput,
  startOfDayInZone,
  startOfMonthInZone,
} from 'shared/lib/dateUtils'
import { downloadBlob, generateCsvAsBlobParts } from 'shared/lib/fileUtils'

import {
  useLazyListOperatorExecutionLogsQuery,
  useLazyListOperatorLoginLogsQuery,
  useLazyListOperatorQueryLogsQuery,
} from '@/hooks/graphql'

const generateOperatorLoginLogCsv = (records: ListOperatorLoginLogs['result']): BlobPart[] => {
  const header = ['オペレーターID', 'ログインID', 'ソースIP', '成功', '日時']
  const rows = records.map((operatorLoginLog) => [
    operatorLoginLog.operatorId || '',
    operatorLoginLog.userInputLoginId,
    operatorLoginLog.ipAddress,
    operatorLoginLog.succeeded ? 'TRUE' : 'FALSE',
    formatAsDatetime(operatorLoginLog.executedMs),
  ])
  return generateCsvAsBlobParts([header, ...rows])
}

const generateOperatorQueryLogCsv = (records: ListOperatorQueryLogs['result']): BlobPart[] => {
  const header = ['オペレーターID', 'ログインID', '日時', '取得利用クエリ']
  const rows = records.map((operatorQueryLog) => [
    operatorQueryLog.operatorId,
    operatorQueryLog.operator.loginId || '',
    formatAsDatetime(operatorQueryLog.executedMs),
    operatorQueryLog.queryName,
  ])
  return generateCsvAsBlobParts([header, ...rows])
}

const generateOperatorExecutionLogCsv = (records: ListOperatorExecutionLogs['result']): BlobPart[] => {
  const header = ['オペレーターID', 'ログインID', '日時', '変更操作の名称', '変更対象のID']
  const rows = records.map((operatorExecutionLog) => [
    operatorExecutionLog.operatorId,
    operatorExecutionLog.operator.loginId || '',
    formatAsDatetime(operatorExecutionLog.executedMs),
    operatorExecutionLog.methodName,
    operatorExecutionLog.manipulatedObjectId,
  ])
  return generateCsvAsBlobParts([header, ...rows])
}

enum ExportType {
  LOGIN,
  QUERY,
  EXECUTION,
}

interface ExportCondition {
  type: ExportType
  from: string
  to: string
}

const ExportOperatorLogs: React.FC<{
  onSuccess: () => void
  onCancel: () => void
  operator: ElementOf<ListOperators['result']>
}> = ({ onSuccess, onCancel, operator }) => {
  const appContext = useContext(OperationAppContext)
  const { register, handleSubmit, control, reset, getValues } = useForm<ExportCondition>({
    mode: 'onSubmit',
    defaultValues: {
      type: ExportType.LOGIN,
      from: formatAsHyphenatedDate(startOfMonthInZone()),
      to: formatAsHyphenatedDate(),
    },
  })
  const [queryVariables, setQueryVariables] = useState<
    ListOperatorLoginLogsVariables | ListOperatorQueryLogsVariables | ListOperatorExecutionLogsVariables
  >({
    skip: 0,
    limit: 10000,
    sort_field: 'executedMs',
    desc: false,
    operatorId: null,
  })
  const [result, setResult] = useState<BlobPart[]>([])

  const { load: loadLoginLogs, data: loginLogs } = useLazyListOperatorLoginLogsQuery(queryVariables)
  const { load: loadQueryLogs, data: queryLogs } = useLazyListOperatorQueryLogsQuery(queryVariables)
  const { load: loadExecutionLogs, data: executionLogs } = useLazyListOperatorExecutionLogsQuery(queryVariables)
  const queries = [loadLoginLogs, loadQueryLogs, loadExecutionLogs]

  const runQuery = (condition: ExportCondition) => {
    setQueryVariables({
      ...queryVariables,
      operatorId: operator.operatorId,
      executedMs: {
        gte: startOfDayInZone(parseDateInput(condition.from)).getTime(),
        lte: endOfDayInZone(parseDateInput(condition.to)).getTime(),
      },
    })

    queries[condition.type]()
  }

  useEffect(() => {
    if (result.length > 0) {
      const type = {
        [ExportType.LOGIN]: 'ログイン',
        [ExportType.QUERY]: 'データ閲覧',
        [ExportType.EXECUTION]: '変更操作',
      }[getValues().type]

      const fileName = `${type}_${operator.operatorId}_${formatAsJST(
        new Date(getValues().from),
        'yMMdd',
      )}-${formatAsJST(new Date(getValues().to), 'yMMdd')}.csv`

      downloadBlob(result, fileName)
      appContext.flashMessage('読み込みました', 'success')
      onSuccess()
    }
  }, [appContext, onSuccess, result, getValues, operator])

  useEffect(() => {
    if (loginLogs && loginLogs.result) setResult(generateOperatorLoginLogCsv(loginLogs.result))
    if (queryLogs && queryLogs.result) setResult(generateOperatorQueryLogCsv(queryLogs.result))
    if (executionLogs && executionLogs.result) setResult(generateOperatorExecutionLogCsv(executionLogs.result))
  }, [loginLogs, queryLogs, executionLogs])

  return (
    <DialogForm
      title={`${operator.loginId === null ? operator.name : operator.loginId} の操作履歴を出力する`}
      submitButtonTitle="ダウンロード"
      reset={reset}
      size="xs"
      onCancel={onCancel}
      onSubmit={handleSubmit(runQuery)}
    >
      <FormControl fullWidth margin="normal">
        <InputLabel id="type">データの種類</InputLabel>
        <Controller
          control={control}
          name="type"
          render={({ field }) => (
            <Select {...field} labelId="type" label="データの種類">
              <MenuItem value={ExportType.LOGIN}>ログイン</MenuItem>
              <MenuItem value={ExportType.QUERY}>データ閲覧</MenuItem>
              <MenuItem value={ExportType.EXECUTION}>変更操作</MenuItem>
            </Select>
          )}
        />
      </FormControl>
      <TextField
        margin="normal"
        label="開始日時"
        type="date"
        fullWidth
        InputLabelProps={{ shrink: true }}
        {...register('from')}
      />
      <TextField
        margin="normal"
        label="終了日時"
        type="date"
        fullWidth
        InputLabelProps={{ shrink: true }}
        {...register('to')}
      />
    </DialogForm>
  )
}

export default ExportOperatorLogs
