import { AlertProps } from '@mui/material/Alert'
import { createContext } from 'react'

export interface OperationAppContextType {
  currentOperator: {
    sessionToken: string
    name: string
    id: string
    roles: number[]
    lastPasswordUpdatedMs: number
  } | null
  setCurrentOperator: (currentOperator: OperationAppContextType['currentOperator']) => void
  flashMessage: (message: string, severity: AlertProps['severity']) => void
  setLoading: (isLoading: boolean) => void
}

export const context = createContext<OperationAppContextType>({
  currentOperator: null,
  setCurrentOperator: () => {
    /* give an assignment operation */
  },
  flashMessage: () => {
    /* give an assignment operation */
  },
  setLoading: () => {
    /* give an assignment operation */
  },
})
