import { useRouter } from 'next/router'
import { useCallback, useMemo } from 'react'

import { buildRouteParams } from 'shared/lib/url'

const useRouteQuery = <T extends string>(
  keys: T[],
): [
  { [key in T]: string | null },
  (newConditions: { [key in T]: string | null }, options?: { replace?: boolean }) => void,
  {
    arrayOf: (key: T) => string[]
    toggleArrayOf: (key: T, valInArray: string) => void
  },
] => {
  const router = useRouter()

  const condition = useMemo(() => {
    return keys.reduce<Record<T, string | null>>(
      (cond, key) => {
        return {
          ...cond,
          [key]: typeof router.query[key] === 'string' ? router.query[key] : null,
        }
      },
      {} as Record<T, string | null>,
    )
  }, [keys, router.query])

  const updateFilter = useCallback(
    (newConditions: { [key in T]: string | null }, options?: { replace?: boolean }) => {
      const query = keys.reduce((acc, key) => {
        if (newConditions[key]) return { ...acc, [key]: newConditions[key] }
        if (newConditions[key] === null) return acc
        if (condition[key]) return { ...acc, [key]: condition[key] }
        return acc
      }, {})

      if (options?.replace) {
        router.replace(...buildRouteParams(router.pathname, query)) // resetting pagination as result
      } else {
        router.push(...buildRouteParams(router.pathname, query)) // resetting pagination as result
      }
    },
    [keys, condition, router],
  )

  const arrayOf = useCallback(
    (key: T) => {
      return condition[key]?.split(',') ?? []
    },
    [condition],
  )

  const toggleArrayOf = useCallback(
    (key: T, valInArray: string) => {
      const current = condition[key]?.split(',') ?? []
      if (current.includes(valInArray)) {
        updateFilter(
          { ...condition, [key]: current.filter((k) => k !== valInArray).join(',') || null },
          { replace: true },
        )
      } else {
        updateFilter({ ...condition, [key]: [...current, valInArray].join(',') }, { replace: true })
      }
    },
    [condition, updateFilter],
  )

  return [condition, updateFilter, { arrayOf, toggleArrayOf }]
}

export default useRouteQuery
