import { createTheme } from '@mui/material/styles'
import { AppProps } from 'next/app'

import KippOperationApp from 'shared/components/KippOperationApp'
import { initSentry } from 'shared/lib/sentry'

import { makeGraphQLRequest, useOperatorChangePassword, useOperatorLogin } from '@/hooks/grpc'
import { mainOperations } from '@/lib/operations'
import theme from '@/lib/theme'

initSentry()

export const KEY_SESSION_OPERATOR = 'miraibarai_current_operator'
export const KEY_SESSION_EXPIRY = 'miraibarai_session_expires_at'

const miraibaraiTheme = createTheme(theme)

const MiraibaraiApp: React.FC<AppProps & { err: unknown }> = (props) => {
  return (
    <KippOperationApp
      {...props}
      title="ミライバライ管理"
      theme={miraibaraiTheme}
      sessionOperatorKey={KEY_SESSION_OPERATOR}
      sessionExpiryKey={KEY_SESSION_EXPIRY}
      makeGraphQLRequest={makeGraphQLRequest}
      loginOperation={useOperatorLogin}
      changePasswordOperation={useOperatorChangePassword}
      mainOperations={mainOperations}
    ></KippOperationApp>
  )
}

export default MiraibaraiApp
