import { useWrapLazyQuery, useWrapQuery } from 'shared/hooks/graphql'

import { presentGetMiraibaraiAppUser } from '@/hooks/graphql/presenters/miraibaraiAppUsers'
import { presentListPostPaidTopUpFees } from '@/hooks/graphql/presenters/postPaidTopUpFees'
import { presentListPostPayDebts } from '@/hooks/graphql/presenters/postPayDebts'
import * as GetExternalScreeningApiSettingQuery from '@/hooks/graphql/queries/get_external_screening_api_setting.gql'
import * as GetMiraibaraiAppUserQuery from '@/hooks/graphql/queries/get_miraibarai_app_user.gql'
import * as ListMiraibaraiAppUsersQuery from '@/hooks/graphql/queries/list_miraibarai_app_users.gql'
import * as ListOperatorExecutionLogsQuery from '@/hooks/graphql/queries/list_operator_execution_logs.gql'
import * as ListOperatorLoginLogsQuery from '@/hooks/graphql/queries/list_operator_login_logs.gql'
import * as ListOperatorQueryLogsQuery from '@/hooks/graphql/queries/list_operator_query_logs.gql'
import * as ListOperatorsQuery from '@/hooks/graphql/queries/list_operators.gql'
import * as ListPostPaidTopUpFeesQuery from '@/hooks/graphql/queries/list_post_paid_top_up_fees.gql'
import * as ListPostPayDebtsQuery from '@/hooks/graphql/queries/list_post_pay_debts.gql'
import * as ListPostPayScreeningRuleSetsQuery from '@/hooks/graphql/queries/list_post_pay_screening_rule_sets.gql'

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// below codes would be automatically generated
export const useGetExternalScreeningApiSettingQuery = () => {
  return useWrapQuery<GetExternalScreeningApiSetting, Record<string, unknown>>(GetExternalScreeningApiSettingQuery, {})
}

export const useListOperatorsQuery = (variablesOverwrite: Partial<ListOperatorsVariables> = {}) => {
  return useWrapQuery<ListOperators, ListOperatorsVariables>(ListOperatorsQuery, variablesOverwrite)
}

export const useListMiraibaraiAppUsersQuery = (variablesOverwrite: Partial<ListMiraibaraiAppUsersVariables> = {}) => {
  return useWrapQuery<ListMiraibaraiAppUsers, ListMiraibaraiAppUsersVariables>(
    ListMiraibaraiAppUsersQuery,
    variablesOverwrite,
  )
}

export const useGetMiraibaraiAppUserQuery = (variablesOverwrite: Partial<GetMiraibaraiAppUserVariables> = {}) => {
  return useWrapQuery<
    GetMiraibaraiAppUser,
    GetMiraibaraiAppUserVariables,
    ReturnType<typeof presentGetMiraibaraiAppUser>
  >(GetMiraibaraiAppUserQuery, variablesOverwrite, presentGetMiraibaraiAppUser)
}

export const useListPostPaidTopUpFeesQuery = (variablesOverwrite: Partial<ListPostPaidTopUpFeesVariables> = {}) => {
  return useWrapQuery<
    ListPostPaidTopUpFees,
    ListPostPaidTopUpFeesVariables,
    ReturnType<typeof presentListPostPaidTopUpFees>
  >(ListPostPaidTopUpFeesQuery, variablesOverwrite, presentListPostPaidTopUpFees)
}

export const useListPostPayDebtsQuery = (variablesOverwrite: Partial<ListPostPayDebtsVariables> = {}) => {
  return useWrapQuery<ListPostPayDebts, ListPostPayDebtsVariables, ReturnType<typeof presentListPostPayDebts>>(
    ListPostPayDebtsQuery,
    variablesOverwrite,
    presentListPostPayDebts,
  )
}

export const useListPostPayScreeningRuleSetsQuery = (
  variablesOverwrite: Partial<ListPostPayScreeningRuleSetsVariables> = {},
) => {
  return useWrapQuery<ListPostPayScreeningRuleSets, ListPostPayScreeningRuleSetsVariables>(
    ListPostPayScreeningRuleSetsQuery,
    variablesOverwrite,
  )
}

export const useListOperatorExecutionLogsQuery = (
  variablesOverwrite: Partial<ListOperatorExecutionLogsVariables> = {},
) => {
  return useWrapQuery<ListOperatorExecutionLogs, ListOperatorExecutionLogsVariables>(
    ListOperatorExecutionLogsQuery,
    variablesOverwrite,
  )
}

export const useLazyListOperatorExecutionLogsQuery = (
  variablesOverwrite: Partial<ListOperatorExecutionLogsVariables> = {},
) => {
  return useWrapLazyQuery<ListOperatorExecutionLogs, ListOperatorExecutionLogsVariables>(
    ListOperatorExecutionLogsQuery,
    variablesOverwrite,
  )
}

export const useListOperatorLoginLogsQuery = (variablesOverwrite: Partial<ListOperatorLoginLogsVariables> = {}) => {
  return useWrapQuery<ListOperatorLoginLogs, ListOperatorLoginLogsVariables>(
    ListOperatorLoginLogsQuery,
    variablesOverwrite,
  )
}

export const useLazyListOperatorLoginLogsQuery = (variablesOverwrite: Partial<ListOperatorLoginLogsVariables> = {}) => {
  return useWrapLazyQuery<ListOperatorLoginLogs, ListOperatorLoginLogsVariables>(
    ListOperatorLoginLogsQuery,
    variablesOverwrite,
  )
}

export const useListOperatorQueryLogsQuery = (variablesOverwrite: Partial<ListOperatorQueryLogsVariables> = {}) => {
  return useWrapQuery<ListOperatorQueryLogs, ListOperatorQueryLogsVariables>(
    ListOperatorQueryLogsQuery,
    variablesOverwrite,
  )
}

export const useLazyListOperatorQueryLogsQuery = (variablesOverwrite: Partial<ListOperatorQueryLogsVariables> = {}) => {
  return useWrapLazyQuery<ListOperatorQueryLogs, ListOperatorQueryLogsVariables>(
    ListOperatorQueryLogsQuery,
    variablesOverwrite,
  )
}

/* eslint-enable @typescript-eslint/explicit-module-boundary-types */
