import {
  ApolloError,
  ApolloQueryResult,
  DocumentNode,
  OperationVariables,
  QueryLazyOptions,
  useLazyQuery,
  useQuery,
} from '@apollo/client'
import { useContext, useEffect, useMemo, useRef } from 'react'

import { context as OperationAppContext } from 'shared/context/OperationApp'

export const useWrapQuery = <QueryResponseType, Variables, Presentation = void>(
  { query, assumedRoles: roles }: { query: DocumentNode; assumedRoles: number[] },
  overwrite: Partial<Variables>,
  presenter?: (data: QueryResponseType) => Presentation,
): {
  data: QueryResponseType | undefined
  refetch: (variables?: Partial<Variables> | undefined) => Promise<ApolloQueryResult<QueryResponseType>>
  presentation: Presentation | undefined
} => {
  const loadingMemo = useRef<boolean>(false)
  const lastErrorMemo = useRef<ApolloError | undefined>(undefined)
  const appContext = useContext(OperationAppContext)
  const { loading, data, refetch, error } = useQuery<QueryResponseType>(query, {
    variables: {
      _assumed_role: roles.find((role) => appContext.currentOperator?.roles.includes(role)),
      limit: 25,
      skip: 0,
      ...overwrite,
    },
    notifyOnNetworkStatusChange: true,
    // Giving this option instead of ApolloClient setup since default options don't work apparently
    // https://github.com/apollographql/apollo-client/issues/2555
    fetchPolicy: 'no-cache',
  })

  useEffect(() => {
    if (loadingMemo.current !== loading) {
      appContext.setLoading(loading)
      loadingMemo.current = loading
    }
  }, [appContext, loading])

  useEffect(() => {
    if (lastErrorMemo.current !== error) {
      appContext.flashMessage('リクエストに失敗しました', 'error')
      lastErrorMemo.current = error
    }
  }, [appContext, error])

  const presentation = useMemo(() => (presenter && data ? presenter(data) : undefined), [data, presenter])

  return { data, refetch, presentation }
}

export const useWrapLazyQuery = <QueryResponseType, Variables extends OperationVariables>(
  { query, assumedRoles: roles }: { query: DocumentNode; assumedRoles: number[] },
  overwrite: Partial<Variables>,
): {
  data: QueryResponseType | undefined
  load: (options?: QueryLazyOptions<Variables> | undefined) => void
} => {
  const loadingMemo = useRef<boolean>(false)
  const lastErrorMemo = useRef<ApolloError | undefined>(undefined)
  const appContext = useContext(OperationAppContext)
  const [load, { loading, data, error }] = useLazyQuery<QueryResponseType>(query, {
    variables: {
      _assumed_role: roles.find((role) => appContext.currentOperator?.roles.includes(role)),
      limit: 25,
      skip: 0,
      ...overwrite,
    },
    notifyOnNetworkStatusChange: true,
    // Giving this option instead of ApolloClient setup since default options don't work apparently
    // https://github.com/apollographql/apollo-client/issues/2555
    fetchPolicy: 'no-cache',
  })

  useEffect(() => {
    if (loadingMemo.current !== loading) {
      appContext.setLoading(loading)
      loadingMemo.current = loading
    }
  }, [appContext, loading])

  useEffect(() => {
    if (lastErrorMemo.current !== error) {
      appContext.flashMessage('リクエストに失敗しました', 'error')
      lastErrorMemo.current = error
    }
  }, [appContext, error])

  return { data, load }
}
