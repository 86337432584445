// source: scalapb/scalapb.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return Function('return this')(); }).call(null);

var google_protobuf_descriptor_pb = require('google-protobuf/google/protobuf/descriptor_pb.js');
goog.object.extend(proto, google_protobuf_descriptor_pb);
goog.exportSymbol('proto.scalapb.Collection', null, global);
goog.exportSymbol('proto.scalapb.EnumOptions', null, global);
goog.exportSymbol('proto.scalapb.EnumValueOptions', null, global);
goog.exportSymbol('proto.scalapb.FieldOptions', null, global);
goog.exportSymbol('proto.scalapb.FieldTransformation', null, global);
goog.exportSymbol('proto.scalapb.MatchType', null, global);
goog.exportSymbol('proto.scalapb.MessageOptions', null, global);
goog.exportSymbol('proto.scalapb.OneofOptions', null, global);
goog.exportSymbol('proto.scalapb.PreprocessorOutput', null, global);
goog.exportSymbol('proto.scalapb.ScalaPbOptions', null, global);
goog.exportSymbol('proto.scalapb.ScalaPbOptions.AuxEnumOptions', null, global);
goog.exportSymbol('proto.scalapb.ScalaPbOptions.AuxEnumValueOptions', null, global);
goog.exportSymbol('proto.scalapb.ScalaPbOptions.AuxFieldOptions', null, global);
goog.exportSymbol('proto.scalapb.ScalaPbOptions.AuxMessageOptions', null, global);
goog.exportSymbol('proto.scalapb.ScalaPbOptions.EnumValueNaming', null, global);
goog.exportSymbol('proto.scalapb.ScalaPbOptions.OptionsScope', null, global);
goog.exportSymbol('proto.scalapb.enumOptions', null, global);
goog.exportSymbol('proto.scalapb.enumValue', null, global);
goog.exportSymbol('proto.scalapb.field', null, global);
goog.exportSymbol('proto.scalapb.message', null, global);
goog.exportSymbol('proto.scalapb.oneof', null, global);
goog.exportSymbol('proto.scalapb.options', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.scalapb.ScalaPbOptions = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, 500, proto.scalapb.ScalaPbOptions.repeatedFields_, null);
};
goog.inherits(proto.scalapb.ScalaPbOptions, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.scalapb.ScalaPbOptions.displayName = 'proto.scalapb.ScalaPbOptions';
}

/**
 * The extensions registered with this message class. This is a map of
 * extension field number to fieldInfo object.
 *
 * For example:
 *     { 123: {fieldIndex: 123, fieldName: {my_field_name: 0}, ctor: proto.example.MyMessage} }
 *
 * fieldName contains the JsCompiler renamed field name property so that it
 * works in OPTIMIZED mode.
 *
 * @type {!Object<number, jspb.ExtensionFieldInfo>}
 */
proto.scalapb.ScalaPbOptions.extensions = {};


/**
 * The extensions registered with this message class. This is a map of
 * extension field number to fieldInfo object.
 *
 * For example:
 *     { 123: {fieldIndex: 123, fieldName: {my_field_name: 0}, ctor: proto.example.MyMessage} }
 *
 * fieldName contains the JsCompiler renamed field name property so that it
 * works in OPTIMIZED mode.
 *
 * @type {!Object<number, jspb.ExtensionFieldBinaryInfo>}
 */
proto.scalapb.ScalaPbOptions.extensionsBinary = {};

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.scalapb.ScalaPbOptions.AuxMessageOptions = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.scalapb.ScalaPbOptions.AuxMessageOptions, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.scalapb.ScalaPbOptions.AuxMessageOptions.displayName = 'proto.scalapb.ScalaPbOptions.AuxMessageOptions';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.scalapb.ScalaPbOptions.AuxFieldOptions = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.scalapb.ScalaPbOptions.AuxFieldOptions, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.scalapb.ScalaPbOptions.AuxFieldOptions.displayName = 'proto.scalapb.ScalaPbOptions.AuxFieldOptions';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.scalapb.ScalaPbOptions.AuxEnumOptions = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.scalapb.ScalaPbOptions.AuxEnumOptions, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.scalapb.ScalaPbOptions.AuxEnumOptions.displayName = 'proto.scalapb.ScalaPbOptions.AuxEnumOptions';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.scalapb.ScalaPbOptions.AuxEnumValueOptions = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.scalapb.ScalaPbOptions.AuxEnumValueOptions, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.scalapb.ScalaPbOptions.AuxEnumValueOptions.displayName = 'proto.scalapb.ScalaPbOptions.AuxEnumValueOptions';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.scalapb.MessageOptions = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, 14, proto.scalapb.MessageOptions.repeatedFields_, null);
};
goog.inherits(proto.scalapb.MessageOptions, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.scalapb.MessageOptions.displayName = 'proto.scalapb.MessageOptions';
}

/**
 * The extensions registered with this message class. This is a map of
 * extension field number to fieldInfo object.
 *
 * For example:
 *     { 123: {fieldIndex: 123, fieldName: {my_field_name: 0}, ctor: proto.example.MyMessage} }
 *
 * fieldName contains the JsCompiler renamed field name property so that it
 * works in OPTIMIZED mode.
 *
 * @type {!Object<number, jspb.ExtensionFieldInfo>}
 */
proto.scalapb.MessageOptions.extensions = {};


/**
 * The extensions registered with this message class. This is a map of
 * extension field number to fieldInfo object.
 *
 * For example:
 *     { 123: {fieldIndex: 123, fieldName: {my_field_name: 0}, ctor: proto.example.MyMessage} }
 *
 * fieldName contains the JsCompiler renamed field name property so that it
 * works in OPTIMIZED mode.
 *
 * @type {!Object<number, jspb.ExtensionFieldBinaryInfo>}
 */
proto.scalapb.MessageOptions.extensionsBinary = {};

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.scalapb.Collection = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.scalapb.Collection, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.scalapb.Collection.displayName = 'proto.scalapb.Collection';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.scalapb.FieldOptions = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, 32, proto.scalapb.FieldOptions.repeatedFields_, null);
};
goog.inherits(proto.scalapb.FieldOptions, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.scalapb.FieldOptions.displayName = 'proto.scalapb.FieldOptions';
}

/**
 * The extensions registered with this message class. This is a map of
 * extension field number to fieldInfo object.
 *
 * For example:
 *     { 123: {fieldIndex: 123, fieldName: {my_field_name: 0}, ctor: proto.example.MyMessage} }
 *
 * fieldName contains the JsCompiler renamed field name property so that it
 * works in OPTIMIZED mode.
 *
 * @type {!Object<number, jspb.ExtensionFieldInfo>}
 */
proto.scalapb.FieldOptions.extensions = {};


/**
 * The extensions registered with this message class. This is a map of
 * extension field number to fieldInfo object.
 *
 * For example:
 *     { 123: {fieldIndex: 123, fieldName: {my_field_name: 0}, ctor: proto.example.MyMessage} }
 *
 * fieldName contains the JsCompiler renamed field name property so that it
 * works in OPTIMIZED mode.
 *
 * @type {!Object<number, jspb.ExtensionFieldBinaryInfo>}
 */
proto.scalapb.FieldOptions.extensionsBinary = {};

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.scalapb.EnumOptions = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, 7, proto.scalapb.EnumOptions.repeatedFields_, null);
};
goog.inherits(proto.scalapb.EnumOptions, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.scalapb.EnumOptions.displayName = 'proto.scalapb.EnumOptions';
}

/**
 * The extensions registered with this message class. This is a map of
 * extension field number to fieldInfo object.
 *
 * For example:
 *     { 123: {fieldIndex: 123, fieldName: {my_field_name: 0}, ctor: proto.example.MyMessage} }
 *
 * fieldName contains the JsCompiler renamed field name property so that it
 * works in OPTIMIZED mode.
 *
 * @type {!Object<number, jspb.ExtensionFieldInfo>}
 */
proto.scalapb.EnumOptions.extensions = {};


/**
 * The extensions registered with this message class. This is a map of
 * extension field number to fieldInfo object.
 *
 * For example:
 *     { 123: {fieldIndex: 123, fieldName: {my_field_name: 0}, ctor: proto.example.MyMessage} }
 *
 * fieldName contains the JsCompiler renamed field name property so that it
 * works in OPTIMIZED mode.
 *
 * @type {!Object<number, jspb.ExtensionFieldBinaryInfo>}
 */
proto.scalapb.EnumOptions.extensionsBinary = {};

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.scalapb.EnumValueOptions = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, 4, proto.scalapb.EnumValueOptions.repeatedFields_, null);
};
goog.inherits(proto.scalapb.EnumValueOptions, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.scalapb.EnumValueOptions.displayName = 'proto.scalapb.EnumValueOptions';
}

/**
 * The extensions registered with this message class. This is a map of
 * extension field number to fieldInfo object.
 *
 * For example:
 *     { 123: {fieldIndex: 123, fieldName: {my_field_name: 0}, ctor: proto.example.MyMessage} }
 *
 * fieldName contains the JsCompiler renamed field name property so that it
 * works in OPTIMIZED mode.
 *
 * @type {!Object<number, jspb.ExtensionFieldInfo>}
 */
proto.scalapb.EnumValueOptions.extensions = {};


/**
 * The extensions registered with this message class. This is a map of
 * extension field number to fieldInfo object.
 *
 * For example:
 *     { 123: {fieldIndex: 123, fieldName: {my_field_name: 0}, ctor: proto.example.MyMessage} }
 *
 * fieldName contains the JsCompiler renamed field name property so that it
 * works in OPTIMIZED mode.
 *
 * @type {!Object<number, jspb.ExtensionFieldBinaryInfo>}
 */
proto.scalapb.EnumValueOptions.extensionsBinary = {};

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.scalapb.OneofOptions = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, 3, proto.scalapb.OneofOptions.repeatedFields_, null);
};
goog.inherits(proto.scalapb.OneofOptions, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.scalapb.OneofOptions.displayName = 'proto.scalapb.OneofOptions';
}

/**
 * The extensions registered with this message class. This is a map of
 * extension field number to fieldInfo object.
 *
 * For example:
 *     { 123: {fieldIndex: 123, fieldName: {my_field_name: 0}, ctor: proto.example.MyMessage} }
 *
 * fieldName contains the JsCompiler renamed field name property so that it
 * works in OPTIMIZED mode.
 *
 * @type {!Object<number, jspb.ExtensionFieldInfo>}
 */
proto.scalapb.OneofOptions.extensions = {};


/**
 * The extensions registered with this message class. This is a map of
 * extension field number to fieldInfo object.
 *
 * For example:
 *     { 123: {fieldIndex: 123, fieldName: {my_field_name: 0}, ctor: proto.example.MyMessage} }
 *
 * fieldName contains the JsCompiler renamed field name property so that it
 * works in OPTIMIZED mode.
 *
 * @type {!Object<number, jspb.ExtensionFieldBinaryInfo>}
 */
proto.scalapb.OneofOptions.extensionsBinary = {};

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.scalapb.FieldTransformation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.scalapb.FieldTransformation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.scalapb.FieldTransformation.displayName = 'proto.scalapb.FieldTransformation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.scalapb.PreprocessorOutput = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.scalapb.PreprocessorOutput, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.scalapb.PreprocessorOutput.displayName = 'proto.scalapb.PreprocessorOutput';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.scalapb.ScalaPbOptions.repeatedFields_ = [3,4,18,19,20,22,24,25];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.scalapb.ScalaPbOptions.prototype.toObject = function(opt_includeInstance) {
  return proto.scalapb.ScalaPbOptions.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.scalapb.ScalaPbOptions} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scalapb.ScalaPbOptions.toObject = function(includeInstance, msg) {
  var f, obj = {
    packageName: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    flatPackage: (f = jspb.Message.getBooleanField(msg, 2)) == null ? undefined : f,
    importList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    preambleList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    singleFile: (f = jspb.Message.getBooleanField(msg, 5)) == null ? undefined : f,
    noPrimitiveWrappers: (f = jspb.Message.getBooleanField(msg, 7)) == null ? undefined : f,
    primitiveWrappers: (f = jspb.Message.getBooleanField(msg, 6)) == null ? undefined : f,
    collectionType: (f = jspb.Message.getField(msg, 8)) == null ? undefined : f,
    preserveUnknownFields: jspb.Message.getBooleanFieldWithDefault(msg, 9, true),
    objectName: (f = jspb.Message.getField(msg, 10)) == null ? undefined : f,
    scope: (f = jspb.Message.getField(msg, 11)) == null ? undefined : f,
    lenses: jspb.Message.getBooleanFieldWithDefault(msg, 12, true),
    retainSourceCodeInfo: (f = jspb.Message.getBooleanField(msg, 13)) == null ? undefined : f,
    mapType: (f = jspb.Message.getField(msg, 14)) == null ? undefined : f,
    noDefaultValuesInConstructor: (f = jspb.Message.getBooleanField(msg, 15)) == null ? undefined : f,
    enumValueNaming: (f = jspb.Message.getField(msg, 16)) == null ? undefined : f,
    enumStripPrefix: jspb.Message.getBooleanFieldWithDefault(msg, 17, false),
    bytesType: (f = jspb.Message.getField(msg, 21)) == null ? undefined : f,
    javaConversions: (f = jspb.Message.getBooleanField(msg, 23)) == null ? undefined : f,
    auxMessageOptionsList: jspb.Message.toObjectList(msg.getAuxMessageOptionsList(),
    proto.scalapb.ScalaPbOptions.AuxMessageOptions.toObject, includeInstance),
    auxFieldOptionsList: jspb.Message.toObjectList(msg.getAuxFieldOptionsList(),
    proto.scalapb.ScalaPbOptions.AuxFieldOptions.toObject, includeInstance),
    auxEnumOptionsList: jspb.Message.toObjectList(msg.getAuxEnumOptionsList(),
    proto.scalapb.ScalaPbOptions.AuxEnumOptions.toObject, includeInstance),
    auxEnumValueOptionsList: jspb.Message.toObjectList(msg.getAuxEnumValueOptionsList(),
    proto.scalapb.ScalaPbOptions.AuxEnumValueOptions.toObject, includeInstance),
    preprocessorsList: (f = jspb.Message.getRepeatedField(msg, 24)) == null ? undefined : f,
    fieldTransformationsList: jspb.Message.toObjectList(msg.getFieldTransformationsList(),
    proto.scalapb.FieldTransformation.toObject, includeInstance),
    ignoreAllTransformations: (f = jspb.Message.getBooleanField(msg, 26)) == null ? undefined : f,
    getters: jspb.Message.getBooleanFieldWithDefault(msg, 27, true),
    scala3Sources: (f = jspb.Message.getBooleanField(msg, 28)) == null ? undefined : f,
    testOnlyNoJavaConversions: (f = jspb.Message.getBooleanField(msg, 999)) == null ? undefined : f
  };

  jspb.Message.toObjectExtension(/** @type {!jspb.Message} */ (msg), obj,
      proto.scalapb.ScalaPbOptions.extensions, proto.scalapb.ScalaPbOptions.prototype.getExtension,
      includeInstance);
  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.scalapb.ScalaPbOptions}
 */
proto.scalapb.ScalaPbOptions.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.scalapb.ScalaPbOptions;
  return proto.scalapb.ScalaPbOptions.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.scalapb.ScalaPbOptions} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.scalapb.ScalaPbOptions}
 */
proto.scalapb.ScalaPbOptions.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPackageName(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFlatPackage(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addImport(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addPreamble(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSingleFile(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNoPrimitiveWrappers(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPrimitiveWrappers(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setCollectionType(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPreserveUnknownFields(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setObjectName(value);
      break;
    case 11:
      var value = /** @type {!proto.scalapb.ScalaPbOptions.OptionsScope} */ (reader.readEnum());
      msg.setScope(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setLenses(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRetainSourceCodeInfo(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setMapType(value);
      break;
    case 15:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNoDefaultValuesInConstructor(value);
      break;
    case 16:
      var value = /** @type {!proto.scalapb.ScalaPbOptions.EnumValueNaming} */ (reader.readEnum());
      msg.setEnumValueNaming(value);
      break;
    case 17:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnumStripPrefix(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setBytesType(value);
      break;
    case 23:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setJavaConversions(value);
      break;
    case 18:
      var value = new proto.scalapb.ScalaPbOptions.AuxMessageOptions;
      reader.readMessage(value,proto.scalapb.ScalaPbOptions.AuxMessageOptions.deserializeBinaryFromReader);
      msg.addAuxMessageOptions(value);
      break;
    case 19:
      var value = new proto.scalapb.ScalaPbOptions.AuxFieldOptions;
      reader.readMessage(value,proto.scalapb.ScalaPbOptions.AuxFieldOptions.deserializeBinaryFromReader);
      msg.addAuxFieldOptions(value);
      break;
    case 20:
      var value = new proto.scalapb.ScalaPbOptions.AuxEnumOptions;
      reader.readMessage(value,proto.scalapb.ScalaPbOptions.AuxEnumOptions.deserializeBinaryFromReader);
      msg.addAuxEnumOptions(value);
      break;
    case 22:
      var value = new proto.scalapb.ScalaPbOptions.AuxEnumValueOptions;
      reader.readMessage(value,proto.scalapb.ScalaPbOptions.AuxEnumValueOptions.deserializeBinaryFromReader);
      msg.addAuxEnumValueOptions(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.addPreprocessors(value);
      break;
    case 25:
      var value = new proto.scalapb.FieldTransformation;
      reader.readMessage(value,proto.scalapb.FieldTransformation.deserializeBinaryFromReader);
      msg.addFieldTransformations(value);
      break;
    case 26:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIgnoreAllTransformations(value);
      break;
    case 27:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setGetters(value);
      break;
    case 28:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setScala3Sources(value);
      break;
    case 999:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setTestOnlyNoJavaConversions(value);
      break;
    default:
      jspb.Message.readBinaryExtension(msg, reader,
        proto.scalapb.ScalaPbOptions.extensionsBinary,
        proto.scalapb.ScalaPbOptions.prototype.getExtension,
        proto.scalapb.ScalaPbOptions.prototype.setExtension);
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.scalapb.ScalaPbOptions.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.scalapb.ScalaPbOptions.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.scalapb.ScalaPbOptions} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scalapb.ScalaPbOptions.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getImportList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getPreambleList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeBool(
      5,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeBool(
      7,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeBool(
      6,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeString(
      8,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 9));
  if (f != null) {
    writer.writeBool(
      9,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 10));
  if (f != null) {
    writer.writeString(
      10,
      f
    );
  }
  f = /** @type {!proto.scalapb.ScalaPbOptions.OptionsScope} */ (jspb.Message.getField(message, 11));
  if (f != null) {
    writer.writeEnum(
      11,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 12));
  if (f != null) {
    writer.writeBool(
      12,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 13));
  if (f != null) {
    writer.writeBool(
      13,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 14));
  if (f != null) {
    writer.writeString(
      14,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 15));
  if (f != null) {
    writer.writeBool(
      15,
      f
    );
  }
  f = /** @type {!proto.scalapb.ScalaPbOptions.EnumValueNaming} */ (jspb.Message.getField(message, 16));
  if (f != null) {
    writer.writeEnum(
      16,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 17));
  if (f != null) {
    writer.writeBool(
      17,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 21));
  if (f != null) {
    writer.writeString(
      21,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 23));
  if (f != null) {
    writer.writeBool(
      23,
      f
    );
  }
  f = message.getAuxMessageOptionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      18,
      f,
      proto.scalapb.ScalaPbOptions.AuxMessageOptions.serializeBinaryToWriter
    );
  }
  f = message.getAuxFieldOptionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      19,
      f,
      proto.scalapb.ScalaPbOptions.AuxFieldOptions.serializeBinaryToWriter
    );
  }
  f = message.getAuxEnumOptionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      20,
      f,
      proto.scalapb.ScalaPbOptions.AuxEnumOptions.serializeBinaryToWriter
    );
  }
  f = message.getAuxEnumValueOptionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      22,
      f,
      proto.scalapb.ScalaPbOptions.AuxEnumValueOptions.serializeBinaryToWriter
    );
  }
  f = message.getPreprocessorsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      24,
      f
    );
  }
  f = message.getFieldTransformationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      25,
      f,
      proto.scalapb.FieldTransformation.serializeBinaryToWriter
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 26));
  if (f != null) {
    writer.writeBool(
      26,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 27));
  if (f != null) {
    writer.writeBool(
      27,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 28));
  if (f != null) {
    writer.writeBool(
      28,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 999));
  if (f != null) {
    writer.writeBool(
      999,
      f
    );
  }
  jspb.Message.serializeBinaryExtensions(message, writer,
    proto.scalapb.ScalaPbOptions.extensionsBinary, proto.scalapb.ScalaPbOptions.prototype.getExtension);
};


/**
 * @enum {number}
 */
proto.scalapb.ScalaPbOptions.OptionsScope = {
  FILE: 0,
  PACKAGE: 1
};

/**
 * @enum {number}
 */
proto.scalapb.ScalaPbOptions.EnumValueNaming = {
  AS_IN_PROTO: 0,
  CAMEL_CASE: 1
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.scalapb.ScalaPbOptions.AuxMessageOptions.prototype.toObject = function(opt_includeInstance) {
  return proto.scalapb.ScalaPbOptions.AuxMessageOptions.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.scalapb.ScalaPbOptions.AuxMessageOptions} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scalapb.ScalaPbOptions.AuxMessageOptions.toObject = function(includeInstance, msg) {
  var f, obj = {
    target: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    options: (f = msg.getOptions()) && proto.scalapb.MessageOptions.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.scalapb.ScalaPbOptions.AuxMessageOptions}
 */
proto.scalapb.ScalaPbOptions.AuxMessageOptions.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.scalapb.ScalaPbOptions.AuxMessageOptions;
  return proto.scalapb.ScalaPbOptions.AuxMessageOptions.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.scalapb.ScalaPbOptions.AuxMessageOptions} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.scalapb.ScalaPbOptions.AuxMessageOptions}
 */
proto.scalapb.ScalaPbOptions.AuxMessageOptions.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTarget(value);
      break;
    case 2:
      var value = new proto.scalapb.MessageOptions;
      reader.readMessage(value,proto.scalapb.MessageOptions.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.scalapb.ScalaPbOptions.AuxMessageOptions.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.scalapb.ScalaPbOptions.AuxMessageOptions.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.scalapb.ScalaPbOptions.AuxMessageOptions} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scalapb.ScalaPbOptions.AuxMessageOptions.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.scalapb.MessageOptions.serializeBinaryToWriter
    );
  }
};


/**
 * optional string target = 1;
 * @return {string}
 */
proto.scalapb.ScalaPbOptions.AuxMessageOptions.prototype.getTarget = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.scalapb.ScalaPbOptions.AuxMessageOptions} returns this
 */
proto.scalapb.ScalaPbOptions.AuxMessageOptions.prototype.setTarget = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.scalapb.ScalaPbOptions.AuxMessageOptions} returns this
 */
proto.scalapb.ScalaPbOptions.AuxMessageOptions.prototype.clearTarget = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scalapb.ScalaPbOptions.AuxMessageOptions.prototype.hasTarget = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional MessageOptions options = 2;
 * @return {?proto.scalapb.MessageOptions}
 */
proto.scalapb.ScalaPbOptions.AuxMessageOptions.prototype.getOptions = function() {
  return /** @type{?proto.scalapb.MessageOptions} */ (
    jspb.Message.getWrapperField(this, proto.scalapb.MessageOptions, 2));
};


/**
 * @param {?proto.scalapb.MessageOptions|undefined} value
 * @return {!proto.scalapb.ScalaPbOptions.AuxMessageOptions} returns this
*/
proto.scalapb.ScalaPbOptions.AuxMessageOptions.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.scalapb.ScalaPbOptions.AuxMessageOptions} returns this
 */
proto.scalapb.ScalaPbOptions.AuxMessageOptions.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scalapb.ScalaPbOptions.AuxMessageOptions.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.scalapb.ScalaPbOptions.AuxFieldOptions.prototype.toObject = function(opt_includeInstance) {
  return proto.scalapb.ScalaPbOptions.AuxFieldOptions.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.scalapb.ScalaPbOptions.AuxFieldOptions} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scalapb.ScalaPbOptions.AuxFieldOptions.toObject = function(includeInstance, msg) {
  var f, obj = {
    target: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    options: (f = msg.getOptions()) && proto.scalapb.FieldOptions.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.scalapb.ScalaPbOptions.AuxFieldOptions}
 */
proto.scalapb.ScalaPbOptions.AuxFieldOptions.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.scalapb.ScalaPbOptions.AuxFieldOptions;
  return proto.scalapb.ScalaPbOptions.AuxFieldOptions.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.scalapb.ScalaPbOptions.AuxFieldOptions} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.scalapb.ScalaPbOptions.AuxFieldOptions}
 */
proto.scalapb.ScalaPbOptions.AuxFieldOptions.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTarget(value);
      break;
    case 2:
      var value = new proto.scalapb.FieldOptions;
      reader.readMessage(value,proto.scalapb.FieldOptions.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.scalapb.ScalaPbOptions.AuxFieldOptions.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.scalapb.ScalaPbOptions.AuxFieldOptions.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.scalapb.ScalaPbOptions.AuxFieldOptions} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scalapb.ScalaPbOptions.AuxFieldOptions.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.scalapb.FieldOptions.serializeBinaryToWriter
    );
  }
};


/**
 * optional string target = 1;
 * @return {string}
 */
proto.scalapb.ScalaPbOptions.AuxFieldOptions.prototype.getTarget = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.scalapb.ScalaPbOptions.AuxFieldOptions} returns this
 */
proto.scalapb.ScalaPbOptions.AuxFieldOptions.prototype.setTarget = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.scalapb.ScalaPbOptions.AuxFieldOptions} returns this
 */
proto.scalapb.ScalaPbOptions.AuxFieldOptions.prototype.clearTarget = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scalapb.ScalaPbOptions.AuxFieldOptions.prototype.hasTarget = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional FieldOptions options = 2;
 * @return {?proto.scalapb.FieldOptions}
 */
proto.scalapb.ScalaPbOptions.AuxFieldOptions.prototype.getOptions = function() {
  return /** @type{?proto.scalapb.FieldOptions} */ (
    jspb.Message.getWrapperField(this, proto.scalapb.FieldOptions, 2));
};


/**
 * @param {?proto.scalapb.FieldOptions|undefined} value
 * @return {!proto.scalapb.ScalaPbOptions.AuxFieldOptions} returns this
*/
proto.scalapb.ScalaPbOptions.AuxFieldOptions.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.scalapb.ScalaPbOptions.AuxFieldOptions} returns this
 */
proto.scalapb.ScalaPbOptions.AuxFieldOptions.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scalapb.ScalaPbOptions.AuxFieldOptions.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.scalapb.ScalaPbOptions.AuxEnumOptions.prototype.toObject = function(opt_includeInstance) {
  return proto.scalapb.ScalaPbOptions.AuxEnumOptions.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.scalapb.ScalaPbOptions.AuxEnumOptions} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scalapb.ScalaPbOptions.AuxEnumOptions.toObject = function(includeInstance, msg) {
  var f, obj = {
    target: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    options: (f = msg.getOptions()) && proto.scalapb.EnumOptions.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.scalapb.ScalaPbOptions.AuxEnumOptions}
 */
proto.scalapb.ScalaPbOptions.AuxEnumOptions.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.scalapb.ScalaPbOptions.AuxEnumOptions;
  return proto.scalapb.ScalaPbOptions.AuxEnumOptions.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.scalapb.ScalaPbOptions.AuxEnumOptions} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.scalapb.ScalaPbOptions.AuxEnumOptions}
 */
proto.scalapb.ScalaPbOptions.AuxEnumOptions.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTarget(value);
      break;
    case 2:
      var value = new proto.scalapb.EnumOptions;
      reader.readMessage(value,proto.scalapb.EnumOptions.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.scalapb.ScalaPbOptions.AuxEnumOptions.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.scalapb.ScalaPbOptions.AuxEnumOptions.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.scalapb.ScalaPbOptions.AuxEnumOptions} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scalapb.ScalaPbOptions.AuxEnumOptions.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.scalapb.EnumOptions.serializeBinaryToWriter
    );
  }
};


/**
 * optional string target = 1;
 * @return {string}
 */
proto.scalapb.ScalaPbOptions.AuxEnumOptions.prototype.getTarget = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.scalapb.ScalaPbOptions.AuxEnumOptions} returns this
 */
proto.scalapb.ScalaPbOptions.AuxEnumOptions.prototype.setTarget = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.scalapb.ScalaPbOptions.AuxEnumOptions} returns this
 */
proto.scalapb.ScalaPbOptions.AuxEnumOptions.prototype.clearTarget = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scalapb.ScalaPbOptions.AuxEnumOptions.prototype.hasTarget = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional EnumOptions options = 2;
 * @return {?proto.scalapb.EnumOptions}
 */
proto.scalapb.ScalaPbOptions.AuxEnumOptions.prototype.getOptions = function() {
  return /** @type{?proto.scalapb.EnumOptions} */ (
    jspb.Message.getWrapperField(this, proto.scalapb.EnumOptions, 2));
};


/**
 * @param {?proto.scalapb.EnumOptions|undefined} value
 * @return {!proto.scalapb.ScalaPbOptions.AuxEnumOptions} returns this
*/
proto.scalapb.ScalaPbOptions.AuxEnumOptions.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.scalapb.ScalaPbOptions.AuxEnumOptions} returns this
 */
proto.scalapb.ScalaPbOptions.AuxEnumOptions.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scalapb.ScalaPbOptions.AuxEnumOptions.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.scalapb.ScalaPbOptions.AuxEnumValueOptions.prototype.toObject = function(opt_includeInstance) {
  return proto.scalapb.ScalaPbOptions.AuxEnumValueOptions.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.scalapb.ScalaPbOptions.AuxEnumValueOptions} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scalapb.ScalaPbOptions.AuxEnumValueOptions.toObject = function(includeInstance, msg) {
  var f, obj = {
    target: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    options: (f = msg.getOptions()) && proto.scalapb.EnumValueOptions.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.scalapb.ScalaPbOptions.AuxEnumValueOptions}
 */
proto.scalapb.ScalaPbOptions.AuxEnumValueOptions.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.scalapb.ScalaPbOptions.AuxEnumValueOptions;
  return proto.scalapb.ScalaPbOptions.AuxEnumValueOptions.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.scalapb.ScalaPbOptions.AuxEnumValueOptions} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.scalapb.ScalaPbOptions.AuxEnumValueOptions}
 */
proto.scalapb.ScalaPbOptions.AuxEnumValueOptions.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTarget(value);
      break;
    case 2:
      var value = new proto.scalapb.EnumValueOptions;
      reader.readMessage(value,proto.scalapb.EnumValueOptions.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.scalapb.ScalaPbOptions.AuxEnumValueOptions.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.scalapb.ScalaPbOptions.AuxEnumValueOptions.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.scalapb.ScalaPbOptions.AuxEnumValueOptions} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scalapb.ScalaPbOptions.AuxEnumValueOptions.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.scalapb.EnumValueOptions.serializeBinaryToWriter
    );
  }
};


/**
 * optional string target = 1;
 * @return {string}
 */
proto.scalapb.ScalaPbOptions.AuxEnumValueOptions.prototype.getTarget = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.scalapb.ScalaPbOptions.AuxEnumValueOptions} returns this
 */
proto.scalapb.ScalaPbOptions.AuxEnumValueOptions.prototype.setTarget = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.scalapb.ScalaPbOptions.AuxEnumValueOptions} returns this
 */
proto.scalapb.ScalaPbOptions.AuxEnumValueOptions.prototype.clearTarget = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scalapb.ScalaPbOptions.AuxEnumValueOptions.prototype.hasTarget = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional EnumValueOptions options = 2;
 * @return {?proto.scalapb.EnumValueOptions}
 */
proto.scalapb.ScalaPbOptions.AuxEnumValueOptions.prototype.getOptions = function() {
  return /** @type{?proto.scalapb.EnumValueOptions} */ (
    jspb.Message.getWrapperField(this, proto.scalapb.EnumValueOptions, 2));
};


/**
 * @param {?proto.scalapb.EnumValueOptions|undefined} value
 * @return {!proto.scalapb.ScalaPbOptions.AuxEnumValueOptions} returns this
*/
proto.scalapb.ScalaPbOptions.AuxEnumValueOptions.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.scalapb.ScalaPbOptions.AuxEnumValueOptions} returns this
 */
proto.scalapb.ScalaPbOptions.AuxEnumValueOptions.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scalapb.ScalaPbOptions.AuxEnumValueOptions.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string package_name = 1;
 * @return {string}
 */
proto.scalapb.ScalaPbOptions.prototype.getPackageName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.scalapb.ScalaPbOptions} returns this
 */
proto.scalapb.ScalaPbOptions.prototype.setPackageName = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.scalapb.ScalaPbOptions} returns this
 */
proto.scalapb.ScalaPbOptions.prototype.clearPackageName = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scalapb.ScalaPbOptions.prototype.hasPackageName = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool flat_package = 2;
 * @return {boolean}
 */
proto.scalapb.ScalaPbOptions.prototype.getFlatPackage = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.scalapb.ScalaPbOptions} returns this
 */
proto.scalapb.ScalaPbOptions.prototype.setFlatPackage = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.scalapb.ScalaPbOptions} returns this
 */
proto.scalapb.ScalaPbOptions.prototype.clearFlatPackage = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scalapb.ScalaPbOptions.prototype.hasFlatPackage = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated string import = 3;
 * @return {!Array<string>}
 */
proto.scalapb.ScalaPbOptions.prototype.getImportList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.scalapb.ScalaPbOptions} returns this
 */
proto.scalapb.ScalaPbOptions.prototype.setImportList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.scalapb.ScalaPbOptions} returns this
 */
proto.scalapb.ScalaPbOptions.prototype.addImport = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.scalapb.ScalaPbOptions} returns this
 */
proto.scalapb.ScalaPbOptions.prototype.clearImportList = function() {
  return this.setImportList([]);
};


/**
 * repeated string preamble = 4;
 * @return {!Array<string>}
 */
proto.scalapb.ScalaPbOptions.prototype.getPreambleList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.scalapb.ScalaPbOptions} returns this
 */
proto.scalapb.ScalaPbOptions.prototype.setPreambleList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.scalapb.ScalaPbOptions} returns this
 */
proto.scalapb.ScalaPbOptions.prototype.addPreamble = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.scalapb.ScalaPbOptions} returns this
 */
proto.scalapb.ScalaPbOptions.prototype.clearPreambleList = function() {
  return this.setPreambleList([]);
};


/**
 * optional bool single_file = 5;
 * @return {boolean}
 */
proto.scalapb.ScalaPbOptions.prototype.getSingleFile = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.scalapb.ScalaPbOptions} returns this
 */
proto.scalapb.ScalaPbOptions.prototype.setSingleFile = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.scalapb.ScalaPbOptions} returns this
 */
proto.scalapb.ScalaPbOptions.prototype.clearSingleFile = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scalapb.ScalaPbOptions.prototype.hasSingleFile = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional bool no_primitive_wrappers = 7;
 * @return {boolean}
 */
proto.scalapb.ScalaPbOptions.prototype.getNoPrimitiveWrappers = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.scalapb.ScalaPbOptions} returns this
 */
proto.scalapb.ScalaPbOptions.prototype.setNoPrimitiveWrappers = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.scalapb.ScalaPbOptions} returns this
 */
proto.scalapb.ScalaPbOptions.prototype.clearNoPrimitiveWrappers = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scalapb.ScalaPbOptions.prototype.hasNoPrimitiveWrappers = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional bool primitive_wrappers = 6;
 * @return {boolean}
 */
proto.scalapb.ScalaPbOptions.prototype.getPrimitiveWrappers = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.scalapb.ScalaPbOptions} returns this
 */
proto.scalapb.ScalaPbOptions.prototype.setPrimitiveWrappers = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.scalapb.ScalaPbOptions} returns this
 */
proto.scalapb.ScalaPbOptions.prototype.clearPrimitiveWrappers = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scalapb.ScalaPbOptions.prototype.hasPrimitiveWrappers = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string collection_type = 8;
 * @return {string}
 */
proto.scalapb.ScalaPbOptions.prototype.getCollectionType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.scalapb.ScalaPbOptions} returns this
 */
proto.scalapb.ScalaPbOptions.prototype.setCollectionType = function(value) {
  return jspb.Message.setField(this, 8, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.scalapb.ScalaPbOptions} returns this
 */
proto.scalapb.ScalaPbOptions.prototype.clearCollectionType = function() {
  return jspb.Message.setField(this, 8, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scalapb.ScalaPbOptions.prototype.hasCollectionType = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional bool preserve_unknown_fields = 9;
 * @return {boolean}
 */
proto.scalapb.ScalaPbOptions.prototype.getPreserveUnknownFields = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, true));
};


/**
 * @param {boolean} value
 * @return {!proto.scalapb.ScalaPbOptions} returns this
 */
proto.scalapb.ScalaPbOptions.prototype.setPreserveUnknownFields = function(value) {
  return jspb.Message.setField(this, 9, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.scalapb.ScalaPbOptions} returns this
 */
proto.scalapb.ScalaPbOptions.prototype.clearPreserveUnknownFields = function() {
  return jspb.Message.setField(this, 9, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scalapb.ScalaPbOptions.prototype.hasPreserveUnknownFields = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional string object_name = 10;
 * @return {string}
 */
proto.scalapb.ScalaPbOptions.prototype.getObjectName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.scalapb.ScalaPbOptions} returns this
 */
proto.scalapb.ScalaPbOptions.prototype.setObjectName = function(value) {
  return jspb.Message.setField(this, 10, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.scalapb.ScalaPbOptions} returns this
 */
proto.scalapb.ScalaPbOptions.prototype.clearObjectName = function() {
  return jspb.Message.setField(this, 10, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scalapb.ScalaPbOptions.prototype.hasObjectName = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional OptionsScope scope = 11;
 * @return {!proto.scalapb.ScalaPbOptions.OptionsScope}
 */
proto.scalapb.ScalaPbOptions.prototype.getScope = function() {
  return /** @type {!proto.scalapb.ScalaPbOptions.OptionsScope} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {!proto.scalapb.ScalaPbOptions.OptionsScope} value
 * @return {!proto.scalapb.ScalaPbOptions} returns this
 */
proto.scalapb.ScalaPbOptions.prototype.setScope = function(value) {
  return jspb.Message.setField(this, 11, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.scalapb.ScalaPbOptions} returns this
 */
proto.scalapb.ScalaPbOptions.prototype.clearScope = function() {
  return jspb.Message.setField(this, 11, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scalapb.ScalaPbOptions.prototype.hasScope = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional bool lenses = 12;
 * @return {boolean}
 */
proto.scalapb.ScalaPbOptions.prototype.getLenses = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, true));
};


/**
 * @param {boolean} value
 * @return {!proto.scalapb.ScalaPbOptions} returns this
 */
proto.scalapb.ScalaPbOptions.prototype.setLenses = function(value) {
  return jspb.Message.setField(this, 12, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.scalapb.ScalaPbOptions} returns this
 */
proto.scalapb.ScalaPbOptions.prototype.clearLenses = function() {
  return jspb.Message.setField(this, 12, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scalapb.ScalaPbOptions.prototype.hasLenses = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional bool retain_source_code_info = 13;
 * @return {boolean}
 */
proto.scalapb.ScalaPbOptions.prototype.getRetainSourceCodeInfo = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.scalapb.ScalaPbOptions} returns this
 */
proto.scalapb.ScalaPbOptions.prototype.setRetainSourceCodeInfo = function(value) {
  return jspb.Message.setField(this, 13, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.scalapb.ScalaPbOptions} returns this
 */
proto.scalapb.ScalaPbOptions.prototype.clearRetainSourceCodeInfo = function() {
  return jspb.Message.setField(this, 13, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scalapb.ScalaPbOptions.prototype.hasRetainSourceCodeInfo = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional string map_type = 14;
 * @return {string}
 */
proto.scalapb.ScalaPbOptions.prototype.getMapType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.scalapb.ScalaPbOptions} returns this
 */
proto.scalapb.ScalaPbOptions.prototype.setMapType = function(value) {
  return jspb.Message.setField(this, 14, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.scalapb.ScalaPbOptions} returns this
 */
proto.scalapb.ScalaPbOptions.prototype.clearMapType = function() {
  return jspb.Message.setField(this, 14, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scalapb.ScalaPbOptions.prototype.hasMapType = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional bool no_default_values_in_constructor = 15;
 * @return {boolean}
 */
proto.scalapb.ScalaPbOptions.prototype.getNoDefaultValuesInConstructor = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 15, false));
};


/**
 * @param {boolean} value
 * @return {!proto.scalapb.ScalaPbOptions} returns this
 */
proto.scalapb.ScalaPbOptions.prototype.setNoDefaultValuesInConstructor = function(value) {
  return jspb.Message.setField(this, 15, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.scalapb.ScalaPbOptions} returns this
 */
proto.scalapb.ScalaPbOptions.prototype.clearNoDefaultValuesInConstructor = function() {
  return jspb.Message.setField(this, 15, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scalapb.ScalaPbOptions.prototype.hasNoDefaultValuesInConstructor = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional EnumValueNaming enum_value_naming = 16;
 * @return {!proto.scalapb.ScalaPbOptions.EnumValueNaming}
 */
proto.scalapb.ScalaPbOptions.prototype.getEnumValueNaming = function() {
  return /** @type {!proto.scalapb.ScalaPbOptions.EnumValueNaming} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {!proto.scalapb.ScalaPbOptions.EnumValueNaming} value
 * @return {!proto.scalapb.ScalaPbOptions} returns this
 */
proto.scalapb.ScalaPbOptions.prototype.setEnumValueNaming = function(value) {
  return jspb.Message.setField(this, 16, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.scalapb.ScalaPbOptions} returns this
 */
proto.scalapb.ScalaPbOptions.prototype.clearEnumValueNaming = function() {
  return jspb.Message.setField(this, 16, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scalapb.ScalaPbOptions.prototype.hasEnumValueNaming = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional bool enum_strip_prefix = 17;
 * @return {boolean}
 */
proto.scalapb.ScalaPbOptions.prototype.getEnumStripPrefix = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 17, false));
};


/**
 * @param {boolean} value
 * @return {!proto.scalapb.ScalaPbOptions} returns this
 */
proto.scalapb.ScalaPbOptions.prototype.setEnumStripPrefix = function(value) {
  return jspb.Message.setField(this, 17, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.scalapb.ScalaPbOptions} returns this
 */
proto.scalapb.ScalaPbOptions.prototype.clearEnumStripPrefix = function() {
  return jspb.Message.setField(this, 17, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scalapb.ScalaPbOptions.prototype.hasEnumStripPrefix = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional string bytes_type = 21;
 * @return {string}
 */
proto.scalapb.ScalaPbOptions.prototype.getBytesType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.scalapb.ScalaPbOptions} returns this
 */
proto.scalapb.ScalaPbOptions.prototype.setBytesType = function(value) {
  return jspb.Message.setField(this, 21, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.scalapb.ScalaPbOptions} returns this
 */
proto.scalapb.ScalaPbOptions.prototype.clearBytesType = function() {
  return jspb.Message.setField(this, 21, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scalapb.ScalaPbOptions.prototype.hasBytesType = function() {
  return jspb.Message.getField(this, 21) != null;
};


/**
 * optional bool java_conversions = 23;
 * @return {boolean}
 */
proto.scalapb.ScalaPbOptions.prototype.getJavaConversions = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 23, false));
};


/**
 * @param {boolean} value
 * @return {!proto.scalapb.ScalaPbOptions} returns this
 */
proto.scalapb.ScalaPbOptions.prototype.setJavaConversions = function(value) {
  return jspb.Message.setField(this, 23, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.scalapb.ScalaPbOptions} returns this
 */
proto.scalapb.ScalaPbOptions.prototype.clearJavaConversions = function() {
  return jspb.Message.setField(this, 23, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scalapb.ScalaPbOptions.prototype.hasJavaConversions = function() {
  return jspb.Message.getField(this, 23) != null;
};


/**
 * repeated AuxMessageOptions aux_message_options = 18;
 * @return {!Array<!proto.scalapb.ScalaPbOptions.AuxMessageOptions>}
 */
proto.scalapb.ScalaPbOptions.prototype.getAuxMessageOptionsList = function() {
  return /** @type{!Array<!proto.scalapb.ScalaPbOptions.AuxMessageOptions>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.scalapb.ScalaPbOptions.AuxMessageOptions, 18));
};


/**
 * @param {!Array<!proto.scalapb.ScalaPbOptions.AuxMessageOptions>} value
 * @return {!proto.scalapb.ScalaPbOptions} returns this
*/
proto.scalapb.ScalaPbOptions.prototype.setAuxMessageOptionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 18, value);
};


/**
 * @param {!proto.scalapb.ScalaPbOptions.AuxMessageOptions=} opt_value
 * @param {number=} opt_index
 * @return {!proto.scalapb.ScalaPbOptions.AuxMessageOptions}
 */
proto.scalapb.ScalaPbOptions.prototype.addAuxMessageOptions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 18, opt_value, proto.scalapb.ScalaPbOptions.AuxMessageOptions, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.scalapb.ScalaPbOptions} returns this
 */
proto.scalapb.ScalaPbOptions.prototype.clearAuxMessageOptionsList = function() {
  return this.setAuxMessageOptionsList([]);
};


/**
 * repeated AuxFieldOptions aux_field_options = 19;
 * @return {!Array<!proto.scalapb.ScalaPbOptions.AuxFieldOptions>}
 */
proto.scalapb.ScalaPbOptions.prototype.getAuxFieldOptionsList = function() {
  return /** @type{!Array<!proto.scalapb.ScalaPbOptions.AuxFieldOptions>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.scalapb.ScalaPbOptions.AuxFieldOptions, 19));
};


/**
 * @param {!Array<!proto.scalapb.ScalaPbOptions.AuxFieldOptions>} value
 * @return {!proto.scalapb.ScalaPbOptions} returns this
*/
proto.scalapb.ScalaPbOptions.prototype.setAuxFieldOptionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 19, value);
};


/**
 * @param {!proto.scalapb.ScalaPbOptions.AuxFieldOptions=} opt_value
 * @param {number=} opt_index
 * @return {!proto.scalapb.ScalaPbOptions.AuxFieldOptions}
 */
proto.scalapb.ScalaPbOptions.prototype.addAuxFieldOptions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 19, opt_value, proto.scalapb.ScalaPbOptions.AuxFieldOptions, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.scalapb.ScalaPbOptions} returns this
 */
proto.scalapb.ScalaPbOptions.prototype.clearAuxFieldOptionsList = function() {
  return this.setAuxFieldOptionsList([]);
};


/**
 * repeated AuxEnumOptions aux_enum_options = 20;
 * @return {!Array<!proto.scalapb.ScalaPbOptions.AuxEnumOptions>}
 */
proto.scalapb.ScalaPbOptions.prototype.getAuxEnumOptionsList = function() {
  return /** @type{!Array<!proto.scalapb.ScalaPbOptions.AuxEnumOptions>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.scalapb.ScalaPbOptions.AuxEnumOptions, 20));
};


/**
 * @param {!Array<!proto.scalapb.ScalaPbOptions.AuxEnumOptions>} value
 * @return {!proto.scalapb.ScalaPbOptions} returns this
*/
proto.scalapb.ScalaPbOptions.prototype.setAuxEnumOptionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 20, value);
};


/**
 * @param {!proto.scalapb.ScalaPbOptions.AuxEnumOptions=} opt_value
 * @param {number=} opt_index
 * @return {!proto.scalapb.ScalaPbOptions.AuxEnumOptions}
 */
proto.scalapb.ScalaPbOptions.prototype.addAuxEnumOptions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 20, opt_value, proto.scalapb.ScalaPbOptions.AuxEnumOptions, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.scalapb.ScalaPbOptions} returns this
 */
proto.scalapb.ScalaPbOptions.prototype.clearAuxEnumOptionsList = function() {
  return this.setAuxEnumOptionsList([]);
};


/**
 * repeated AuxEnumValueOptions aux_enum_value_options = 22;
 * @return {!Array<!proto.scalapb.ScalaPbOptions.AuxEnumValueOptions>}
 */
proto.scalapb.ScalaPbOptions.prototype.getAuxEnumValueOptionsList = function() {
  return /** @type{!Array<!proto.scalapb.ScalaPbOptions.AuxEnumValueOptions>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.scalapb.ScalaPbOptions.AuxEnumValueOptions, 22));
};


/**
 * @param {!Array<!proto.scalapb.ScalaPbOptions.AuxEnumValueOptions>} value
 * @return {!proto.scalapb.ScalaPbOptions} returns this
*/
proto.scalapb.ScalaPbOptions.prototype.setAuxEnumValueOptionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 22, value);
};


/**
 * @param {!proto.scalapb.ScalaPbOptions.AuxEnumValueOptions=} opt_value
 * @param {number=} opt_index
 * @return {!proto.scalapb.ScalaPbOptions.AuxEnumValueOptions}
 */
proto.scalapb.ScalaPbOptions.prototype.addAuxEnumValueOptions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 22, opt_value, proto.scalapb.ScalaPbOptions.AuxEnumValueOptions, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.scalapb.ScalaPbOptions} returns this
 */
proto.scalapb.ScalaPbOptions.prototype.clearAuxEnumValueOptionsList = function() {
  return this.setAuxEnumValueOptionsList([]);
};


/**
 * repeated string preprocessors = 24;
 * @return {!Array<string>}
 */
proto.scalapb.ScalaPbOptions.prototype.getPreprocessorsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 24));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.scalapb.ScalaPbOptions} returns this
 */
proto.scalapb.ScalaPbOptions.prototype.setPreprocessorsList = function(value) {
  return jspb.Message.setField(this, 24, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.scalapb.ScalaPbOptions} returns this
 */
proto.scalapb.ScalaPbOptions.prototype.addPreprocessors = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 24, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.scalapb.ScalaPbOptions} returns this
 */
proto.scalapb.ScalaPbOptions.prototype.clearPreprocessorsList = function() {
  return this.setPreprocessorsList([]);
};


/**
 * repeated FieldTransformation field_transformations = 25;
 * @return {!Array<!proto.scalapb.FieldTransformation>}
 */
proto.scalapb.ScalaPbOptions.prototype.getFieldTransformationsList = function() {
  return /** @type{!Array<!proto.scalapb.FieldTransformation>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.scalapb.FieldTransformation, 25));
};


/**
 * @param {!Array<!proto.scalapb.FieldTransformation>} value
 * @return {!proto.scalapb.ScalaPbOptions} returns this
*/
proto.scalapb.ScalaPbOptions.prototype.setFieldTransformationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 25, value);
};


/**
 * @param {!proto.scalapb.FieldTransformation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.scalapb.FieldTransformation}
 */
proto.scalapb.ScalaPbOptions.prototype.addFieldTransformations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 25, opt_value, proto.scalapb.FieldTransformation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.scalapb.ScalaPbOptions} returns this
 */
proto.scalapb.ScalaPbOptions.prototype.clearFieldTransformationsList = function() {
  return this.setFieldTransformationsList([]);
};


/**
 * optional bool ignore_all_transformations = 26;
 * @return {boolean}
 */
proto.scalapb.ScalaPbOptions.prototype.getIgnoreAllTransformations = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 26, false));
};


/**
 * @param {boolean} value
 * @return {!proto.scalapb.ScalaPbOptions} returns this
 */
proto.scalapb.ScalaPbOptions.prototype.setIgnoreAllTransformations = function(value) {
  return jspb.Message.setField(this, 26, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.scalapb.ScalaPbOptions} returns this
 */
proto.scalapb.ScalaPbOptions.prototype.clearIgnoreAllTransformations = function() {
  return jspb.Message.setField(this, 26, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scalapb.ScalaPbOptions.prototype.hasIgnoreAllTransformations = function() {
  return jspb.Message.getField(this, 26) != null;
};


/**
 * optional bool getters = 27;
 * @return {boolean}
 */
proto.scalapb.ScalaPbOptions.prototype.getGetters = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 27, true));
};


/**
 * @param {boolean} value
 * @return {!proto.scalapb.ScalaPbOptions} returns this
 */
proto.scalapb.ScalaPbOptions.prototype.setGetters = function(value) {
  return jspb.Message.setField(this, 27, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.scalapb.ScalaPbOptions} returns this
 */
proto.scalapb.ScalaPbOptions.prototype.clearGetters = function() {
  return jspb.Message.setField(this, 27, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scalapb.ScalaPbOptions.prototype.hasGetters = function() {
  return jspb.Message.getField(this, 27) != null;
};


/**
 * optional bool scala3_sources = 28;
 * @return {boolean}
 */
proto.scalapb.ScalaPbOptions.prototype.getScala3Sources = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 28, false));
};


/**
 * @param {boolean} value
 * @return {!proto.scalapb.ScalaPbOptions} returns this
 */
proto.scalapb.ScalaPbOptions.prototype.setScala3Sources = function(value) {
  return jspb.Message.setField(this, 28, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.scalapb.ScalaPbOptions} returns this
 */
proto.scalapb.ScalaPbOptions.prototype.clearScala3Sources = function() {
  return jspb.Message.setField(this, 28, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scalapb.ScalaPbOptions.prototype.hasScala3Sources = function() {
  return jspb.Message.getField(this, 28) != null;
};


/**
 * optional bool test_only_no_java_conversions = 999;
 * @return {boolean}
 */
proto.scalapb.ScalaPbOptions.prototype.getTestOnlyNoJavaConversions = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 999, false));
};


/**
 * @param {boolean} value
 * @return {!proto.scalapb.ScalaPbOptions} returns this
 */
proto.scalapb.ScalaPbOptions.prototype.setTestOnlyNoJavaConversions = function(value) {
  return jspb.Message.setField(this, 999, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.scalapb.ScalaPbOptions} returns this
 */
proto.scalapb.ScalaPbOptions.prototype.clearTestOnlyNoJavaConversions = function() {
  return jspb.Message.setField(this, 999, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scalapb.ScalaPbOptions.prototype.hasTestOnlyNoJavaConversions = function() {
  return jspb.Message.getField(this, 999) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.scalapb.MessageOptions.repeatedFields_ = [1,2,3,5,6,8,10,11,12,13];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.scalapb.MessageOptions.prototype.toObject = function(opt_includeInstance) {
  return proto.scalapb.MessageOptions.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.scalapb.MessageOptions} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scalapb.MessageOptions.toObject = function(includeInstance, msg) {
  var f, obj = {
    extendsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    companionExtendsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    annotationsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    type: (f = jspb.Message.getField(msg, 4)) == null ? undefined : f,
    companionAnnotationsList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    sealedOneofExtendsList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    noBox: (f = jspb.Message.getBooleanField(msg, 7)) == null ? undefined : f,
    unknownFieldsAnnotationsList: (f = jspb.Message.getRepeatedField(msg, 8)) == null ? undefined : f,
    noDefaultValuesInConstructor: (f = jspb.Message.getBooleanField(msg, 9)) == null ? undefined : f,
    sealedOneofCompanionExtendsList: (f = jspb.Message.getRepeatedField(msg, 10)) == null ? undefined : f,
    derivesList: (f = jspb.Message.getRepeatedField(msg, 11)) == null ? undefined : f,
    sealedOneofDerivesList: (f = jspb.Message.getRepeatedField(msg, 12)) == null ? undefined : f,
    sealedOneofEmptyExtendsList: (f = jspb.Message.getRepeatedField(msg, 13)) == null ? undefined : f
  };

  jspb.Message.toObjectExtension(/** @type {!jspb.Message} */ (msg), obj,
      proto.scalapb.MessageOptions.extensions, proto.scalapb.MessageOptions.prototype.getExtension,
      includeInstance);
  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.scalapb.MessageOptions}
 */
proto.scalapb.MessageOptions.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.scalapb.MessageOptions;
  return proto.scalapb.MessageOptions.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.scalapb.MessageOptions} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.scalapb.MessageOptions}
 */
proto.scalapb.MessageOptions.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addExtends(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addCompanionExtends(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addAnnotations(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.addCompanionAnnotations(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.addSealedOneofExtends(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNoBox(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.addUnknownFieldsAnnotations(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNoDefaultValuesInConstructor(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.addSealedOneofCompanionExtends(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.addDerives(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.addSealedOneofDerives(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.addSealedOneofEmptyExtends(value);
      break;
    default:
      jspb.Message.readBinaryExtension(msg, reader,
        proto.scalapb.MessageOptions.extensionsBinary,
        proto.scalapb.MessageOptions.prototype.getExtension,
        proto.scalapb.MessageOptions.prototype.setExtension);
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.scalapb.MessageOptions.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.scalapb.MessageOptions.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.scalapb.MessageOptions} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scalapb.MessageOptions.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExtendsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getCompanionExtendsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getAnnotationsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCompanionAnnotationsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      5,
      f
    );
  }
  f = message.getSealedOneofExtendsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      6,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getUnknownFieldsAnnotationsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      8,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 9));
  if (f != null) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getSealedOneofCompanionExtendsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      10,
      f
    );
  }
  f = message.getDerivesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      11,
      f
    );
  }
  f = message.getSealedOneofDerivesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      12,
      f
    );
  }
  f = message.getSealedOneofEmptyExtendsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      13,
      f
    );
  }
  jspb.Message.serializeBinaryExtensions(message, writer,
    proto.scalapb.MessageOptions.extensionsBinary, proto.scalapb.MessageOptions.prototype.getExtension);
};


/**
 * repeated string extends = 1;
 * @return {!Array<string>}
 */
proto.scalapb.MessageOptions.prototype.getExtendsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.scalapb.MessageOptions} returns this
 */
proto.scalapb.MessageOptions.prototype.setExtendsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.scalapb.MessageOptions} returns this
 */
proto.scalapb.MessageOptions.prototype.addExtends = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.scalapb.MessageOptions} returns this
 */
proto.scalapb.MessageOptions.prototype.clearExtendsList = function() {
  return this.setExtendsList([]);
};


/**
 * repeated string companion_extends = 2;
 * @return {!Array<string>}
 */
proto.scalapb.MessageOptions.prototype.getCompanionExtendsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.scalapb.MessageOptions} returns this
 */
proto.scalapb.MessageOptions.prototype.setCompanionExtendsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.scalapb.MessageOptions} returns this
 */
proto.scalapb.MessageOptions.prototype.addCompanionExtends = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.scalapb.MessageOptions} returns this
 */
proto.scalapb.MessageOptions.prototype.clearCompanionExtendsList = function() {
  return this.setCompanionExtendsList([]);
};


/**
 * repeated string annotations = 3;
 * @return {!Array<string>}
 */
proto.scalapb.MessageOptions.prototype.getAnnotationsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.scalapb.MessageOptions} returns this
 */
proto.scalapb.MessageOptions.prototype.setAnnotationsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.scalapb.MessageOptions} returns this
 */
proto.scalapb.MessageOptions.prototype.addAnnotations = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.scalapb.MessageOptions} returns this
 */
proto.scalapb.MessageOptions.prototype.clearAnnotationsList = function() {
  return this.setAnnotationsList([]);
};


/**
 * optional string type = 4;
 * @return {string}
 */
proto.scalapb.MessageOptions.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.scalapb.MessageOptions} returns this
 */
proto.scalapb.MessageOptions.prototype.setType = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.scalapb.MessageOptions} returns this
 */
proto.scalapb.MessageOptions.prototype.clearType = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scalapb.MessageOptions.prototype.hasType = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * repeated string companion_annotations = 5;
 * @return {!Array<string>}
 */
proto.scalapb.MessageOptions.prototype.getCompanionAnnotationsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.scalapb.MessageOptions} returns this
 */
proto.scalapb.MessageOptions.prototype.setCompanionAnnotationsList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.scalapb.MessageOptions} returns this
 */
proto.scalapb.MessageOptions.prototype.addCompanionAnnotations = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.scalapb.MessageOptions} returns this
 */
proto.scalapb.MessageOptions.prototype.clearCompanionAnnotationsList = function() {
  return this.setCompanionAnnotationsList([]);
};


/**
 * repeated string sealed_oneof_extends = 6;
 * @return {!Array<string>}
 */
proto.scalapb.MessageOptions.prototype.getSealedOneofExtendsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.scalapb.MessageOptions} returns this
 */
proto.scalapb.MessageOptions.prototype.setSealedOneofExtendsList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.scalapb.MessageOptions} returns this
 */
proto.scalapb.MessageOptions.prototype.addSealedOneofExtends = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.scalapb.MessageOptions} returns this
 */
proto.scalapb.MessageOptions.prototype.clearSealedOneofExtendsList = function() {
  return this.setSealedOneofExtendsList([]);
};


/**
 * optional bool no_box = 7;
 * @return {boolean}
 */
proto.scalapb.MessageOptions.prototype.getNoBox = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.scalapb.MessageOptions} returns this
 */
proto.scalapb.MessageOptions.prototype.setNoBox = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.scalapb.MessageOptions} returns this
 */
proto.scalapb.MessageOptions.prototype.clearNoBox = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scalapb.MessageOptions.prototype.hasNoBox = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * repeated string unknown_fields_annotations = 8;
 * @return {!Array<string>}
 */
proto.scalapb.MessageOptions.prototype.getUnknownFieldsAnnotationsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 8));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.scalapb.MessageOptions} returns this
 */
proto.scalapb.MessageOptions.prototype.setUnknownFieldsAnnotationsList = function(value) {
  return jspb.Message.setField(this, 8, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.scalapb.MessageOptions} returns this
 */
proto.scalapb.MessageOptions.prototype.addUnknownFieldsAnnotations = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 8, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.scalapb.MessageOptions} returns this
 */
proto.scalapb.MessageOptions.prototype.clearUnknownFieldsAnnotationsList = function() {
  return this.setUnknownFieldsAnnotationsList([]);
};


/**
 * optional bool no_default_values_in_constructor = 9;
 * @return {boolean}
 */
proto.scalapb.MessageOptions.prototype.getNoDefaultValuesInConstructor = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.scalapb.MessageOptions} returns this
 */
proto.scalapb.MessageOptions.prototype.setNoDefaultValuesInConstructor = function(value) {
  return jspb.Message.setField(this, 9, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.scalapb.MessageOptions} returns this
 */
proto.scalapb.MessageOptions.prototype.clearNoDefaultValuesInConstructor = function() {
  return jspb.Message.setField(this, 9, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scalapb.MessageOptions.prototype.hasNoDefaultValuesInConstructor = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * repeated string sealed_oneof_companion_extends = 10;
 * @return {!Array<string>}
 */
proto.scalapb.MessageOptions.prototype.getSealedOneofCompanionExtendsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 10));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.scalapb.MessageOptions} returns this
 */
proto.scalapb.MessageOptions.prototype.setSealedOneofCompanionExtendsList = function(value) {
  return jspb.Message.setField(this, 10, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.scalapb.MessageOptions} returns this
 */
proto.scalapb.MessageOptions.prototype.addSealedOneofCompanionExtends = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 10, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.scalapb.MessageOptions} returns this
 */
proto.scalapb.MessageOptions.prototype.clearSealedOneofCompanionExtendsList = function() {
  return this.setSealedOneofCompanionExtendsList([]);
};


/**
 * repeated string derives = 11;
 * @return {!Array<string>}
 */
proto.scalapb.MessageOptions.prototype.getDerivesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 11));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.scalapb.MessageOptions} returns this
 */
proto.scalapb.MessageOptions.prototype.setDerivesList = function(value) {
  return jspb.Message.setField(this, 11, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.scalapb.MessageOptions} returns this
 */
proto.scalapb.MessageOptions.prototype.addDerives = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 11, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.scalapb.MessageOptions} returns this
 */
proto.scalapb.MessageOptions.prototype.clearDerivesList = function() {
  return this.setDerivesList([]);
};


/**
 * repeated string sealed_oneof_derives = 12;
 * @return {!Array<string>}
 */
proto.scalapb.MessageOptions.prototype.getSealedOneofDerivesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 12));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.scalapb.MessageOptions} returns this
 */
proto.scalapb.MessageOptions.prototype.setSealedOneofDerivesList = function(value) {
  return jspb.Message.setField(this, 12, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.scalapb.MessageOptions} returns this
 */
proto.scalapb.MessageOptions.prototype.addSealedOneofDerives = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 12, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.scalapb.MessageOptions} returns this
 */
proto.scalapb.MessageOptions.prototype.clearSealedOneofDerivesList = function() {
  return this.setSealedOneofDerivesList([]);
};


/**
 * repeated string sealed_oneof_empty_extends = 13;
 * @return {!Array<string>}
 */
proto.scalapb.MessageOptions.prototype.getSealedOneofEmptyExtendsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 13));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.scalapb.MessageOptions} returns this
 */
proto.scalapb.MessageOptions.prototype.setSealedOneofEmptyExtendsList = function(value) {
  return jspb.Message.setField(this, 13, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.scalapb.MessageOptions} returns this
 */
proto.scalapb.MessageOptions.prototype.addSealedOneofEmptyExtends = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 13, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.scalapb.MessageOptions} returns this
 */
proto.scalapb.MessageOptions.prototype.clearSealedOneofEmptyExtendsList = function() {
  return this.setSealedOneofEmptyExtendsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.scalapb.Collection.prototype.toObject = function(opt_includeInstance) {
  return proto.scalapb.Collection.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.scalapb.Collection} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scalapb.Collection.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    nonEmpty: (f = jspb.Message.getBooleanField(msg, 2)) == null ? undefined : f,
    adapter: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.scalapb.Collection}
 */
proto.scalapb.Collection.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.scalapb.Collection;
  return proto.scalapb.Collection.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.scalapb.Collection} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.scalapb.Collection}
 */
proto.scalapb.Collection.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNonEmpty(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdapter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.scalapb.Collection.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.scalapb.Collection.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.scalapb.Collection} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scalapb.Collection.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeBool(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string type = 1;
 * @return {string}
 */
proto.scalapb.Collection.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.scalapb.Collection} returns this
 */
proto.scalapb.Collection.prototype.setType = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.scalapb.Collection} returns this
 */
proto.scalapb.Collection.prototype.clearType = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scalapb.Collection.prototype.hasType = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool non_empty = 2;
 * @return {boolean}
 */
proto.scalapb.Collection.prototype.getNonEmpty = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.scalapb.Collection} returns this
 */
proto.scalapb.Collection.prototype.setNonEmpty = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.scalapb.Collection} returns this
 */
proto.scalapb.Collection.prototype.clearNonEmpty = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scalapb.Collection.prototype.hasNonEmpty = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string adapter = 3;
 * @return {string}
 */
proto.scalapb.Collection.prototype.getAdapter = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.scalapb.Collection} returns this
 */
proto.scalapb.Collection.prototype.setAdapter = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.scalapb.Collection} returns this
 */
proto.scalapb.Collection.prototype.clearAdapter = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scalapb.Collection.prototype.hasAdapter = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.scalapb.FieldOptions.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.scalapb.FieldOptions.prototype.toObject = function(opt_includeInstance) {
  return proto.scalapb.FieldOptions.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.scalapb.FieldOptions} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scalapb.FieldOptions.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    scalaName: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    collectionType: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f,
    collection: (f = msg.getCollection()) && proto.scalapb.Collection.toObject(includeInstance, f),
    keyType: (f = jspb.Message.getField(msg, 4)) == null ? undefined : f,
    valueType: (f = jspb.Message.getField(msg, 5)) == null ? undefined : f,
    annotationsList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    mapType: (f = jspb.Message.getField(msg, 7)) == null ? undefined : f,
    noDefaultValueInConstructor: (f = jspb.Message.getBooleanField(msg, 9)) == null ? undefined : f,
    noBox: (f = jspb.Message.getBooleanField(msg, 30)) == null ? undefined : f,
    required: (f = jspb.Message.getBooleanField(msg, 31)) == null ? undefined : f
  };

  jspb.Message.toObjectExtension(/** @type {!jspb.Message} */ (msg), obj,
      proto.scalapb.FieldOptions.extensions, proto.scalapb.FieldOptions.prototype.getExtension,
      includeInstance);
  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.scalapb.FieldOptions}
 */
proto.scalapb.FieldOptions.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.scalapb.FieldOptions;
  return proto.scalapb.FieldOptions.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.scalapb.FieldOptions} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.scalapb.FieldOptions}
 */
proto.scalapb.FieldOptions.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setScalaName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCollectionType(value);
      break;
    case 8:
      var value = new proto.scalapb.Collection;
      reader.readMessage(value,proto.scalapb.Collection.deserializeBinaryFromReader);
      msg.setCollection(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setKeyType(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setValueType(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.addAnnotations(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setMapType(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNoDefaultValueInConstructor(value);
      break;
    case 30:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNoBox(value);
      break;
    case 31:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRequired(value);
      break;
    default:
      jspb.Message.readBinaryExtension(msg, reader,
        proto.scalapb.FieldOptions.extensionsBinary,
        proto.scalapb.FieldOptions.prototype.getExtension,
        proto.scalapb.FieldOptions.prototype.setExtension);
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.scalapb.FieldOptions.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.scalapb.FieldOptions.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.scalapb.FieldOptions} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scalapb.FieldOptions.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCollection();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.scalapb.Collection.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeString(
      4,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getAnnotationsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      6,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeString(
      7,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 9));
  if (f != null) {
    writer.writeBool(
      9,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 30));
  if (f != null) {
    writer.writeBool(
      30,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 31));
  if (f != null) {
    writer.writeBool(
      31,
      f
    );
  }
  jspb.Message.serializeBinaryExtensions(message, writer,
    proto.scalapb.FieldOptions.extensionsBinary, proto.scalapb.FieldOptions.prototype.getExtension);
};


/**
 * optional string type = 1;
 * @return {string}
 */
proto.scalapb.FieldOptions.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.scalapb.FieldOptions} returns this
 */
proto.scalapb.FieldOptions.prototype.setType = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.scalapb.FieldOptions} returns this
 */
proto.scalapb.FieldOptions.prototype.clearType = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scalapb.FieldOptions.prototype.hasType = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string scala_name = 2;
 * @return {string}
 */
proto.scalapb.FieldOptions.prototype.getScalaName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.scalapb.FieldOptions} returns this
 */
proto.scalapb.FieldOptions.prototype.setScalaName = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.scalapb.FieldOptions} returns this
 */
proto.scalapb.FieldOptions.prototype.clearScalaName = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scalapb.FieldOptions.prototype.hasScalaName = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string collection_type = 3;
 * @return {string}
 */
proto.scalapb.FieldOptions.prototype.getCollectionType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.scalapb.FieldOptions} returns this
 */
proto.scalapb.FieldOptions.prototype.setCollectionType = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.scalapb.FieldOptions} returns this
 */
proto.scalapb.FieldOptions.prototype.clearCollectionType = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scalapb.FieldOptions.prototype.hasCollectionType = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Collection collection = 8;
 * @return {?proto.scalapb.Collection}
 */
proto.scalapb.FieldOptions.prototype.getCollection = function() {
  return /** @type{?proto.scalapb.Collection} */ (
    jspb.Message.getWrapperField(this, proto.scalapb.Collection, 8));
};


/**
 * @param {?proto.scalapb.Collection|undefined} value
 * @return {!proto.scalapb.FieldOptions} returns this
*/
proto.scalapb.FieldOptions.prototype.setCollection = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.scalapb.FieldOptions} returns this
 */
proto.scalapb.FieldOptions.prototype.clearCollection = function() {
  return this.setCollection(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scalapb.FieldOptions.prototype.hasCollection = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional string key_type = 4;
 * @return {string}
 */
proto.scalapb.FieldOptions.prototype.getKeyType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.scalapb.FieldOptions} returns this
 */
proto.scalapb.FieldOptions.prototype.setKeyType = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.scalapb.FieldOptions} returns this
 */
proto.scalapb.FieldOptions.prototype.clearKeyType = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scalapb.FieldOptions.prototype.hasKeyType = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string value_type = 5;
 * @return {string}
 */
proto.scalapb.FieldOptions.prototype.getValueType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.scalapb.FieldOptions} returns this
 */
proto.scalapb.FieldOptions.prototype.setValueType = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.scalapb.FieldOptions} returns this
 */
proto.scalapb.FieldOptions.prototype.clearValueType = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scalapb.FieldOptions.prototype.hasValueType = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * repeated string annotations = 6;
 * @return {!Array<string>}
 */
proto.scalapb.FieldOptions.prototype.getAnnotationsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.scalapb.FieldOptions} returns this
 */
proto.scalapb.FieldOptions.prototype.setAnnotationsList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.scalapb.FieldOptions} returns this
 */
proto.scalapb.FieldOptions.prototype.addAnnotations = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.scalapb.FieldOptions} returns this
 */
proto.scalapb.FieldOptions.prototype.clearAnnotationsList = function() {
  return this.setAnnotationsList([]);
};


/**
 * optional string map_type = 7;
 * @return {string}
 */
proto.scalapb.FieldOptions.prototype.getMapType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.scalapb.FieldOptions} returns this
 */
proto.scalapb.FieldOptions.prototype.setMapType = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.scalapb.FieldOptions} returns this
 */
proto.scalapb.FieldOptions.prototype.clearMapType = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scalapb.FieldOptions.prototype.hasMapType = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional bool no_default_value_in_constructor = 9;
 * @return {boolean}
 */
proto.scalapb.FieldOptions.prototype.getNoDefaultValueInConstructor = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.scalapb.FieldOptions} returns this
 */
proto.scalapb.FieldOptions.prototype.setNoDefaultValueInConstructor = function(value) {
  return jspb.Message.setField(this, 9, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.scalapb.FieldOptions} returns this
 */
proto.scalapb.FieldOptions.prototype.clearNoDefaultValueInConstructor = function() {
  return jspb.Message.setField(this, 9, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scalapb.FieldOptions.prototype.hasNoDefaultValueInConstructor = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional bool no_box = 30;
 * @return {boolean}
 */
proto.scalapb.FieldOptions.prototype.getNoBox = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 30, false));
};


/**
 * @param {boolean} value
 * @return {!proto.scalapb.FieldOptions} returns this
 */
proto.scalapb.FieldOptions.prototype.setNoBox = function(value) {
  return jspb.Message.setField(this, 30, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.scalapb.FieldOptions} returns this
 */
proto.scalapb.FieldOptions.prototype.clearNoBox = function() {
  return jspb.Message.setField(this, 30, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scalapb.FieldOptions.prototype.hasNoBox = function() {
  return jspb.Message.getField(this, 30) != null;
};


/**
 * optional bool required = 31;
 * @return {boolean}
 */
proto.scalapb.FieldOptions.prototype.getRequired = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 31, false));
};


/**
 * @param {boolean} value
 * @return {!proto.scalapb.FieldOptions} returns this
 */
proto.scalapb.FieldOptions.prototype.setRequired = function(value) {
  return jspb.Message.setField(this, 31, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.scalapb.FieldOptions} returns this
 */
proto.scalapb.FieldOptions.prototype.clearRequired = function() {
  return jspb.Message.setField(this, 31, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scalapb.FieldOptions.prototype.hasRequired = function() {
  return jspb.Message.getField(this, 31) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.scalapb.EnumOptions.repeatedFields_ = [1,2,4,5,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.scalapb.EnumOptions.prototype.toObject = function(opt_includeInstance) {
  return proto.scalapb.EnumOptions.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.scalapb.EnumOptions} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scalapb.EnumOptions.toObject = function(includeInstance, msg) {
  var f, obj = {
    extendsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    companionExtendsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    type: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f,
    baseAnnotationsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    recognizedAnnotationsList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    unrecognizedAnnotationsList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f
  };

  jspb.Message.toObjectExtension(/** @type {!jspb.Message} */ (msg), obj,
      proto.scalapb.EnumOptions.extensions, proto.scalapb.EnumOptions.prototype.getExtension,
      includeInstance);
  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.scalapb.EnumOptions}
 */
proto.scalapb.EnumOptions.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.scalapb.EnumOptions;
  return proto.scalapb.EnumOptions.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.scalapb.EnumOptions} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.scalapb.EnumOptions}
 */
proto.scalapb.EnumOptions.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addExtends(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addCompanionExtends(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addBaseAnnotations(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.addRecognizedAnnotations(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.addUnrecognizedAnnotations(value);
      break;
    default:
      jspb.Message.readBinaryExtension(msg, reader,
        proto.scalapb.EnumOptions.extensionsBinary,
        proto.scalapb.EnumOptions.prototype.getExtension,
        proto.scalapb.EnumOptions.prototype.setExtension);
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.scalapb.EnumOptions.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.scalapb.EnumOptions.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.scalapb.EnumOptions} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scalapb.EnumOptions.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExtendsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getCompanionExtendsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getBaseAnnotationsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = message.getRecognizedAnnotationsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      5,
      f
    );
  }
  f = message.getUnrecognizedAnnotationsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      6,
      f
    );
  }
  jspb.Message.serializeBinaryExtensions(message, writer,
    proto.scalapb.EnumOptions.extensionsBinary, proto.scalapb.EnumOptions.prototype.getExtension);
};


/**
 * repeated string extends = 1;
 * @return {!Array<string>}
 */
proto.scalapb.EnumOptions.prototype.getExtendsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.scalapb.EnumOptions} returns this
 */
proto.scalapb.EnumOptions.prototype.setExtendsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.scalapb.EnumOptions} returns this
 */
proto.scalapb.EnumOptions.prototype.addExtends = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.scalapb.EnumOptions} returns this
 */
proto.scalapb.EnumOptions.prototype.clearExtendsList = function() {
  return this.setExtendsList([]);
};


/**
 * repeated string companion_extends = 2;
 * @return {!Array<string>}
 */
proto.scalapb.EnumOptions.prototype.getCompanionExtendsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.scalapb.EnumOptions} returns this
 */
proto.scalapb.EnumOptions.prototype.setCompanionExtendsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.scalapb.EnumOptions} returns this
 */
proto.scalapb.EnumOptions.prototype.addCompanionExtends = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.scalapb.EnumOptions} returns this
 */
proto.scalapb.EnumOptions.prototype.clearCompanionExtendsList = function() {
  return this.setCompanionExtendsList([]);
};


/**
 * optional string type = 3;
 * @return {string}
 */
proto.scalapb.EnumOptions.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.scalapb.EnumOptions} returns this
 */
proto.scalapb.EnumOptions.prototype.setType = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.scalapb.EnumOptions} returns this
 */
proto.scalapb.EnumOptions.prototype.clearType = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scalapb.EnumOptions.prototype.hasType = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated string base_annotations = 4;
 * @return {!Array<string>}
 */
proto.scalapb.EnumOptions.prototype.getBaseAnnotationsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.scalapb.EnumOptions} returns this
 */
proto.scalapb.EnumOptions.prototype.setBaseAnnotationsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.scalapb.EnumOptions} returns this
 */
proto.scalapb.EnumOptions.prototype.addBaseAnnotations = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.scalapb.EnumOptions} returns this
 */
proto.scalapb.EnumOptions.prototype.clearBaseAnnotationsList = function() {
  return this.setBaseAnnotationsList([]);
};


/**
 * repeated string recognized_annotations = 5;
 * @return {!Array<string>}
 */
proto.scalapb.EnumOptions.prototype.getRecognizedAnnotationsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.scalapb.EnumOptions} returns this
 */
proto.scalapb.EnumOptions.prototype.setRecognizedAnnotationsList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.scalapb.EnumOptions} returns this
 */
proto.scalapb.EnumOptions.prototype.addRecognizedAnnotations = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.scalapb.EnumOptions} returns this
 */
proto.scalapb.EnumOptions.prototype.clearRecognizedAnnotationsList = function() {
  return this.setRecognizedAnnotationsList([]);
};


/**
 * repeated string unrecognized_annotations = 6;
 * @return {!Array<string>}
 */
proto.scalapb.EnumOptions.prototype.getUnrecognizedAnnotationsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.scalapb.EnumOptions} returns this
 */
proto.scalapb.EnumOptions.prototype.setUnrecognizedAnnotationsList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.scalapb.EnumOptions} returns this
 */
proto.scalapb.EnumOptions.prototype.addUnrecognizedAnnotations = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.scalapb.EnumOptions} returns this
 */
proto.scalapb.EnumOptions.prototype.clearUnrecognizedAnnotationsList = function() {
  return this.setUnrecognizedAnnotationsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.scalapb.EnumValueOptions.repeatedFields_ = [1,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.scalapb.EnumValueOptions.prototype.toObject = function(opt_includeInstance) {
  return proto.scalapb.EnumValueOptions.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.scalapb.EnumValueOptions} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scalapb.EnumValueOptions.toObject = function(includeInstance, msg) {
  var f, obj = {
    extendsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    scalaName: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    annotationsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f
  };

  jspb.Message.toObjectExtension(/** @type {!jspb.Message} */ (msg), obj,
      proto.scalapb.EnumValueOptions.extensions, proto.scalapb.EnumValueOptions.prototype.getExtension,
      includeInstance);
  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.scalapb.EnumValueOptions}
 */
proto.scalapb.EnumValueOptions.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.scalapb.EnumValueOptions;
  return proto.scalapb.EnumValueOptions.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.scalapb.EnumValueOptions} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.scalapb.EnumValueOptions}
 */
proto.scalapb.EnumValueOptions.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addExtends(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setScalaName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addAnnotations(value);
      break;
    default:
      jspb.Message.readBinaryExtension(msg, reader,
        proto.scalapb.EnumValueOptions.extensionsBinary,
        proto.scalapb.EnumValueOptions.prototype.getExtension,
        proto.scalapb.EnumValueOptions.prototype.setExtension);
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.scalapb.EnumValueOptions.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.scalapb.EnumValueOptions.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.scalapb.EnumValueOptions} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scalapb.EnumValueOptions.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExtendsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAnnotationsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  jspb.Message.serializeBinaryExtensions(message, writer,
    proto.scalapb.EnumValueOptions.extensionsBinary, proto.scalapb.EnumValueOptions.prototype.getExtension);
};


/**
 * repeated string extends = 1;
 * @return {!Array<string>}
 */
proto.scalapb.EnumValueOptions.prototype.getExtendsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.scalapb.EnumValueOptions} returns this
 */
proto.scalapb.EnumValueOptions.prototype.setExtendsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.scalapb.EnumValueOptions} returns this
 */
proto.scalapb.EnumValueOptions.prototype.addExtends = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.scalapb.EnumValueOptions} returns this
 */
proto.scalapb.EnumValueOptions.prototype.clearExtendsList = function() {
  return this.setExtendsList([]);
};


/**
 * optional string scala_name = 2;
 * @return {string}
 */
proto.scalapb.EnumValueOptions.prototype.getScalaName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.scalapb.EnumValueOptions} returns this
 */
proto.scalapb.EnumValueOptions.prototype.setScalaName = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.scalapb.EnumValueOptions} returns this
 */
proto.scalapb.EnumValueOptions.prototype.clearScalaName = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scalapb.EnumValueOptions.prototype.hasScalaName = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated string annotations = 3;
 * @return {!Array<string>}
 */
proto.scalapb.EnumValueOptions.prototype.getAnnotationsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.scalapb.EnumValueOptions} returns this
 */
proto.scalapb.EnumValueOptions.prototype.setAnnotationsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.scalapb.EnumValueOptions} returns this
 */
proto.scalapb.EnumValueOptions.prototype.addAnnotations = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.scalapb.EnumValueOptions} returns this
 */
proto.scalapb.EnumValueOptions.prototype.clearAnnotationsList = function() {
  return this.setAnnotationsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.scalapb.OneofOptions.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.scalapb.OneofOptions.prototype.toObject = function(opt_includeInstance) {
  return proto.scalapb.OneofOptions.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.scalapb.OneofOptions} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scalapb.OneofOptions.toObject = function(includeInstance, msg) {
  var f, obj = {
    extendsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    scalaName: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f
  };

  jspb.Message.toObjectExtension(/** @type {!jspb.Message} */ (msg), obj,
      proto.scalapb.OneofOptions.extensions, proto.scalapb.OneofOptions.prototype.getExtension,
      includeInstance);
  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.scalapb.OneofOptions}
 */
proto.scalapb.OneofOptions.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.scalapb.OneofOptions;
  return proto.scalapb.OneofOptions.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.scalapb.OneofOptions} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.scalapb.OneofOptions}
 */
proto.scalapb.OneofOptions.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addExtends(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setScalaName(value);
      break;
    default:
      jspb.Message.readBinaryExtension(msg, reader,
        proto.scalapb.OneofOptions.extensionsBinary,
        proto.scalapb.OneofOptions.prototype.getExtension,
        proto.scalapb.OneofOptions.prototype.setExtension);
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.scalapb.OneofOptions.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.scalapb.OneofOptions.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.scalapb.OneofOptions} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scalapb.OneofOptions.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExtendsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  jspb.Message.serializeBinaryExtensions(message, writer,
    proto.scalapb.OneofOptions.extensionsBinary, proto.scalapb.OneofOptions.prototype.getExtension);
};


/**
 * repeated string extends = 1;
 * @return {!Array<string>}
 */
proto.scalapb.OneofOptions.prototype.getExtendsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.scalapb.OneofOptions} returns this
 */
proto.scalapb.OneofOptions.prototype.setExtendsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.scalapb.OneofOptions} returns this
 */
proto.scalapb.OneofOptions.prototype.addExtends = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.scalapb.OneofOptions} returns this
 */
proto.scalapb.OneofOptions.prototype.clearExtendsList = function() {
  return this.setExtendsList([]);
};


/**
 * optional string scala_name = 2;
 * @return {string}
 */
proto.scalapb.OneofOptions.prototype.getScalaName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.scalapb.OneofOptions} returns this
 */
proto.scalapb.OneofOptions.prototype.setScalaName = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.scalapb.OneofOptions} returns this
 */
proto.scalapb.OneofOptions.prototype.clearScalaName = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scalapb.OneofOptions.prototype.hasScalaName = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.scalapb.FieldTransformation.prototype.toObject = function(opt_includeInstance) {
  return proto.scalapb.FieldTransformation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.scalapb.FieldTransformation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scalapb.FieldTransformation.toObject = function(includeInstance, msg) {
  var f, obj = {
    when: (f = msg.getWhen()) && google_protobuf_descriptor_pb.FieldDescriptorProto.toObject(includeInstance, f),
    matchType: jspb.Message.getFieldWithDefault(msg, 2, 0),
    set: (f = msg.getSet()) && google_protobuf_descriptor_pb.FieldOptions.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.scalapb.FieldTransformation}
 */
proto.scalapb.FieldTransformation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.scalapb.FieldTransformation;
  return proto.scalapb.FieldTransformation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.scalapb.FieldTransformation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.scalapb.FieldTransformation}
 */
proto.scalapb.FieldTransformation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_descriptor_pb.FieldDescriptorProto;
      reader.readMessage(value,google_protobuf_descriptor_pb.FieldDescriptorProto.deserializeBinaryFromReader);
      msg.setWhen(value);
      break;
    case 2:
      var value = /** @type {!proto.scalapb.MatchType} */ (reader.readEnum());
      msg.setMatchType(value);
      break;
    case 3:
      var value = new google_protobuf_descriptor_pb.FieldOptions;
      reader.readMessage(value,google_protobuf_descriptor_pb.FieldOptions.deserializeBinaryFromReader);
      msg.setSet(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.scalapb.FieldTransformation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.scalapb.FieldTransformation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.scalapb.FieldTransformation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scalapb.FieldTransformation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWhen();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_descriptor_pb.FieldDescriptorProto.serializeBinaryToWriter
    );
  }
  f = /** @type {!proto.scalapb.MatchType} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getSet();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_descriptor_pb.FieldOptions.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.FieldDescriptorProto when = 1;
 * @return {?proto.google.protobuf.FieldDescriptorProto}
 */
proto.scalapb.FieldTransformation.prototype.getWhen = function() {
  return /** @type{?proto.google.protobuf.FieldDescriptorProto} */ (
    jspb.Message.getWrapperField(this, google_protobuf_descriptor_pb.FieldDescriptorProto, 1));
};


/**
 * @param {?proto.google.protobuf.FieldDescriptorProto|undefined} value
 * @return {!proto.scalapb.FieldTransformation} returns this
*/
proto.scalapb.FieldTransformation.prototype.setWhen = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.scalapb.FieldTransformation} returns this
 */
proto.scalapb.FieldTransformation.prototype.clearWhen = function() {
  return this.setWhen(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scalapb.FieldTransformation.prototype.hasWhen = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional MatchType match_type = 2;
 * @return {!proto.scalapb.MatchType}
 */
proto.scalapb.FieldTransformation.prototype.getMatchType = function() {
  return /** @type {!proto.scalapb.MatchType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.scalapb.MatchType} value
 * @return {!proto.scalapb.FieldTransformation} returns this
 */
proto.scalapb.FieldTransformation.prototype.setMatchType = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.scalapb.FieldTransformation} returns this
 */
proto.scalapb.FieldTransformation.prototype.clearMatchType = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scalapb.FieldTransformation.prototype.hasMatchType = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.FieldOptions set = 3;
 * @return {?proto.google.protobuf.FieldOptions}
 */
proto.scalapb.FieldTransformation.prototype.getSet = function() {
  return /** @type{?proto.google.protobuf.FieldOptions} */ (
    jspb.Message.getWrapperField(this, google_protobuf_descriptor_pb.FieldOptions, 3));
};


/**
 * @param {?proto.google.protobuf.FieldOptions|undefined} value
 * @return {!proto.scalapb.FieldTransformation} returns this
*/
proto.scalapb.FieldTransformation.prototype.setSet = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.scalapb.FieldTransformation} returns this
 */
proto.scalapb.FieldTransformation.prototype.clearSet = function() {
  return this.setSet(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scalapb.FieldTransformation.prototype.hasSet = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.scalapb.PreprocessorOutput.prototype.toObject = function(opt_includeInstance) {
  return proto.scalapb.PreprocessorOutput.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.scalapb.PreprocessorOutput} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scalapb.PreprocessorOutput.toObject = function(includeInstance, msg) {
  var f, obj = {
    optionsByFileMap: (f = msg.getOptionsByFileMap()) ? f.toObject(includeInstance, proto.scalapb.ScalaPbOptions.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.scalapb.PreprocessorOutput}
 */
proto.scalapb.PreprocessorOutput.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.scalapb.PreprocessorOutput;
  return proto.scalapb.PreprocessorOutput.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.scalapb.PreprocessorOutput} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.scalapb.PreprocessorOutput}
 */
proto.scalapb.PreprocessorOutput.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getOptionsByFileMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.scalapb.ScalaPbOptions.deserializeBinaryFromReader, "", new proto.scalapb.ScalaPbOptions());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.scalapb.PreprocessorOutput.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.scalapb.PreprocessorOutput.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.scalapb.PreprocessorOutput} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scalapb.PreprocessorOutput.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOptionsByFileMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.scalapb.ScalaPbOptions.serializeBinaryToWriter);
  }
};


/**
 * map<string, ScalaPbOptions> options_by_file = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.scalapb.ScalaPbOptions>}
 */
proto.scalapb.PreprocessorOutput.prototype.getOptionsByFileMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.scalapb.ScalaPbOptions>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      proto.scalapb.ScalaPbOptions));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.scalapb.PreprocessorOutput} returns this
 */
proto.scalapb.PreprocessorOutput.prototype.clearOptionsByFileMap = function() {
  this.getOptionsByFileMap().clear();
  return this;};


/**
 * @enum {number}
 */
proto.scalapb.MatchType = {
  CONTAINS: 0,
  EXACT: 1,
  PRESENCE: 2
};


/**
 * A tuple of {field number, class constructor} for the extension
 * field named `options`.
 * @type {!jspb.ExtensionFieldInfo<!proto.scalapb.ScalaPbOptions>}
 */
proto.scalapb.options = new jspb.ExtensionFieldInfo(
    1020,
    {options: 0},
    proto.scalapb.ScalaPbOptions,
     /** @type {?function((boolean|undefined),!jspb.Message=): !Object} */ (
         proto.scalapb.ScalaPbOptions.toObject),
    0);

google_protobuf_descriptor_pb.FileOptions.extensionsBinary[1020] = new jspb.ExtensionFieldBinaryInfo(
    proto.scalapb.options,
    jspb.BinaryReader.prototype.readMessage,
    jspb.BinaryWriter.prototype.writeMessage,
    proto.scalapb.ScalaPbOptions.serializeBinaryToWriter,
    proto.scalapb.ScalaPbOptions.deserializeBinaryFromReader,
    false);
// This registers the extension field with the extended class, so that
// toObject() will function correctly.
google_protobuf_descriptor_pb.FileOptions.extensions[1020] = proto.scalapb.options;


/**
 * A tuple of {field number, class constructor} for the extension
 * field named `message`.
 * @type {!jspb.ExtensionFieldInfo<!proto.scalapb.MessageOptions>}
 */
proto.scalapb.message = new jspb.ExtensionFieldInfo(
    1020,
    {message: 0},
    proto.scalapb.MessageOptions,
     /** @type {?function((boolean|undefined),!jspb.Message=): !Object} */ (
         proto.scalapb.MessageOptions.toObject),
    0);

google_protobuf_descriptor_pb.MessageOptions.extensionsBinary[1020] = new jspb.ExtensionFieldBinaryInfo(
    proto.scalapb.message,
    jspb.BinaryReader.prototype.readMessage,
    jspb.BinaryWriter.prototype.writeMessage,
    proto.scalapb.MessageOptions.serializeBinaryToWriter,
    proto.scalapb.MessageOptions.deserializeBinaryFromReader,
    false);
// This registers the extension field with the extended class, so that
// toObject() will function correctly.
google_protobuf_descriptor_pb.MessageOptions.extensions[1020] = proto.scalapb.message;


/**
 * A tuple of {field number, class constructor} for the extension
 * field named `field`.
 * @type {!jspb.ExtensionFieldInfo<!proto.scalapb.FieldOptions>}
 */
proto.scalapb.field = new jspb.ExtensionFieldInfo(
    1020,
    {field: 0},
    proto.scalapb.FieldOptions,
     /** @type {?function((boolean|undefined),!jspb.Message=): !Object} */ (
         proto.scalapb.FieldOptions.toObject),
    0);

google_protobuf_descriptor_pb.FieldOptions.extensionsBinary[1020] = new jspb.ExtensionFieldBinaryInfo(
    proto.scalapb.field,
    jspb.BinaryReader.prototype.readMessage,
    jspb.BinaryWriter.prototype.writeMessage,
    proto.scalapb.FieldOptions.serializeBinaryToWriter,
    proto.scalapb.FieldOptions.deserializeBinaryFromReader,
    false);
// This registers the extension field with the extended class, so that
// toObject() will function correctly.
google_protobuf_descriptor_pb.FieldOptions.extensions[1020] = proto.scalapb.field;


/**
 * A tuple of {field number, class constructor} for the extension
 * field named `enumOptions`.
 * @type {!jspb.ExtensionFieldInfo<!proto.scalapb.EnumOptions>}
 */
proto.scalapb.enumOptions = new jspb.ExtensionFieldInfo(
    1020,
    {enumOptions: 0},
    proto.scalapb.EnumOptions,
     /** @type {?function((boolean|undefined),!jspb.Message=): !Object} */ (
         proto.scalapb.EnumOptions.toObject),
    0);

google_protobuf_descriptor_pb.EnumOptions.extensionsBinary[1020] = new jspb.ExtensionFieldBinaryInfo(
    proto.scalapb.enumOptions,
    jspb.BinaryReader.prototype.readMessage,
    jspb.BinaryWriter.prototype.writeMessage,
    proto.scalapb.EnumOptions.serializeBinaryToWriter,
    proto.scalapb.EnumOptions.deserializeBinaryFromReader,
    false);
// This registers the extension field with the extended class, so that
// toObject() will function correctly.
google_protobuf_descriptor_pb.EnumOptions.extensions[1020] = proto.scalapb.enumOptions;


/**
 * A tuple of {field number, class constructor} for the extension
 * field named `enumValue`.
 * @type {!jspb.ExtensionFieldInfo<!proto.scalapb.EnumValueOptions>}
 */
proto.scalapb.enumValue = new jspb.ExtensionFieldInfo(
    1020,
    {enumValue: 0},
    proto.scalapb.EnumValueOptions,
     /** @type {?function((boolean|undefined),!jspb.Message=): !Object} */ (
         proto.scalapb.EnumValueOptions.toObject),
    0);

google_protobuf_descriptor_pb.EnumValueOptions.extensionsBinary[1020] = new jspb.ExtensionFieldBinaryInfo(
    proto.scalapb.enumValue,
    jspb.BinaryReader.prototype.readMessage,
    jspb.BinaryWriter.prototype.writeMessage,
    proto.scalapb.EnumValueOptions.serializeBinaryToWriter,
    proto.scalapb.EnumValueOptions.deserializeBinaryFromReader,
    false);
// This registers the extension field with the extended class, so that
// toObject() will function correctly.
google_protobuf_descriptor_pb.EnumValueOptions.extensions[1020] = proto.scalapb.enumValue;


/**
 * A tuple of {field number, class constructor} for the extension
 * field named `oneof`.
 * @type {!jspb.ExtensionFieldInfo<!proto.scalapb.OneofOptions>}
 */
proto.scalapb.oneof = new jspb.ExtensionFieldInfo(
    1020,
    {oneof: 0},
    proto.scalapb.OneofOptions,
     /** @type {?function((boolean|undefined),!jspb.Message=): !Object} */ (
         proto.scalapb.OneofOptions.toObject),
    0);

google_protobuf_descriptor_pb.OneofOptions.extensionsBinary[1020] = new jspb.ExtensionFieldBinaryInfo(
    proto.scalapb.oneof,
    jspb.BinaryReader.prototype.readMessage,
    jspb.BinaryWriter.prototype.writeMessage,
    proto.scalapb.OneofOptions.serializeBinaryToWriter,
    proto.scalapb.OneofOptions.deserializeBinaryFromReader,
    false);
// This registers the extension field with the extended class, so that
// toObject() will function correctly.
google_protobuf_descriptor_pb.OneofOptions.extensions[1020] = proto.scalapb.oneof;

goog.object.extend(exports, proto.scalapb);
