import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material'
import React, { useMemo } from 'react'

import { formatAmount } from 'shared/lib/decorators'

const PaginatableTable: React.FC<
  React.PropsWithChildren<{
    cols: string[]
    page: number
    perPage: number
    onPaginate: (page: number) => void
    totalCount?: number
    header?: JSX.Element
  }>
> = ({ cols, page, perPage, onPaginate, totalCount, header = <></>, children }) => {
  const onChangePage = (_event: unknown, page: number) => {
    onPaginate(page + 1)
  }

  const [isDataAvailable, isDataEmpty] = useMemo(
    () => [totalCount !== undefined && totalCount > 0, totalCount === 0],
    [totalCount],
  )

  return (
    <Paper elevation={3} style={{ margin: '1.5em 0' }}>
      {header}
      <TableContainer style={{ maxHeight: 'calc(100vh - 240px)' }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {cols.map((col, index) => (
                <TableCell key={index} align="left">
                  {col}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {isDataEmpty && (
            <TableBody>
              <TableRow>
                <TableCell colSpan={cols.length} align="center">
                  📭 該当のデータはありません
                </TableCell>
              </TableRow>
            </TableBody>
          )}
          {isDataAvailable && <TableBody style={{ overflow: 'scroll' }}>{children}</TableBody>}
        </Table>
      </TableContainer>
      {isDataAvailable && (
        <TablePagination
          component="div"
          count={totalCount ?? 0}
          page={page - 1}
          rowsPerPage={perPage}
          rowsPerPageOptions={[perPage]}
          onPageChange={onChangePage}
          labelDisplayedRows={({ from, to, count }) =>
            `${formatAmount(from)} 件目から ${formatAmount(to)} 件目（全 ${formatAmount(count)} 件）`
          }
        />
      )}
    </Paper>
  )
}

export default PaginatableTable
