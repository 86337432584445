// source: miraibaraiops/post_pay_api.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return Function('return this')(); }).call(null);

var scalapb_scalapb_pb = require('../scalapb/scalapb_pb.js');
goog.object.extend(proto, scalapb_scalapb_pb);
var miraibaraiops_ops_common_pb = require('../miraibaraiops/ops_common_pb.js');
goog.object.extend(proto, miraibaraiops_ops_common_pb);
var server_post_pay_pb = require('../server/post_pay_pb.js');
goog.object.extend(proto, server_post_pay_pb);
var server_screening_pb = require('../server/screening_pb.js');
goog.object.extend(proto, server_screening_pb);
goog.exportSymbol('proto.com.kipp.miraibarai.grpc.ops.v1.CancelPostPayDebtRequest', null, global);
goog.exportSymbol('proto.com.kipp.miraibarai.grpc.ops.v1.CancelPostPayDebtResponse', null, global);
goog.exportSymbol('proto.com.kipp.miraibarai.grpc.ops.v1.PinPostPayCreditLimitRequest', null, global);
goog.exportSymbol('proto.com.kipp.miraibarai.grpc.ops.v1.PinPostPayCreditLimitResponse', null, global);
goog.exportSymbol('proto.com.kipp.miraibarai.grpc.ops.v1.TransferCollectionOwnershipRequest', null, global);
goog.exportSymbol('proto.com.kipp.miraibarai.grpc.ops.v1.TransferCollectionOwnershipResponse', null, global);
goog.exportSymbol('proto.com.kipp.miraibarai.grpc.ops.v1.UpdateExternalScreeningApiSettingRequest', null, global);
goog.exportSymbol('proto.com.kipp.miraibarai.grpc.ops.v1.UpdateExternalScreeningApiSettingResponse', null, global);
goog.exportSymbol('proto.com.kipp.miraibarai.grpc.ops.v1.UpdatePostPaidTopUpFeesRequest', null, global);
goog.exportSymbol('proto.com.kipp.miraibarai.grpc.ops.v1.UpdatePostPaidTopUpFeesResponse', null, global);
goog.exportSymbol('proto.com.kipp.miraibarai.grpc.ops.v1.UpdatePostPayScreeningRuleSetRequest', null, global);
goog.exportSymbol('proto.com.kipp.miraibarai.grpc.ops.v1.UpdatePostPayScreeningRuleSetResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.kipp.miraibarai.grpc.ops.v1.PinPostPayCreditLimitRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.kipp.miraibarai.grpc.ops.v1.PinPostPayCreditLimitRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.kipp.miraibarai.grpc.ops.v1.PinPostPayCreditLimitRequest.displayName = 'proto.com.kipp.miraibarai.grpc.ops.v1.PinPostPayCreditLimitRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.kipp.miraibarai.grpc.ops.v1.PinPostPayCreditLimitResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.kipp.miraibarai.grpc.ops.v1.PinPostPayCreditLimitResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.kipp.miraibarai.grpc.ops.v1.PinPostPayCreditLimitResponse.displayName = 'proto.com.kipp.miraibarai.grpc.ops.v1.PinPostPayCreditLimitResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.kipp.miraibarai.grpc.ops.v1.UpdatePostPayScreeningRuleSetRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.kipp.miraibarai.grpc.ops.v1.UpdatePostPayScreeningRuleSetRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.kipp.miraibarai.grpc.ops.v1.UpdatePostPayScreeningRuleSetRequest.displayName = 'proto.com.kipp.miraibarai.grpc.ops.v1.UpdatePostPayScreeningRuleSetRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.kipp.miraibarai.grpc.ops.v1.UpdatePostPayScreeningRuleSetResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.kipp.miraibarai.grpc.ops.v1.UpdatePostPayScreeningRuleSetResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.kipp.miraibarai.grpc.ops.v1.UpdatePostPayScreeningRuleSetResponse.displayName = 'proto.com.kipp.miraibarai.grpc.ops.v1.UpdatePostPayScreeningRuleSetResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.kipp.miraibarai.grpc.ops.v1.CancelPostPayDebtRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.kipp.miraibarai.grpc.ops.v1.CancelPostPayDebtRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.kipp.miraibarai.grpc.ops.v1.CancelPostPayDebtRequest.displayName = 'proto.com.kipp.miraibarai.grpc.ops.v1.CancelPostPayDebtRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.kipp.miraibarai.grpc.ops.v1.CancelPostPayDebtResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.kipp.miraibarai.grpc.ops.v1.CancelPostPayDebtResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.kipp.miraibarai.grpc.ops.v1.CancelPostPayDebtResponse.displayName = 'proto.com.kipp.miraibarai.grpc.ops.v1.CancelPostPayDebtResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.kipp.miraibarai.grpc.ops.v1.UpdateExternalScreeningApiSettingRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.kipp.miraibarai.grpc.ops.v1.UpdateExternalScreeningApiSettingRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.kipp.miraibarai.grpc.ops.v1.UpdateExternalScreeningApiSettingRequest.displayName = 'proto.com.kipp.miraibarai.grpc.ops.v1.UpdateExternalScreeningApiSettingRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.kipp.miraibarai.grpc.ops.v1.UpdateExternalScreeningApiSettingResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.kipp.miraibarai.grpc.ops.v1.UpdateExternalScreeningApiSettingResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.kipp.miraibarai.grpc.ops.v1.UpdateExternalScreeningApiSettingResponse.displayName = 'proto.com.kipp.miraibarai.grpc.ops.v1.UpdateExternalScreeningApiSettingResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.kipp.miraibarai.grpc.ops.v1.UpdatePostPaidTopUpFeesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.kipp.miraibarai.grpc.ops.v1.UpdatePostPaidTopUpFeesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.kipp.miraibarai.grpc.ops.v1.UpdatePostPaidTopUpFeesRequest.displayName = 'proto.com.kipp.miraibarai.grpc.ops.v1.UpdatePostPaidTopUpFeesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.kipp.miraibarai.grpc.ops.v1.UpdatePostPaidTopUpFeesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.kipp.miraibarai.grpc.ops.v1.UpdatePostPaidTopUpFeesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.kipp.miraibarai.grpc.ops.v1.UpdatePostPaidTopUpFeesResponse.displayName = 'proto.com.kipp.miraibarai.grpc.ops.v1.UpdatePostPaidTopUpFeesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.kipp.miraibarai.grpc.ops.v1.TransferCollectionOwnershipRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.kipp.miraibarai.grpc.ops.v1.TransferCollectionOwnershipRequest.repeatedFields_, null);
};
goog.inherits(proto.com.kipp.miraibarai.grpc.ops.v1.TransferCollectionOwnershipRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.kipp.miraibarai.grpc.ops.v1.TransferCollectionOwnershipRequest.displayName = 'proto.com.kipp.miraibarai.grpc.ops.v1.TransferCollectionOwnershipRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.kipp.miraibarai.grpc.ops.v1.TransferCollectionOwnershipResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.kipp.miraibarai.grpc.ops.v1.TransferCollectionOwnershipResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.kipp.miraibarai.grpc.ops.v1.TransferCollectionOwnershipResponse.displayName = 'proto.com.kipp.miraibarai.grpc.ops.v1.TransferCollectionOwnershipResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.PinPostPayCreditLimitRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.kipp.miraibarai.grpc.ops.v1.PinPostPayCreditLimitRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.kipp.miraibarai.grpc.ops.v1.PinPostPayCreditLimitRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.kipp.miraibarai.grpc.ops.v1.PinPostPayCreditLimitRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    appUserId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    creditLimitValue: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.kipp.miraibarai.grpc.ops.v1.PinPostPayCreditLimitRequest}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.PinPostPayCreditLimitRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.kipp.miraibarai.grpc.ops.v1.PinPostPayCreditLimitRequest;
  return proto.com.kipp.miraibarai.grpc.ops.v1.PinPostPayCreditLimitRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.kipp.miraibarai.grpc.ops.v1.PinPostPayCreditLimitRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.kipp.miraibarai.grpc.ops.v1.PinPostPayCreditLimitRequest}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.PinPostPayCreditLimitRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppUserId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setCreditLimitValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.PinPostPayCreditLimitRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.kipp.miraibarai.grpc.ops.v1.PinPostPayCreditLimitRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.kipp.miraibarai.grpc.ops.v1.PinPostPayCreditLimitRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.kipp.miraibarai.grpc.ops.v1.PinPostPayCreditLimitRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAppUserId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCreditLimitValue();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
};


/**
 * optional string app_user_id = 1;
 * @return {string}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.PinPostPayCreditLimitRequest.prototype.getAppUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.kipp.miraibarai.grpc.ops.v1.PinPostPayCreditLimitRequest} returns this
 */
proto.com.kipp.miraibarai.grpc.ops.v1.PinPostPayCreditLimitRequest.prototype.setAppUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint64 credit_limit_value = 2;
 * @return {number}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.PinPostPayCreditLimitRequest.prototype.getCreditLimitValue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.kipp.miraibarai.grpc.ops.v1.PinPostPayCreditLimitRequest} returns this
 */
proto.com.kipp.miraibarai.grpc.ops.v1.PinPostPayCreditLimitRequest.prototype.setCreditLimitValue = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.PinPostPayCreditLimitResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.kipp.miraibarai.grpc.ops.v1.PinPostPayCreditLimitResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.kipp.miraibarai.grpc.ops.v1.PinPostPayCreditLimitResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.kipp.miraibarai.grpc.ops.v1.PinPostPayCreditLimitResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && miraibaraiops_ops_common_pb.ErrorMessage.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.kipp.miraibarai.grpc.ops.v1.PinPostPayCreditLimitResponse}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.PinPostPayCreditLimitResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.kipp.miraibarai.grpc.ops.v1.PinPostPayCreditLimitResponse;
  return proto.com.kipp.miraibarai.grpc.ops.v1.PinPostPayCreditLimitResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.kipp.miraibarai.grpc.ops.v1.PinPostPayCreditLimitResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.kipp.miraibarai.grpc.ops.v1.PinPostPayCreditLimitResponse}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.PinPostPayCreditLimitResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new miraibaraiops_ops_common_pb.ErrorMessage;
      reader.readMessage(value,miraibaraiops_ops_common_pb.ErrorMessage.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.PinPostPayCreditLimitResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.kipp.miraibarai.grpc.ops.v1.PinPostPayCreditLimitResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.kipp.miraibarai.grpc.ops.v1.PinPostPayCreditLimitResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.kipp.miraibarai.grpc.ops.v1.PinPostPayCreditLimitResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      miraibaraiops_ops_common_pb.ErrorMessage.serializeBinaryToWriter
    );
  }
};


/**
 * optional ErrorMessage error = 1;
 * @return {?proto.com.kipp.miraibarai.grpc.ops.v1.ErrorMessage}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.PinPostPayCreditLimitResponse.prototype.getError = function() {
  return /** @type{?proto.com.kipp.miraibarai.grpc.ops.v1.ErrorMessage} */ (
    jspb.Message.getWrapperField(this, miraibaraiops_ops_common_pb.ErrorMessage, 1));
};


/**
 * @param {?proto.com.kipp.miraibarai.grpc.ops.v1.ErrorMessage|undefined} value
 * @return {!proto.com.kipp.miraibarai.grpc.ops.v1.PinPostPayCreditLimitResponse} returns this
*/
proto.com.kipp.miraibarai.grpc.ops.v1.PinPostPayCreditLimitResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.kipp.miraibarai.grpc.ops.v1.PinPostPayCreditLimitResponse} returns this
 */
proto.com.kipp.miraibarai.grpc.ops.v1.PinPostPayCreditLimitResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.PinPostPayCreditLimitResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.UpdatePostPayScreeningRuleSetRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.kipp.miraibarai.grpc.ops.v1.UpdatePostPayScreeningRuleSetRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.kipp.miraibarai.grpc.ops.v1.UpdatePostPayScreeningRuleSetRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.kipp.miraibarai.grpc.ops.v1.UpdatePostPayScreeningRuleSetRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    ruleSetVersion: jspb.Message.getFieldWithDefault(msg, 1, ""),
    postPayScreeningRuleSetSetting: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.kipp.miraibarai.grpc.ops.v1.UpdatePostPayScreeningRuleSetRequest}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.UpdatePostPayScreeningRuleSetRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.kipp.miraibarai.grpc.ops.v1.UpdatePostPayScreeningRuleSetRequest;
  return proto.com.kipp.miraibarai.grpc.ops.v1.UpdatePostPayScreeningRuleSetRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.kipp.miraibarai.grpc.ops.v1.UpdatePostPayScreeningRuleSetRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.kipp.miraibarai.grpc.ops.v1.UpdatePostPayScreeningRuleSetRequest}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.UpdatePostPayScreeningRuleSetRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRuleSetVersion(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPostPayScreeningRuleSetSetting(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.UpdatePostPayScreeningRuleSetRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.kipp.miraibarai.grpc.ops.v1.UpdatePostPayScreeningRuleSetRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.kipp.miraibarai.grpc.ops.v1.UpdatePostPayScreeningRuleSetRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.kipp.miraibarai.grpc.ops.v1.UpdatePostPayScreeningRuleSetRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRuleSetVersion();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPostPayScreeningRuleSetSetting();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string rule_set_version = 1;
 * @return {string}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.UpdatePostPayScreeningRuleSetRequest.prototype.getRuleSetVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.kipp.miraibarai.grpc.ops.v1.UpdatePostPayScreeningRuleSetRequest} returns this
 */
proto.com.kipp.miraibarai.grpc.ops.v1.UpdatePostPayScreeningRuleSetRequest.prototype.setRuleSetVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string post_pay_screening_rule_set_setting = 2;
 * @return {string}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.UpdatePostPayScreeningRuleSetRequest.prototype.getPostPayScreeningRuleSetSetting = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.kipp.miraibarai.grpc.ops.v1.UpdatePostPayScreeningRuleSetRequest} returns this
 */
proto.com.kipp.miraibarai.grpc.ops.v1.UpdatePostPayScreeningRuleSetRequest.prototype.setPostPayScreeningRuleSetSetting = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.UpdatePostPayScreeningRuleSetResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.kipp.miraibarai.grpc.ops.v1.UpdatePostPayScreeningRuleSetResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.kipp.miraibarai.grpc.ops.v1.UpdatePostPayScreeningRuleSetResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.kipp.miraibarai.grpc.ops.v1.UpdatePostPayScreeningRuleSetResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && miraibaraiops_ops_common_pb.ErrorMessage.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.kipp.miraibarai.grpc.ops.v1.UpdatePostPayScreeningRuleSetResponse}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.UpdatePostPayScreeningRuleSetResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.kipp.miraibarai.grpc.ops.v1.UpdatePostPayScreeningRuleSetResponse;
  return proto.com.kipp.miraibarai.grpc.ops.v1.UpdatePostPayScreeningRuleSetResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.kipp.miraibarai.grpc.ops.v1.UpdatePostPayScreeningRuleSetResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.kipp.miraibarai.grpc.ops.v1.UpdatePostPayScreeningRuleSetResponse}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.UpdatePostPayScreeningRuleSetResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new miraibaraiops_ops_common_pb.ErrorMessage;
      reader.readMessage(value,miraibaraiops_ops_common_pb.ErrorMessage.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.UpdatePostPayScreeningRuleSetResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.kipp.miraibarai.grpc.ops.v1.UpdatePostPayScreeningRuleSetResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.kipp.miraibarai.grpc.ops.v1.UpdatePostPayScreeningRuleSetResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.kipp.miraibarai.grpc.ops.v1.UpdatePostPayScreeningRuleSetResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      miraibaraiops_ops_common_pb.ErrorMessage.serializeBinaryToWriter
    );
  }
};


/**
 * optional ErrorMessage error = 1;
 * @return {?proto.com.kipp.miraibarai.grpc.ops.v1.ErrorMessage}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.UpdatePostPayScreeningRuleSetResponse.prototype.getError = function() {
  return /** @type{?proto.com.kipp.miraibarai.grpc.ops.v1.ErrorMessage} */ (
    jspb.Message.getWrapperField(this, miraibaraiops_ops_common_pb.ErrorMessage, 1));
};


/**
 * @param {?proto.com.kipp.miraibarai.grpc.ops.v1.ErrorMessage|undefined} value
 * @return {!proto.com.kipp.miraibarai.grpc.ops.v1.UpdatePostPayScreeningRuleSetResponse} returns this
*/
proto.com.kipp.miraibarai.grpc.ops.v1.UpdatePostPayScreeningRuleSetResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.kipp.miraibarai.grpc.ops.v1.UpdatePostPayScreeningRuleSetResponse} returns this
 */
proto.com.kipp.miraibarai.grpc.ops.v1.UpdatePostPayScreeningRuleSetResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.UpdatePostPayScreeningRuleSetResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.CancelPostPayDebtRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.kipp.miraibarai.grpc.ops.v1.CancelPostPayDebtRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.kipp.miraibarai.grpc.ops.v1.CancelPostPayDebtRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.kipp.miraibarai.grpc.ops.v1.CancelPostPayDebtRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    debtId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    cancelType: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.kipp.miraibarai.grpc.ops.v1.CancelPostPayDebtRequest}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.CancelPostPayDebtRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.kipp.miraibarai.grpc.ops.v1.CancelPostPayDebtRequest;
  return proto.com.kipp.miraibarai.grpc.ops.v1.CancelPostPayDebtRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.kipp.miraibarai.grpc.ops.v1.CancelPostPayDebtRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.kipp.miraibarai.grpc.ops.v1.CancelPostPayDebtRequest}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.CancelPostPayDebtRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDebtId(value);
      break;
    case 2:
      var value = /** @type {!proto.com.kipp_corp.core.grpc.server.DebtCancelType} */ (reader.readEnum());
      msg.setCancelType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.CancelPostPayDebtRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.kipp.miraibarai.grpc.ops.v1.CancelPostPayDebtRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.kipp.miraibarai.grpc.ops.v1.CancelPostPayDebtRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.kipp.miraibarai.grpc.ops.v1.CancelPostPayDebtRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDebtId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCancelType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional string debt_id = 1;
 * @return {string}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.CancelPostPayDebtRequest.prototype.getDebtId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.kipp.miraibarai.grpc.ops.v1.CancelPostPayDebtRequest} returns this
 */
proto.com.kipp.miraibarai.grpc.ops.v1.CancelPostPayDebtRequest.prototype.setDebtId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional com.kipp_corp.core.grpc.server.DebtCancelType cancel_type = 2;
 * @return {!proto.com.kipp_corp.core.grpc.server.DebtCancelType}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.CancelPostPayDebtRequest.prototype.getCancelType = function() {
  return /** @type {!proto.com.kipp_corp.core.grpc.server.DebtCancelType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.com.kipp_corp.core.grpc.server.DebtCancelType} value
 * @return {!proto.com.kipp.miraibarai.grpc.ops.v1.CancelPostPayDebtRequest} returns this
 */
proto.com.kipp.miraibarai.grpc.ops.v1.CancelPostPayDebtRequest.prototype.setCancelType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.CancelPostPayDebtResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.kipp.miraibarai.grpc.ops.v1.CancelPostPayDebtResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.kipp.miraibarai.grpc.ops.v1.CancelPostPayDebtResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.kipp.miraibarai.grpc.ops.v1.CancelPostPayDebtResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && miraibaraiops_ops_common_pb.ErrorMessage.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.kipp.miraibarai.grpc.ops.v1.CancelPostPayDebtResponse}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.CancelPostPayDebtResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.kipp.miraibarai.grpc.ops.v1.CancelPostPayDebtResponse;
  return proto.com.kipp.miraibarai.grpc.ops.v1.CancelPostPayDebtResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.kipp.miraibarai.grpc.ops.v1.CancelPostPayDebtResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.kipp.miraibarai.grpc.ops.v1.CancelPostPayDebtResponse}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.CancelPostPayDebtResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new miraibaraiops_ops_common_pb.ErrorMessage;
      reader.readMessage(value,miraibaraiops_ops_common_pb.ErrorMessage.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.CancelPostPayDebtResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.kipp.miraibarai.grpc.ops.v1.CancelPostPayDebtResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.kipp.miraibarai.grpc.ops.v1.CancelPostPayDebtResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.kipp.miraibarai.grpc.ops.v1.CancelPostPayDebtResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      miraibaraiops_ops_common_pb.ErrorMessage.serializeBinaryToWriter
    );
  }
};


/**
 * optional ErrorMessage error = 1;
 * @return {?proto.com.kipp.miraibarai.grpc.ops.v1.ErrorMessage}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.CancelPostPayDebtResponse.prototype.getError = function() {
  return /** @type{?proto.com.kipp.miraibarai.grpc.ops.v1.ErrorMessage} */ (
    jspb.Message.getWrapperField(this, miraibaraiops_ops_common_pb.ErrorMessage, 1));
};


/**
 * @param {?proto.com.kipp.miraibarai.grpc.ops.v1.ErrorMessage|undefined} value
 * @return {!proto.com.kipp.miraibarai.grpc.ops.v1.CancelPostPayDebtResponse} returns this
*/
proto.com.kipp.miraibarai.grpc.ops.v1.CancelPostPayDebtResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.kipp.miraibarai.grpc.ops.v1.CancelPostPayDebtResponse} returns this
 */
proto.com.kipp.miraibarai.grpc.ops.v1.CancelPostPayDebtResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.CancelPostPayDebtResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.UpdateExternalScreeningApiSettingRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.kipp.miraibarai.grpc.ops.v1.UpdateExternalScreeningApiSettingRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.kipp.miraibarai.grpc.ops.v1.UpdateExternalScreeningApiSettingRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.kipp.miraibarai.grpc.ops.v1.UpdateExternalScreeningApiSettingRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    data: (f = msg.getData()) && server_screening_pb.ExternalScreeningApiSettingData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.kipp.miraibarai.grpc.ops.v1.UpdateExternalScreeningApiSettingRequest}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.UpdateExternalScreeningApiSettingRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.kipp.miraibarai.grpc.ops.v1.UpdateExternalScreeningApiSettingRequest;
  return proto.com.kipp.miraibarai.grpc.ops.v1.UpdateExternalScreeningApiSettingRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.kipp.miraibarai.grpc.ops.v1.UpdateExternalScreeningApiSettingRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.kipp.miraibarai.grpc.ops.v1.UpdateExternalScreeningApiSettingRequest}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.UpdateExternalScreeningApiSettingRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new server_screening_pb.ExternalScreeningApiSettingData;
      reader.readMessage(value,server_screening_pb.ExternalScreeningApiSettingData.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.UpdateExternalScreeningApiSettingRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.kipp.miraibarai.grpc.ops.v1.UpdateExternalScreeningApiSettingRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.kipp.miraibarai.grpc.ops.v1.UpdateExternalScreeningApiSettingRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.kipp.miraibarai.grpc.ops.v1.UpdateExternalScreeningApiSettingRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      server_screening_pb.ExternalScreeningApiSettingData.serializeBinaryToWriter
    );
  }
};


/**
 * optional com.kipp_corp.core.grpc.server.ExternalScreeningApiSettingData data = 1;
 * @return {?proto.com.kipp_corp.core.grpc.server.ExternalScreeningApiSettingData}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.UpdateExternalScreeningApiSettingRequest.prototype.getData = function() {
  return /** @type{?proto.com.kipp_corp.core.grpc.server.ExternalScreeningApiSettingData} */ (
    jspb.Message.getWrapperField(this, server_screening_pb.ExternalScreeningApiSettingData, 1));
};


/**
 * @param {?proto.com.kipp_corp.core.grpc.server.ExternalScreeningApiSettingData|undefined} value
 * @return {!proto.com.kipp.miraibarai.grpc.ops.v1.UpdateExternalScreeningApiSettingRequest} returns this
*/
proto.com.kipp.miraibarai.grpc.ops.v1.UpdateExternalScreeningApiSettingRequest.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.kipp.miraibarai.grpc.ops.v1.UpdateExternalScreeningApiSettingRequest} returns this
 */
proto.com.kipp.miraibarai.grpc.ops.v1.UpdateExternalScreeningApiSettingRequest.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.UpdateExternalScreeningApiSettingRequest.prototype.hasData = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.UpdateExternalScreeningApiSettingResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.kipp.miraibarai.grpc.ops.v1.UpdateExternalScreeningApiSettingResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.kipp.miraibarai.grpc.ops.v1.UpdateExternalScreeningApiSettingResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.kipp.miraibarai.grpc.ops.v1.UpdateExternalScreeningApiSettingResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && miraibaraiops_ops_common_pb.ErrorMessage.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.kipp.miraibarai.grpc.ops.v1.UpdateExternalScreeningApiSettingResponse}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.UpdateExternalScreeningApiSettingResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.kipp.miraibarai.grpc.ops.v1.UpdateExternalScreeningApiSettingResponse;
  return proto.com.kipp.miraibarai.grpc.ops.v1.UpdateExternalScreeningApiSettingResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.kipp.miraibarai.grpc.ops.v1.UpdateExternalScreeningApiSettingResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.kipp.miraibarai.grpc.ops.v1.UpdateExternalScreeningApiSettingResponse}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.UpdateExternalScreeningApiSettingResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new miraibaraiops_ops_common_pb.ErrorMessage;
      reader.readMessage(value,miraibaraiops_ops_common_pb.ErrorMessage.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.UpdateExternalScreeningApiSettingResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.kipp.miraibarai.grpc.ops.v1.UpdateExternalScreeningApiSettingResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.kipp.miraibarai.grpc.ops.v1.UpdateExternalScreeningApiSettingResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.kipp.miraibarai.grpc.ops.v1.UpdateExternalScreeningApiSettingResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      miraibaraiops_ops_common_pb.ErrorMessage.serializeBinaryToWriter
    );
  }
};


/**
 * optional ErrorMessage error = 1;
 * @return {?proto.com.kipp.miraibarai.grpc.ops.v1.ErrorMessage}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.UpdateExternalScreeningApiSettingResponse.prototype.getError = function() {
  return /** @type{?proto.com.kipp.miraibarai.grpc.ops.v1.ErrorMessage} */ (
    jspb.Message.getWrapperField(this, miraibaraiops_ops_common_pb.ErrorMessage, 1));
};


/**
 * @param {?proto.com.kipp.miraibarai.grpc.ops.v1.ErrorMessage|undefined} value
 * @return {!proto.com.kipp.miraibarai.grpc.ops.v1.UpdateExternalScreeningApiSettingResponse} returns this
*/
proto.com.kipp.miraibarai.grpc.ops.v1.UpdateExternalScreeningApiSettingResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.kipp.miraibarai.grpc.ops.v1.UpdateExternalScreeningApiSettingResponse} returns this
 */
proto.com.kipp.miraibarai.grpc.ops.v1.UpdateExternalScreeningApiSettingResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.UpdateExternalScreeningApiSettingResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.UpdatePostPaidTopUpFeesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.kipp.miraibarai.grpc.ops.v1.UpdatePostPaidTopUpFeesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.kipp.miraibarai.grpc.ops.v1.UpdatePostPaidTopUpFeesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.kipp.miraibarai.grpc.ops.v1.UpdatePostPaidTopUpFeesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    applyFromMs: jspb.Message.getFieldWithDefault(msg, 1, 0),
    feeSetting: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.kipp.miraibarai.grpc.ops.v1.UpdatePostPaidTopUpFeesRequest}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.UpdatePostPaidTopUpFeesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.kipp.miraibarai.grpc.ops.v1.UpdatePostPaidTopUpFeesRequest;
  return proto.com.kipp.miraibarai.grpc.ops.v1.UpdatePostPaidTopUpFeesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.kipp.miraibarai.grpc.ops.v1.UpdatePostPaidTopUpFeesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.kipp.miraibarai.grpc.ops.v1.UpdatePostPaidTopUpFeesRequest}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.UpdatePostPaidTopUpFeesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setApplyFromMs(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFeeSetting(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.UpdatePostPaidTopUpFeesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.kipp.miraibarai.grpc.ops.v1.UpdatePostPaidTopUpFeesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.kipp.miraibarai.grpc.ops.v1.UpdatePostPaidTopUpFeesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.kipp.miraibarai.grpc.ops.v1.UpdatePostPaidTopUpFeesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getApplyFromMs();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getFeeSetting();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional uint64 apply_from_ms = 1;
 * @return {number}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.UpdatePostPaidTopUpFeesRequest.prototype.getApplyFromMs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.kipp.miraibarai.grpc.ops.v1.UpdatePostPaidTopUpFeesRequest} returns this
 */
proto.com.kipp.miraibarai.grpc.ops.v1.UpdatePostPaidTopUpFeesRequest.prototype.setApplyFromMs = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string fee_setting = 2;
 * @return {string}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.UpdatePostPaidTopUpFeesRequest.prototype.getFeeSetting = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.kipp.miraibarai.grpc.ops.v1.UpdatePostPaidTopUpFeesRequest} returns this
 */
proto.com.kipp.miraibarai.grpc.ops.v1.UpdatePostPaidTopUpFeesRequest.prototype.setFeeSetting = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.UpdatePostPaidTopUpFeesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.kipp.miraibarai.grpc.ops.v1.UpdatePostPaidTopUpFeesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.kipp.miraibarai.grpc.ops.v1.UpdatePostPaidTopUpFeesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.kipp.miraibarai.grpc.ops.v1.UpdatePostPaidTopUpFeesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && miraibaraiops_ops_common_pb.ErrorMessage.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.kipp.miraibarai.grpc.ops.v1.UpdatePostPaidTopUpFeesResponse}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.UpdatePostPaidTopUpFeesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.kipp.miraibarai.grpc.ops.v1.UpdatePostPaidTopUpFeesResponse;
  return proto.com.kipp.miraibarai.grpc.ops.v1.UpdatePostPaidTopUpFeesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.kipp.miraibarai.grpc.ops.v1.UpdatePostPaidTopUpFeesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.kipp.miraibarai.grpc.ops.v1.UpdatePostPaidTopUpFeesResponse}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.UpdatePostPaidTopUpFeesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new miraibaraiops_ops_common_pb.ErrorMessage;
      reader.readMessage(value,miraibaraiops_ops_common_pb.ErrorMessage.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.UpdatePostPaidTopUpFeesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.kipp.miraibarai.grpc.ops.v1.UpdatePostPaidTopUpFeesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.kipp.miraibarai.grpc.ops.v1.UpdatePostPaidTopUpFeesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.kipp.miraibarai.grpc.ops.v1.UpdatePostPaidTopUpFeesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      miraibaraiops_ops_common_pb.ErrorMessage.serializeBinaryToWriter
    );
  }
};


/**
 * optional ErrorMessage error = 1;
 * @return {?proto.com.kipp.miraibarai.grpc.ops.v1.ErrorMessage}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.UpdatePostPaidTopUpFeesResponse.prototype.getError = function() {
  return /** @type{?proto.com.kipp.miraibarai.grpc.ops.v1.ErrorMessage} */ (
    jspb.Message.getWrapperField(this, miraibaraiops_ops_common_pb.ErrorMessage, 1));
};


/**
 * @param {?proto.com.kipp.miraibarai.grpc.ops.v1.ErrorMessage|undefined} value
 * @return {!proto.com.kipp.miraibarai.grpc.ops.v1.UpdatePostPaidTopUpFeesResponse} returns this
*/
proto.com.kipp.miraibarai.grpc.ops.v1.UpdatePostPaidTopUpFeesResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.kipp.miraibarai.grpc.ops.v1.UpdatePostPaidTopUpFeesResponse} returns this
 */
proto.com.kipp.miraibarai.grpc.ops.v1.UpdatePostPaidTopUpFeesResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.UpdatePostPaidTopUpFeesResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.kipp.miraibarai.grpc.ops.v1.TransferCollectionOwnershipRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.TransferCollectionOwnershipRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.kipp.miraibarai.grpc.ops.v1.TransferCollectionOwnershipRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.kipp.miraibarai.grpc.ops.v1.TransferCollectionOwnershipRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.kipp.miraibarai.grpc.ops.v1.TransferCollectionOwnershipRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    debtIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.kipp.miraibarai.grpc.ops.v1.TransferCollectionOwnershipRequest}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.TransferCollectionOwnershipRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.kipp.miraibarai.grpc.ops.v1.TransferCollectionOwnershipRequest;
  return proto.com.kipp.miraibarai.grpc.ops.v1.TransferCollectionOwnershipRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.kipp.miraibarai.grpc.ops.v1.TransferCollectionOwnershipRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.kipp.miraibarai.grpc.ops.v1.TransferCollectionOwnershipRequest}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.TransferCollectionOwnershipRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addDebtIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.TransferCollectionOwnershipRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.kipp.miraibarai.grpc.ops.v1.TransferCollectionOwnershipRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.kipp.miraibarai.grpc.ops.v1.TransferCollectionOwnershipRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.kipp.miraibarai.grpc.ops.v1.TransferCollectionOwnershipRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDebtIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string debt_ids = 1;
 * @return {!Array<string>}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.TransferCollectionOwnershipRequest.prototype.getDebtIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.com.kipp.miraibarai.grpc.ops.v1.TransferCollectionOwnershipRequest} returns this
 */
proto.com.kipp.miraibarai.grpc.ops.v1.TransferCollectionOwnershipRequest.prototype.setDebtIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.com.kipp.miraibarai.grpc.ops.v1.TransferCollectionOwnershipRequest} returns this
 */
proto.com.kipp.miraibarai.grpc.ops.v1.TransferCollectionOwnershipRequest.prototype.addDebtIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.kipp.miraibarai.grpc.ops.v1.TransferCollectionOwnershipRequest} returns this
 */
proto.com.kipp.miraibarai.grpc.ops.v1.TransferCollectionOwnershipRequest.prototype.clearDebtIdsList = function() {
  return this.setDebtIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.TransferCollectionOwnershipResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.kipp.miraibarai.grpc.ops.v1.TransferCollectionOwnershipResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.kipp.miraibarai.grpc.ops.v1.TransferCollectionOwnershipResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.kipp.miraibarai.grpc.ops.v1.TransferCollectionOwnershipResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && miraibaraiops_ops_common_pb.ErrorMessage.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.kipp.miraibarai.grpc.ops.v1.TransferCollectionOwnershipResponse}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.TransferCollectionOwnershipResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.kipp.miraibarai.grpc.ops.v1.TransferCollectionOwnershipResponse;
  return proto.com.kipp.miraibarai.grpc.ops.v1.TransferCollectionOwnershipResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.kipp.miraibarai.grpc.ops.v1.TransferCollectionOwnershipResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.kipp.miraibarai.grpc.ops.v1.TransferCollectionOwnershipResponse}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.TransferCollectionOwnershipResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new miraibaraiops_ops_common_pb.ErrorMessage;
      reader.readMessage(value,miraibaraiops_ops_common_pb.ErrorMessage.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.TransferCollectionOwnershipResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.kipp.miraibarai.grpc.ops.v1.TransferCollectionOwnershipResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.kipp.miraibarai.grpc.ops.v1.TransferCollectionOwnershipResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.kipp.miraibarai.grpc.ops.v1.TransferCollectionOwnershipResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      miraibaraiops_ops_common_pb.ErrorMessage.serializeBinaryToWriter
    );
  }
};


/**
 * optional ErrorMessage error = 1;
 * @return {?proto.com.kipp.miraibarai.grpc.ops.v1.ErrorMessage}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.TransferCollectionOwnershipResponse.prototype.getError = function() {
  return /** @type{?proto.com.kipp.miraibarai.grpc.ops.v1.ErrorMessage} */ (
    jspb.Message.getWrapperField(this, miraibaraiops_ops_common_pb.ErrorMessage, 1));
};


/**
 * @param {?proto.com.kipp.miraibarai.grpc.ops.v1.ErrorMessage|undefined} value
 * @return {!proto.com.kipp.miraibarai.grpc.ops.v1.TransferCollectionOwnershipResponse} returns this
*/
proto.com.kipp.miraibarai.grpc.ops.v1.TransferCollectionOwnershipResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.kipp.miraibarai.grpc.ops.v1.TransferCollectionOwnershipResponse} returns this
 */
proto.com.kipp.miraibarai.grpc.ops.v1.TransferCollectionOwnershipResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.kipp.miraibarai.grpc.ops.v1.TransferCollectionOwnershipResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


goog.object.extend(exports, proto.com.kipp.miraibarai.grpc.ops.v1);
