import { useCallback, useState } from 'react'

const useToggle = (initial: boolean): [boolean, { enable: () => void; disable: () => void; toggle: () => void }] => {
  const [bool, setBool] = useState(initial)

  return [
    bool,
    {
      enable: useCallback(() => setBool(true), [setBool]),
      disable: useCallback(() => setBool(false), [setBool]),
      toggle: useCallback(() => setBool(!bool), [bool, setBool]),
    },
  ]
}

export default useToggle
