import { ThemeOptions } from '@mui/material'

// On testing with jest, global styles by Mui doesn't work while multiple tests run in a row
// As a workaround, disable such global styles via theme: https://github.com/mui/material-ui/pull/29213
const disableGlobalForMuiInputBase: ThemeOptions['components'] = {
  MuiInputBase: {
    defaultProps: {
      disableInjectingGlobalStyles: true,
    },
  },
}

export default disableGlobalForMuiInputBase
