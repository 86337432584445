/**
 * The regular expression for passwords.
 */
export const passwordRegexp = /^(?=.*?[a-z])(?=.*?\d).{7,}$/i

export const isValidUrl = (urlString: string): boolean => {
  try {
    const url = new URL(urlString)
    return ['https:', 'http:'].includes(url.protocol)
  } catch (e) {
    return false
  }
}
