import { DebtCancelType } from 'shared/grpc/server/post_pay_pb'
import { formatAsDatetime, formatYYYYMMDDAsCalendarDate } from 'shared/lib/dateUtils'
import { formatAmount, formatAmountE3 } from 'shared/lib/decorators'

enum RepaymentMethod {
  BankPay,
  ConvenienceStore,
  PartnerBalance,
}

const methodToName: Record<RepaymentMethod, string> = {
  [RepaymentMethod.BankPay]: 'Bank Pay払い',
  [RepaymentMethod.ConvenienceStore]: 'コンビニ払い',
  [RepaymentMethod.PartnerBalance]: 'パートナー残高払い',
}

export const presentGetMiraibaraiAppUser = ({ result: [user], operatorComments }: GetMiraibaraiAppUser) => {
  if (!user) return undefined

  const latestPostPayDebts = [...user.postPayDebts].sort((a, b) => b.appliedMs - a.appliedMs)
  const sortedCreditLimits = [...user.postPayCreditLimits].sort((a, b) => b.calculatedMs - a.calculatedMs)
  const totalDebt =
    user.postPayDebts
      .filter((debt) => debt.paidMs === null && debt.canceledMs === null)
      .reduce((sum, debt) => sum + debt.principalAmountE3, 0) /
    10 ** 3

  return {
    id: user.appUserId,
    partnerId: user.partnerId,
    partnerUserId: user.partnerUserId,
    isVerified: user.identityVerificationCompleted,
    nameKanji: user.miraibaraiPersonalData?.nameKanji || 'ユーザー詳細',
    nameKana: user.miraibaraiPersonalData?.nameKana || '不明',
    homePhoneNumber: user.miraibaraiPersonalData?.homePhoneNumber || '不明',
    mobilePhoneNumber: user.miraibaraiPersonalData?.mobilePhoneNumber || '不明',
    emailAddress: user.miraibaraiPersonalData?.emailAddress || '不明',
    birthdate: user.miraibaraiPersonalData?.birthdate
      ? formatYYYYMMDDAsCalendarDate(user.miraibaraiPersonalData.birthdate)
      : '不明',
    gender: ['不明', '男性', '女性', '無回答'][user.miraibaraiPersonalData?.gender.value ?? 0],
    resident: [
      user.miraibaraiPersonalData?.postalCode || '郵便番号不明',
      user.miraibaraiPersonalData?.residentAddress || '住所不明',
    ].join(' '),
    occupation: [
      '不明',
      '会社員/団体職員',
      '会社役員/団体役員',
      '公務員',
      '個人事業主/自営業',
      'パート/アルバイト/契約社員',
      '学生',
      '主婦',
      '専門職（医師/弁護士等の士業）',
      '無職',
      'その他',
    ][user.occupation?.value ?? 0],
    usagePurpose: ['不明', '生活費決済', '事業費決済'][user.usagePurpose?.value ?? 0],
    partnerUserSignupDate: formatYYYYMMDDAsCalendarDate(user.partnerUserSignupDate),
    createdDateTime: formatAsDatetime(user.createdMs),
    isPristine: totalDebt === 0,
    totalDebtAmount: `${formatAmount(totalDebt)} 円`,
    currentCreditAmount: `${formatAmount(
      Math.max((sortedCreditLimits?.[0]?.creditLimitValue ?? 0) - totalDebt, 0),
    )} 円`,
    currentCreditLimit: {
      amount: `${formatAmount(sortedCreditLimits?.[0]?.creditLimitValue ?? 0)} 円`,
      amountValue: sortedCreditLimits?.[0]?.creditLimitValue ?? 0,
      isPinned: sortedCreditLimits?.[0]?.creditCriteria.miraibarai?.appliedRule === 'pin',
    },
    creditLimits: sortedCreditLimits.map((creditLimit) => ({
      isPinned: creditLimit.creditCriteria.miraibarai?.appliedRule === 'pin',
      amount: `${formatAmount(creditLimit.creditLimitValue)} 円`,
      calculatedDateTime: formatAsDatetime(creditLimit.calculatedMs),
    })),
    postPayDebts: latestPostPayDebts.map((debt) => ({
      isPaid: debt.paidMs !== null,
      principalAmount: `${formatAmountE3(debt.principalAmountE3)} 円`,
      status: `${formatAsDatetime(debt.appliedMs)}${
        debt.canceledMs
          ? `（${formatAsDatetime(debt.canceledMs)}に${
              debt.cancelType?.value === DebtCancelType.DEBT_CANCEL_TYPE_REGISTER_OPERATIONAL_RECEIPT
                ? '入金消込として'
                : ''
            }取消し済み）`
          : ''
      }`,
    })),
    postPayReturns: [
      ...user.repaymentsBankPay.map((repayment) => ({ ...repayment, method: RepaymentMethod.BankPay })),
      ...user.repaymentsConvenienceStore.map((repayment) => ({
        ...repayment,
        method: RepaymentMethod.ConvenienceStore,
      })),
      ...user.repaymentsPartnerBalance.map((repayment) => ({ ...repayment, method: RepaymentMethod.PartnerBalance })),
    ]
      .sort((a, b) => b.createdMs - a.createdMs)
      .map((postPayReturn) => ({
        repaymentId: postPayReturn.repaymentId,
        method: methodToName[postPayReturn.method],
        isPaid: postPayReturn.paidMs !== null,
        paidDateTime: postPayReturn.paidMs !== null ? `${formatAsDatetime(postPayReturn.paidMs)} 払込済み` : '',
        principalAmount: `${formatAmountE3(postPayReturn.totalPrincipalAmountE3)} 円`,
        feeAmount: `${formatAmountE3(postPayReturn.totalUsageFeeAmountE3)} 円`,
        delinquencyChargeAmount: `${formatAmountE3(postPayReturn.delinquencyChargeAmountE3)} 円`,
        additionalBillingOperationFeeAmount: `${formatAmountE3(
          postPayReturn.additionalBillingOperationFeeAmountE3,
        )} 円`,
        totalAmount: `${formatAmountE3(
          postPayReturn.totalPrincipalAmountE3 +
            postPayReturn.totalUsageFeeAmountE3 +
            postPayReturn.delinquencyChargeAmountE3 +
            postPayReturn.additionalBillingOperationFeeAmountE3,
        )} 円`,
      })),
    operatorComments: operatorComments.map((comment) => ({
      operator: `${comment.operator.name} (${comment.operator.operatorId})`,
      commentBodyRows: comment.commentBody.split('\n'),
      commentedDateTime: formatAsDatetime(comment.commentedMs),
    })),
  }
}
