import { TextField } from '@mui/material'
import { format } from 'date-fns'
import { useContext, useEffect } from 'react'
import { useForm } from 'react-hook-form'

import DialogConfirmation from 'shared/components/DialogConfirmation'
import { DialogForm } from 'shared/components/DialogForm'
import { context as OperationAppContext } from 'shared/context/OperationApp'
import useToggle from 'shared/hooks/useToggle'

import { usePostPayUpdatePostPayScreeningRuleSet } from '@/hooks/grpc'

interface Form {
  postPayScreeningRuleSetSetting: string
  ruleSetVersion: string
}

const CreateNewPostPayScreeningRuleSet: React.FC<{
  oldSetting: string
  onSuccess: () => void
  onCancel: () => void
}> = ({ oldSetting, onSuccess, onCancel }) => {
  const [openedConfirmDialog, { enable: showDialog, disable: hideDialog }] = useToggle(false)
  const appContext = useContext(OperationAppContext)
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    getValues,
  } = useForm<Form>({
    mode: 'onSubmit',
    defaultValues: {
      ruleSetVersion: format(new Date(), 'yyyyMMdd'),
      postPayScreeningRuleSetSetting: oldSetting,
    },
  })
  const { call, response } = usePostPayUpdatePostPayScreeningRuleSet()

  useEffect(() => {
    if (response && response.error === undefined) {
      appContext.flashMessage('新しいバージョンを作成しました', 'success')
      onSuccess()
    }
  }, [appContext, response, onSuccess])

  const submit = () => {
    hideDialog
    call({ ...getValues() })
  }

  return (
    <>
      <DialogForm
        title="新しいバージョンを作成する"
        submitButtonTitle="作成する"
        size="sm"
        reset={reset}
        onCancel={onCancel}
        onSubmit={handleSubmit(showDialog)}
      >
        <TextField
          margin="normal"
          label="バージョン"
          type="text"
          autoComplete="off"
          fullWidth
          error={!!errors.ruleSetVersion}
          helperText={errors.ruleSetVersion && '入力してください'}
          {...register('ruleSetVersion', { required: true, maxLength: 10 })}
        />
        <TextField
          autoFocus
          multiline
          rows={15}
          margin="normal"
          label="設定"
          type="text"
          fullWidth
          {...register('postPayScreeningRuleSetSetting', { required: true })}
        />
      </DialogForm>
      <DialogConfirmation
        open={openedConfirmDialog}
        title="新しいバージョンを作成する"
        message={`この検査ルールセットを新しいバージョン "${
          getValues().ruleSetVersion
        }" として登録します。よろしいですか？`}
        onConfirm={submit}
        onCancel={hideDialog}
      >
        <pre>{getValues().postPayScreeningRuleSetSetting}</pre>
      </DialogConfirmation>
    </>
  )
}

export default CreateNewPostPayScreeningRuleSet
