import { AccountCircle } from '@mui/icons-material'
import { Box, Button, Grid, Paper, TextField } from '@mui/material'
import { useContext, useEffect, useRef } from 'react'

import { context as OperationAppContext } from 'shared/context/OperationApp'

export type LoginOperation = () => {
  call: (params: { loginId: string; password: string }) => Promise<void>
  response:
    | {
        error?: { errorMessageJa: string } | null
        sessionToken: string
        operatorName: string
        operatorId: string
        rolesList: Array<number>
        lastPasswordUpdatedMs: number
      }
    | undefined
}

const LoginScreen: React.FC<{
  operation: LoginOperation
}> = ({ operation }) => {
  const id = useRef<HTMLInputElement>(null)
  const pw = useRef<HTMLInputElement>(null)
  const { call, response: loginResponse } = operation()
  const operationApp = useContext(OperationAppContext)
  const refForOperationAppContext = useRef(operationApp)

  const submit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (id.current && pw.current) {
      call({ loginId: id.current.value, password: pw.current.value })
    }
  }

  useEffect(() => {
    if (loginResponse) {
      refForOperationAppContext.current.setCurrentOperator({
        name: loginResponse.operatorName,
        id: loginResponse.operatorId,
        sessionToken: loginResponse.sessionToken,
        roles: loginResponse.rolesList,
        lastPasswordUpdatedMs: loginResponse.lastPasswordUpdatedMs,
      })
      refForOperationAppContext.current.flashMessage('ログインしました', 'success')
    }
  }, [loginResponse])

  return (
    <Box mt="30vh">
      <Grid container justifyContent="center">
        <Grid item xs={8} sm={4}>
          <Paper elevation={1}>
            <Box p={2}>
              <form onSubmit={(e) => submit(e)} noValidate>
                <Grid container spacing={2} direction="column" justifyContent="space-around">
                  <Grid item>
                    <Box textAlign="center">
                      <AccountCircle style={{ fontSize: 64 }} color="secondary" />
                    </Box>
                  </Grid>
                  <Grid item>
                    <TextField
                      inputProps={{ ref: id, 'aria-label': 'username' }}
                      type="text"
                      autoComplete="username"
                      label="ユーザー名"
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      inputProps={{ ref: pw, 'aria-label': 'password', role: 'textbox' }}
                      type="password"
                      autoComplete="current-password"
                      label="パスワード"
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                  <Grid item>
                    <Button type="submit" color="primary" variant="contained" size="large" fullWidth>
                      ログイン
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  )
}

export default LoginScreen
