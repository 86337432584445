import { CheckCircle, KeyboardArrowDown, KeyboardArrowRight, NewReleases, Schedule } from '@mui/icons-material'
import { Button, Chip, Collapse, List, ListItem, ListItemText, ListSubheader, TableCell, TableRow } from '@mui/material'
import { grey } from '@mui/material/colors'
import React, { useState } from 'react'

import AccessControl from 'shared/components/AccessControl'
import PaginatableTable from 'shared/components/PaginatableTable'
import PaginatableTableHeader from 'shared/components/PaginatableTableHeader'
import useToggle from 'shared/hooks/useToggle'

import CreateNewPostPaidTopUpFees from '@/components/CreateNewPostPaidTopUpFee'
import { useListPostPaidTopUpFeesQuery } from '@/hooks/graphql'
import { assumedRoles } from '@/hooks/graphql/queries/list_post_pay_screening_rule_sets.gql'

export const allowedRoles = assumedRoles

const PagePostPaidTopUpFees: React.VFC = () => {
  const { data, presentation, refetch } = useListPostPaidTopUpFeesQuery()
  const [showNewFeesDialog, { enable: openDialog, disable: hideDialog }] = useToggle(false)
  const [openedVersions, setOpenedVersions] = useState<number[]>([])

  const header = (
    <PaginatableTableHeader
      title="手数料設定"
      actions={
        <Button variant="contained" color="primary" onClick={openDialog}>
          新しい手数料を設定する
        </Button>
      }
    />
  )

  return (
    <AccessControl allowedRoles={allowedRoles}>
      <PaginatableTable
        page={1}
        perPage={10000}
        cols={['', '適用開始日時', '設定', '作成日時']}
        totalCount={data?.totalCount}
        onPaginate={() => {
          /* noop */
        }}
        header={header}
      >
        {presentation &&
          presentation.map((fees, index) => (
            <TableRow
              selected={fees.isActive}
              sx={{ backgroundColor: fees.isArchived ? grey[400] : 'inherit' }}
              key={`${fees.appliedFromDatetime}-${index}`}
            >
              <TableCell sx={{ maxWidth: '80px' }}>
                {fees.isActive && <Chip label="適用中" icon={<CheckCircle />} color="primary" />}
                {fees.isFuture && fees.isImmediateNext && (
                  <Chip label="次回適用予定" icon={<NewReleases />} color="info" />
                )}
                {fees.isFuture && !fees.isImmediateNext && <Chip label="予約中" icon={<Schedule />} color="default" />}
              </TableCell>
              <TableCell sx={{ maxWidth: '120px' }}>{fees.appliedFromDatetime}</TableCell>
              <TableCell>
                {!fees.isActive && !openedVersions.includes(fees.version) && (
                  <Button
                    startIcon={<KeyboardArrowRight />}
                    size="small"
                    color="info"
                    onClick={() => {
                      setOpenedVersions([...openedVersions, fees.version])
                    }}
                  >
                    設定を見る
                  </Button>
                )}
                {openedVersions.includes(fees.version) && (
                  <Button
                    startIcon={<KeyboardArrowDown />}
                    size="small"
                    color="info"
                    onClick={() => {
                      setOpenedVersions(openedVersions.filter((v) => v !== fees.version))
                    }}
                  >
                    閉じる
                  </Button>
                )}
                <Collapse in={fees.isActive || openedVersions.includes(fees.version)}>
                  {fees.feeTables.map((table, i) => (
                    <List
                      key={i}
                      subheader={
                        <ListSubheader
                          sx={{
                            background: 'none',
                          }}
                        >
                          {table.partner}
                        </ListSubheader>
                      }
                    >
                      {table.tables.map((t, j) => (
                        <ListItem dense key={j}>
                          <ListItemText primary={`${t.amount} 円`} secondary={`${t.from} 円から ${t.to} 円`} />
                        </ListItem>
                      ))}
                    </List>
                  ))}
                </Collapse>
              </TableCell>
              <TableCell sx={{ maxWidth: '120px' }}>{fees.createdDatetime}</TableCell>
            </TableRow>
          ))}
      </PaginatableTable>
      {presentation && showNewFeesDialog && (
        <CreateNewPostPaidTopUpFees
          sampleFeeSetting={presentation.find((p) => p.isActive)?.rawFeeSetting ?? ''}
          onSuccess={() => {
            hideDialog()
            refetch()
          }}
          onCancel={hideDialog}
        />
      )}
    </AccessControl>
  )
}

export default PagePostPaidTopUpFees
