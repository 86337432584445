import { Button, FormControl, TextField, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import DialogConfirmation from 'shared/components/DialogConfirmation'
import { isValidUrl } from 'shared/lib/inputValidation'

import { usePostPayUpdatePostPayScreeningApiSetting } from '@/hooks/grpc'

interface Form {
  endpointUri: string
  bearerToken: string
  graphqlQueryTemplate: string
  webhookUri: string
}

const UpdateExternalScreeningApiSetting: React.FC<{
  endpointUri: Form['endpointUri']
  bearerToken: Form['bearerToken']
  graphqlQueryTemplate: Form['graphqlQueryTemplate']
  webhookUri: Form['webhookUri']
  onSuccess: () => void
}> = ({ endpointUri, bearerToken, graphqlQueryTemplate, webhookUri, onSuccess }) => {
  const [openedDialogType, setDialogType] = useState<'confirm' | null>(null)
  const {
    register,
    formState: { errors, dirtyFields },
    handleSubmit,
    getValues,
    reset: resetForm,
  } = useForm<Form>({
    mode: 'onSubmit',
    defaultValues: {
      endpointUri,
      bearerToken,
      graphqlQueryTemplate,
      webhookUri,
    },
  })
  const { call, response, reset: resetResponse } = usePostPayUpdatePostPayScreeningApiSetting()

  useEffect(() => {
    resetForm({
      endpointUri,
      bearerToken,
      graphqlQueryTemplate,
      webhookUri,
    })
  }, [endpointUri, bearerToken, graphqlQueryTemplate, webhookUri, resetForm])

  useEffect(() => {
    if (response && response.error === undefined) {
      resetResponse()
      onSuccess()
    }
  }, [response, onSuccess, resetResponse])

  const submit = () => {
    setDialogType(null)
    call({
      ...getValues(),
    })
  }

  return (
    <form onSubmit={handleSubmit(() => setDialogType('confirm'))}>
      <TextField
        margin="normal"
        label="エンドポイントの URL"
        type="text"
        fullWidth
        error={!!errors.endpointUri}
        placeholder="https://..."
        helperText={errors.endpointUri && '空文字列か正しい URL を入力してください'}
        {...register('endpointUri', { validate: (field) => field === '' || isValidUrl(field) })}
      />
      <TextField
        margin="normal"
        label="Bearer Token"
        type="text"
        fullWidth
        InputProps={{
          startAdornment: (
            <Typography variant="body2" color="textSecondary">
              Bearer&nbsp;
            </Typography>
          ),
        }}
        error={!!errors.bearerToken}
        helperText={errors.bearerToken && '正しいトークン値を入力してください'}
        {...register('bearerToken', {
          required: true,
          validate: (field) => !field.startsWith('Bearer ') && !!field.match(/^[-._~+/a-z0-9]+=*$/i),
        })}
      />
      <TextField
        margin="normal"
        multiline
        rows={15}
        label="GraphQL クエリ"
        type="text"
        fullWidth
        inputProps={{
          style: {
            fontFamily: 'monospace',
          },
        }}
        error={!!errors.graphqlQueryTemplate}
        helperText={errors.graphqlQueryTemplate && '入力してください'}
        {...register('graphqlQueryTemplate', { required: true })}
      />
      <TextField
        margin="normal"
        label="Webhook を送信する URL"
        type="text"
        fullWidth
        error={!!errors.webhookUri}
        placeholder="https://..."
        helperText={errors.webhookUri && '空文字列か正しい URL を入力してください'}
        {...register('webhookUri', { validate: (field) => field === '' || isValidUrl(field) })}
      />
      <FormControl fullWidth margin="normal">
        <Button
          type="submit"
          variant="contained"
          color="primary"
          size="large"
          disabled={Object.keys(dirtyFields).length === 0}
        >
          更新する
        </Button>
      </FormControl>
      <DialogConfirmation
        open={openedDialogType === 'confirm'}
        title="審査 API の設定を更新する"
        message="設定を反映してよろしいですか？"
        onConfirm={submit}
        onCancel={() => setDialogType(null)}
      />
    </form>
  )
}

export default UpdateExternalScreeningApiSetting
