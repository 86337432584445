import { ThemeOptions } from '@mui/material'
import { amber, pink } from '@mui/material/colors'

import disableGlobalForMuiInputBase from 'shared/lib/disableGlobalForMuiInputBase'

const theme: ThemeOptions = {
  components: {
    ...disableGlobalForMuiInputBase,
  },
  palette: {
    primary: amber,
    secondary: pink,
  },
}

export default theme
