import { Grid, TextField } from '@mui/material'
import { addYears } from 'date-fns'
import { useForm } from 'react-hook-form'

import { DialogForm } from 'shared/components/DialogForm'
import { formatAsHyphenatedDate } from 'shared/lib/dateUtils'

interface FilterMiraibaraiAppUsersCondition {
  appUserId: string
  partnerId: string
  partnerUserId: string
  nameKanji: string
  nameKana: string
  emailAddress: string
  homePhoneNumber: string
  mobilePhoneNumber: string
  postalCode: string
  residentAddress: string
  birthdate: string
}

const FilterMiraibaraiAppUsers: React.FC<{
  onSelect: (form: FilterMiraibaraiAppUsersCondition) => void
  onCancel: () => void
  defaultValues: FilterMiraibaraiAppUsersCondition
}> = ({ onSelect, onCancel, defaultValues }) => {
  const { register, handleSubmit, reset } = useForm<FilterMiraibaraiAppUsersCondition>({
    mode: 'onSubmit',
    defaultValues,
  })

  const submit = (form: FilterMiraibaraiAppUsersCondition) => {
    onSelect(form)
  }

  return (
    <DialogForm
      title="ユーザーを絞り込む"
      submitButtonTitle="この条件で絞り込む"
      reset={reset}
      onCancel={onCancel}
      onSubmit={handleSubmit(submit)}
    >
      <TextField
        autoFocus
        margin="normal"
        label="App User ID"
        placeholder="apu:..."
        type="text"
        fullWidth
        {...register('appUserId')}
      />
      <Grid container direction="row">
        <Grid container item xs={5}>
          <TextField
            margin="normal"
            label="Partner ID"
            placeholder="kyash"
            type="text"
            fullWidth
            {...register('partnerId')}
          />
        </Grid>
        <Grid container item xs={1} />
        <Grid container item xs={6}>
          <TextField margin="normal" label="Partner User ID" type="text" fullWidth {...register('partnerUserId')} />
        </Grid>
      </Grid>
      <Grid container direction="row">
        <Grid container item xs={5}>
          <TextField margin="normal" label="氏名（漢字）" type="text" fullWidth {...register('nameKanji')} />
        </Grid>
        <Grid container item xs={1} />
        <Grid container item xs={6}>
          <TextField margin="normal" label="氏名（カナ）" type="text" fullWidth {...register('nameKana')} />
        </Grid>
      </Grid>
      <Grid container direction="row">
        <Grid container item xs={5}>
          <TextField margin="normal" label="電話番号（自宅）" type="tel" fullWidth {...register('homePhoneNumber')} />
        </Grid>
        <Grid container item xs={1} />
        <Grid container item xs={6}>
          <TextField margin="normal" label="電話番号（携帯）" type="tel" fullWidth {...register('mobilePhoneNumber')} />
        </Grid>
      </Grid>
      <Grid container direction="row">
        <Grid container item xs={3}>
          <TextField margin="normal" label="郵便番号" type="text" fullWidth {...register('postalCode')} />
        </Grid>
        <Grid container item xs={1} />
        <Grid container item xs={8}>
          <TextField margin="normal" label="住所" type="text" fullWidth {...register('residentAddress')} />
        </Grid>
      </Grid>
      <Grid container direction="row">
        <Grid container item xs={3}>
          <TextField
            margin="normal"
            label="生年月日"
            type="date"
            fullWidth
            inputProps={{ max: formatAsHyphenatedDate(addYears(new Date(), -18)) }}
            InputLabelProps={{ shrink: true }}
            {...register('birthdate')}
          />
        </Grid>
        <Grid container item xs={1} />
        <Grid container item xs={8}>
          <TextField margin="normal" label="メールアドレス" type="email" fullWidth {...register('emailAddress')} />
        </Grid>
      </Grid>
    </DialogForm>
  )
}

export default FilterMiraibaraiAppUsers
