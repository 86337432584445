import { gql } from '@apollo/client'

import { OperationRole } from 'shared/grpc/miraibaraiops/ops_common_pb'

export const query = gql`
  query ListOperators(
    $limit: Int!
    $skip: Int!
    $operatorId: String
  ) {
    allIPRanges: operationIpRanges {
      ipRangeId
    }
    totalCount: operatorsCount
    result: operators(
      limit: $limit
      skip: $skip
      operatorId: $operatorId
    ) {
      operatorId
      loginId
      name
      operatorRoles {
        ipRangeId
        operationRole {
          value
        }
      }
      suspendedMs
    }
  }
`

export const assumedRoles = [OperationRole.OPERATION_ROLE_MANAGE_OPERATOR]
