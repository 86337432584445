import Error from 'next/error'
import React, { useContext } from 'react'

import { context as OperationAppContext } from 'shared/context/OperationApp'

const AccessControl: React.FC<
  React.PropsWithChildren<{ allowedRoles: number[]; suppressed?: boolean; fallback?: JSX.Element }>
> = ({ allowedRoles, suppressed, children, fallback = null }) => {
  const appContext = useContext(OperationAppContext)
  const allowed = allowedRoles.some((allowedRole) => (appContext.currentOperator?.roles ?? []).includes(allowedRole))

  const errorContent = () => (suppressed ? fallback : <Error statusCode={403} />)

  return allowed ? <>{children}</> : errorContent()
}

export default AccessControl
