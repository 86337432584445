import { useCallback, useMemo, useState } from 'react'

const useDialog = <DialogKey extends string>(): [
  DialogKey | null,
  { isAnyDialogVisible: boolean; showDialog: (dialogKey: DialogKey) => void; hideDialog: () => void },
] => {
  const [currentDialog, setCurrentDialogState] = useState<DialogKey | null>(null)

  return [
    currentDialog,
    {
      isAnyDialogVisible: useMemo(() => currentDialog !== null, [currentDialog]),
      showDialog: useCallback((dialogKey: DialogKey) => setCurrentDialogState(dialogKey), [setCurrentDialogState]),
      hideDialog: useCallback(() => setCurrentDialogState(null), [setCurrentDialogState]),
    },
  ]
}

export default useDialog
