import { useContext, useEffect } from 'react'

import DialogConfirmation from 'shared/components/DialogConfirmation'
import { context as OperationAppContext } from 'shared/context/OperationApp'
import { DebtCancelType } from 'shared/grpc/server/post_pay_pb'

import { usePostPayCancelPostPayDebt } from '@/hooks/grpc'

const CancelPostPayDebt: React.FC<{
  debtId: string
  operational: boolean
  onSuccess: () => void
  onCancel: () => void
}> = ({ debtId, operational, onSuccess, onCancel }) => {
  const appContext = useContext(OperationAppContext)
  const { call, response } = usePostPayCancelPostPayDebt()

  const cancelDebt = () => {
    call({
      debtId,
      cancelType: operational
        ? DebtCancelType.DEBT_CANCEL_TYPE_REGISTER_OPERATIONAL_RECEIPT
        : DebtCancelType.DEBT_CANCEL_TYPE_CANCEL_UNINTENTIONAL_POSTPAY_APPLICATION,
    })
  }

  const cancel = () => {
    onCancel()
  }

  useEffect(() => {
    if (response && response.error === undefined) {
      appContext.flashMessage(`${debtId} を${operational ? '入金消込として取消しました' : '取消しました'}`, 'success')
      onSuccess()
    }
  }, [appContext, response, onSuccess, debtId, operational])

  return (
    <DialogConfirmation
      open={true}
      title={operational ? '後払いチャージを入金消込として取消す' : '後払いチャージを取消す'}
      message={`${debtId} を${
        operational ? '入金消込として取消します' : '取消します'
      }。元に戻すことはできませんが、よろしいですか？`}
      onCancel={cancel}
      onConfirm={cancelDebt}
    />
  )
}

export default CancelPostPayDebt
