import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'

const DialogRemindFreezingAccount: React.FC<{
  remainingDays: number
  onClose: () => void
}> = ({ remainingDays, onClose }) => {
  return (
    <Dialog
      open={true}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">パスワードの変更期限が迫っています</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          パスワードの有効期限が<strong>残り{remainingDays}日</strong>
          です。パスワードを変更しないまま有効期限を過ぎると、セキュリティポリシーに基づき、アカウントが永久に停止されます。
          <br />
          <br />
          ページ右上のユーザーアイコンから、すみやかに変更することをおすすめします。
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" autoFocus>
          理解した
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DialogRemindFreezingAccount
