import {
  AlternateEmail,
  Cake,
  Cancel,
  CheckCircle,
  EmojiEmotions,
  Face,
  Home,
  PhoneIphone,
  Policy,
  Receipt,
  Room,
  Storefront,
} from '@mui/icons-material'
import { Button, Chip, Collapse, TableCell, TableRow } from '@mui/material'
import { useMemo } from 'react'

import AccessControl from 'shared/components/AccessControl'
import { InternalLink } from 'shared/components/InternalLink'
import PaginatableTable from 'shared/components/PaginatableTable'
import PaginatableTableHeader from 'shared/components/PaginatableTableHeader'
import usePagination from 'shared/hooks/usePagination'
import useRouteQuery from 'shared/hooks/useRouteQuery'
import useToggle from 'shared/hooks/useToggle'
import { formatAsDatetime, formatAsJST, formatYYYYMMDDAsCalendarDate, parseDateInput } from 'shared/lib/dateUtils'
import { kebabSmsNumber } from 'shared/lib/decorators'
import { useDecorativeColors } from 'shared/lib/styles'

import FilterMiraibaraiAppUsers from '@/components/FilterMiraibaraiAppUsers'
import { useListMiraibaraiAppUsersQuery } from '@/hooks/graphql'
import { assumedRoles } from '@/hooks/graphql/queries/list_miraibarai_app_users.gql'

export const allowedRoles = assumedRoles

const PageMiraibaraiAppUsers: React.VFC = () => {
  const [query, updateQuery] = useRouteQuery([
    'app-user-id',
    'partner-id',
    'partner-user-id',
    'name-kanji',
    'name-kana',
    'email-address',
    'home-phone-number',
    'mobile-phone-number',
    'postal-code',
    'resident-address',
    'birthdate',
  ])
  const filterCondition = useMemo(
    () => ({
      appUserId: query['app-user-id'],
      partnerId: query['partner-id'],
      partnerUserId: query['partner-user-id'],
      nameKanji: query['name-kanji'],
      nameKana: query['name-kana'],
      emailAddress: query['email-address'],
      homePhoneNumber: query['home-phone-number'],
      mobilePhoneNumber: query['mobile-phone-number'],
      postalCode: query['postal-code'],
      residentAddress: query['resident-address'],
      birthdate: query['birthdate'] ? formatAsJST(parseDateInput(query['birthdate']), 'yMMdd') : null,
    }),
    [query],
  )
  const { skip, paginate, perPage, currentPage } = usePagination()
  const { data } = useListMiraibaraiAppUsersQuery({ skip, ...filterCondition })
  const [showFilterDialog, { enable: openFilterDialog, disable: hideFilterDialog }] = useToggle(false)
  const [showPersonalData, { toggle: togglePersonalDataMask }] = useToggle(false)
  const buttonColorStyles = useDecorativeColors()

  const header = (
    <PaginatableTableHeader
      title="ユーザー一覧"
      onClickFilter={openFilterDialog}
      pills={
        <>
          {query['app-user-id'] && (
            <Chip
              label={query['app-user-id']}
              icon={<Face />}
              color="primary"
              onDelete={() => updateQuery({ ...query, 'app-user-id': null })}
            />
          )}
          {query['partner-id'] && (
            <Chip
              label={query['partner-id']}
              icon={<Storefront />}
              color="secondary"
              onDelete={() => updateQuery({ ...query, 'partner-id': null })}
            />
          )}
          {query['partner-user-id'] && (
            <Chip
              label={query['partner-user-id']}
              icon={<EmojiEmotions />}
              color="secondary"
              onDelete={() => updateQuery({ ...query, 'partner-user-id': null })}
            />
          )}
          {query['name-kanji'] && (
            <Chip
              label={query['name-kanji']}
              icon={<Policy />}
              color="default"
              onDelete={() => updateQuery({ ...query, 'name-kanji': null })}
            />
          )}
          {query['name-kana'] && (
            <Chip
              label={query['name-kana']}
              icon={<Policy />}
              color="default"
              onDelete={() => updateQuery({ ...query, 'name-kana': null })}
            />
          )}
          {query['email-address'] && (
            <Chip
              label={query['email-address']}
              icon={<AlternateEmail />}
              color="default"
              onDelete={() => updateQuery({ ...query, 'email-address': null })}
            />
          )}
          {query['home-phone-number'] && (
            <Chip
              label={query['home-phone-number']}
              icon={<Home />}
              color="default"
              onDelete={() => updateQuery({ ...query, 'home-phone-number': null })}
            />
          )}
          {query['mobile-phone-number'] && (
            <Chip
              label={query['mobile-phone-number']}
              icon={<PhoneIphone />}
              color="default"
              onDelete={() => updateQuery({ ...query, 'mobile-phone-number': null })}
            />
          )}
          {query['postal-code'] && (
            <Chip
              label={query['postal-code']}
              icon={<Receipt />}
              color="default"
              onDelete={() => updateQuery({ ...query, 'postal-code': null })}
            />
          )}
          {query['resident-address'] && (
            <Chip
              label={query['resident-address']}
              icon={<Room />}
              color="default"
              onDelete={() => updateQuery({ ...query, 'resident-address': null })}
            />
          )}
          {query['birthdate'] && (
            <Chip
              label={query['birthdate']}
              icon={<Cake />}
              color="default"
              onDelete={() => updateQuery({ ...query, birthdate: null })}
            />
          )}
        </>
      }
      actions={
        <Button size="small" variant="contained" color="secondary" onClick={togglePersonalDataMask}>
          {showPersonalData ? '個人情報を隠す' : '個人情報を表示する'}
        </Button>
      }
    />
  )

  return (
    <AccessControl allowedRoles={allowedRoles}>
      <PaginatableTable
        page={currentPage}
        perPage={perPage}
        cols={['', '加盟店', '登録者情報', '登録日時']}
        totalCount={data?.totalCount}
        onPaginate={paginate}
        header={header}
      >
        {data &&
          data.result.map((user, index) => (
            <TableRow hover key={`${user.appUserId}-${currentPage}-${index}`}>
              <TableCell>
                <ruby>
                  {user.miraibaraiPersonalData?.nameKanji}
                  <rp>(</rp>
                  <rt>{user.miraibaraiPersonalData?.nameKana}</rt>
                  <rp>)</rp>
                </ruby>
                <br />
                <InternalLink text href={`/miraibarai-app-users/show?id=${user.appUserId}`}>
                  <strong>{user.appUserId}</strong>
                </InternalLink>
              </TableCell>
              <TableCell>
                <strong>{user.partnerId}</strong>
                <br />
                {user.partnerUserId}
              </TableCell>
              <TableCell>
                {user.identityVerificationCompleted && (
                  <Chip
                    size={showPersonalData ? 'small' : 'medium'}
                    label="本人確認済み"
                    icon={<CheckCircle sx={buttonColorStyles.green} />}
                    color="primary"
                    sx={buttonColorStyles.green}
                  />
                )}
                {!user.identityVerificationCompleted && (
                  <Chip
                    size={showPersonalData ? 'small' : 'medium'}
                    label="本人情報未確認"
                    icon={<Cancel sx={buttonColorStyles.red} />}
                    color="primary"
                    sx={buttonColorStyles.red}
                  />
                )}
                <Collapse in={showPersonalData}>
                  <br />
                  {user.miraibaraiPersonalData?.emailAddress}
                  <br />
                  {user.miraibaraiPersonalData?.homePhoneNumber}
                  <br />
                  {user.miraibaraiPersonalData?.mobilePhoneNumber &&
                    kebabSmsNumber(user.miraibaraiPersonalData?.mobilePhoneNumber)}
                  <br />
                  {user.miraibaraiPersonalData?.birthdate &&
                    formatYYYYMMDDAsCalendarDate(user.miraibaraiPersonalData.birthdate)}
                </Collapse>
              </TableCell>
              <TableCell>{formatAsDatetime(user.createdMs)}</TableCell>
            </TableRow>
          ))}
      </PaginatableTable>
      {showFilterDialog && (
        <FilterMiraibaraiAppUsers
          defaultValues={{
            appUserId: query['app-user-id'] ?? '',
            partnerId: query['partner-id'] ?? '',
            partnerUserId: query['partner-user-id'] ?? '',
            nameKanji: query['name-kanji'] ?? '',
            nameKana: query['name-kana'] ?? '',
            emailAddress: query['email-address'] ?? '',
            homePhoneNumber: query['home-phone-number'] ?? '',
            mobilePhoneNumber: query['mobile-phone-number'] ?? '',
            postalCode: query['postal-code'] ?? '',
            residentAddress: query['resident-address'] ?? '',
            birthdate: query['birthdate'] ?? '',
          }}
          onSelect={(newFilter) => {
            updateQuery({
              'app-user-id': newFilter.appUserId,
              'partner-id': newFilter.partnerId,
              'partner-user-id': newFilter.partnerUserId,
              'name-kanji': newFilter.nameKanji,
              'name-kana': newFilter.nameKana,
              'email-address': newFilter.emailAddress,
              'home-phone-number': newFilter.homePhoneNumber,
              'mobile-phone-number': newFilter.mobilePhoneNumber,
              'postal-code': newFilter.postalCode,
              'resident-address': newFilter.residentAddress,
              birthdate: newFilter.birthdate,
            })
            hideFilterDialog()
          }}
          onCancel={hideFilterDialog}
        />
      )}
    </AccessControl>
  )
}

export default PageMiraibaraiAppUsers
