import {
  ArrowRight,
  Ballot,
  Build,
  Group,
  GroupAdd,
  History,
  PriceChange,
  SettingsInputComponent,
  SwapHorizontalCircle,
} from '@mui/icons-material'

import { Operation } from 'shared/components/GlobalNavigation'

import { allowedRoles as ExternalScreeningApiSettingPageAllowedRoles } from '@/pages/external-screening-api-setting'
import { allowedRoles as GraphiQLPageAllowedRoles } from '@/pages/graphiql'
import { allowedRoles as MiraibaraiAppUsersPageAllowedRoles } from '@/pages/miraibarai-app-users'
import { allowedRoles as OperatorLogsPageAllowedRoles } from '@/pages/operator-logs/login'
import { allowedRoles as OperatorPageAllowedRoles } from '@/pages/operators'
import { allowedRoles as PostPaidTopUpFeesPageAllowedRoles } from '@/pages/post-paid-top-up-fees'
import { allowedRoles as PostPayDebtsPageAllowedRoles } from '@/pages/post-pay-debts'
import { allowedRoles as PostPayScreeningRuleSetsPageAllowedRoles } from '@/pages/post-pay-screening-rule-sets'

export const mainOperations: Operation[] = [
  ['ユーザー', Group, '/miraibarai-app-users', MiraibaraiAppUsersPageAllowedRoles],
  ['後払いチャージ', SwapHorizontalCircle, '/post-pay-debts', PostPayDebtsPageAllowedRoles],
  ['検査ルールセット', Ballot, '/post-pay-screening-rule-sets', PostPayScreeningRuleSetsPageAllowedRoles],
  [
    '外部接続設定',
    SettingsInputComponent,
    '/external-screening-api-setting',
    ExternalScreeningApiSettingPageAllowedRoles,
  ],
  ['手数料設定', PriceChange, '/post-paid-top-up-fees', PostPaidTopUpFeesPageAllowedRoles],
  ['オペレーター', GroupAdd, '/operators', OperatorPageAllowedRoles],
  [
    'オペレーター操作履歴',
    History,
    '',
    OperatorLogsPageAllowedRoles,
    [
      ['ログイン試行', ArrowRight, '/operator-logs/login'],
      ['データ閲覧', ArrowRight, '/operator-logs/query'],
      ['変更操作', ArrowRight, '/operator-logs/execution'],
    ],
  ],
  ['GraphiQL', Build, '/graphiql', GraphiQLPageAllowedRoles],
]
