import { gql } from '@apollo/client'

import { OperationRole } from 'shared/grpc/miraibaraiops/ops_common_pb'

export const query = gql`
  query ListOperatorQueryLogs(
    $limit: Int!
    $skip: Int!
    $sort_field: String!
    $desc: Boolean!
    $operatorId: String
    $executedMs: IntRange = {}
  ) {
    totalCount: operatorQueryLogsCount(
      operatorId: $operatorId
      executedMs: $executedMs
    )
    result: operatorQueryLogs(
      limit: $limit
      skip: $skip
      sort_field: $sort_field
      desc: $desc
      operatorId: $operatorId
      executedMs: $executedMs
    ) {
      operatorId
      executedMs
      queryName
      operator {
        loginId
      }
    }
  }
`

export const assumedRoles = [OperationRole.OPERATION_ROLE_MANAGE_OPERATOR]
