// source: server/post_pay.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return Function('return this')(); }).call(null);

var scalapb_scalapb_pb = require('../scalapb/scalapb_pb.js');
goog.object.extend(proto, scalapb_scalapb_pb);
var miraibaraiapp_miraibarai_user_pb = require('../miraibaraiapp/miraibarai_user_pb.js');
goog.object.extend(proto, miraibaraiapp_miraibarai_user_pb);
goog.exportSymbol('proto.com.kipp_corp.core.grpc.server.CollectionData', null, global);
goog.exportSymbol('proto.com.kipp_corp.core.grpc.server.CreditCriteria', null, global);
goog.exportSymbol('proto.com.kipp_corp.core.grpc.server.CreditHistory', null, global);
goog.exportSymbol('proto.com.kipp_corp.core.grpc.server.DebtCancelType', null, global);
goog.exportSymbol('proto.com.kipp_corp.core.grpc.server.EpreCreditCriteria', null, global);
goog.exportSymbol('proto.com.kipp_corp.core.grpc.server.FeeTableItem', null, global);
goog.exportSymbol('proto.com.kipp_corp.core.grpc.server.MiraibaraiCreditCriteria', null, global);
goog.exportSymbol('proto.com.kipp_corp.core.grpc.server.PartnerFeeTable', null, global);
goog.exportSymbol('proto.com.kipp_corp.core.grpc.server.PerCreditData', null, global);
goog.exportSymbol('proto.com.kipp_corp.core.grpc.server.PerDebtData', null, global);
goog.exportSymbol('proto.com.kipp_corp.core.grpc.server.PostPaidTopUpFeeSetting', null, global);
goog.exportSymbol('proto.com.kipp_corp.core.grpc.server.PostPayDailyReportData', null, global);
goog.exportSymbol('proto.com.kipp_corp.core.grpc.server.PostPayMonthlyReportData', null, global);
goog.exportSymbol('proto.com.kipp_corp.core.grpc.server.PostPayScreeningRuleSetSetting', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.kipp_corp.core.grpc.server.CreditHistory = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.kipp_corp.core.grpc.server.CreditHistory, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.kipp_corp.core.grpc.server.CreditHistory.displayName = 'proto.com.kipp_corp.core.grpc.server.CreditHistory';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.kipp_corp.core.grpc.server.CreditCriteria = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.kipp_corp.core.grpc.server.CreditCriteria.repeatedFields_, null);
};
goog.inherits(proto.com.kipp_corp.core.grpc.server.CreditCriteria, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.kipp_corp.core.grpc.server.CreditCriteria.displayName = 'proto.com.kipp_corp.core.grpc.server.CreditCriteria';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.kipp_corp.core.grpc.server.EpreCreditCriteria = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.kipp_corp.core.grpc.server.EpreCreditCriteria, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.kipp_corp.core.grpc.server.EpreCreditCriteria.displayName = 'proto.com.kipp_corp.core.grpc.server.EpreCreditCriteria';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.kipp_corp.core.grpc.server.MiraibaraiCreditCriteria = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.kipp_corp.core.grpc.server.MiraibaraiCreditCriteria, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.kipp_corp.core.grpc.server.MiraibaraiCreditCriteria.displayName = 'proto.com.kipp_corp.core.grpc.server.MiraibaraiCreditCriteria';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.kipp_corp.core.grpc.server.PostPayDailyReportData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.kipp_corp.core.grpc.server.PostPayDailyReportData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.kipp_corp.core.grpc.server.PostPayDailyReportData.displayName = 'proto.com.kipp_corp.core.grpc.server.PostPayDailyReportData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.kipp_corp.core.grpc.server.PostPayMonthlyReportData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.kipp_corp.core.grpc.server.PostPayMonthlyReportData.repeatedFields_, null);
};
goog.inherits(proto.com.kipp_corp.core.grpc.server.PostPayMonthlyReportData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.kipp_corp.core.grpc.server.PostPayMonthlyReportData.displayName = 'proto.com.kipp_corp.core.grpc.server.PostPayMonthlyReportData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.kipp_corp.core.grpc.server.PerDebtData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.kipp_corp.core.grpc.server.PerDebtData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.kipp_corp.core.grpc.server.PerDebtData.displayName = 'proto.com.kipp_corp.core.grpc.server.PerDebtData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.kipp_corp.core.grpc.server.PerCreditData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.kipp_corp.core.grpc.server.PerCreditData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.kipp_corp.core.grpc.server.PerCreditData.displayName = 'proto.com.kipp_corp.core.grpc.server.PerCreditData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.kipp_corp.core.grpc.server.CollectionData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.kipp_corp.core.grpc.server.CollectionData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.kipp_corp.core.grpc.server.CollectionData.displayName = 'proto.com.kipp_corp.core.grpc.server.CollectionData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.kipp_corp.core.grpc.server.PostPayScreeningRuleSetSetting = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.kipp_corp.core.grpc.server.PostPayScreeningRuleSetSetting.repeatedFields_, null);
};
goog.inherits(proto.com.kipp_corp.core.grpc.server.PostPayScreeningRuleSetSetting, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.kipp_corp.core.grpc.server.PostPayScreeningRuleSetSetting.displayName = 'proto.com.kipp_corp.core.grpc.server.PostPayScreeningRuleSetSetting';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.kipp_corp.core.grpc.server.PostPaidTopUpFeeSetting = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.kipp_corp.core.grpc.server.PostPaidTopUpFeeSetting.repeatedFields_, null);
};
goog.inherits(proto.com.kipp_corp.core.grpc.server.PostPaidTopUpFeeSetting, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.kipp_corp.core.grpc.server.PostPaidTopUpFeeSetting.displayName = 'proto.com.kipp_corp.core.grpc.server.PostPaidTopUpFeeSetting';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.kipp_corp.core.grpc.server.PartnerFeeTable = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.kipp_corp.core.grpc.server.PartnerFeeTable.repeatedFields_, null);
};
goog.inherits(proto.com.kipp_corp.core.grpc.server.PartnerFeeTable, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.kipp_corp.core.grpc.server.PartnerFeeTable.displayName = 'proto.com.kipp_corp.core.grpc.server.PartnerFeeTable';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.kipp_corp.core.grpc.server.FeeTableItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.kipp_corp.core.grpc.server.FeeTableItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.kipp_corp.core.grpc.server.FeeTableItem.displayName = 'proto.com.kipp_corp.core.grpc.server.FeeTableItem';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.kipp_corp.core.grpc.server.CreditHistory.prototype.toObject = function(opt_includeInstance) {
  return proto.com.kipp_corp.core.grpc.server.CreditHistory.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.kipp_corp.core.grpc.server.CreditHistory} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.kipp_corp.core.grpc.server.CreditHistory.toObject = function(includeInstance, msg) {
  var f, obj = {
    creditLimitValue: jspb.Message.getFieldWithDefault(msg, 1, 0),
    calculatedMs: jspb.Message.getFieldWithDefault(msg, 2, 0),
    appliedRule: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.kipp_corp.core.grpc.server.CreditHistory}
 */
proto.com.kipp_corp.core.grpc.server.CreditHistory.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.kipp_corp.core.grpc.server.CreditHistory;
  return proto.com.kipp_corp.core.grpc.server.CreditHistory.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.kipp_corp.core.grpc.server.CreditHistory} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.kipp_corp.core.grpc.server.CreditHistory}
 */
proto.com.kipp_corp.core.grpc.server.CreditHistory.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setCreditLimitValue(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCalculatedMs(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppliedRule(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.kipp_corp.core.grpc.server.CreditHistory.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.kipp_corp.core.grpc.server.CreditHistory.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.kipp_corp.core.grpc.server.CreditHistory} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.kipp_corp.core.grpc.server.CreditHistory.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCreditLimitValue();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getCalculatedMs();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getAppliedRule();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional uint64 credit_limit_value = 1;
 * @return {number}
 */
proto.com.kipp_corp.core.grpc.server.CreditHistory.prototype.getCreditLimitValue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.kipp_corp.core.grpc.server.CreditHistory} returns this
 */
proto.com.kipp_corp.core.grpc.server.CreditHistory.prototype.setCreditLimitValue = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 calculated_ms = 2;
 * @return {number}
 */
proto.com.kipp_corp.core.grpc.server.CreditHistory.prototype.getCalculatedMs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.kipp_corp.core.grpc.server.CreditHistory} returns this
 */
proto.com.kipp_corp.core.grpc.server.CreditHistory.prototype.setCalculatedMs = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string applied_rule = 3;
 * @return {string}
 */
proto.com.kipp_corp.core.grpc.server.CreditHistory.prototype.getAppliedRule = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.kipp_corp.core.grpc.server.CreditHistory} returns this
 */
proto.com.kipp_corp.core.grpc.server.CreditHistory.prototype.setAppliedRule = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.kipp_corp.core.grpc.server.CreditCriteria.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.kipp_corp.core.grpc.server.CreditCriteria.prototype.toObject = function(opt_includeInstance) {
  return proto.com.kipp_corp.core.grpc.server.CreditCriteria.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.kipp_corp.core.grpc.server.CreditCriteria} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.kipp_corp.core.grpc.server.CreditCriteria.toObject = function(includeInstance, msg) {
  var f, obj = {
    epre: (f = msg.getEpre()) && proto.com.kipp_corp.core.grpc.server.EpreCreditCriteria.toObject(includeInstance, f),
    miraibarai: (f = msg.getMiraibarai()) && proto.com.kipp_corp.core.grpc.server.MiraibaraiCreditCriteria.toObject(includeInstance, f),
    obsoleteHistoriesList: jspb.Message.toObjectList(msg.getObsoleteHistoriesList(),
    proto.com.kipp_corp.core.grpc.server.CreditHistory.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.kipp_corp.core.grpc.server.CreditCriteria}
 */
proto.com.kipp_corp.core.grpc.server.CreditCriteria.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.kipp_corp.core.grpc.server.CreditCriteria;
  return proto.com.kipp_corp.core.grpc.server.CreditCriteria.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.kipp_corp.core.grpc.server.CreditCriteria} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.kipp_corp.core.grpc.server.CreditCriteria}
 */
proto.com.kipp_corp.core.grpc.server.CreditCriteria.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.kipp_corp.core.grpc.server.EpreCreditCriteria;
      reader.readMessage(value,proto.com.kipp_corp.core.grpc.server.EpreCreditCriteria.deserializeBinaryFromReader);
      msg.setEpre(value);
      break;
    case 2:
      var value = new proto.com.kipp_corp.core.grpc.server.MiraibaraiCreditCriteria;
      reader.readMessage(value,proto.com.kipp_corp.core.grpc.server.MiraibaraiCreditCriteria.deserializeBinaryFromReader);
      msg.setMiraibarai(value);
      break;
    case 3:
      var value = new proto.com.kipp_corp.core.grpc.server.CreditHistory;
      reader.readMessage(value,proto.com.kipp_corp.core.grpc.server.CreditHistory.deserializeBinaryFromReader);
      msg.addObsoleteHistories(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.kipp_corp.core.grpc.server.CreditCriteria.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.kipp_corp.core.grpc.server.CreditCriteria.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.kipp_corp.core.grpc.server.CreditCriteria} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.kipp_corp.core.grpc.server.CreditCriteria.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEpre();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.com.kipp_corp.core.grpc.server.EpreCreditCriteria.serializeBinaryToWriter
    );
  }
  f = message.getMiraibarai();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.com.kipp_corp.core.grpc.server.MiraibaraiCreditCriteria.serializeBinaryToWriter
    );
  }
  f = message.getObsoleteHistoriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.com.kipp_corp.core.grpc.server.CreditHistory.serializeBinaryToWriter
    );
  }
};


/**
 * optional EpreCreditCriteria epre = 1;
 * @return {?proto.com.kipp_corp.core.grpc.server.EpreCreditCriteria}
 */
proto.com.kipp_corp.core.grpc.server.CreditCriteria.prototype.getEpre = function() {
  return /** @type{?proto.com.kipp_corp.core.grpc.server.EpreCreditCriteria} */ (
    jspb.Message.getWrapperField(this, proto.com.kipp_corp.core.grpc.server.EpreCreditCriteria, 1));
};


/**
 * @param {?proto.com.kipp_corp.core.grpc.server.EpreCreditCriteria|undefined} value
 * @return {!proto.com.kipp_corp.core.grpc.server.CreditCriteria} returns this
*/
proto.com.kipp_corp.core.grpc.server.CreditCriteria.prototype.setEpre = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.kipp_corp.core.grpc.server.CreditCriteria} returns this
 */
proto.com.kipp_corp.core.grpc.server.CreditCriteria.prototype.clearEpre = function() {
  return this.setEpre(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.kipp_corp.core.grpc.server.CreditCriteria.prototype.hasEpre = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional MiraibaraiCreditCriteria miraibarai = 2;
 * @return {?proto.com.kipp_corp.core.grpc.server.MiraibaraiCreditCriteria}
 */
proto.com.kipp_corp.core.grpc.server.CreditCriteria.prototype.getMiraibarai = function() {
  return /** @type{?proto.com.kipp_corp.core.grpc.server.MiraibaraiCreditCriteria} */ (
    jspb.Message.getWrapperField(this, proto.com.kipp_corp.core.grpc.server.MiraibaraiCreditCriteria, 2));
};


/**
 * @param {?proto.com.kipp_corp.core.grpc.server.MiraibaraiCreditCriteria|undefined} value
 * @return {!proto.com.kipp_corp.core.grpc.server.CreditCriteria} returns this
*/
proto.com.kipp_corp.core.grpc.server.CreditCriteria.prototype.setMiraibarai = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.kipp_corp.core.grpc.server.CreditCriteria} returns this
 */
proto.com.kipp_corp.core.grpc.server.CreditCriteria.prototype.clearMiraibarai = function() {
  return this.setMiraibarai(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.kipp_corp.core.grpc.server.CreditCriteria.prototype.hasMiraibarai = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated CreditHistory obsolete_histories = 3;
 * @return {!Array<!proto.com.kipp_corp.core.grpc.server.CreditHistory>}
 */
proto.com.kipp_corp.core.grpc.server.CreditCriteria.prototype.getObsoleteHistoriesList = function() {
  return /** @type{!Array<!proto.com.kipp_corp.core.grpc.server.CreditHistory>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.kipp_corp.core.grpc.server.CreditHistory, 3));
};


/**
 * @param {!Array<!proto.com.kipp_corp.core.grpc.server.CreditHistory>} value
 * @return {!proto.com.kipp_corp.core.grpc.server.CreditCriteria} returns this
*/
proto.com.kipp_corp.core.grpc.server.CreditCriteria.prototype.setObsoleteHistoriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.com.kipp_corp.core.grpc.server.CreditHistory=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.kipp_corp.core.grpc.server.CreditHistory}
 */
proto.com.kipp_corp.core.grpc.server.CreditCriteria.prototype.addObsoleteHistories = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.com.kipp_corp.core.grpc.server.CreditHistory, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.kipp_corp.core.grpc.server.CreditCriteria} returns this
 */
proto.com.kipp_corp.core.grpc.server.CreditCriteria.prototype.clearObsoleteHistoriesList = function() {
  return this.setObsoleteHistoriesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.kipp_corp.core.grpc.server.EpreCreditCriteria.prototype.toObject = function(opt_includeInstance) {
  return proto.com.kipp_corp.core.grpc.server.EpreCreditCriteria.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.kipp_corp.core.grpc.server.EpreCreditCriteria} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.kipp_corp.core.grpc.server.EpreCreditCriteria.toObject = function(includeInstance, msg) {
  var f, obj = {
    appliedRule: jspb.Message.getFieldWithDefault(msg, 1, ""),
    resetTimestampMs: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.kipp_corp.core.grpc.server.EpreCreditCriteria}
 */
proto.com.kipp_corp.core.grpc.server.EpreCreditCriteria.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.kipp_corp.core.grpc.server.EpreCreditCriteria;
  return proto.com.kipp_corp.core.grpc.server.EpreCreditCriteria.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.kipp_corp.core.grpc.server.EpreCreditCriteria} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.kipp_corp.core.grpc.server.EpreCreditCriteria}
 */
proto.com.kipp_corp.core.grpc.server.EpreCreditCriteria.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppliedRule(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setResetTimestampMs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.kipp_corp.core.grpc.server.EpreCreditCriteria.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.kipp_corp.core.grpc.server.EpreCreditCriteria.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.kipp_corp.core.grpc.server.EpreCreditCriteria} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.kipp_corp.core.grpc.server.EpreCreditCriteria.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAppliedRule();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getResetTimestampMs();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional string applied_rule = 1;
 * @return {string}
 */
proto.com.kipp_corp.core.grpc.server.EpreCreditCriteria.prototype.getAppliedRule = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.kipp_corp.core.grpc.server.EpreCreditCriteria} returns this
 */
proto.com.kipp_corp.core.grpc.server.EpreCreditCriteria.prototype.setAppliedRule = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 reset_timestamp_ms = 2;
 * @return {number}
 */
proto.com.kipp_corp.core.grpc.server.EpreCreditCriteria.prototype.getResetTimestampMs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.kipp_corp.core.grpc.server.EpreCreditCriteria} returns this
 */
proto.com.kipp_corp.core.grpc.server.EpreCreditCriteria.prototype.setResetTimestampMs = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.kipp_corp.core.grpc.server.MiraibaraiCreditCriteria.prototype.toObject = function(opt_includeInstance) {
  return proto.com.kipp_corp.core.grpc.server.MiraibaraiCreditCriteria.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.kipp_corp.core.grpc.server.MiraibaraiCreditCriteria} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.kipp_corp.core.grpc.server.MiraibaraiCreditCriteria.toObject = function(includeInstance, msg) {
  var f, obj = {
    occupation: jspb.Message.getFieldWithDefault(msg, 1, 0),
    identityVerificationCompleted: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    partnerUserSignupDate: jspb.Message.getFieldWithDefault(msg, 3, ""),
    longOverdueCount: jspb.Message.getFieldWithDefault(msg, 4, 0),
    firstUsageMs: jspb.Message.getFieldWithDefault(msg, 5, 0),
    baseTimeMs: jspb.Message.getFieldWithDefault(msg, 6, 0),
    appliedRule: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.kipp_corp.core.grpc.server.MiraibaraiCreditCriteria}
 */
proto.com.kipp_corp.core.grpc.server.MiraibaraiCreditCriteria.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.kipp_corp.core.grpc.server.MiraibaraiCreditCriteria;
  return proto.com.kipp_corp.core.grpc.server.MiraibaraiCreditCriteria.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.kipp_corp.core.grpc.server.MiraibaraiCreditCriteria} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.kipp_corp.core.grpc.server.MiraibaraiCreditCriteria}
 */
proto.com.kipp_corp.core.grpc.server.MiraibaraiCreditCriteria.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.com.kipp.miraibarai.grpc.app.v1.Occupation} */ (reader.readEnum());
      msg.setOccupation(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIdentityVerificationCompleted(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPartnerUserSignupDate(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLongOverdueCount(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setFirstUsageMs(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBaseTimeMs(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppliedRule(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.kipp_corp.core.grpc.server.MiraibaraiCreditCriteria.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.kipp_corp.core.grpc.server.MiraibaraiCreditCriteria.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.kipp_corp.core.grpc.server.MiraibaraiCreditCriteria} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.kipp_corp.core.grpc.server.MiraibaraiCreditCriteria.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOccupation();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getIdentityVerificationCompleted();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getPartnerUserSignupDate();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLongOverdueCount();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getFirstUsageMs();
  if (f !== 0) {
    writer.writeUint64(
      5,
      f
    );
  }
  f = message.getBaseTimeMs();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getAppliedRule();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional com.kipp.miraibarai.grpc.app.v1.Occupation occupation = 1;
 * @return {!proto.com.kipp.miraibarai.grpc.app.v1.Occupation}
 */
proto.com.kipp_corp.core.grpc.server.MiraibaraiCreditCriteria.prototype.getOccupation = function() {
  return /** @type {!proto.com.kipp.miraibarai.grpc.app.v1.Occupation} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.com.kipp.miraibarai.grpc.app.v1.Occupation} value
 * @return {!proto.com.kipp_corp.core.grpc.server.MiraibaraiCreditCriteria} returns this
 */
proto.com.kipp_corp.core.grpc.server.MiraibaraiCreditCriteria.prototype.setOccupation = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional bool identity_verification_completed = 2;
 * @return {boolean}
 */
proto.com.kipp_corp.core.grpc.server.MiraibaraiCreditCriteria.prototype.getIdentityVerificationCompleted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.kipp_corp.core.grpc.server.MiraibaraiCreditCriteria} returns this
 */
proto.com.kipp_corp.core.grpc.server.MiraibaraiCreditCriteria.prototype.setIdentityVerificationCompleted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional string partner_user_signup_date = 3;
 * @return {string}
 */
proto.com.kipp_corp.core.grpc.server.MiraibaraiCreditCriteria.prototype.getPartnerUserSignupDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.kipp_corp.core.grpc.server.MiraibaraiCreditCriteria} returns this
 */
proto.com.kipp_corp.core.grpc.server.MiraibaraiCreditCriteria.prototype.setPartnerUserSignupDate = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional uint32 long_overdue_count = 4;
 * @return {number}
 */
proto.com.kipp_corp.core.grpc.server.MiraibaraiCreditCriteria.prototype.getLongOverdueCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.kipp_corp.core.grpc.server.MiraibaraiCreditCriteria} returns this
 */
proto.com.kipp_corp.core.grpc.server.MiraibaraiCreditCriteria.prototype.setLongOverdueCount = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint64 first_usage_ms = 5;
 * @return {number}
 */
proto.com.kipp_corp.core.grpc.server.MiraibaraiCreditCriteria.prototype.getFirstUsageMs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.kipp_corp.core.grpc.server.MiraibaraiCreditCriteria} returns this
 */
proto.com.kipp_corp.core.grpc.server.MiraibaraiCreditCriteria.prototype.setFirstUsageMs = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 base_time_ms = 6;
 * @return {number}
 */
proto.com.kipp_corp.core.grpc.server.MiraibaraiCreditCriteria.prototype.getBaseTimeMs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.kipp_corp.core.grpc.server.MiraibaraiCreditCriteria} returns this
 */
proto.com.kipp_corp.core.grpc.server.MiraibaraiCreditCriteria.prototype.setBaseTimeMs = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string applied_rule = 7;
 * @return {string}
 */
proto.com.kipp_corp.core.grpc.server.MiraibaraiCreditCriteria.prototype.getAppliedRule = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.kipp_corp.core.grpc.server.MiraibaraiCreditCriteria} returns this
 */
proto.com.kipp_corp.core.grpc.server.MiraibaraiCreditCriteria.prototype.setAppliedRule = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.kipp_corp.core.grpc.server.PostPayDailyReportData.prototype.toObject = function(opt_includeInstance) {
  return proto.com.kipp_corp.core.grpc.server.PostPayDailyReportData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.kipp_corp.core.grpc.server.PostPayDailyReportData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.kipp_corp.core.grpc.server.PostPayDailyReportData.toObject = function(includeInstance, msg) {
  var f, obj = {
    date: jspb.Message.getFieldWithDefault(msg, 1, ""),
    totalAmount: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.kipp_corp.core.grpc.server.PostPayDailyReportData}
 */
proto.com.kipp_corp.core.grpc.server.PostPayDailyReportData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.kipp_corp.core.grpc.server.PostPayDailyReportData;
  return proto.com.kipp_corp.core.grpc.server.PostPayDailyReportData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.kipp_corp.core.grpc.server.PostPayDailyReportData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.kipp_corp.core.grpc.server.PostPayDailyReportData}
 */
proto.com.kipp_corp.core.grpc.server.PostPayDailyReportData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDate(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalAmount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.kipp_corp.core.grpc.server.PostPayDailyReportData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.kipp_corp.core.grpc.server.PostPayDailyReportData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.kipp_corp.core.grpc.server.PostPayDailyReportData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.kipp_corp.core.grpc.server.PostPayDailyReportData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDate();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTotalAmount();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional string date = 1;
 * @return {string}
 */
proto.com.kipp_corp.core.grpc.server.PostPayDailyReportData.prototype.getDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.kipp_corp.core.grpc.server.PostPayDailyReportData} returns this
 */
proto.com.kipp_corp.core.grpc.server.PostPayDailyReportData.prototype.setDate = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 total_amount = 2;
 * @return {number}
 */
proto.com.kipp_corp.core.grpc.server.PostPayDailyReportData.prototype.getTotalAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.kipp_corp.core.grpc.server.PostPayDailyReportData} returns this
 */
proto.com.kipp_corp.core.grpc.server.PostPayDailyReportData.prototype.setTotalAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.kipp_corp.core.grpc.server.PostPayMonthlyReportData.repeatedFields_ = [2,3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.kipp_corp.core.grpc.server.PostPayMonthlyReportData.prototype.toObject = function(opt_includeInstance) {
  return proto.com.kipp_corp.core.grpc.server.PostPayMonthlyReportData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.kipp_corp.core.grpc.server.PostPayMonthlyReportData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.kipp_corp.core.grpc.server.PostPayMonthlyReportData.toObject = function(includeInstance, msg) {
  var f, obj = {
    month: jspb.Message.getFieldWithDefault(msg, 1, ""),
    perDebtDataList: jspb.Message.toObjectList(msg.getPerDebtDataList(),
    proto.com.kipp_corp.core.grpc.server.PerDebtData.toObject, includeInstance),
    perCreditDataList: jspb.Message.toObjectList(msg.getPerCreditDataList(),
    proto.com.kipp_corp.core.grpc.server.PerCreditData.toObject, includeInstance),
    collectionDataList: jspb.Message.toObjectList(msg.getCollectionDataList(),
    proto.com.kipp_corp.core.grpc.server.CollectionData.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.kipp_corp.core.grpc.server.PostPayMonthlyReportData}
 */
proto.com.kipp_corp.core.grpc.server.PostPayMonthlyReportData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.kipp_corp.core.grpc.server.PostPayMonthlyReportData;
  return proto.com.kipp_corp.core.grpc.server.PostPayMonthlyReportData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.kipp_corp.core.grpc.server.PostPayMonthlyReportData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.kipp_corp.core.grpc.server.PostPayMonthlyReportData}
 */
proto.com.kipp_corp.core.grpc.server.PostPayMonthlyReportData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMonth(value);
      break;
    case 2:
      var value = new proto.com.kipp_corp.core.grpc.server.PerDebtData;
      reader.readMessage(value,proto.com.kipp_corp.core.grpc.server.PerDebtData.deserializeBinaryFromReader);
      msg.addPerDebtData(value);
      break;
    case 3:
      var value = new proto.com.kipp_corp.core.grpc.server.PerCreditData;
      reader.readMessage(value,proto.com.kipp_corp.core.grpc.server.PerCreditData.deserializeBinaryFromReader);
      msg.addPerCreditData(value);
      break;
    case 4:
      var value = new proto.com.kipp_corp.core.grpc.server.CollectionData;
      reader.readMessage(value,proto.com.kipp_corp.core.grpc.server.CollectionData.deserializeBinaryFromReader);
      msg.addCollectionData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.kipp_corp.core.grpc.server.PostPayMonthlyReportData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.kipp_corp.core.grpc.server.PostPayMonthlyReportData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.kipp_corp.core.grpc.server.PostPayMonthlyReportData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.kipp_corp.core.grpc.server.PostPayMonthlyReportData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMonth();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPerDebtDataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.com.kipp_corp.core.grpc.server.PerDebtData.serializeBinaryToWriter
    );
  }
  f = message.getPerCreditDataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.com.kipp_corp.core.grpc.server.PerCreditData.serializeBinaryToWriter
    );
  }
  f = message.getCollectionDataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.com.kipp_corp.core.grpc.server.CollectionData.serializeBinaryToWriter
    );
  }
};


/**
 * optional string month = 1;
 * @return {string}
 */
proto.com.kipp_corp.core.grpc.server.PostPayMonthlyReportData.prototype.getMonth = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.kipp_corp.core.grpc.server.PostPayMonthlyReportData} returns this
 */
proto.com.kipp_corp.core.grpc.server.PostPayMonthlyReportData.prototype.setMonth = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated PerDebtData per_debt_data = 2;
 * @return {!Array<!proto.com.kipp_corp.core.grpc.server.PerDebtData>}
 */
proto.com.kipp_corp.core.grpc.server.PostPayMonthlyReportData.prototype.getPerDebtDataList = function() {
  return /** @type{!Array<!proto.com.kipp_corp.core.grpc.server.PerDebtData>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.kipp_corp.core.grpc.server.PerDebtData, 2));
};


/**
 * @param {!Array<!proto.com.kipp_corp.core.grpc.server.PerDebtData>} value
 * @return {!proto.com.kipp_corp.core.grpc.server.PostPayMonthlyReportData} returns this
*/
proto.com.kipp_corp.core.grpc.server.PostPayMonthlyReportData.prototype.setPerDebtDataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.com.kipp_corp.core.grpc.server.PerDebtData=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.kipp_corp.core.grpc.server.PerDebtData}
 */
proto.com.kipp_corp.core.grpc.server.PostPayMonthlyReportData.prototype.addPerDebtData = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.com.kipp_corp.core.grpc.server.PerDebtData, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.kipp_corp.core.grpc.server.PostPayMonthlyReportData} returns this
 */
proto.com.kipp_corp.core.grpc.server.PostPayMonthlyReportData.prototype.clearPerDebtDataList = function() {
  return this.setPerDebtDataList([]);
};


/**
 * repeated PerCreditData per_credit_data = 3;
 * @return {!Array<!proto.com.kipp_corp.core.grpc.server.PerCreditData>}
 */
proto.com.kipp_corp.core.grpc.server.PostPayMonthlyReportData.prototype.getPerCreditDataList = function() {
  return /** @type{!Array<!proto.com.kipp_corp.core.grpc.server.PerCreditData>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.kipp_corp.core.grpc.server.PerCreditData, 3));
};


/**
 * @param {!Array<!proto.com.kipp_corp.core.grpc.server.PerCreditData>} value
 * @return {!proto.com.kipp_corp.core.grpc.server.PostPayMonthlyReportData} returns this
*/
proto.com.kipp_corp.core.grpc.server.PostPayMonthlyReportData.prototype.setPerCreditDataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.com.kipp_corp.core.grpc.server.PerCreditData=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.kipp_corp.core.grpc.server.PerCreditData}
 */
proto.com.kipp_corp.core.grpc.server.PostPayMonthlyReportData.prototype.addPerCreditData = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.com.kipp_corp.core.grpc.server.PerCreditData, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.kipp_corp.core.grpc.server.PostPayMonthlyReportData} returns this
 */
proto.com.kipp_corp.core.grpc.server.PostPayMonthlyReportData.prototype.clearPerCreditDataList = function() {
  return this.setPerCreditDataList([]);
};


/**
 * repeated CollectionData collection_data = 4;
 * @return {!Array<!proto.com.kipp_corp.core.grpc.server.CollectionData>}
 */
proto.com.kipp_corp.core.grpc.server.PostPayMonthlyReportData.prototype.getCollectionDataList = function() {
  return /** @type{!Array<!proto.com.kipp_corp.core.grpc.server.CollectionData>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.kipp_corp.core.grpc.server.CollectionData, 4));
};


/**
 * @param {!Array<!proto.com.kipp_corp.core.grpc.server.CollectionData>} value
 * @return {!proto.com.kipp_corp.core.grpc.server.PostPayMonthlyReportData} returns this
*/
proto.com.kipp_corp.core.grpc.server.PostPayMonthlyReportData.prototype.setCollectionDataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.com.kipp_corp.core.grpc.server.CollectionData=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.kipp_corp.core.grpc.server.CollectionData}
 */
proto.com.kipp_corp.core.grpc.server.PostPayMonthlyReportData.prototype.addCollectionData = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.com.kipp_corp.core.grpc.server.CollectionData, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.kipp_corp.core.grpc.server.PostPayMonthlyReportData} returns this
 */
proto.com.kipp_corp.core.grpc.server.PostPayMonthlyReportData.prototype.clearCollectionDataList = function() {
  return this.setCollectionDataList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.kipp_corp.core.grpc.server.PerDebtData.prototype.toObject = function(opt_includeInstance) {
  return proto.com.kipp_corp.core.grpc.server.PerDebtData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.kipp_corp.core.grpc.server.PerDebtData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.kipp_corp.core.grpc.server.PerDebtData.toObject = function(includeInstance, msg) {
  var f, obj = {
    appUserId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    debtId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    unpaidPrincipalAmount: jspb.Message.getFieldWithDefault(msg, 4, 0),
    creditLimit: jspb.Message.getFieldWithDefault(msg, 5, 0),
    unpaidFeeAmount: jspb.Message.getFieldWithDefault(msg, 6, 0),
    overdueDate: jspb.Message.getFieldWithDefault(msg, 7, ""),
    overdueMonths: jspb.Message.getFieldWithDefault(msg, 8, 0),
    overduePrincipalAmount: jspb.Message.getFieldWithDefault(msg, 9, 0),
    overdueFeeAmount: jspb.Message.getFieldWithDefault(msg, 10, 0),
    appliedMs: jspb.Message.getFieldWithDefault(msg, 11, 0),
    dueDate: jspb.Message.getFieldWithDefault(msg, 12, ""),
    principalAmount: jspb.Message.getFieldWithDefault(msg, 13, 0),
    partnerCode: jspb.Message.getFieldWithDefault(msg, 14, 0),
    firstCardCreatedMs: jspb.Message.getFieldWithDefault(msg, 15, 0),
    hasOverdueInYear: jspb.Message.getBooleanFieldWithDefault(msg, 16, false),
    ffgOverdueCodeByCredit: jspb.Message.getFieldWithDefault(msg, 17, ""),
    isDebtCollectionRequired: jspb.Message.getBooleanFieldWithDefault(msg, 18, false),
    hasDefaultByCredit: jspb.Message.getBooleanFieldWithDefault(msg, 19, false),
    ffgDebtCode: jspb.Message.getFieldWithDefault(msg, 20, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.kipp_corp.core.grpc.server.PerDebtData}
 */
proto.com.kipp_corp.core.grpc.server.PerDebtData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.kipp_corp.core.grpc.server.PerDebtData;
  return proto.com.kipp_corp.core.grpc.server.PerDebtData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.kipp_corp.core.grpc.server.PerDebtData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.kipp_corp.core.grpc.server.PerDebtData}
 */
proto.com.kipp_corp.core.grpc.server.PerDebtData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppUserId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDebtId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUnpaidPrincipalAmount(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreditLimit(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUnpaidFeeAmount(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setOverdueDate(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOverdueMonths(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOverduePrincipalAmount(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOverdueFeeAmount(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAppliedMs(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setDueDate(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPrincipalAmount(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPartnerCode(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setFirstCardCreatedMs(value);
      break;
    case 16:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasOverdueInYear(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setFfgOverdueCodeByCredit(value);
      break;
    case 18:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsDebtCollectionRequired(value);
      break;
    case 19:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasDefaultByCredit(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setFfgDebtCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.kipp_corp.core.grpc.server.PerDebtData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.kipp_corp.core.grpc.server.PerDebtData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.kipp_corp.core.grpc.server.PerDebtData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.kipp_corp.core.grpc.server.PerDebtData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAppUserId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDebtId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getUnpaidPrincipalAmount();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getCreditLimit();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getUnpaidFeeAmount();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getOverdueDate();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getOverdueMonths();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getOverduePrincipalAmount();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getOverdueFeeAmount();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getAppliedMs();
  if (f !== 0) {
    writer.writeInt64(
      11,
      f
    );
  }
  f = message.getDueDate();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getPrincipalAmount();
  if (f !== 0) {
    writer.writeInt64(
      13,
      f
    );
  }
  f = message.getPartnerCode();
  if (f !== 0) {
    writer.writeInt32(
      14,
      f
    );
  }
  f = message.getFirstCardCreatedMs();
  if (f !== 0) {
    writer.writeInt64(
      15,
      f
    );
  }
  f = message.getHasOverdueInYear();
  if (f) {
    writer.writeBool(
      16,
      f
    );
  }
  f = message.getFfgOverdueCodeByCredit();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getIsDebtCollectionRequired();
  if (f) {
    writer.writeBool(
      18,
      f
    );
  }
  f = message.getHasDefaultByCredit();
  if (f) {
    writer.writeBool(
      19,
      f
    );
  }
  f = message.getFfgDebtCode();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
};


/**
 * optional string app_user_id = 2;
 * @return {string}
 */
proto.com.kipp_corp.core.grpc.server.PerDebtData.prototype.getAppUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.kipp_corp.core.grpc.server.PerDebtData} returns this
 */
proto.com.kipp_corp.core.grpc.server.PerDebtData.prototype.setAppUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string debt_id = 3;
 * @return {string}
 */
proto.com.kipp_corp.core.grpc.server.PerDebtData.prototype.getDebtId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.kipp_corp.core.grpc.server.PerDebtData} returns this
 */
proto.com.kipp_corp.core.grpc.server.PerDebtData.prototype.setDebtId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 unpaid_principal_amount = 4;
 * @return {number}
 */
proto.com.kipp_corp.core.grpc.server.PerDebtData.prototype.getUnpaidPrincipalAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.kipp_corp.core.grpc.server.PerDebtData} returns this
 */
proto.com.kipp_corp.core.grpc.server.PerDebtData.prototype.setUnpaidPrincipalAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 credit_limit = 5;
 * @return {number}
 */
proto.com.kipp_corp.core.grpc.server.PerDebtData.prototype.getCreditLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.kipp_corp.core.grpc.server.PerDebtData} returns this
 */
proto.com.kipp_corp.core.grpc.server.PerDebtData.prototype.setCreditLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 unpaid_fee_amount = 6;
 * @return {number}
 */
proto.com.kipp_corp.core.grpc.server.PerDebtData.prototype.getUnpaidFeeAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.kipp_corp.core.grpc.server.PerDebtData} returns this
 */
proto.com.kipp_corp.core.grpc.server.PerDebtData.prototype.setUnpaidFeeAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string overdue_date = 7;
 * @return {string}
 */
proto.com.kipp_corp.core.grpc.server.PerDebtData.prototype.getOverdueDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.kipp_corp.core.grpc.server.PerDebtData} returns this
 */
proto.com.kipp_corp.core.grpc.server.PerDebtData.prototype.setOverdueDate = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional int32 overdue_months = 8;
 * @return {number}
 */
proto.com.kipp_corp.core.grpc.server.PerDebtData.prototype.getOverdueMonths = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.kipp_corp.core.grpc.server.PerDebtData} returns this
 */
proto.com.kipp_corp.core.grpc.server.PerDebtData.prototype.setOverdueMonths = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int64 overdue_principal_amount = 9;
 * @return {number}
 */
proto.com.kipp_corp.core.grpc.server.PerDebtData.prototype.getOverduePrincipalAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.kipp_corp.core.grpc.server.PerDebtData} returns this
 */
proto.com.kipp_corp.core.grpc.server.PerDebtData.prototype.setOverduePrincipalAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int64 overdue_fee_amount = 10;
 * @return {number}
 */
proto.com.kipp_corp.core.grpc.server.PerDebtData.prototype.getOverdueFeeAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.kipp_corp.core.grpc.server.PerDebtData} returns this
 */
proto.com.kipp_corp.core.grpc.server.PerDebtData.prototype.setOverdueFeeAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int64 applied_ms = 11;
 * @return {number}
 */
proto.com.kipp_corp.core.grpc.server.PerDebtData.prototype.getAppliedMs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.kipp_corp.core.grpc.server.PerDebtData} returns this
 */
proto.com.kipp_corp.core.grpc.server.PerDebtData.prototype.setAppliedMs = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional string due_date = 12;
 * @return {string}
 */
proto.com.kipp_corp.core.grpc.server.PerDebtData.prototype.getDueDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.kipp_corp.core.grpc.server.PerDebtData} returns this
 */
proto.com.kipp_corp.core.grpc.server.PerDebtData.prototype.setDueDate = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional int64 principal_amount = 13;
 * @return {number}
 */
proto.com.kipp_corp.core.grpc.server.PerDebtData.prototype.getPrincipalAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.kipp_corp.core.grpc.server.PerDebtData} returns this
 */
proto.com.kipp_corp.core.grpc.server.PerDebtData.prototype.setPrincipalAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional int32 partner_code = 14;
 * @return {number}
 */
proto.com.kipp_corp.core.grpc.server.PerDebtData.prototype.getPartnerCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.kipp_corp.core.grpc.server.PerDebtData} returns this
 */
proto.com.kipp_corp.core.grpc.server.PerDebtData.prototype.setPartnerCode = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional int64 first_card_created_ms = 15;
 * @return {number}
 */
proto.com.kipp_corp.core.grpc.server.PerDebtData.prototype.getFirstCardCreatedMs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.kipp_corp.core.grpc.server.PerDebtData} returns this
 */
proto.com.kipp_corp.core.grpc.server.PerDebtData.prototype.setFirstCardCreatedMs = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional bool has_overdue_in_year = 16;
 * @return {boolean}
 */
proto.com.kipp_corp.core.grpc.server.PerDebtData.prototype.getHasOverdueInYear = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 16, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.kipp_corp.core.grpc.server.PerDebtData} returns this
 */
proto.com.kipp_corp.core.grpc.server.PerDebtData.prototype.setHasOverdueInYear = function(value) {
  return jspb.Message.setProto3BooleanField(this, 16, value);
};


/**
 * optional string ffg_overdue_code_by_credit = 17;
 * @return {string}
 */
proto.com.kipp_corp.core.grpc.server.PerDebtData.prototype.getFfgOverdueCodeByCredit = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.kipp_corp.core.grpc.server.PerDebtData} returns this
 */
proto.com.kipp_corp.core.grpc.server.PerDebtData.prototype.setFfgOverdueCodeByCredit = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional bool is_debt_collection_required = 18;
 * @return {boolean}
 */
proto.com.kipp_corp.core.grpc.server.PerDebtData.prototype.getIsDebtCollectionRequired = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 18, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.kipp_corp.core.grpc.server.PerDebtData} returns this
 */
proto.com.kipp_corp.core.grpc.server.PerDebtData.prototype.setIsDebtCollectionRequired = function(value) {
  return jspb.Message.setProto3BooleanField(this, 18, value);
};


/**
 * optional bool has_default_by_credit = 19;
 * @return {boolean}
 */
proto.com.kipp_corp.core.grpc.server.PerDebtData.prototype.getHasDefaultByCredit = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 19, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.kipp_corp.core.grpc.server.PerDebtData} returns this
 */
proto.com.kipp_corp.core.grpc.server.PerDebtData.prototype.setHasDefaultByCredit = function(value) {
  return jspb.Message.setProto3BooleanField(this, 19, value);
};


/**
 * optional string ffg_debt_code = 20;
 * @return {string}
 */
proto.com.kipp_corp.core.grpc.server.PerDebtData.prototype.getFfgDebtCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.kipp_corp.core.grpc.server.PerDebtData} returns this
 */
proto.com.kipp_corp.core.grpc.server.PerDebtData.prototype.setFfgDebtCode = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.kipp_corp.core.grpc.server.PerCreditData.prototype.toObject = function(opt_includeInstance) {
  return proto.com.kipp_corp.core.grpc.server.PerCreditData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.kipp_corp.core.grpc.server.PerCreditData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.kipp_corp.core.grpc.server.PerCreditData.toObject = function(includeInstance, msg) {
  var f, obj = {
    appUserId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    unpaidPrincipalAmount: jspb.Message.getFieldWithDefault(msg, 2, 0),
    creditLimit: jspb.Message.getFieldWithDefault(msg, 3, 0),
    unpaidFeeAmount: jspb.Message.getFieldWithDefault(msg, 4, 0),
    overdueDate: jspb.Message.getFieldWithDefault(msg, 5, ""),
    overdueMonths: jspb.Message.getFieldWithDefault(msg, 6, 0),
    overduePrincipalAmount: jspb.Message.getFieldWithDefault(msg, 7, 0),
    overdueFeeAmount: jspb.Message.getFieldWithDefault(msg, 8, 0),
    smallestAppliedMs: jspb.Message.getFieldWithDefault(msg, 9, 0),
    dueDate: jspb.Message.getFieldWithDefault(msg, 10, ""),
    principalAmount: jspb.Message.getFieldWithDefault(msg, 11, 0),
    prepaidCardCount: jspb.Message.getFieldWithDefault(msg, 12, 0),
    firstCardCreatedMs: jspb.Message.getFieldWithDefault(msg, 13, 0),
    ffgOverdueCodeByCredit: jspb.Message.getFieldWithDefault(msg, 14, ""),
    hasOverdueInYear: jspb.Message.getBooleanFieldWithDefault(msg, 15, false),
    hasDefaultByCredit: jspb.Message.getBooleanFieldWithDefault(msg, 16, false),
    ffgDebtCode: jspb.Message.getFieldWithDefault(msg, 17, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.kipp_corp.core.grpc.server.PerCreditData}
 */
proto.com.kipp_corp.core.grpc.server.PerCreditData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.kipp_corp.core.grpc.server.PerCreditData;
  return proto.com.kipp_corp.core.grpc.server.PerCreditData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.kipp_corp.core.grpc.server.PerCreditData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.kipp_corp.core.grpc.server.PerCreditData}
 */
proto.com.kipp_corp.core.grpc.server.PerCreditData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppUserId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUnpaidPrincipalAmount(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreditLimit(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUnpaidFeeAmount(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setOverdueDate(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOverdueMonths(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOverduePrincipalAmount(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOverdueFeeAmount(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSmallestAppliedMs(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setDueDate(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPrincipalAmount(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPrepaidCardCount(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setFirstCardCreatedMs(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setFfgOverdueCodeByCredit(value);
      break;
    case 15:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasOverdueInYear(value);
      break;
    case 16:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasDefaultByCredit(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setFfgDebtCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.kipp_corp.core.grpc.server.PerCreditData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.kipp_corp.core.grpc.server.PerCreditData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.kipp_corp.core.grpc.server.PerCreditData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.kipp_corp.core.grpc.server.PerCreditData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAppUserId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUnpaidPrincipalAmount();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getCreditLimit();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getUnpaidFeeAmount();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getOverdueDate();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getOverdueMonths();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getOverduePrincipalAmount();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getOverdueFeeAmount();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getSmallestAppliedMs();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getDueDate();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getPrincipalAmount();
  if (f !== 0) {
    writer.writeInt64(
      11,
      f
    );
  }
  f = message.getPrepaidCardCount();
  if (f !== 0) {
    writer.writeInt32(
      12,
      f
    );
  }
  f = message.getFirstCardCreatedMs();
  if (f !== 0) {
    writer.writeInt64(
      13,
      f
    );
  }
  f = message.getFfgOverdueCodeByCredit();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getHasOverdueInYear();
  if (f) {
    writer.writeBool(
      15,
      f
    );
  }
  f = message.getHasDefaultByCredit();
  if (f) {
    writer.writeBool(
      16,
      f
    );
  }
  f = message.getFfgDebtCode();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
};


/**
 * optional string app_user_id = 1;
 * @return {string}
 */
proto.com.kipp_corp.core.grpc.server.PerCreditData.prototype.getAppUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.kipp_corp.core.grpc.server.PerCreditData} returns this
 */
proto.com.kipp_corp.core.grpc.server.PerCreditData.prototype.setAppUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 unpaid_principal_amount = 2;
 * @return {number}
 */
proto.com.kipp_corp.core.grpc.server.PerCreditData.prototype.getUnpaidPrincipalAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.kipp_corp.core.grpc.server.PerCreditData} returns this
 */
proto.com.kipp_corp.core.grpc.server.PerCreditData.prototype.setUnpaidPrincipalAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 credit_limit = 3;
 * @return {number}
 */
proto.com.kipp_corp.core.grpc.server.PerCreditData.prototype.getCreditLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.kipp_corp.core.grpc.server.PerCreditData} returns this
 */
proto.com.kipp_corp.core.grpc.server.PerCreditData.prototype.setCreditLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 unpaid_fee_amount = 4;
 * @return {number}
 */
proto.com.kipp_corp.core.grpc.server.PerCreditData.prototype.getUnpaidFeeAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.kipp_corp.core.grpc.server.PerCreditData} returns this
 */
proto.com.kipp_corp.core.grpc.server.PerCreditData.prototype.setUnpaidFeeAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string overdue_date = 5;
 * @return {string}
 */
proto.com.kipp_corp.core.grpc.server.PerCreditData.prototype.getOverdueDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.kipp_corp.core.grpc.server.PerCreditData} returns this
 */
proto.com.kipp_corp.core.grpc.server.PerCreditData.prototype.setOverdueDate = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int32 overdue_months = 6;
 * @return {number}
 */
proto.com.kipp_corp.core.grpc.server.PerCreditData.prototype.getOverdueMonths = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.kipp_corp.core.grpc.server.PerCreditData} returns this
 */
proto.com.kipp_corp.core.grpc.server.PerCreditData.prototype.setOverdueMonths = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int64 overdue_principal_amount = 7;
 * @return {number}
 */
proto.com.kipp_corp.core.grpc.server.PerCreditData.prototype.getOverduePrincipalAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.kipp_corp.core.grpc.server.PerCreditData} returns this
 */
proto.com.kipp_corp.core.grpc.server.PerCreditData.prototype.setOverduePrincipalAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int64 overdue_fee_amount = 8;
 * @return {number}
 */
proto.com.kipp_corp.core.grpc.server.PerCreditData.prototype.getOverdueFeeAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.kipp_corp.core.grpc.server.PerCreditData} returns this
 */
proto.com.kipp_corp.core.grpc.server.PerCreditData.prototype.setOverdueFeeAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int64 smallest_applied_ms = 9;
 * @return {number}
 */
proto.com.kipp_corp.core.grpc.server.PerCreditData.prototype.getSmallestAppliedMs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.kipp_corp.core.grpc.server.PerCreditData} returns this
 */
proto.com.kipp_corp.core.grpc.server.PerCreditData.prototype.setSmallestAppliedMs = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional string due_date = 10;
 * @return {string}
 */
proto.com.kipp_corp.core.grpc.server.PerCreditData.prototype.getDueDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.kipp_corp.core.grpc.server.PerCreditData} returns this
 */
proto.com.kipp_corp.core.grpc.server.PerCreditData.prototype.setDueDate = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional int64 principal_amount = 11;
 * @return {number}
 */
proto.com.kipp_corp.core.grpc.server.PerCreditData.prototype.getPrincipalAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.kipp_corp.core.grpc.server.PerCreditData} returns this
 */
proto.com.kipp_corp.core.grpc.server.PerCreditData.prototype.setPrincipalAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional int32 prepaid_card_count = 12;
 * @return {number}
 */
proto.com.kipp_corp.core.grpc.server.PerCreditData.prototype.getPrepaidCardCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.kipp_corp.core.grpc.server.PerCreditData} returns this
 */
proto.com.kipp_corp.core.grpc.server.PerCreditData.prototype.setPrepaidCardCount = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional int64 first_card_created_ms = 13;
 * @return {number}
 */
proto.com.kipp_corp.core.grpc.server.PerCreditData.prototype.getFirstCardCreatedMs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.kipp_corp.core.grpc.server.PerCreditData} returns this
 */
proto.com.kipp_corp.core.grpc.server.PerCreditData.prototype.setFirstCardCreatedMs = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional string ffg_overdue_code_by_credit = 14;
 * @return {string}
 */
proto.com.kipp_corp.core.grpc.server.PerCreditData.prototype.getFfgOverdueCodeByCredit = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.kipp_corp.core.grpc.server.PerCreditData} returns this
 */
proto.com.kipp_corp.core.grpc.server.PerCreditData.prototype.setFfgOverdueCodeByCredit = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional bool has_overdue_in_year = 15;
 * @return {boolean}
 */
proto.com.kipp_corp.core.grpc.server.PerCreditData.prototype.getHasOverdueInYear = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 15, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.kipp_corp.core.grpc.server.PerCreditData} returns this
 */
proto.com.kipp_corp.core.grpc.server.PerCreditData.prototype.setHasOverdueInYear = function(value) {
  return jspb.Message.setProto3BooleanField(this, 15, value);
};


/**
 * optional bool has_default_by_credit = 16;
 * @return {boolean}
 */
proto.com.kipp_corp.core.grpc.server.PerCreditData.prototype.getHasDefaultByCredit = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 16, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.kipp_corp.core.grpc.server.PerCreditData} returns this
 */
proto.com.kipp_corp.core.grpc.server.PerCreditData.prototype.setHasDefaultByCredit = function(value) {
  return jspb.Message.setProto3BooleanField(this, 16, value);
};


/**
 * optional string ffg_debt_code = 17;
 * @return {string}
 */
proto.com.kipp_corp.core.grpc.server.PerCreditData.prototype.getFfgDebtCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.kipp_corp.core.grpc.server.PerCreditData} returns this
 */
proto.com.kipp_corp.core.grpc.server.PerCreditData.prototype.setFfgDebtCode = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.kipp_corp.core.grpc.server.CollectionData.prototype.toObject = function(opt_includeInstance) {
  return proto.com.kipp_corp.core.grpc.server.CollectionData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.kipp_corp.core.grpc.server.CollectionData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.kipp_corp.core.grpc.server.CollectionData.toObject = function(includeInstance, msg) {
  var f, obj = {
    appUserId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    debtId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    collectedMs: jspb.Message.getFieldWithDefault(msg, 3, 0),
    principalAmount: jspb.Message.getFieldWithDefault(msg, 4, 0),
    collectedFeeAmount: jspb.Message.getFieldWithDefault(msg, 5, 0),
    collectedPrincipalAmount: jspb.Message.getFieldWithDefault(msg, 6, 0),
    isDefault: jspb.Message.getBooleanFieldWithDefault(msg, 7, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.kipp_corp.core.grpc.server.CollectionData}
 */
proto.com.kipp_corp.core.grpc.server.CollectionData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.kipp_corp.core.grpc.server.CollectionData;
  return proto.com.kipp_corp.core.grpc.server.CollectionData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.kipp_corp.core.grpc.server.CollectionData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.kipp_corp.core.grpc.server.CollectionData}
 */
proto.com.kipp_corp.core.grpc.server.CollectionData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppUserId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDebtId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCollectedMs(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPrincipalAmount(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCollectedFeeAmount(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCollectedPrincipalAmount(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsDefault(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.kipp_corp.core.grpc.server.CollectionData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.kipp_corp.core.grpc.server.CollectionData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.kipp_corp.core.grpc.server.CollectionData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.kipp_corp.core.grpc.server.CollectionData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAppUserId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDebtId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCollectedMs();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getPrincipalAmount();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getCollectedFeeAmount();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getCollectedPrincipalAmount();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getIsDefault();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
};


/**
 * optional string app_user_id = 1;
 * @return {string}
 */
proto.com.kipp_corp.core.grpc.server.CollectionData.prototype.getAppUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.kipp_corp.core.grpc.server.CollectionData} returns this
 */
proto.com.kipp_corp.core.grpc.server.CollectionData.prototype.setAppUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string debt_id = 2;
 * @return {string}
 */
proto.com.kipp_corp.core.grpc.server.CollectionData.prototype.getDebtId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.kipp_corp.core.grpc.server.CollectionData} returns this
 */
proto.com.kipp_corp.core.grpc.server.CollectionData.prototype.setDebtId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 collected_ms = 3;
 * @return {number}
 */
proto.com.kipp_corp.core.grpc.server.CollectionData.prototype.getCollectedMs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.kipp_corp.core.grpc.server.CollectionData} returns this
 */
proto.com.kipp_corp.core.grpc.server.CollectionData.prototype.setCollectedMs = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 principal_amount = 4;
 * @return {number}
 */
proto.com.kipp_corp.core.grpc.server.CollectionData.prototype.getPrincipalAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.kipp_corp.core.grpc.server.CollectionData} returns this
 */
proto.com.kipp_corp.core.grpc.server.CollectionData.prototype.setPrincipalAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 collected_fee_amount = 5;
 * @return {number}
 */
proto.com.kipp_corp.core.grpc.server.CollectionData.prototype.getCollectedFeeAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.kipp_corp.core.grpc.server.CollectionData} returns this
 */
proto.com.kipp_corp.core.grpc.server.CollectionData.prototype.setCollectedFeeAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 collected_principal_amount = 6;
 * @return {number}
 */
proto.com.kipp_corp.core.grpc.server.CollectionData.prototype.getCollectedPrincipalAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.kipp_corp.core.grpc.server.CollectionData} returns this
 */
proto.com.kipp_corp.core.grpc.server.CollectionData.prototype.setCollectedPrincipalAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional bool is_default = 7;
 * @return {boolean}
 */
proto.com.kipp_corp.core.grpc.server.CollectionData.prototype.getIsDefault = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.kipp_corp.core.grpc.server.CollectionData} returns this
 */
proto.com.kipp_corp.core.grpc.server.CollectionData.prototype.setIsDefault = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.kipp_corp.core.grpc.server.PostPayScreeningRuleSetSetting.repeatedFields_ = [14,15];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.kipp_corp.core.grpc.server.PostPayScreeningRuleSetSetting.prototype.toObject = function(opt_includeInstance) {
  return proto.com.kipp_corp.core.grpc.server.PostPayScreeningRuleSetSetting.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.kipp_corp.core.grpc.server.PostPayScreeningRuleSetSetting} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.kipp_corp.core.grpc.server.PostPayScreeningRuleSetSetting.toObject = function(includeInstance, msg) {
  var f, obj = {
    rejectThresholdScore: jspb.Message.getFieldWithDefault(msg, 1, 0),
    notIdentityVerifiedScore: jspb.Message.getFieldWithDefault(msg, 2, 0),
    duplicateDeviceIdentifierScore: jspb.Message.getFieldWithDefault(msg, 3, 0),
    soonAfterPartnerRegistrationScore: jspb.Message.getFieldWithDefault(msg, 4, 0),
    mobilePhoneNumberInSuspectedNumberListScore: jspb.Message.getFieldWithDefault(msg, 5, 0),
    duplicateIpScore: jspb.Message.getFieldWithDefault(msg, 6, 0),
    deviceLanguageNotIntendedScore: jspb.Message.getFieldWithDefault(msg, 7, 0),
    emailDomainNotTrustedScore: jspb.Message.getFieldWithDefault(msg, 8, 0),
    duplicateNameKanaScore: jspb.Message.getFieldWithDefault(msg, 9, 0),
    lastIpOverseasScore: jspb.Message.getFieldWithDefault(msg, 10, 0),
    nameKanjiNotContainKanjiScore: jspb.Message.getFieldWithDefault(msg, 11, 0),
    oldAgeScore: jspb.Message.getFieldWithDefault(msg, 16, 0),
    youngAgeScore: jspb.Message.getFieldWithDefault(msg, 18, 0),
    soonAfterPartnerRegistrationThresholdMs: jspb.Message.getFieldWithDefault(msg, 12, 0),
    duplicateIpCountThreshold: jspb.Message.getFieldWithDefault(msg, 13, 0),
    intendedLanguagesList: (f = jspb.Message.getRepeatedField(msg, 14)) == null ? undefined : f,
    trustedEmailDomainsList: (f = jspb.Message.getRepeatedField(msg, 15)) == null ? undefined : f,
    oldAgeThreshold: jspb.Message.getFieldWithDefault(msg, 17, 0),
    youngAgeThreshold: jspb.Message.getFieldWithDefault(msg, 19, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.kipp_corp.core.grpc.server.PostPayScreeningRuleSetSetting}
 */
proto.com.kipp_corp.core.grpc.server.PostPayScreeningRuleSetSetting.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.kipp_corp.core.grpc.server.PostPayScreeningRuleSetSetting;
  return proto.com.kipp_corp.core.grpc.server.PostPayScreeningRuleSetSetting.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.kipp_corp.core.grpc.server.PostPayScreeningRuleSetSetting} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.kipp_corp.core.grpc.server.PostPayScreeningRuleSetSetting}
 */
proto.com.kipp_corp.core.grpc.server.PostPayScreeningRuleSetSetting.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRejectThresholdScore(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setNotIdentityVerifiedScore(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDuplicateDeviceIdentifierScore(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSoonAfterPartnerRegistrationScore(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMobilePhoneNumberInSuspectedNumberListScore(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDuplicateIpScore(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDeviceLanguageNotIntendedScore(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setEmailDomainNotTrustedScore(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDuplicateNameKanaScore(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLastIpOverseasScore(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setNameKanjiNotContainKanjiScore(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setOldAgeScore(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setYoungAgeScore(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setSoonAfterPartnerRegistrationThresholdMs(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setDuplicateIpCountThreshold(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.addIntendedLanguages(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.addTrustedEmailDomains(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setOldAgeThreshold(value);
      break;
    case 19:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setYoungAgeThreshold(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.kipp_corp.core.grpc.server.PostPayScreeningRuleSetSetting.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.kipp_corp.core.grpc.server.PostPayScreeningRuleSetSetting.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.kipp_corp.core.grpc.server.PostPayScreeningRuleSetSetting} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.kipp_corp.core.grpc.server.PostPayScreeningRuleSetSetting.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRejectThresholdScore();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getNotIdentityVerifiedScore();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getDuplicateDeviceIdentifierScore();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getSoonAfterPartnerRegistrationScore();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getMobilePhoneNumberInSuspectedNumberListScore();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getDuplicateIpScore();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getDeviceLanguageNotIntendedScore();
  if (f !== 0) {
    writer.writeUint32(
      7,
      f
    );
  }
  f = message.getEmailDomainNotTrustedScore();
  if (f !== 0) {
    writer.writeUint32(
      8,
      f
    );
  }
  f = message.getDuplicateNameKanaScore();
  if (f !== 0) {
    writer.writeUint32(
      9,
      f
    );
  }
  f = message.getLastIpOverseasScore();
  if (f !== 0) {
    writer.writeUint32(
      10,
      f
    );
  }
  f = message.getNameKanjiNotContainKanjiScore();
  if (f !== 0) {
    writer.writeUint32(
      11,
      f
    );
  }
  f = message.getOldAgeScore();
  if (f !== 0) {
    writer.writeUint32(
      16,
      f
    );
  }
  f = message.getYoungAgeScore();
  if (f !== 0) {
    writer.writeUint32(
      18,
      f
    );
  }
  f = message.getSoonAfterPartnerRegistrationThresholdMs();
  if (f !== 0) {
    writer.writeUint64(
      12,
      f
    );
  }
  f = message.getDuplicateIpCountThreshold();
  if (f !== 0) {
    writer.writeUint64(
      13,
      f
    );
  }
  f = message.getIntendedLanguagesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      14,
      f
    );
  }
  f = message.getTrustedEmailDomainsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      15,
      f
    );
  }
  f = message.getOldAgeThreshold();
  if (f !== 0) {
    writer.writeUint32(
      17,
      f
    );
  }
  f = message.getYoungAgeThreshold();
  if (f !== 0) {
    writer.writeUint32(
      19,
      f
    );
  }
};


/**
 * optional uint32 reject_threshold_score = 1;
 * @return {number}
 */
proto.com.kipp_corp.core.grpc.server.PostPayScreeningRuleSetSetting.prototype.getRejectThresholdScore = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.kipp_corp.core.grpc.server.PostPayScreeningRuleSetSetting} returns this
 */
proto.com.kipp_corp.core.grpc.server.PostPayScreeningRuleSetSetting.prototype.setRejectThresholdScore = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 not_identity_verified_score = 2;
 * @return {number}
 */
proto.com.kipp_corp.core.grpc.server.PostPayScreeningRuleSetSetting.prototype.getNotIdentityVerifiedScore = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.kipp_corp.core.grpc.server.PostPayScreeningRuleSetSetting} returns this
 */
proto.com.kipp_corp.core.grpc.server.PostPayScreeningRuleSetSetting.prototype.setNotIdentityVerifiedScore = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 duplicate_device_identifier_score = 3;
 * @return {number}
 */
proto.com.kipp_corp.core.grpc.server.PostPayScreeningRuleSetSetting.prototype.getDuplicateDeviceIdentifierScore = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.kipp_corp.core.grpc.server.PostPayScreeningRuleSetSetting} returns this
 */
proto.com.kipp_corp.core.grpc.server.PostPayScreeningRuleSetSetting.prototype.setDuplicateDeviceIdentifierScore = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 soon_after_partner_registration_score = 4;
 * @return {number}
 */
proto.com.kipp_corp.core.grpc.server.PostPayScreeningRuleSetSetting.prototype.getSoonAfterPartnerRegistrationScore = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.kipp_corp.core.grpc.server.PostPayScreeningRuleSetSetting} returns this
 */
proto.com.kipp_corp.core.grpc.server.PostPayScreeningRuleSetSetting.prototype.setSoonAfterPartnerRegistrationScore = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint32 mobile_phone_number_in_suspected_number_list_score = 5;
 * @return {number}
 */
proto.com.kipp_corp.core.grpc.server.PostPayScreeningRuleSetSetting.prototype.getMobilePhoneNumberInSuspectedNumberListScore = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.kipp_corp.core.grpc.server.PostPayScreeningRuleSetSetting} returns this
 */
proto.com.kipp_corp.core.grpc.server.PostPayScreeningRuleSetSetting.prototype.setMobilePhoneNumberInSuspectedNumberListScore = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional uint32 duplicate_ip_score = 6;
 * @return {number}
 */
proto.com.kipp_corp.core.grpc.server.PostPayScreeningRuleSetSetting.prototype.getDuplicateIpScore = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.kipp_corp.core.grpc.server.PostPayScreeningRuleSetSetting} returns this
 */
proto.com.kipp_corp.core.grpc.server.PostPayScreeningRuleSetSetting.prototype.setDuplicateIpScore = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional uint32 device_language_not_intended_score = 7;
 * @return {number}
 */
proto.com.kipp_corp.core.grpc.server.PostPayScreeningRuleSetSetting.prototype.getDeviceLanguageNotIntendedScore = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.kipp_corp.core.grpc.server.PostPayScreeningRuleSetSetting} returns this
 */
proto.com.kipp_corp.core.grpc.server.PostPayScreeningRuleSetSetting.prototype.setDeviceLanguageNotIntendedScore = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional uint32 email_domain_not_trusted_score = 8;
 * @return {number}
 */
proto.com.kipp_corp.core.grpc.server.PostPayScreeningRuleSetSetting.prototype.getEmailDomainNotTrustedScore = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.kipp_corp.core.grpc.server.PostPayScreeningRuleSetSetting} returns this
 */
proto.com.kipp_corp.core.grpc.server.PostPayScreeningRuleSetSetting.prototype.setEmailDomainNotTrustedScore = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional uint32 duplicate_name_kana_score = 9;
 * @return {number}
 */
proto.com.kipp_corp.core.grpc.server.PostPayScreeningRuleSetSetting.prototype.getDuplicateNameKanaScore = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.kipp_corp.core.grpc.server.PostPayScreeningRuleSetSetting} returns this
 */
proto.com.kipp_corp.core.grpc.server.PostPayScreeningRuleSetSetting.prototype.setDuplicateNameKanaScore = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional uint32 last_ip_overseas_score = 10;
 * @return {number}
 */
proto.com.kipp_corp.core.grpc.server.PostPayScreeningRuleSetSetting.prototype.getLastIpOverseasScore = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.kipp_corp.core.grpc.server.PostPayScreeningRuleSetSetting} returns this
 */
proto.com.kipp_corp.core.grpc.server.PostPayScreeningRuleSetSetting.prototype.setLastIpOverseasScore = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional uint32 name_kanji_not_contain_kanji_score = 11;
 * @return {number}
 */
proto.com.kipp_corp.core.grpc.server.PostPayScreeningRuleSetSetting.prototype.getNameKanjiNotContainKanjiScore = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.kipp_corp.core.grpc.server.PostPayScreeningRuleSetSetting} returns this
 */
proto.com.kipp_corp.core.grpc.server.PostPayScreeningRuleSetSetting.prototype.setNameKanjiNotContainKanjiScore = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional uint32 old_age_score = 16;
 * @return {number}
 */
proto.com.kipp_corp.core.grpc.server.PostPayScreeningRuleSetSetting.prototype.getOldAgeScore = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.kipp_corp.core.grpc.server.PostPayScreeningRuleSetSetting} returns this
 */
proto.com.kipp_corp.core.grpc.server.PostPayScreeningRuleSetSetting.prototype.setOldAgeScore = function(value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * optional uint32 young_age_score = 18;
 * @return {number}
 */
proto.com.kipp_corp.core.grpc.server.PostPayScreeningRuleSetSetting.prototype.getYoungAgeScore = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.kipp_corp.core.grpc.server.PostPayScreeningRuleSetSetting} returns this
 */
proto.com.kipp_corp.core.grpc.server.PostPayScreeningRuleSetSetting.prototype.setYoungAgeScore = function(value) {
  return jspb.Message.setProto3IntField(this, 18, value);
};


/**
 * optional uint64 soon_after_partner_registration_threshold_ms = 12;
 * @return {number}
 */
proto.com.kipp_corp.core.grpc.server.PostPayScreeningRuleSetSetting.prototype.getSoonAfterPartnerRegistrationThresholdMs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.kipp_corp.core.grpc.server.PostPayScreeningRuleSetSetting} returns this
 */
proto.com.kipp_corp.core.grpc.server.PostPayScreeningRuleSetSetting.prototype.setSoonAfterPartnerRegistrationThresholdMs = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional uint64 duplicate_ip_count_threshold = 13;
 * @return {number}
 */
proto.com.kipp_corp.core.grpc.server.PostPayScreeningRuleSetSetting.prototype.getDuplicateIpCountThreshold = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.kipp_corp.core.grpc.server.PostPayScreeningRuleSetSetting} returns this
 */
proto.com.kipp_corp.core.grpc.server.PostPayScreeningRuleSetSetting.prototype.setDuplicateIpCountThreshold = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * repeated string intended_languages = 14;
 * @return {!Array<string>}
 */
proto.com.kipp_corp.core.grpc.server.PostPayScreeningRuleSetSetting.prototype.getIntendedLanguagesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 14));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.com.kipp_corp.core.grpc.server.PostPayScreeningRuleSetSetting} returns this
 */
proto.com.kipp_corp.core.grpc.server.PostPayScreeningRuleSetSetting.prototype.setIntendedLanguagesList = function(value) {
  return jspb.Message.setField(this, 14, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.com.kipp_corp.core.grpc.server.PostPayScreeningRuleSetSetting} returns this
 */
proto.com.kipp_corp.core.grpc.server.PostPayScreeningRuleSetSetting.prototype.addIntendedLanguages = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 14, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.kipp_corp.core.grpc.server.PostPayScreeningRuleSetSetting} returns this
 */
proto.com.kipp_corp.core.grpc.server.PostPayScreeningRuleSetSetting.prototype.clearIntendedLanguagesList = function() {
  return this.setIntendedLanguagesList([]);
};


/**
 * repeated string trusted_email_domains = 15;
 * @return {!Array<string>}
 */
proto.com.kipp_corp.core.grpc.server.PostPayScreeningRuleSetSetting.prototype.getTrustedEmailDomainsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 15));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.com.kipp_corp.core.grpc.server.PostPayScreeningRuleSetSetting} returns this
 */
proto.com.kipp_corp.core.grpc.server.PostPayScreeningRuleSetSetting.prototype.setTrustedEmailDomainsList = function(value) {
  return jspb.Message.setField(this, 15, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.com.kipp_corp.core.grpc.server.PostPayScreeningRuleSetSetting} returns this
 */
proto.com.kipp_corp.core.grpc.server.PostPayScreeningRuleSetSetting.prototype.addTrustedEmailDomains = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 15, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.kipp_corp.core.grpc.server.PostPayScreeningRuleSetSetting} returns this
 */
proto.com.kipp_corp.core.grpc.server.PostPayScreeningRuleSetSetting.prototype.clearTrustedEmailDomainsList = function() {
  return this.setTrustedEmailDomainsList([]);
};


/**
 * optional uint32 old_age_threshold = 17;
 * @return {number}
 */
proto.com.kipp_corp.core.grpc.server.PostPayScreeningRuleSetSetting.prototype.getOldAgeThreshold = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.kipp_corp.core.grpc.server.PostPayScreeningRuleSetSetting} returns this
 */
proto.com.kipp_corp.core.grpc.server.PostPayScreeningRuleSetSetting.prototype.setOldAgeThreshold = function(value) {
  return jspb.Message.setProto3IntField(this, 17, value);
};


/**
 * optional uint32 young_age_threshold = 19;
 * @return {number}
 */
proto.com.kipp_corp.core.grpc.server.PostPayScreeningRuleSetSetting.prototype.getYoungAgeThreshold = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.kipp_corp.core.grpc.server.PostPayScreeningRuleSetSetting} returns this
 */
proto.com.kipp_corp.core.grpc.server.PostPayScreeningRuleSetSetting.prototype.setYoungAgeThreshold = function(value) {
  return jspb.Message.setProto3IntField(this, 19, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.kipp_corp.core.grpc.server.PostPaidTopUpFeeSetting.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.kipp_corp.core.grpc.server.PostPaidTopUpFeeSetting.prototype.toObject = function(opt_includeInstance) {
  return proto.com.kipp_corp.core.grpc.server.PostPaidTopUpFeeSetting.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.kipp_corp.core.grpc.server.PostPaidTopUpFeeSetting} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.kipp_corp.core.grpc.server.PostPaidTopUpFeeSetting.toObject = function(includeInstance, msg) {
  var f, obj = {
    generalFeeTableList: jspb.Message.toObjectList(msg.getGeneralFeeTableList(),
    proto.com.kipp_corp.core.grpc.server.FeeTableItem.toObject, includeInstance),
    partnerFeeTablesList: jspb.Message.toObjectList(msg.getPartnerFeeTablesList(),
    proto.com.kipp_corp.core.grpc.server.PartnerFeeTable.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.kipp_corp.core.grpc.server.PostPaidTopUpFeeSetting}
 */
proto.com.kipp_corp.core.grpc.server.PostPaidTopUpFeeSetting.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.kipp_corp.core.grpc.server.PostPaidTopUpFeeSetting;
  return proto.com.kipp_corp.core.grpc.server.PostPaidTopUpFeeSetting.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.kipp_corp.core.grpc.server.PostPaidTopUpFeeSetting} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.kipp_corp.core.grpc.server.PostPaidTopUpFeeSetting}
 */
proto.com.kipp_corp.core.grpc.server.PostPaidTopUpFeeSetting.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.kipp_corp.core.grpc.server.FeeTableItem;
      reader.readMessage(value,proto.com.kipp_corp.core.grpc.server.FeeTableItem.deserializeBinaryFromReader);
      msg.addGeneralFeeTable(value);
      break;
    case 2:
      var value = new proto.com.kipp_corp.core.grpc.server.PartnerFeeTable;
      reader.readMessage(value,proto.com.kipp_corp.core.grpc.server.PartnerFeeTable.deserializeBinaryFromReader);
      msg.addPartnerFeeTables(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.kipp_corp.core.grpc.server.PostPaidTopUpFeeSetting.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.kipp_corp.core.grpc.server.PostPaidTopUpFeeSetting.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.kipp_corp.core.grpc.server.PostPaidTopUpFeeSetting} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.kipp_corp.core.grpc.server.PostPaidTopUpFeeSetting.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGeneralFeeTableList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.com.kipp_corp.core.grpc.server.FeeTableItem.serializeBinaryToWriter
    );
  }
  f = message.getPartnerFeeTablesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.com.kipp_corp.core.grpc.server.PartnerFeeTable.serializeBinaryToWriter
    );
  }
};


/**
 * repeated FeeTableItem general_fee_table = 1;
 * @return {!Array<!proto.com.kipp_corp.core.grpc.server.FeeTableItem>}
 */
proto.com.kipp_corp.core.grpc.server.PostPaidTopUpFeeSetting.prototype.getGeneralFeeTableList = function() {
  return /** @type{!Array<!proto.com.kipp_corp.core.grpc.server.FeeTableItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.kipp_corp.core.grpc.server.FeeTableItem, 1));
};


/**
 * @param {!Array<!proto.com.kipp_corp.core.grpc.server.FeeTableItem>} value
 * @return {!proto.com.kipp_corp.core.grpc.server.PostPaidTopUpFeeSetting} returns this
*/
proto.com.kipp_corp.core.grpc.server.PostPaidTopUpFeeSetting.prototype.setGeneralFeeTableList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.com.kipp_corp.core.grpc.server.FeeTableItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.kipp_corp.core.grpc.server.FeeTableItem}
 */
proto.com.kipp_corp.core.grpc.server.PostPaidTopUpFeeSetting.prototype.addGeneralFeeTable = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.com.kipp_corp.core.grpc.server.FeeTableItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.kipp_corp.core.grpc.server.PostPaidTopUpFeeSetting} returns this
 */
proto.com.kipp_corp.core.grpc.server.PostPaidTopUpFeeSetting.prototype.clearGeneralFeeTableList = function() {
  return this.setGeneralFeeTableList([]);
};


/**
 * repeated PartnerFeeTable partner_fee_tables = 2;
 * @return {!Array<!proto.com.kipp_corp.core.grpc.server.PartnerFeeTable>}
 */
proto.com.kipp_corp.core.grpc.server.PostPaidTopUpFeeSetting.prototype.getPartnerFeeTablesList = function() {
  return /** @type{!Array<!proto.com.kipp_corp.core.grpc.server.PartnerFeeTable>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.kipp_corp.core.grpc.server.PartnerFeeTable, 2));
};


/**
 * @param {!Array<!proto.com.kipp_corp.core.grpc.server.PartnerFeeTable>} value
 * @return {!proto.com.kipp_corp.core.grpc.server.PostPaidTopUpFeeSetting} returns this
*/
proto.com.kipp_corp.core.grpc.server.PostPaidTopUpFeeSetting.prototype.setPartnerFeeTablesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.com.kipp_corp.core.grpc.server.PartnerFeeTable=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.kipp_corp.core.grpc.server.PartnerFeeTable}
 */
proto.com.kipp_corp.core.grpc.server.PostPaidTopUpFeeSetting.prototype.addPartnerFeeTables = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.com.kipp_corp.core.grpc.server.PartnerFeeTable, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.kipp_corp.core.grpc.server.PostPaidTopUpFeeSetting} returns this
 */
proto.com.kipp_corp.core.grpc.server.PostPaidTopUpFeeSetting.prototype.clearPartnerFeeTablesList = function() {
  return this.setPartnerFeeTablesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.kipp_corp.core.grpc.server.PartnerFeeTable.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.kipp_corp.core.grpc.server.PartnerFeeTable.prototype.toObject = function(opt_includeInstance) {
  return proto.com.kipp_corp.core.grpc.server.PartnerFeeTable.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.kipp_corp.core.grpc.server.PartnerFeeTable} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.kipp_corp.core.grpc.server.PartnerFeeTable.toObject = function(includeInstance, msg) {
  var f, obj = {
    partnerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    tableList: jspb.Message.toObjectList(msg.getTableList(),
    proto.com.kipp_corp.core.grpc.server.FeeTableItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.kipp_corp.core.grpc.server.PartnerFeeTable}
 */
proto.com.kipp_corp.core.grpc.server.PartnerFeeTable.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.kipp_corp.core.grpc.server.PartnerFeeTable;
  return proto.com.kipp_corp.core.grpc.server.PartnerFeeTable.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.kipp_corp.core.grpc.server.PartnerFeeTable} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.kipp_corp.core.grpc.server.PartnerFeeTable}
 */
proto.com.kipp_corp.core.grpc.server.PartnerFeeTable.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPartnerId(value);
      break;
    case 2:
      var value = new proto.com.kipp_corp.core.grpc.server.FeeTableItem;
      reader.readMessage(value,proto.com.kipp_corp.core.grpc.server.FeeTableItem.deserializeBinaryFromReader);
      msg.addTable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.kipp_corp.core.grpc.server.PartnerFeeTable.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.kipp_corp.core.grpc.server.PartnerFeeTable.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.kipp_corp.core.grpc.server.PartnerFeeTable} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.kipp_corp.core.grpc.server.PartnerFeeTable.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPartnerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTableList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.com.kipp_corp.core.grpc.server.FeeTableItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional string partner_id = 1;
 * @return {string}
 */
proto.com.kipp_corp.core.grpc.server.PartnerFeeTable.prototype.getPartnerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.kipp_corp.core.grpc.server.PartnerFeeTable} returns this
 */
proto.com.kipp_corp.core.grpc.server.PartnerFeeTable.prototype.setPartnerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated FeeTableItem table = 2;
 * @return {!Array<!proto.com.kipp_corp.core.grpc.server.FeeTableItem>}
 */
proto.com.kipp_corp.core.grpc.server.PartnerFeeTable.prototype.getTableList = function() {
  return /** @type{!Array<!proto.com.kipp_corp.core.grpc.server.FeeTableItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.kipp_corp.core.grpc.server.FeeTableItem, 2));
};


/**
 * @param {!Array<!proto.com.kipp_corp.core.grpc.server.FeeTableItem>} value
 * @return {!proto.com.kipp_corp.core.grpc.server.PartnerFeeTable} returns this
*/
proto.com.kipp_corp.core.grpc.server.PartnerFeeTable.prototype.setTableList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.com.kipp_corp.core.grpc.server.FeeTableItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.kipp_corp.core.grpc.server.FeeTableItem}
 */
proto.com.kipp_corp.core.grpc.server.PartnerFeeTable.prototype.addTable = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.com.kipp_corp.core.grpc.server.FeeTableItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.kipp_corp.core.grpc.server.PartnerFeeTable} returns this
 */
proto.com.kipp_corp.core.grpc.server.PartnerFeeTable.prototype.clearTableList = function() {
  return this.setTableList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.kipp_corp.core.grpc.server.FeeTableItem.prototype.toObject = function(opt_includeInstance) {
  return proto.com.kipp_corp.core.grpc.server.FeeTableItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.kipp_corp.core.grpc.server.FeeTableItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.kipp_corp.core.grpc.server.FeeTableItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    amountFrom: jspb.Message.getFieldWithDefault(msg, 1, 0),
    amountTo: jspb.Message.getFieldWithDefault(msg, 2, 0),
    feeAmount: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.kipp_corp.core.grpc.server.FeeTableItem}
 */
proto.com.kipp_corp.core.grpc.server.FeeTableItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.kipp_corp.core.grpc.server.FeeTableItem;
  return proto.com.kipp_corp.core.grpc.server.FeeTableItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.kipp_corp.core.grpc.server.FeeTableItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.kipp_corp.core.grpc.server.FeeTableItem}
 */
proto.com.kipp_corp.core.grpc.server.FeeTableItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setAmountFrom(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setAmountTo(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setFeeAmount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.kipp_corp.core.grpc.server.FeeTableItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.kipp_corp.core.grpc.server.FeeTableItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.kipp_corp.core.grpc.server.FeeTableItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.kipp_corp.core.grpc.server.FeeTableItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAmountFrom();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getAmountTo();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getFeeAmount();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
};


/**
 * optional uint64 amount_from = 1;
 * @return {number}
 */
proto.com.kipp_corp.core.grpc.server.FeeTableItem.prototype.getAmountFrom = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.kipp_corp.core.grpc.server.FeeTableItem} returns this
 */
proto.com.kipp_corp.core.grpc.server.FeeTableItem.prototype.setAmountFrom = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint64 amount_to = 2;
 * @return {number}
 */
proto.com.kipp_corp.core.grpc.server.FeeTableItem.prototype.getAmountTo = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.kipp_corp.core.grpc.server.FeeTableItem} returns this
 */
proto.com.kipp_corp.core.grpc.server.FeeTableItem.prototype.setAmountTo = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint64 fee_amount = 3;
 * @return {number}
 */
proto.com.kipp_corp.core.grpc.server.FeeTableItem.prototype.getFeeAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.kipp_corp.core.grpc.server.FeeTableItem} returns this
 */
proto.com.kipp_corp.core.grpc.server.FeeTableItem.prototype.setFeeAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * @enum {number}
 */
proto.com.kipp_corp.core.grpc.server.DebtCancelType = {
  DEBT_CANCEL_TYPE_INVALID: 0,
  DEBT_CANCEL_TYPE_CANCEL_UNINTENTIONAL_POSTPAY_APPLICATION: 1,
  DEBT_CANCEL_TYPE_REGISTER_OPERATIONAL_RECEIPT: 2
};

goog.object.extend(exports, proto.com.kipp_corp.core.grpc.server);
