import { useRouter } from 'next/router'
import { useEffect } from 'react'

export const useAutoReload = (reload: () => void): void => {
  const router = useRouter()
  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') {
      return
    }

    const handleRouteChange = async (): Promise<void> => {
      const nextData = document.querySelector('#__NEXT_DATA__')
      if (!nextData?.textContent) {
        return
      }

      const json: { buildId: string } = JSON.parse(nextData.textContent)
      const response = await fetch(`/_next/static/${json.buildId}/_buildManifest.js`, {
        method: 'HEAD',
        cache: 'no-store',
      })
      // Our production env returns 403 for missing files
      if ([403, 404].includes(response.status)) {
        reload()
      }
    }

    router.events.on('routeChangeStart', handleRouteChange)

    return () => {
      router.events.off('routeChangeStart', handleRouteChange)
    }
  }, [router.events, reload])
}
