import { useContext, useEffect } from 'react'

import DialogConfirmation from 'shared/components/DialogConfirmation'
import { context as OperationAppContext } from 'shared/context/OperationApp'

import { useOperatorResumeOperator } from '@/hooks/grpc'

const ResumeOperator: React.FC<{
  operator: ElementOf<ListOperators['result']>
  onSuccess: () => void
  onCancel: () => void
}> = ({ operator, onSuccess, onCancel }) => {
  const appContext = useContext(OperationAppContext)
  const { call, response } = useOperatorResumeOperator()

  const suspend = () => {
    call({ targetOperatorId: operator.operatorId })
  }

  const cancel = () => {
    onCancel()
  }

  useEffect(() => {
    if (response && response.error === undefined) {
      appContext.flashMessage(`${operator.name} の凍結を解除しました`, 'success')
      onSuccess()
    }
  }, [appContext, operator, response, onSuccess])

  return (
    <DialogConfirmation
      open
      title="凍結を解除する"
      message={`${
        operator.loginId === null ? operator.name : operator.loginId
      } の利用の凍結を解除します。\nこのオペレーターは管理画面へログインできるようになります。問題はありませんか？`}
      onCancel={cancel}
      onConfirm={suspend}
    />
  )
}

export default ResumeOperator
