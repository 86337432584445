import { gql } from '@apollo/client'

import { OperationRole } from 'shared/grpc/miraibaraiops/ops_common_pb'

export const query = gql`
  query ListPostPayDebts(
    $limit: Int!
    $skip: Int!
    $debtId: String
    $miraibaraiPartnerId: String
    $appUserId: String
    $appliedMs: IntRange
    $dueMs: IntRange
    $principalAmountE3: IntRange
    $paidMsIsDefined: Boolean
    $canceledMsIsDefined: Boolean
    $canceledMs: IntRange
    $cancelType: Int
  ) {
    totalCount: postPayDebtsCount(
      debtId: $debtId
      miraibaraiPartnerId: $miraibaraiPartnerId
      appUserId: $appUserId
      appliedMs: $appliedMs
      dueMs: $dueMs
      principalAmountE3: $principalAmountE3
      paidMsIsDefined: $paidMsIsDefined
      canceledMsIsDefined: $canceledMsIsDefined
      canceledMs: $canceledMs
      cancelType: $cancelType
    )
    result: postPayDebts(
      sort_field: "appliedMs"
      desc: true
      limit: $limit
      skip: $skip
      debtId: $debtId
      miraibaraiPartnerId: $miraibaraiPartnerId
      appUserId: $appUserId
      appliedMs: $appliedMs
      dueMs: $dueMs
      principalAmountE3: $principalAmountE3
      paidMsIsDefined: $paidMsIsDefined
      canceledMsIsDefined: $canceledMsIsDefined
      canceledMs: $canceledMs
      cancelType: $cancelType
    ) {
      debtId
      miraibaraiPartnerId
      principalAmountE3
      feeAmountE3
      appliedMs
      paidMs
      dueMs
      canceledMs
      cancelType {
        value
      }
      miraibaraiAppUser {
        appUserId
        miraibaraiPersonalData {
          nameKanji
        }
      }
      repayment: debtRepaymentAssignments {
        byBankPayPayment: groupedPostPayReturnBankPayPayment {
          repaymentId
          paidMs
        }
        byConvenienceStore: groupedPostPayReturnConvenienceStorePayment {
          repaymentId
          paidMs
        }
        byPartnerBalance: groupedPostPayReturnPartnerBalancePayment {
          repaymentId
          paidMs
        }
      }
    }
  }
`

export const assumedRoles = [OperationRole.OPERATION_ROLE_CUSTOMER_SUPPORT]
