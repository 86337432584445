export const kebabSmsNumber = (phoneNumber: string): string => {
  if (phoneNumber.length !== 11) return phoneNumber
  const [, area, local, num] = phoneNumber.match(/(\d{3})(\d{4})(\d{4})/) ?? [null, phoneNumber]
  return [area, local, num].join('-')
}

export const formatAmountE3 = (amountE3: number): string => {
  return formatAmount(amountE3 / 1000)
}

export const formatAmount = (amount: number): string => {
  return new Intl.NumberFormat('ja-JP').format(amount)
}
