import { FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import { Controller, useForm } from 'react-hook-form'

import { DialogForm } from 'shared/components/DialogForm'

export enum PAYMENT_STATUS {
  ANY,
  PAID,
  UNPAID,
  CANCELED_LEGACY,
  CANCELED,
  CANCELED_OPERATIONAL,
}

interface FilterPostPayDebtsCondition {
  debtId: string
  partnerId: string
  appUserId: string
  appliedStart: string
  appliedEnd: string
  dueStart: string
  dueEnd: string
  principalAmountMin: number | null
  principalAmountMax: number | null
  status: PAYMENT_STATUS
}

const FilterPostPayDebts: React.FC<{
  onSelect: (form: FilterPostPayDebtsCondition) => void
  onCancel: () => void
  defaultValues: FilterPostPayDebtsCondition
}> = ({ onSelect, onCancel, defaultValues }) => {
  const { register, handleSubmit, reset, control } = useForm<FilterPostPayDebtsCondition>({
    mode: 'onSubmit',
    defaultValues,
  })

  const submit = (form: FilterPostPayDebtsCondition) => {
    onSelect(form)
  }

  return (
    <DialogForm
      title="後払いチャージを絞り込む"
      submitButtonTitle="この条件で絞り込む"
      reset={reset}
      onCancel={onCancel}
      onSubmit={handleSubmit(submit)}
    >
      <TextField
        autoFocus
        margin="normal"
        label="Debt ID"
        placeholder="dbt:..."
        type="text"
        fullWidth
        {...register('debtId')}
      />
      <TextField
        margin="normal"
        label="Partner ID"
        placeholder="kyash"
        type="text"
        fullWidth
        {...register('partnerId')}
      />
      <TextField
        margin="normal"
        label="App User ID"
        placeholder="apu:..."
        type="text"
        fullWidth
        {...register('appUserId')}
      />
      <FormControl fullWidth margin="normal">
        <InputLabel id="status">返済ステータス</InputLabel>
        <Controller
          control={control}
          name="status"
          render={({ field }) => (
            <Select {...field} labelId="status" label="返済ステータス">
              <MenuItem value={PAYMENT_STATUS.ANY}>すべて</MenuItem>
              <MenuItem value={PAYMENT_STATUS.PAID}>返済済み</MenuItem>
              <MenuItem value={PAYMENT_STATUS.UNPAID}>未返済</MenuItem>
              <MenuItem value={PAYMENT_STATUS.CANCELED_LEGACY}>取消済み（2022/4/6 変更適用以前）</MenuItem>
              <MenuItem value={PAYMENT_STATUS.CANCELED}>取消済み（2022/4/6 変更適用以降）</MenuItem>
              <MenuItem value={PAYMENT_STATUS.CANCELED_OPERATIONAL}>入金消込済み</MenuItem>
            </Select>
          )}
        />
      </FormControl>
      <Grid container direction="row">
        <Grid container item xs={5}>
          <TextField
            margin="normal"
            label="申請日時（開始）"
            type="date"
            fullWidth
            InputLabelProps={{ shrink: true }}
            {...register('appliedStart')}
          />
        </Grid>
        <Grid container item xs={1} />
        <Grid container item xs={6}>
          <TextField
            margin="normal"
            label="申請日時（終了）"
            type="date"
            fullWidth
            InputLabelProps={{ shrink: true }}
            {...register('appliedEnd')}
          />
        </Grid>
      </Grid>
      <Grid container direction="row">
        <Grid container item xs={5}>
          <TextField
            margin="normal"
            label="返済期限（開始）"
            type="date"
            fullWidth
            InputLabelProps={{ shrink: true }}
            {...register('dueStart')}
          />
        </Grid>
        <Grid container item xs={1} />
        <Grid container item xs={6}>
          <TextField
            margin="normal"
            label="返済期限（終了）"
            type="date"
            fullWidth
            InputLabelProps={{ shrink: true }}
            {...register('dueEnd')}
          />
        </Grid>
      </Grid>
      <Grid container direction="row">
        <Grid container item xs={5}>
          <TextField
            margin="normal"
            label="金額（下限）"
            type="number"
            fullWidth
            InputLabelProps={{ shrink: true }}
            {...register('principalAmountMin')}
          />
        </Grid>
        <Grid container item xs={1} />
        <Grid container item xs={6}>
          <TextField
            margin="normal"
            label="金額（上限）"
            type="number"
            fullWidth
            InputLabelProps={{ shrink: true }}
            {...register('principalAmountMax')}
          />
        </Grid>
      </Grid>
    </DialogForm>
  )
}

export default FilterPostPayDebts
