import * as Sentry from '@sentry/react'

export const initSentry = (): void => {
  if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
    Sentry.init({
      enabled: process.env.NODE_ENV === 'production',
      dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
      environment: process.env.NEXT_PUBLIC_SENTRY_APP_NAME,
      release: `${process.env.NEXT_PUBLIC_COMMIT_SHA}-${process.env.NEXT_PUBLIC_SENTRY_APP_NAME}`,
    })
  }
}
