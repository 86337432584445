import { Checkbox, FormControlLabel, FormGroup, FormLabel } from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'

import { DialogForm } from 'shared/components/DialogForm'
import { context as OperationAppContext } from 'shared/context/OperationApp'
import { AssignRoleRequest } from 'shared/grpc/miraibaraiops/operator_api_pb'
import { OperationRole } from 'shared/grpc/miraibaraiops/ops_common_pb'

import { useOperatorAssignRole } from '@/hooks/grpc'
import { operatorRoleNames } from '@/lib/models'

const requestObjectFrom = (roles: string[][]) => {
  return roles.flatMap((ipRangeIds, index) => {
    return ipRangeIds.map((id) => {
      return { ipRangeId: id, role: index }
    })
  })
}

const EditRoles: React.FC<{
  allIpRanges: ListOperators['allIPRanges']
  operator: ElementOf<ListOperators['result']>
  onSuccess: () => void
  onCancel: () => void
}> = ({ allIpRanges, operator, onSuccess, onCancel }) => {
  const appContext = useContext(OperationAppContext)
  const [roles, setRoles] = useState<string[][]>(
    operator.operatorRoles.reduce(
      (operatorRoles, operator) => {
        operatorRoles[operator.operationRole.value] = [
          ...operatorRoles[operator.operationRole.value],
          operator.ipRangeId,
        ]
        return operatorRoles
      },
      Array(Object.keys(OperationRole).length).fill([]),
    ),
  )
  const { control, handleSubmit, register, reset, setValue } = useForm<AssignRoleRequest.AsObject>({
    mode: 'onSubmit',
    defaultValues: {
      operatorId: operator.operatorId,
      roleAssignmentsList: requestObjectFrom(roles),
    },
  })
  const { call, response } = useOperatorAssignRole()

  useEffect(() => {
    if (!response) return
    if (response.error) {
      console.error(response.error)
    } else {
      appContext.flashMessage(`${operator.name} の拠点や権限を編集しました`, 'success')
      reset()
      onSuccess()
    }
  }, [appContext, operator, response, onSuccess, reset])

  const handleCheck = (index: number, ipRangeId: string, checked: boolean) => {
    const newRoles = roles.map((role, i) => {
      if (index !== i) return role
      if (checked && !role.includes(ipRangeId)) return [...role, ipRangeId]
      if (!checked) return role.filter((r) => r !== ipRangeId)
      return role
    })
    setRoles(newRoles)
    return requestObjectFrom(newRoles)
  }

  return (
    <DialogForm
      title={`${operator.name} の拠点や権限を編集する`}
      submitButtonTitle="保存する"
      reset={reset}
      onCancel={onCancel}
      onSubmit={handleSubmit(call)}
    >
      <input type="hidden" {...register('operatorId')} />
      {/* Using Number() since Object.entries expects 'key: string' */}
      {Object.entries(operatorRoleNames).map(([key, label], index) => {
        return Number(key) === OperationRole.OPERATION_ROLE_INVALID ? (
          <div key={index}></div>
        ) : (
          <FormGroup key={index}>
            <FormLabel component="legend" style={{ marginTop: '1em' }}>
              {label}
            </FormLabel>
            <FormGroup row>
              <Controller
                name="roleAssignmentsList"
                control={control}
                render={({ field }) => (
                  <>
                    {allIpRanges.map((ipRange) => (
                      <FormControlLabel
                        key={`${index}-${ipRange.ipRangeId}`}
                        label={ipRange.ipRangeId}
                        control={
                          <Checkbox
                            {...field}
                            checked={roles[Number(key)].includes(ipRange.ipRangeId)}
                            onChange={(_, checked) => {
                              const newList = handleCheck(Number(key), ipRange.ipRangeId, checked)
                              // TODO: check if react-hook-form is fixed
                              // field.onChange(newList) doesn't persist latest selection correctly
                              // Apparently, single edit isn't persisted
                              // Using setValue explicitly instead as workaround
                              // field.onChange(newList)
                              setValue('roleAssignmentsList', newList)
                            }}
                          />
                        }
                      />
                    ))}
                  </>
                )}
              />
            </FormGroup>
          </FormGroup>
        )
      })}
    </DialogForm>
  )
}

export default EditRoles
