import { AssertionError } from 'assert'
import * as grpcWeb from 'grpc-web'
import { useContext, useState } from 'react'

import { context as OperationAppContext } from 'shared/context/OperationApp'

export const promisifyGrpcClient = <T extends { getError: () => unknown }>(
  curriedGrpcRequest: (c: (err: grpcWeb.RpcError, res: T) => void) => void,
): Promise<T> => {
  return new Promise<T>((resolve, reject) => {
    curriedGrpcRequest((err: grpcWeb.RpcError, res: T) => {
      if (err) {
        reject(err)
        return
      }
      if (res.getError()) {
        reject(res.getError())
        return
      }
      resolve(res)
    })
  })
}

export const requestMetadata = (token: string): Record<string, string> => {
  return {
    'x-kipp-session-token': token,
  }
}

export type GrpcHookType<Param, Response> = () => {
  call: (params: Param) => Promise<void>
  response: Response | undefined
}

export function errorTypeFor(e: unknown): asserts e is { array?: string[] } | { message?: string } {
  if (typeof e !== 'object') {
    throw new AssertionError({ message: 'Unintentional exception message' })
  }
}

export const useGrpc = <
  RequestAsObject extends Record<string, unknown>,
  Response extends { getError: () => unknown; toObject: () => ResponseAsObject },
  ResponseAsObject,
>(
  clientCallback: (
    params: RequestAsObject,
    sessionToken: string,
    cb: (err: grpcWeb.RpcError, res: Response) => void,
  ) => void,
  skipDelayAfterRequest?: boolean,
): { call: (params: RequestAsObject) => Promise<void>; response: ResponseAsObject | undefined; reset: () => void } => {
  const [response, setResponse] = useState<ResponseAsObject>()
  const appContext = useContext(OperationAppContext)

  const call = async (params: RequestAsObject) => {
    try {
      appContext.setLoading(true)
      const sessionToken = appContext.currentOperator?.sessionToken || ''
      const response = await promisifyGrpcClient<Response>((cb) => {
        clientCallback(params, sessionToken, cb)
      })
      if (!skipDelayAfterRequest) await new Promise((resolve) => setTimeout(resolve, 750))
      setResponse(response.toObject())
    } catch (e) {
      errorTypeFor(e)
      const serverError = 'array' in e && e.array?.[0] // consider taking from rejected `response` instead
      const clientError = 'message' in e && e.message ? '通信が正常に行えませんでした' : undefined
      appContext.flashMessage(serverError || clientError || '想定されていないエラーが発生しました', 'error')
      console.error('Failed in gRPC request', e)

      /* Uncomment after hiding unexpected features for low-permission users in Epre
      if (e?.code === grpcWeb.StatusCode.UNAUTHENTICATED) {
        appContext.setCurrentOperator(null)
        appContext.flashMessage('許可されていない操作を行ったためログアウトされました', 'error')
      }
      */
    } finally {
      appContext.setLoading(false)
    }
  }

  const reset = () => setResponse(undefined)

  return { call, response, reset }
}
