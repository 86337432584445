import { gql } from '@apollo/client'

import { OperationRole } from 'shared/grpc/miraibaraiops/ops_common_pb'

export const query = gql`
  query GetMiraibaraiAppUser(
    $appUserId: String!
  ) {
    result: miraibaraiAppUsers(
      appUserId: $appUserId
    ) {
      appUserId
      partnerId
      partnerUserId
      miraibaraiPersonalData {
        nameKanji
        nameKana
        emailAddress
        birthdate
        homePhoneNumber
        mobilePhoneNumber
        gender {
          value
        }
        postalCode
        residentAddress
      }
      occupation {
        value
      }
      usagePurpose {
        value
      }
      partnerUserSignupDate
      createdMs
      identityVerificationCompleted
      postPayCreditLimits {
        creditLimitValue
        calculatedMs
        creditCriteria {
          miraibarai {
            appliedRule
          }
        }
      }
      postPayDebts {
        paidMs
        appliedMs
        canceledMs
        cancelType {
          value
        }
        principalAmountE3
      }
      repaymentsBankPay: groupedPostPayReturnBankPayPayments {
        repaymentId
        createdMs
        paidMs
        totalPrincipalAmountE3
        totalUsageFeeAmountE3
        delinquencyChargeAmountE3
        additionalBillingOperationFeeAmountE3
      }
      repaymentsConvenienceStore: groupedPostPayReturnConvenienceStorePayments {
        repaymentId
        createdMs
        paidMs
        totalPrincipalAmountE3
        totalUsageFeeAmountE3
        delinquencyChargeAmountE3
        additionalBillingOperationFeeAmountE3
      }
      repaymentsPartnerBalance: groupedPostPayReturnPartnerBalancePayments {
        repaymentId
        createdMs
        paidMs
        totalPrincipalAmountE3
        totalUsageFeeAmountE3
        delinquencyChargeAmountE3
        additionalBillingOperationFeeAmountE3
      }
    }
    operatorComments: appUserOperatorComments(
      appUserId: $appUserId
      sort_field: "appUserOperatorCommentId"
      desc: true
    ) {
      appUserOperatorCommentId
      commentedMs
      commentBody
      operator {
        operatorId
        name
      }
    }
  }
`

export const assumedRoles = [OperationRole.OPERATION_ROLE_CUSTOMER_SUPPORT]
