import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import React from 'react'

const DialogConfirmation: React.FC<
  React.PropsWithChildren<{
    title: string
    message: string
    onCancel: () => void
    onConfirm?: () => void
    open: boolean
  }>
> = ({ onCancel, onConfirm, open, title, message, children }) => {
  return (
    <Dialog
      open={open}
      onClose={onCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{message}</DialogContentText>
        {children}
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary" autoFocus>
          キャンセル
        </Button>
        <Button onClick={onConfirm} color="primary" disabled={onConfirm === null}>
          実行する
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DialogConfirmation
