import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  TextField,
} from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import { DialogForm } from 'shared/components/DialogForm'
import { context as OperationAppContext } from 'shared/context/OperationApp'
import { AddOperatorRequest } from 'shared/grpc/miraibaraiops/operator_api_pb'
import { passwordRegexp } from 'shared/lib/inputValidation'

import { useOperatorAddOperator } from '@/hooks/grpc'

const CreateOperator: React.FC<{
  onSuccess: () => void
  onCancel: () => void
}> = ({ onSuccess, onCancel }) => {
  const appContext = useContext(OperationAppContext)
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<AddOperatorRequest.AsObject>({
    mode: 'onSubmit',
  })
  const { call, response } = useOperatorAddOperator()
  const [isVisibleIdPw, setIdPwVisibility] = useState(true)
  const [isVisibleToken, setTokenVisibility] = useState(false)

  useEffect(() => {
    if (response) {
      if (response.token) {
        setTokenVisibility(true)
      } else {
        appContext.flashMessage('新しいオペレーターを追加しました', 'success')
        reset()
        onSuccess()
      }
    }
  }, [appContext, response, onSuccess, reset])

  return (
    <DialogForm
      title="オペレーターを追加する"
      submitButtonTitle="追加する"
      reset={reset}
      size="xs"
      onCancel={onCancel}
      onSubmit={handleSubmit(call)}
    >
      <TextField
        autoFocus
        margin="normal"
        label="名前"
        type="text"
        autoComplete="off"
        fullWidth
        error={!!errors.name}
        helperText={errors.loginId && '60文字以内で入力してください'}
        {...register('name', { required: true, maxLength: 60 })}
      />
      <FormControlLabel
        control={<Checkbox {...register('createApiToken')} onChange={(e) => setIdPwVisibility(!e.target.checked)} />}
        label="APIトークンを生成する"
      />
      {isVisibleIdPw && (
        <>
          <TextField
            margin="normal"
            label="ログインID"
            type="text"
            autoComplete="off"
            fullWidth
            error={!!errors.loginId}
            helperText={errors.loginId && '半角英数字とアンダースコアのみで入力してください(最大32字)'}
            {...register('loginId', { required: true, pattern: /^[a-zA-Z0-9_]{0,32}$/ })}
          />
          <TextField
            margin="normal"
            label="パスワード"
            type="password"
            fullWidth
            error={!!errors.password}
            helperText={errors.password && '英字、数字を含む7字以上の文字列を入力してください'}
            {...register('password', { required: true, pattern: passwordRegexp })}
          />
        </>
      )}
      {
        <Dialog open={isVisibleToken} onClose={onSuccess} maxWidth="xs">
          <DialogTitle>APIトークンを生成しました</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <pre>
                <code>{response?.token}</code>
              </pre>
              <br />
              生成された上記のトークンは再度確認することができません。確実に情報を控えてください。
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={onSuccess} color="primary">
              閉じる
            </Button>
          </DialogActions>
        </Dialog>
      }
    </DialogForm>
  )
}

export default CreateOperator
