import { AccountCircle, ChevronLeft, Menu as MenuIcon, SvgIconComponent } from '@mui/icons-material'
import {
  AppBar as MuiAppBar,
  AppBarProps as MuiAppBarProps,
  Badge,
  Box,
  Divider,
  Drawer as MuiDrawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material'
import { grey } from '@mui/material/colors'
import { CSSObject, styled, Theme } from '@mui/material/styles'
import { addDays, differenceInCalendarDays } from 'date-fns'
import React, { Fragment, useContext, useMemo, useState } from 'react'

import AccessControl from 'shared/components/AccessControl'
import ChangePassword, { ChangePasswordOperation } from 'shared/components/ChangePassword'
import { InternalLink } from 'shared/components/InternalLink'
import { context as OperationAppContext } from 'shared/context/OperationApp'
import useToggle from 'shared/hooks/useToggle'

// Link text, icon, path, allowed roles?, sub operations, sub header
export type Operation = [string, SvgIconComponent, string, number[]?, SubOperation[]?, string?]
type SubOperation = [string, SvgIconComponent, string]

// https://material-ui.com/components/drawers/#mini-variant-drawer
const drawerWidth = 256

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
})

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
})

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar, giving any to avoid overload error
  ...(theme.mixins.toolbar as any), //eslint-disable-line
}))

interface AppBarProps extends MuiAppBarProps {
  open?: boolean
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}))

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}))

const GlobalNavigation: React.FC<
  React.PropsWithChildren<{
    mainOperations: Operation[]
    subOperations?: Operation[]
    title: string
    additionalHeaderActions: React.ReactElement
    changePasswordOperation: ChangePasswordOperation
  }>
> = ({ additionalHeaderActions, children, mainOperations, subOperations = [], title, changePasswordOperation }) => {
  const [drawerOpened, setDrawerOpened] = useState(true)
  const [moreMenuAnchorEl, setMoreMenuAnchorEl] = useState<Element | null>(null)
  const [showChangePassword, setShowChangePassword] = useState(false)
  const [hadChangedPassword, { enable: passwordHadChanged }] = useToggle(false)
  const operationApp = useContext(OperationAppContext)

  const logout = () => {
    operationApp.setCurrentOperator(null)
  }

  const onChangePassword = () => {
    operationApp.flashMessage('パスワードを変更しました', 'success')
    passwordHadChanged()
    freeUpTarget()
  }

  const freeUpTarget = () => {
    setMoreMenuAnchorEl(null)
    setShowChangePassword(false)
  }

  const daysUntilPasswordExpires: number | 'updated' = useMemo(() => {
    if (hadChangedPassword) {
      return 'updated'
    } else if (operationApp.currentOperator?.lastPasswordUpdatedMs) {
      return differenceInCalendarDays(
        addDays(new Date(operationApp.currentOperator.lastPasswordUpdatedMs), 90),
        new Date(),
      )
    } else {
      return 0
    }
  }, [operationApp.currentOperator, hadChangedPassword])

  // Move to shared
  const linkFor = (text: string, Icon: SvgIconComponent, href: string, type: 'parent' | 'single' | 'child') => (
    <InternalLink href={href}>
      <ListItem
        component="a"
        button
        key={text}
        selected={
          ['single', 'child'].includes(type) &&
          window.location.pathname.replace(/(\/show)?\.html$/, '') === href.replace(/\?.+/, '')
        }
        style={type === 'child' ? { paddingLeft: '2em' } : {}}
      >
        <ListItemIcon>
          <Icon />
        </ListItemIcon>
        <ListItemText primary={text} disableTypography />
      </ListItem>
    </InternalLink>
  )

  const listFor = (operations: Operation[]) => (
    <List>
      {operations.map(([text, icon, href, roles, nested, subheader], index) => {
        return (
          <Fragment key={index}>
            <AccessControl allowedRoles={roles || []} suppressed>
              {subheader && <ListSubheader>{subheader}</ListSubheader>}
              {nested && nested.length > 0 ? (
                <>
                  {linkFor(text, icon, nested[0][2], 'parent')}
                  {nested.map(([childText, childIcon, childhref], childIndex) => (
                    <Fragment key={childIndex}>{linkFor(childText, childIcon, childhref, 'child')}</Fragment>
                  ))}
                </>
              ) : (
                <>{linkFor(text, icon, href, 'single')}</>
              )}
            </AccessControl>
          </Fragment>
        )
      })}
    </List>
  )

  return (
    <Box display="flex">
      <AppBar position="fixed" open={drawerOpened}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={() => setDrawerOpened(!drawerOpened)}
            size="large"
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" style={{ flexGrow: 1 }}>
            {title}
          </Typography>
          {additionalHeaderActions}
          <Tooltip title={operationApp.currentOperator?.name ?? ''}>
            <IconButton
              color="inherit"
              aria-label="menu-user"
              aria-controls="menu-user"
              aria-haspopup="true"
              onClick={(e) => setMoreMenuAnchorEl(e.currentTarget)}
              size="large"
            >
              <Badge
                color="error"
                badgeContent={typeof daysUntilPasswordExpires === 'number' && daysUntilPasswordExpires <= 30 ? '!!' : 0}
              >
                <AccountCircle />
              </Badge>
            </IconButton>
          </Tooltip>
          <Menu
            id="menu-user"
            keepMounted
            open={moreMenuAnchorEl !== null}
            onClose={freeUpTarget}
            anchorReference="anchorEl"
            anchorEl={moreMenuAnchorEl}
            sx={{ whiteSpace: 'unset', wordBreak: 'break-all' }}
          >
            <MenuItem onClick={() => setShowChangePassword(true)}>
              <ListItemText
                primary="パスワードを変更する"
                secondary={
                  <Typography variant="body2" color={grey[700]}>
                    {typeof daysUntilPasswordExpires !== 'number' ? (
                      '次回ログイン時に有効日数が表示されます'
                    ) : daysUntilPasswordExpires > 1 ? (
                      <>
                        現在のパスワードは残り{daysUntilPasswordExpires}日間有効です
                        <br />
                        有効な期間を過ぎるとアカウントが永久に停止されます
                      </>
                    ) : (
                      <>
                        パスワードをすぐに変更してください。
                        <br />
                        1日以内にアカウントが永久停止されます。
                      </>
                    )}
                  </Typography>
                }
              />
            </MenuItem>
            <MenuItem onClick={logout}>ログアウト</MenuItem>
          </Menu>
          {showChangePassword && (
            <ChangePassword operation={changePasswordOperation} onSuccess={onChangePassword} onCancel={freeUpTarget} />
          )}
        </Toolbar>
      </AppBar>
      <Drawer aria-label="All Contents" role="contentinfo" variant="permanent" open={drawerOpened}>
        <DrawerHeader>
          <IconButton onClick={() => setDrawerOpened(false)} size="large">
            <ChevronLeft />
          </IconButton>
        </DrawerHeader>
        <Divider />
        <>
          {listFor(mainOperations)}
          {mainOperations.length > 0 && subOperations.length > 0 && <Divider />}
          {listFor(subOperations)}
        </>
      </Drawer>
      <Box flexGrow={1} px={1} pt={8}>
        {children}
      </Box>
    </Box>
  )
}

export default GlobalNavigation
