import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import type { FieldValues, UseFormHandleSubmit, UseFormReset } from 'react-hook-form'

interface DialogFormProps<Form extends FieldValues> {
  title: string
  submitButtonTitle: string
  reset: UseFormReset<Form>
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
  onCancel: () => void
  onSubmit: ReturnType<UseFormHandleSubmit<Form>>
}

export function DialogForm<Form extends FieldValues>({
  title,
  submitButtonTitle,
  reset,
  size = 'sm',
  onCancel,
  onSubmit,
  children,
}: DialogFormProps<Form> & { children: React.ReactNode }): React.ReactElement {
  const cancel = () => {
    reset()
    onCancel()
  }

  return (
    <Dialog open maxWidth={size} fullWidth>
      <form onSubmit={onSubmit}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>{children}</DialogContent>
        <DialogActions>
          <Button onClick={cancel}>キャンセル</Button>
          <Button type="submit" color="primary">
            {submitButtonTitle}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}
