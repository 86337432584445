import { isFuture, isPast } from 'date-fns'

import { formatAsDatetime } from 'shared/lib/dateUtils'
import { formatAmount } from 'shared/lib/decorators'

const formatTable = (table: FeeTableItem) => ({
  from: formatAmount(table.amountFrom),
  to: formatAmount(table.amountTo),
  amount: formatAmount(table.feeAmount),
})

const rawFeeSetting = (setting: ElementOf<ListPostPaidTopUpFees['result']>['feeSetting']) => {
  let raw = ''
  setting.generalFeeTable.forEach((generalTable) => {
    raw += `general_fee_table { amount_from: ${generalTable.amountFrom} amount_to: ${generalTable.amountTo} fee_amount: ${generalTable.feeAmount} }\n`
  })
  setting.partnerFeeTables.forEach((partner) => {
    raw += `partner_fee_tables { partner_id: "${partner.partnerId}"\n`
    partner.table.forEach((partnerTable) => {
      raw += `table { amount_from: ${partnerTable.amountFrom} amount_to: ${partnerTable.amountTo} fee_amount: ${partnerTable.feeAmount} }\n`
    })
    raw += '}'
  })
  return raw
}

export const presentListPostPaidTopUpFees = ({ result }: ListPostPaidTopUpFees) => {
  const activeRuleApplyFromMs: number | null =
    result.filter((fee) => isPast(fee.applyFromMs)).sort((a, b) => b.applyFromMs - a.applyFromMs)[0]?.applyFromMs ??
    null
  const nextRuleApplyFromMs: number | null =
    result.filter((fee) => isFuture(fee.applyFromMs)).sort((a, b) => a.applyFromMs - b.applyFromMs)[0]?.applyFromMs ??
    null

  return result.map((postPaidTopUpFee) => {
    return {
      version: postPaidTopUpFee.applyFromMs,
      appliedFromDatetime: formatAsDatetime(postPaidTopUpFee.applyFromMs),
      createdDatetime: formatAsDatetime(postPaidTopUpFee.createdMs),
      isActive: activeRuleApplyFromMs === postPaidTopUpFee.applyFromMs,
      isFuture: isFuture(postPaidTopUpFee.applyFromMs),
      isImmediateNext: nextRuleApplyFromMs === postPaidTopUpFee.applyFromMs,
      isArchived: postPaidTopUpFee.applyFromMs < activeRuleApplyFromMs ?? 0,
      feeTables: [
        {
          partner: 'デフォルト',
          tables: postPaidTopUpFee.feeSetting.generalFeeTable.map(formatTable),
        },
        ...postPaidTopUpFee.feeSetting.partnerFeeTables.map((partnerTable) => ({
          partner: partnerTable.partnerId,
          tables: partnerTable.table.map(formatTable),
        })),
      ],
      rawFeeSetting: rawFeeSetting(postPaidTopUpFee.feeSetting),
    }
  })
}
