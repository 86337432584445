import { AvTimer, CheckCircle, Face, MonetizationOn, Storefront, SwapHorizontalCircle } from '@mui/icons-material'
import { Chip, Menu, MenuItem, TableCell, TableRow, Typography } from '@mui/material'
import React, { useMemo, useState } from 'react'

import AccessControl from 'shared/components/AccessControl'
import { InternalLink } from 'shared/components/InternalLink'
import PaginatableTable from 'shared/components/PaginatableTable'
import PaginatableTableHeader from 'shared/components/PaginatableTableHeader'
import { DebtCancelType } from 'shared/grpc/server/post_pay_pb'
import useDialog from 'shared/hooks/useDialog'
import usePagination from 'shared/hooks/usePagination'
import useRouteQuery from 'shared/hooks/useRouteQuery'
import { endOfDayInZone, formatAsCalendarDate, parseDateInput, startOfDayInZone } from 'shared/lib/dateUtils'
import { formatAmountE3 } from 'shared/lib/decorators'
import { useDecorativeColors } from 'shared/lib/styles'

import CancelPostPayDebt from '@/components/CancelPostPayDebt'
import FilterPostPayDebts, { PAYMENT_STATUS } from '@/components/FilterPostPayDebts'
import { useListPostPayDebtsQuery } from '@/hooks/graphql'
import { assumedRoles } from '@/hooks/graphql/queries/list_post_pay_debts.gql'

export const allowedRoles = assumedRoles

const PagePostPayDebts: React.FC = () => {
  const [query, updateQuery] = useRouteQuery([
    'debt-id',
    'partner-id',
    'app-user-id',
    'applied-start',
    'applied-end',
    'due-start',
    'due-end',
    'principal-amount-min',
    'principal-amount-max',
    'status',
  ])
  const filterCondition = useMemo(
    () => ({
      debtId: query['debt-id'],
      partnerId: query['partner-id'],
      appUserId: query['app-user-id'],
      appliedMs: {
        gte: query['applied-start'] ? startOfDayInZone(parseDateInput(query['applied-start'])).getTime() : null,
        lte: query['applied-end'] ? endOfDayInZone(parseDateInput(query['applied-end'])).getTime() : null,
      },
      dueMs: {
        gte: query['due-start'] ? startOfDayInZone(parseDateInput(query['due-start'])).getTime() : null,
        lte: query['due-end'] ? endOfDayInZone(parseDateInput(query['due-end'])).getTime() : null,
      },
      principalAmountE3: {
        gte: query['principal-amount-min'] ? Number(query['principal-amount-min']) * 1_000 : null,
        lte: query['principal-amount-max'] ? Number(query['principal-amount-max']) * 1_000 : null,
      },
      paidMsIsDefined: (() => {
        switch (Number(query.status)) {
          case PAYMENT_STATUS.PAID:
            return true
          case PAYMENT_STATUS.UNPAID:
            return false
          default:
            return null
        }
      })(),
      canceledMs:
        Number(query.status) === PAYMENT_STATUS.CANCELED_LEGACY
          ? {
              lte: startOfDayInZone(parseDateInput('2022-04-06')).getTime(),
            }
          : null,
      cancelType: (() => {
        switch (Number(query.status)) {
          case PAYMENT_STATUS.CANCELED:
            return DebtCancelType.DEBT_CANCEL_TYPE_CANCEL_UNINTENTIONAL_POSTPAY_APPLICATION
          case PAYMENT_STATUS.CANCELED_OPERATIONAL:
            return DebtCancelType.DEBT_CANCEL_TYPE_REGISTER_OPERATIONAL_RECEIPT
          default:
            return null
        }
      })(),
    }),
    [query],
  )

  const { skip, paginate, perPage, currentPage } = usePagination()
  const { data, presentation: postPayDebts, refetch } = useListPostPayDebtsQuery({ skip, ...filterCondition })
  const [currentDialog, { showDialog, hideDialog }] = useDialog<'filter' | 'cancel' | 'cancel-operational'>()
  const [selectedDebt, selectDebt] = useState<{ id: string; canceled: boolean } | null>(null)
  const [menuPosition, setMenuPosition] = useState<{ x: number; y: number } | null>(null)

  const buttonColorStyles = useDecorativeColors()

  const header = (
    <PaginatableTableHeader
      title="後払いチャージ一覧"
      onClickFilter={() => showDialog('filter')}
      pills={
        <>
          {query['debt-id'] && (
            <Chip
              label={query['debt-id']}
              icon={<SwapHorizontalCircle />}
              color="primary"
              onDelete={() => updateQuery({ ...query, 'debt-id': null })}
            />
          )}
          {query['partner-id'] && (
            <Chip
              label={query['partner-id']}
              icon={<Storefront />}
              color="secondary"
              onDelete={() => updateQuery({ ...query, 'partner-id': null })}
            />
          )}
          {query['app-user-id'] && (
            <Chip
              label={query['app-user-id']}
              icon={<Face />}
              color="primary"
              onDelete={() => updateQuery({ ...query, 'app-user-id': null })}
            />
          )}
          {query['applied-start'] && (
            <Chip
              label={`申請が ${formatAsCalendarDate(query['applied-start'])} 以降`}
              icon={<AvTimer />}
              color="default"
              onDelete={() => updateQuery({ ...query, 'applied-start': null })}
            />
          )}
          {query['applied-end'] && (
            <Chip
              label={`申請が ${formatAsCalendarDate(query['applied-end'])} 以前`}
              icon={<AvTimer />}
              color="default"
              onDelete={() => updateQuery({ ...query, 'applied-end': null })}
            />
          )}
          {query['due-start'] && (
            <Chip
              label={`期限が ${formatAsCalendarDate(query['due-start'])} 以降`}
              icon={<AvTimer />}
              color="secondary"
              onDelete={() => updateQuery({ ...query, 'due-start': null })}
            />
          )}
          {query['due-end'] && (
            <Chip
              label={`期限が ${formatAsCalendarDate(query['due-end'])} 以前`}
              icon={<AvTimer />}
              color="secondary"
              onDelete={() => updateQuery({ ...query, 'due-end': null })}
            />
          )}
          {query['principal-amount-min'] && (
            <Chip
              label={`${formatAmountE3(Number(query['principal-amount-min']) * 1_000)} 円以上`}
              icon={<MonetizationOn />}
              color="primary"
              onDelete={() => updateQuery({ ...query, 'principal-amount-min': null })}
            />
          )}
          {query['principal-amount-max'] && (
            <Chip
              label={`${formatAmountE3(Number(query['principal-amount-max']) * 1_000)} 円以下`}
              icon={<MonetizationOn />}
              color="primary"
              onDelete={() => updateQuery({ ...query, 'principal-amount-max': null })}
            />
          )}
          {query['status'] && (
            <Chip
              label={(() => {
                switch (Number(query.status)) {
                  case PAYMENT_STATUS.PAID:
                    return '返済済みのみ'
                  case PAYMENT_STATUS.UNPAID:
                    return '未返済のみ'
                  case PAYMENT_STATUS.CANCELED_LEGACY:
                    return '取消済み（2022/4/6 変更適用以前）'
                  case PAYMENT_STATUS.CANCELED:
                    return '取消済み（2022/4/6 変更適用以降）'
                  case PAYMENT_STATUS.CANCELED_OPERATIONAL:
                    return '入金消込済み'
                }
              })()}
              icon={<CheckCircle />}
              color="primary"
              onDelete={() => updateQuery({ ...query, status: null })}
            />
          )}
        </>
      }
    />
  )

  return (
    <AccessControl allowedRoles={allowedRoles}>
      <PaginatableTable
        page={currentPage}
        perPage={perPage}
        cols={['', '', '利用者', '金額', '手数料', '返済日程']}
        totalCount={data?.totalCount}
        onPaginate={paginate}
        header={header}
      >
        {postPayDebts &&
          postPayDebts.map((debt, index) => (
            <TableRow
              hover
              key={`${debt.id}-${currentPage}-${index}`}
              selected={debt.id === selectedDebt?.id}
              onClick={(e) => {
                if (document.getSelection()?.toString()) return
                selectDebt(debt)
                setMenuPosition({ x: e.clientX, y: e.clientY })
              }}
            >
              <TableCell>
                <Chip label={debt.status} {...(debt.statusColor ? { sx: buttonColorStyles[debt.statusColor] } : {})} />
              </TableCell>
              <TableCell>
                <strong>{debt.id}</strong>
                <br />
                {debt.partnerId}
              </TableCell>
              <TableCell>
                {debt.nameKanji}
                <br />
                <InternalLink text href={`/miraibarai-app-users/show?id=${debt.appUserId}`}>
                  <strong>{debt.appUserId}</strong>
                </InternalLink>
              </TableCell>
              <TableCell>{debt.principalAmount}</TableCell>
              <TableCell>{debt.feeAmount}</TableCell>
              <TableCell>
                {debt.events.map((event, i) => (
                  <React.Fragment key={i}>
                    {event.possible && (
                      <Typography color={event.color ?? ''} variant="body2">
                        {event.label}
                      </Typography>
                    )}
                    {!event.possible && (
                      <Typography color={event.color ?? 'textSecondary'} variant="body2">
                        <s>{event.label}</s>
                      </Typography>
                    )}
                  </React.Fragment>
                ))}
              </TableCell>
            </TableRow>
          ))}
      </PaginatableTable>
      {selectedDebt && (
        <Menu
          keepMounted
          open={menuPosition !== null}
          anchorReference="anchorPosition"
          onClose={() => {
            setMenuPosition(null)
            selectDebt(null)
          }}
          anchorPosition={menuPosition !== null ? { top: menuPosition.y, left: menuPosition.x } : undefined}
        >
          {!selectedDebt.canceled && <MenuItem onClick={() => showDialog('cancel')}>この取引を取消す</MenuItem>}
          {!selectedDebt.canceled && (
            <MenuItem onClick={() => showDialog('cancel-operational')}>この取引を入金消込として取消す</MenuItem>
          )}
          {selectedDebt.canceled && <MenuItem disabled>この取引は取消済みです</MenuItem>}
        </Menu>
      )}
      {currentDialog === 'filter' && (
        <FilterPostPayDebts
          defaultValues={{
            debtId: query['debt-id'] ?? '',
            partnerId: query['partner-id'] ?? '',
            appUserId: query['app-user-id'] ?? '',
            appliedStart: query['applied-start'] ?? '',
            appliedEnd: query['applied-end'] ?? '',
            dueStart: query['due-start'] ?? '',
            dueEnd: query['due-end'] ?? '',
            principalAmountMin: query['principal-amount-min'] ? Number(query['principal-amount-min']) : null,
            principalAmountMax: query['principal-amount-max'] ? Number(query['principal-amount-max']) : null,
            status: Number(query['status']),
          }}
          onSelect={(newFilter) => {
            updateQuery({
              'debt-id': newFilter.debtId,
              'partner-id': newFilter.partnerId,
              'app-user-id': newFilter.appUserId,
              'applied-start': newFilter.appliedStart,
              'applied-end': newFilter.appliedEnd,
              'due-start': newFilter.dueStart,
              'due-end': newFilter.dueEnd,
              'principal-amount-min': newFilter.principalAmountMin ? String(newFilter.principalAmountMin) : null,
              'principal-amount-max': newFilter.principalAmountMax ? String(newFilter.principalAmountMax) : null,
              status: newFilter.status ? String(newFilter.status) : null,
            })
            hideDialog()
          }}
          onCancel={hideDialog}
        />
      )}
      {currentDialog && ['cancel', 'cancel-operational'].includes(currentDialog) && selectedDebt && (
        <CancelPostPayDebt
          debtId={selectedDebt.id}
          operational={currentDialog === 'cancel-operational'}
          onSuccess={() => {
            hideDialog()
            selectDebt(null)
            refetch()
          }}
          onCancel={() => {
            hideDialog()
            selectDebt(null)
          }}
        />
      )}
    </AccessControl>
  )
}

export default PagePostPayDebts
