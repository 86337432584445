import { TextField } from '@mui/material'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'

import { DialogForm } from 'shared/components/DialogForm'
import { passwordRegexp } from 'shared/lib/inputValidation'

interface FormChangePassword {
  oldPassword: string
  newPassword: string
  newPasswordConfirmation: string
}

export type ChangePasswordOperation = () => {
  call: (params: { oldPassword: string; newPassword: string }) => Promise<void>
  response: { error?: { errorMessageJa: string } | null } | undefined
}

const ChangePassword: React.FC<{
  operation: ChangePasswordOperation
  onSuccess: () => void
  onCancel: () => void
}> = ({ onSuccess, onCancel, operation }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
  } = useForm<FormChangePassword>({
    mode: 'onSubmit',
  })
  const { call, response } = operation()

  const submit = (form: FormChangePassword) => {
    call({
      oldPassword: form.oldPassword,
      newPassword: form.newPassword,
    })
  }

  useEffect(() => {
    if (!response) return
    if (response.error) {
      console.error(response.error)
    } else {
      reset()
      onSuccess()
    }
  }, [response, onSuccess, reset])

  return (
    <DialogForm
      title="パスワードを変更する"
      submitButtonTitle="変更する"
      reset={reset}
      size="xs"
      onCancel={onCancel}
      onSubmit={handleSubmit(submit)}
    >
      <TextField
        autoFocus
        autoComplete="current-password"
        margin="normal"
        label="現在のパスワード"
        type="password"
        fullWidth
        error={!!errors.oldPassword}
        helperText={errors.oldPassword && '英字、数字を含む7字以上の文字列を入力してください'}
        {...register('oldPassword', { required: true, pattern: passwordRegexp })}
      />
      <TextField
        autoComplete="new-password"
        margin="normal"
        label="新しいパスワード"
        type="password"
        fullWidth
        error={!!errors.newPassword}
        helperText={errors.newPassword && '英字、数字を含む7字以上の文字列を入力してください'}
        {...register('newPassword', { required: true, pattern: passwordRegexp })}
      />
      <TextField
        autoComplete="new-password"
        margin="normal"
        label="パスワードの再入力"
        type="password"
        fullWidth
        error={!!errors.newPasswordConfirmation}
        helperText={errors.newPasswordConfirmation && '入力されたパスワードが一致していません'}
        {...register('newPasswordConfirmation', {
          required: true,
          validate: { match: (passworConfirmation) => passworConfirmation === getValues('newPassword') },
        })}
      />
    </DialogForm>
  )
}

export default ChangePassword
