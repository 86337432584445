import { useContext, useEffect } from 'react'

import DialogConfirmation from 'shared/components/DialogConfirmation'
import { context as OperationAppContext } from 'shared/context/OperationApp'

import { useOperatorSuspendOperator } from '@/hooks/grpc'

const SuspendOperator: React.FC<{
  operator: ElementOf<ListOperators['result']>
  onSuccess: () => void
  onCancel: () => void
}> = ({ operator, onSuccess, onCancel }) => {
  const appContext = useContext(OperationAppContext)
  const { call, response } = useOperatorSuspendOperator()

  const suspend = () => {
    call({ targetOperatorId: operator.operatorId })
  }

  const cancel = () => {
    onCancel()
  }

  useEffect(() => {
    if (response && response.error === undefined) {
      appContext.flashMessage(`${operator.name} を凍結しました`, 'success')
      onSuccess()
    }
  }, [appContext, operator, response, onSuccess])

  return (
    <DialogConfirmation
      open={true}
      title="凍結する"
      message={`${
        operator.loginId === null ? operator.name : operator.loginId
      } の利用を凍結します。\nこのオペレーターは管理画面へログインできなくなります。後悔はありませんか？`}
      onCancel={cancel}
      onConfirm={suspend}
    />
  )
}

export default SuspendOperator
