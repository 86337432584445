import { SvgIconComponent } from '@mui/icons-material'
import { IconButton, Link as MaterialLink } from '@mui/material'
import Link from 'next/link'
import { useMemo } from 'react'

export const InternalLink: React.FC<
  React.PropsWithChildren<{ href: string; text?: boolean; SvgIconButton?: SvgIconComponent }>
> = ({ children, href, text = false, SvgIconButton }) => {
  const linkProps = useMemo(() => {
    const [path, ...rest] = href.split('?')
    const as = rest.length === 0 ? `${href}.html` : `${path}.html?${rest}`
    return { href, as, passHref: true, legacyBehavior: true }
  }, [href])

  const content = useMemo(() => {
    if (SvgIconButton) {
      return (
        <IconButton edge="end" size="large">
          <SvgIconButton />
        </IconButton>
      )
    } else if (text) {
      return <MaterialLink sx={{ textDecoration: 'none' }}>{children}</MaterialLink>
    } else {
      return children
    }
  }, [SvgIconButton, children, text])

  return <Link {...linkProps}>{content}</Link>
}
