import { Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel } from '@mui/material'
import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'

import { DialogForm } from 'shared/components/DialogForm'
import { OperationRole } from 'shared/grpc/miraibaraiops/ops_common_pb'

import { operatorRoleNames } from '@/lib/models'

export interface FilterOperatorsCondition {
  roles: OperationRole[]
  operatorId: string
}

const possibleRoles = Object.values(OperationRole).filter(
  (r, i): r is OperationRole => i !== 0 && typeof r !== 'string',
)

const FilterOperators: React.FC<{
  onSelect: (form: FilterOperatorsCondition) => void
  onCancel: () => void
}> = ({ onSelect, onCancel }) => {
  const [selectedRoles, setSelectedRoles] = useState<OperationRole[]>([])
  const { handleSubmit, reset, control } = useForm<FilterOperatorsCondition>({
    mode: 'onSubmit',
  })

  const submit = (form: FilterOperatorsCondition) => {
    onSelect(form)
  }

  const handleCheck = (role: OperationRole, checked: boolean) => {
    let newRoles: OperationRole[] = []
    if (checked && !selectedRoles.includes(role)) {
      newRoles = [...selectedRoles, role]
    }
    if (!checked) {
      newRoles = newRoles.filter((newRole) => newRole === role)
    }

    setSelectedRoles(newRoles)
    return newRoles
  }

  return (
    <DialogForm
      title="オペレーターを絞り込む"
      submitButtonTitle="この条件で絞り込む"
      reset={reset}
      size="xs"
      onCancel={onCancel}
      onSubmit={handleSubmit(submit)}
    >
      <FormControl fullWidth margin="normal">
        <FormLabel component="legend">役割</FormLabel>
        <FormGroup row>
          <Controller
            name="roles"
            control={control}
            render={({ field }) => (
              <>
                {possibleRoles.map((role, index) => (
                  <FormControlLabel
                    key={index}
                    label={operatorRoleNames[role]}
                    control={
                      <Checkbox
                        {...field}
                        checked={selectedRoles.includes(role as OperationRole)}
                        onChange={(_, checked) => {
                          field.onChange(handleCheck(role, checked))
                        }}
                      />
                    }
                  />
                ))}
              </>
            )}
          />
        </FormGroup>
      </FormControl>
    </DialogForm>
  )
}

export default FilterOperators
