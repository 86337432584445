import { AccountCircle, SupervisedUserCircle } from '@mui/icons-material'
import { Button, Chip, Menu, MenuItem, TableCell, TableRow } from '@mui/material'
import { MouseEvent, useMemo, useState } from 'react'

import AccessControl from 'shared/components/AccessControl'
import PaginatableTable from 'shared/components/PaginatableTable'
import PaginatableTableHeader from 'shared/components/PaginatableTableHeader'
import useRouteQuery from 'shared/hooks/useRouteQuery'

import ChangeOtherOperatorPassword from '@/components/operators/ChangeOtherOperatorPassword'
import CreateOperator from '@/components/operators/CreateOperator'
import EditRoles from '@/components/operators/EditRoles'
import ExportOperatorLogs from '@/components/operators/ExportOperatorLogs'
import FilterOperators, { FilterOperatorsCondition } from '@/components/operators/FilterOperators'
import ResumeOperator from '@/components/operators/ResumeOperator'
import SuspendOperator from '@/components/operators/SuspendOperator'
import { useListOperatorsQuery } from '@/hooks/graphql'
import { assumedRoles } from '@/hooks/graphql/queries/list_operators.gql'

/*
 * Since operators model doesn't support filter by role,
 * this page fetches all operators(hopefully) and filter in this page's end
 * not relying on common pagination logic at all
 */
const NUM_OF_OPERATORS_PER_SINGLE_FETCH = 1_000

const OperatorRole = [
  ['', 'default'],
  ['オペレーター管理', 'default'],
  ['カスタマーサポート', 'primary'],
  ['サービス管理', 'secondary'],
] as const

export const allowedRoles = assumedRoles

// TODO: Share operators table logic with Epre
const PageOperators: React.VFC = () => {
  const [query, updateQuery] = useRouteQuery(['operator-id', 'roles'])
  const [menuPosition, setMenuPosition] = useState<{ x: number; y: number } | null>(null)
  const [targetOperator, setTargetOperator] = useState<ElementOf<ListOperators['result']> | null>(null)
  const [dialogType, setDialogType] = useState<
    'suspend' | 'resume' | 'role' | 'password' | 'create' | 'audit' | 'filter' | null
  >(null)
  const { data, refetch } = useListOperatorsQuery({
    skip: 0,
    limit: NUM_OF_OPERATORS_PER_SINGLE_FETCH,
    operatorId: query['operator-id'],
  })

  const selectOperator = (event: MouseEvent, operator: ElementOf<ListOperators['result']>) => {
    setTargetOperator(operator)
    setMenuPosition({ x: event.clientX, y: event.clientY })
  }

  const refetchAndFreeUpTarget = () => {
    refetch()
    freeUpTarget()
  }

  const freeUpTarget = () => {
    setMenuPosition(null)
    setTargetOperator(null)
    setDialogType(null)
  }

  const filterOperators = (condition: FilterOperatorsCondition) => {
    updateQuery({ roles: condition.roles.map(String).join(','), 'operator-id': condition.operatorId })
    setDialogType(null)
  }

  const operators = useMemo(() => {
    return data && query.roles
      ? data.result.filter((operator) =>
          operator.operatorRoles.some(
            (operatorRole) =>
              query.roles && query.roles.split(',').map(Number).includes(operatorRole.operationRole.value),
          ),
        )
      : data?.result
  }, [data, query])

  const header = (
    <PaginatableTableHeader
      title="オペレーター一覧"
      onClickFilter={() => setDialogType('filter')}
      pills={
        <>
          {query['operator-id'] && (
            <Chip
              label={query['operator-id']}
              icon={<AccountCircle />}
              color="secondary"
              onDelete={() => updateQuery({ ...query, 'operator-id': null })}
            />
          )}
          {query.roles &&
            query.roles.split(',').map((role, i) => (
              <Chip
                key={i}
                label={OperatorRole[Number(role)][0]}
                icon={<SupervisedUserCircle />}
                color="default"
                onDelete={() =>
                  updateQuery({
                    ...query,
                    roles:
                      (query.roles as string)
                        .split(',')
                        .filter((r) => r !== role)
                        .join(',') || null,
                  })
                }
              />
            ))}
        </>
      }
      actions={
        <Button variant="contained" color="primary" onClick={() => setDialogType('create')}>
          追加する
        </Button>
      }
    />
  )

  return (
    <AccessControl allowedRoles={allowedRoles}>
      <PaginatableTable
        page={1}
        perPage={NUM_OF_OPERATORS_PER_SINGLE_FETCH}
        cols={['オペレーター', 'ログインID', '権限']}
        totalCount={operators?.length ?? 0}
        onPaginate={() => {
          /* no-op */
        }}
        header={header}
      >
        {operators &&
          operators.map((operator) => (
            <TableRow
              hover
              key={operator.operatorId}
              selected={targetOperator === operator}
              onClick={(e) => {
                if (document.getSelection()?.toString()) return
                selectOperator(e, operator)
              }}
              style={operator.suspendedMs && targetOperator !== operator ? { background: '#eee' } : {}}
            >
              <TableCell align="left">
                <strong>{operator.name}</strong>
                <br />
                {operator.operatorId}
              </TableCell>
              <TableCell align="left">
                {operator.loginId ?? 'API利用'}
                <br />
                {operator.suspendedMs && '凍結済み'}
              </TableCell>
              <TableCell align="left">
                {operator.operatorRoles.map((role, index) => {
                  return (
                    <Chip
                      key={index}
                      label={`${OperatorRole[role.operationRole.value][0]}@${role.ipRangeId}`}
                      color={OperatorRole[role.operationRole.value][1]}
                      size="small"
                      style={{ margin: '2px' }}
                    />
                  )
                })}
              </TableCell>
            </TableRow>
          ))}
      </PaginatableTable>
      <Menu
        keepMounted
        open={menuPosition !== null}
        anchorReference="anchorPosition"
        onClose={freeUpTarget}
        anchorPosition={menuPosition !== null ? { top: menuPosition.y, left: menuPosition.x } : undefined}
      >
        {targetOperator?.suspendedMs === null ? (
          <MenuItem onClick={() => setDialogType('suspend')}>凍結する</MenuItem>
        ) : (
          <MenuItem onClick={() => setDialogType('resume')}>凍結を解除する</MenuItem>
        )}
        <MenuItem onClick={() => setDialogType('role')}>拠点や権限を編集する</MenuItem>
        {targetOperator?.loginId !== null && (
          <MenuItem onClick={() => setDialogType('password')}>パスワードを変更する</MenuItem>
        )}
        <MenuItem onClick={() => setDialogType('audit')}>このオペレーターの操作履歴を出力する</MenuItem>
      </Menu>
      {targetOperator && dialogType === 'suspend' && (
        <SuspendOperator operator={targetOperator} onSuccess={refetchAndFreeUpTarget} onCancel={freeUpTarget} />
      )}
      {targetOperator && dialogType === 'resume' && (
        <ResumeOperator operator={targetOperator} onSuccess={refetchAndFreeUpTarget} onCancel={freeUpTarget} />
      )}
      {data && targetOperator && dialogType === 'role' && (
        <EditRoles
          allIpRanges={data.allIPRanges}
          operator={targetOperator}
          onSuccess={refetchAndFreeUpTarget}
          onCancel={freeUpTarget}
        />
      )}
      {targetOperator && dialogType === 'password' && (
        <ChangeOtherOperatorPassword
          operator={targetOperator}
          onSuccess={refetchAndFreeUpTarget}
          onCancel={freeUpTarget}
        />
      )}
      {dialogType === 'create' && <CreateOperator onSuccess={refetchAndFreeUpTarget} onCancel={freeUpTarget} />}
      {targetOperator && dialogType === 'audit' && (
        <ExportOperatorLogs operator={targetOperator} onSuccess={refetchAndFreeUpTarget} onCancel={freeUpTarget} />
      )}
      {dialogType === 'filter' && <FilterOperators onSelect={filterOperators} onCancel={freeUpTarget} />}
    </AccessControl>
  )
}

export default PageOperators
