import AccessControl from 'shared/components/AccessControl'
import PaginatableTableOperatorLogin from 'shared/components/PaginatableTableOperatorLogin'
import usePagination from 'shared/hooks/usePagination'

import { useListOperatorLoginLogsQuery } from '@/hooks/graphql'
import { assumedRoles } from '@/hooks/graphql/queries/list_operator_login_logs.gql'

export const allowedRoles = assumedRoles

const PageOperatorLogsLogin: React.VFC = () => {
  const { skip, paginate, perPage, currentPage } = usePagination(100)
  const { data } = useListOperatorLoginLogsQuery({ skip, limit: perPage, sort_field: 'executedMs', desc: true })
  return (
    <AccessControl allowedRoles={allowedRoles}>
      <PaginatableTableOperatorLogin data={data} perPage={perPage} currentPage={currentPage} paginate={paginate} />
    </AccessControl>
  )
}

export default PageOperatorLogsLogin
