import { FindInPage } from '@mui/icons-material'
import { IconButton, Toolbar } from '@mui/material'
import { styled } from '@mui/material/styles'

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  '& .MuiChip-root': {
    margin: theme.spacing(0.5),
  },
}))

const PaginatableTableHeader: React.FC<{
  title: string
  onClickFilter?: () => void
  pills?: JSX.Element
  actions?: JSX.Element
}> = ({ title, onClickFilter, pills = <></>, actions = <></> }) => {
  return (
    <Toolbar
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <DrawerHeader>
        <p>{title}</p>
        {onClickFilter && (
          <IconButton onClick={onClickFilter} size="large">
            <FindInPage color="primary" />
          </IconButton>
        )}
        {pills}
      </DrawerHeader>
      {actions}
    </Toolbar>
  )
}

export default PaginatableTableHeader
